export default [
  {
    "iata": "ARY",
    "city": "",
    "region": "Australia",
    "tz": "Australia/Melbourne"
  },
  {
    "iata": "BLN",
    "city": "",
    "region": "Australia",
    "tz": "Australia/Melbourne"
  },
  {
    "iata": "BWQ",
    "city": "",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "BZD",
    "city": "",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "CMD",
    "city": "",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "CWW",
    "city": "",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "CYG",
    "city": "",
    "region": "Australia",
    "tz": "Australia/Melbourne"
  },
  {
    "iata": "DRN",
    "city": "",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "DYA",
    "city": "",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "ECH",
    "city": "",
    "region": "Australia",
    "tz": "Australia/Melbourne"
  },
  {
    "iata": "GUH",
    "city": "",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "HTU",
    "city": "",
    "region": "Australia",
    "tz": "Australia/Melbourne"
  },
  {
    "iata": "HXX",
    "city": "",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "KGY",
    "city": "",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "KPS",
    "city": "",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "KRA",
    "city": "",
    "region": "Australia",
    "tz": "Australia/Melbourne"
  },
  {
    "iata": "MRG",
    "city": "",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "NGA",
    "city": "",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "PPI",
    "city": "",
    "region": "Australia",
    "tz": "Australia/Adelaide"
  },
  {
    "iata": "QRM",
    "city": "",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "QRR",
    "city": "",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "RPM",
    "city": "",
    "region": "Australia",
    "tz": "Australia/Darwin"
  },
  {
    "iata": "SIO",
    "city": "",
    "region": "Australia",
    "tz": "Australia/Hobart"
  },
  {
    "iata": "SNB",
    "city": "",
    "region": "Australia",
    "tz": "Australia/Darwin"
  },
  {
    "iata": "SWC",
    "city": "",
    "region": "Australia",
    "tz": "Australia/Melbourne"
  },
  {
    "iata": "TUM",
    "city": "",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "TYB",
    "city": "",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "WGT",
    "city": "",
    "region": "Australia",
    "tz": "Australia/Melbourne"
  },
  {
    "iata": "WKB",
    "city": "",
    "region": "Australia",
    "tz": "Australia/Melbourne"
  },
  {
    "iata": "CVC",
    "city": "",
    "region": "Georgia",
    "tz": "Australia/Adelaide"
  },
  {
    "iata": "RTC",
    "city": "",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "UUN",
    "city": "",
    "region": "Mongolia",
    "tz": "Asia/Choibalsan"
  },
  {
    "iata": "BPH",
    "city": "",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "MXI",
    "city": "",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "VLK",
    "city": "",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "KMX",
    "city": "",
    "region": "Saudi Arabia",
    "tz": "Asia/Riyadh"
  },
  {
    "iata": "AAH",
    "city": "Aachen",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "AAL",
    "city": "Aalborg",
    "region": "Denmark",
    "tz": "Europe/Copenhagen"
  },
  {
    "iata": "AAR",
    "city": "Aarhus",
    "region": "Denmark",
    "tz": "Europe/Copenhagen"
  },
  {
    "iata": "JEG",
    "city": "Aasiaat",
    "region": "Greenland",
    "tz": "America/Godthab"
  },
  {
    "iata": "ABD",
    "city": "Abadan",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "ABF",
    "city": "Abaiang Atoll",
    "region": "Kiribati",
    "tz": "Pacific/Tarawa"
  },
  {
    "iata": "ABA",
    "city": "Abakan",
    "region": "Russia",
    "tz": "Asia/Krasnoyarsk"
  },
  {
    "iata": "YXX",
    "city": "Abbotsford",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "AEH",
    "city": "Abeche",
    "region": "Chad",
    "tz": "Africa/Ndjamena"
  },
  {
    "iata": "AEA",
    "city": "Abemama",
    "region": "Kiribati",
    "tz": "Pacific/Tarawa"
  },
  {
    "iata": "APG",
    "city": "Aberdeen",
    "region": "Maryland",
    "tz": "America/New_York"
  },
  {
    "iata": "ABR",
    "city": "Aberdeen",
    "region": "South Dakota",
    "tz": "America/Chicago"
  },
  {
    "iata": "ABZ",
    "city": "Aberdeen",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "AHB",
    "city": "Abha",
    "region": "Saudi Arabia",
    "tz": "Asia/Riyadh"
  },
  {
    "iata": "ABJ",
    "city": "Abidjan",
    "region": "Cote d'Ivoire",
    "tz": "Africa/Abidjan"
  },
  {
    "iata": "ABI",
    "city": "Abilene",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "DYS",
    "city": "Abilene",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "MTV",
    "city": "Ablow",
    "region": "Vanuatu",
    "tz": "Pacific/Efate"
  },
  {
    "iata": "AUH",
    "city": "Abu Dhabi",
    "region": "United Arab Emirates",
    "tz": "Asia/Dubai"
  },
  {
    "iata": "AZI",
    "city": "Abu Dhabi",
    "region": "United Arab Emirates",
    "tz": "Asia/Dubai"
  },
  {
    "iata": "ABS",
    "city": "Abu Simbel",
    "region": "Egypt",
    "tz": "Africa/Cairo"
  },
  {
    "iata": "ABV",
    "city": "Abuja",
    "region": "Nigeria",
    "tz": "Africa/Lagos"
  },
  {
    "iata": "ACD",
    "city": "Acandi",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "ACA",
    "city": "Acapulco",
    "region": "Mexico",
    "tz": "America/Mexico_City"
  },
  {
    "iata": "AGV",
    "city": "Acarigua",
    "region": "Venezuela",
    "tz": "America/Caracas"
  },
  {
    "iata": "ACC",
    "city": "Accra",
    "region": "Ghana",
    "tz": "Africa/Accra"
  },
  {
    "iata": "ACS",
    "city": "Achinsk",
    "region": "Russia",
    "tz": "Asia/Krasnoyarsk"
  },
  {
    "iata": "ADK",
    "city": "Adak Island",
    "region": "Alaska",
    "tz": "America/Adak"
  },
  {
    "iata": "ADA",
    "city": "Adana",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "UAB",
    "city": "Adana",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "ADD",
    "city": "Addis Ababa",
    "region": "Ethiopia",
    "tz": "Africa/Addis_Ababa"
  },
  {
    "iata": "ADS",
    "city": "Addison",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "ADL",
    "city": "Adelaide",
    "region": "Australia",
    "tz": "Australia/Adelaide"
  },
  {
    "iata": "ADE",
    "city": "Aden",
    "region": "Yemen",
    "tz": "Asia/Aden"
  },
  {
    "iata": "ADF",
    "city": "Adiyaman",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "AZR",
    "city": "Adrar",
    "region": "Algeria",
    "tz": "Africa/Algiers"
  },
  {
    "iata": "AFT",
    "city": "Afutara",
    "region": "Solomon Islands",
    "tz": "Pacific/Guadalcanal"
  },
  {
    "iata": "AFY",
    "city": "Afyon",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "AJY",
    "city": "Agadez",
    "region": "Niger",
    "tz": "Africa/Niamey"
  },
  {
    "iata": "AGA",
    "city": "Agadir",
    "region": "Morocco",
    "tz": "Africa/Casablanca"
  },
  {
    "iata": "GUM",
    "city": "Agana",
    "region": "Guam",
    "tz": "Pacific/Guam"
  },
  {
    "iata": "IXA",
    "city": "Agartala",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "AGX",
    "city": "Agatti Island",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "AGF",
    "city": "Agen",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "AGZ",
    "city": "Aggeneys",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "AGR",
    "city": "Agra",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "AJI",
    "city": "Agri",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "AGQ",
    "city": "Agrinion",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "BQN",
    "city": "Aguadilla",
    "region": "Puerto Rico",
    "tz": "America/Puerto_Rico"
  },
  {
    "iata": "AGU",
    "city": "Aguascalientes",
    "region": "Mexico",
    "tz": "America/Mexico_City"
  },
  {
    "iata": "AGJ",
    "city": "Aguni",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "AHE",
    "city": "Ahe",
    "region": "French Polynesia",
    "tz": "Pacific/Tahiti"
  },
  {
    "iata": "XIJ",
    "city": "Ahmed Al Jaber AB",
    "region": "Kuwait",
    "tz": "Asia/Kuwait"
  },
  {
    "iata": "AMD",
    "city": "Ahmedabad",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "AHS",
    "city": "Ahuas",
    "region": "Honduras",
    "tz": "America/Tegucigalpa"
  },
  {
    "iata": "AWZ",
    "city": "Ahwaz",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "AIK",
    "city": "Aiken",
    "region": "South Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "WSY",
    "city": "Airlie Beach",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "AIT",
    "city": "Aitutaki",
    "region": "Cook Islands",
    "tz": "Pacific/Rarotonga"
  },
  {
    "iata": "QXB",
    "city": "Aix-les-milles",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "AJL",
    "city": "Aizwal",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "AJA",
    "city": "Ajaccio",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "MFX",
    "city": "Ajaccio",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "AKK",
    "city": "Akhiok",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "AKI",
    "city": "Akiak",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "AXT",
    "city": "Akita",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "LAK",
    "city": "Aklavik",
    "region": "Canada",
    "tz": "America/Yellowknife"
  },
  {
    "iata": "AKD",
    "city": "Akola",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "AKO",
    "city": "Akron",
    "region": "Colorado",
    "tz": "America/Denver"
  },
  {
    "iata": "AKC",
    "city": "Akron",
    "region": "Ohio",
    "tz": "America/New_York"
  },
  {
    "iata": "CAK",
    "city": "Akron",
    "region": "Ohio",
    "tz": "America/New_York"
  },
  {
    "iata": "AKT",
    "city": "Akrotiri",
    "region": "Cyprus",
    "tz": "Asia/Nicosia"
  },
  {
    "iata": "AKU",
    "city": "Aksu",
    "region": "China",
    "tz": "Asia/Kashgar"
  },
  {
    "iata": "SCO",
    "city": "Aktau",
    "region": "Kazakhstan",
    "tz": "Asia/Aqtau"
  },
  {
    "iata": "AKX",
    "city": "Aktyubinsk",
    "region": "Kazakhstan",
    "tz": "Asia/Aqtobe"
  },
  {
    "iata": "AKV",
    "city": "Akulivik",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "AKR",
    "city": "Akure",
    "region": "Nigeria",
    "tz": "Africa/Lagos"
  },
  {
    "iata": "AEY",
    "city": "Akureyri",
    "region": "Iceland",
    "tz": "Atlantic/Reykjavik"
  },
  {
    "iata": "KQA",
    "city": "Akutan",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "AAN",
    "city": "Al Ain",
    "region": "United Arab Emirates",
    "tz": "Asia/Dubai"
  },
  {
    "iata": "LAQ",
    "city": "Al Bayda'",
    "region": "Libya",
    "tz": "Africa/Tripoli"
  },
  {
    "iata": "AAY",
    "city": "Al Ghaidah Intl",
    "region": "Yemen",
    "tz": "Asia/Aden"
  },
  {
    "iata": "AHU",
    "city": "Al Hociema",
    "region": "Morocco",
    "tz": "Africa/Casablanca"
  },
  {
    "iata": "AJF",
    "city": "Al-Jawf",
    "region": "Saudi Arabia",
    "tz": "Asia/Riyadh"
  },
  {
    "iata": "HOF",
    "city": "Al-ahsa",
    "region": "Saudi Arabia",
    "tz": "Asia/Riyadh"
  },
  {
    "iata": "AUK",
    "city": "Alakanuk",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "ALM",
    "city": "Alamogordo",
    "region": "New Mexico",
    "tz": "America/Denver"
  },
  {
    "iata": "HMN",
    "city": "Alamogordo",
    "region": "New Mexico",
    "tz": "America/Denver"
  },
  {
    "iata": "ALS",
    "city": "Alamosa",
    "region": "Colorado",
    "tz": "America/Denver"
  },
  {
    "iata": "GZP",
    "city": "Alanya",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "ALH",
    "city": "Albany",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "ABY",
    "city": "Albany",
    "region": "Georgia",
    "tz": "America/New_York"
  },
  {
    "iata": "ALB",
    "city": "Albany",
    "region": "New York",
    "tz": "America/New_York"
  },
  {
    "iata": "ALL",
    "city": "Albenga",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "LBI",
    "city": "Albi",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "ABN",
    "city": "Albina",
    "region": "Suriname",
    "tz": "America/Paramaribo"
  },
  {
    "iata": "ABQ",
    "city": "Albuquerque",
    "region": "New Mexico",
    "tz": "America/Denver"
  },
  {
    "iata": "ABX",
    "city": "Albury",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "ADH",
    "city": "Aldan",
    "region": "Russia",
    "tz": "Asia/Yakutsk"
  },
  {
    "iata": "ACI",
    "city": "Alderney",
    "region": "Guernsey",
    "tz": "Europe/Guernsey"
  },
  {
    "iata": "WKK",
    "city": "Aleknagik",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "ALP",
    "city": "Aleppo",
    "region": "Syria",
    "tz": "Asia/Damascus"
  },
  {
    "iata": "YLT",
    "city": "Alert",
    "region": "Canada",
    "tz": "America/Pangnirtung"
  },
  {
    "iata": "YAL",
    "city": "Alert Bay",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "AES",
    "city": "Alesund",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "ALJ",
    "city": "Alexander Bay",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "ALX",
    "city": "Alexander City",
    "region": "Alabama",
    "tz": "America/Chicago"
  },
  {
    "iata": "ALR",
    "city": "Alexandra",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "ALY",
    "city": "Alexandria",
    "region": "Egypt",
    "tz": "Africa/Cairo"
  },
  {
    "iata": "HBE",
    "city": "Alexandria",
    "region": "Egypt",
    "tz": "Africa/Cairo"
  },
  {
    "iata": "AEX",
    "city": "Alexandria",
    "region": "Louisiana",
    "tz": "America/Chicago"
  },
  {
    "iata": "ESF",
    "city": "Alexandria",
    "region": "Louisiana",
    "tz": "America/Chicago"
  },
  {
    "iata": "AXN",
    "city": "Alexandria",
    "region": "Minnesota",
    "tz": "America/Chicago"
  },
  {
    "iata": "AXD",
    "city": "Alexandroupolis",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "AHO",
    "city": "Alghero",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "ALG",
    "city": "Algier",
    "region": "Algeria",
    "tz": "Africa/Algiers"
  },
  {
    "iata": "ALC",
    "city": "Alicante",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "ALI",
    "city": "Alice",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "ASP",
    "city": "Alice Springs",
    "region": "Australia",
    "tz": "Australia/Darwin"
  },
  {
    "iata": "BIM",
    "city": "Alice Town",
    "region": "Bahamas",
    "tz": "America/Nassau"
  },
  {
    "iata": "IXD",
    "city": "Allahabad",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "AET",
    "city": "Allakaket",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "ADY",
    "city": "Alldays",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "ABE",
    "city": "Allentown",
    "region": "Pennsylvania",
    "tz": "America/New_York"
  },
  {
    "iata": "AIA",
    "city": "Alliance",
    "region": "Nebraska",
    "tz": "America/Denver"
  },
  {
    "iata": "LLU",
    "city": "Alluitsup Paa",
    "region": "Greenland",
    "tz": "America/Godthab"
  },
  {
    "iata": "YTF",
    "city": "Alma",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "ALA",
    "city": "Alma-ata",
    "region": "Kazakhstan",
    "tz": "Asia/Almaty"
  },
  {
    "iata": "LEI",
    "city": "Almeria",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "IUE",
    "city": "Alofi",
    "region": "Niue",
    "tz": "Pacific/Niue"
  },
  {
    "iata": "ARD",
    "city": "Alor Island",
    "region": "Indonesia",
    "tz": "Asia/Makassar"
  },
  {
    "iata": "AOR",
    "city": "Alor Setar",
    "region": "Malaysia",
    "tz": "Asia/Kuala_Lumpur"
  },
  {
    "iata": "APN",
    "city": "Alpena",
    "region": "Michigan",
    "tz": "America/Detroit"
  },
  {
    "iata": "ABH",
    "city": "Alpha",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "ALF",
    "city": "Alta",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "AFL",
    "city": "Alta Floresta",
    "region": "Brazil",
    "tz": "America/Cuiaba"
  },
  {
    "iata": "LTI",
    "city": "Altai",
    "region": "Mongolia",
    "tz": "Asia/Hovd"
  },
  {
    "iata": "ATM",
    "city": "Altamira",
    "region": "Brazil",
    "tz": "America/Manaus"
  },
  {
    "iata": "AOC",
    "city": "Altenburg",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "ACH",
    "city": "Altenrhein",
    "region": "Switzerland",
    "tz": "Europe/Zurich"
  },
  {
    "iata": "ARR",
    "city": "Alto Rio Senguer",
    "region": "Argentina",
    "tz": "America/Argentina/Catamarca"
  },
  {
    "iata": "ALN",
    "city": "Alton/St Louis",
    "region": "Illinois",
    "tz": "America/Chicago"
  },
  {
    "iata": "AOO",
    "city": "Altoona",
    "region": "Pennsylvania",
    "tz": "America/New_York"
  },
  {
    "iata": "LTS",
    "city": "Altus",
    "region": "Oklahoma",
    "tz": "America/Chicago"
  },
  {
    "iata": "ALU",
    "city": "Alula",
    "region": "Somalia",
    "tz": "Africa/Mogadishu"
  },
  {
    "iata": "AMC",
    "city": "Am Timan",
    "region": "Chad",
    "tz": "Africa/Ndjamena"
  },
  {
    "iata": "AXJ",
    "city": "Amakusa",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "ASJ",
    "city": "Amami",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "AMA",
    "city": "Amarillo",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "ATF",
    "city": "Ambato",
    "region": "Ecuador",
    "tz": "America/Guayaquil"
  },
  {
    "iata": "WAM",
    "city": "Ambatondrazaka",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "ABM",
    "city": "Amberley",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "AMB",
    "city": "Ambilobe",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "ABL",
    "city": "Ambler",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "ASV",
    "city": "Amboseli National Park",
    "region": "Kenya",
    "tz": "Africa/Nairobi"
  },
  {
    "iata": "ULB",
    "city": "Ambryn Island",
    "region": "Vanuatu",
    "tz": "Pacific/Efate"
  },
  {
    "iata": "AMV",
    "city": "Amderma",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "SID",
    "city": "Amilcar Cabral",
    "region": "Cape Verde",
    "tz": "Atlantic/Cape_Verde"
  },
  {
    "iata": "ADJ",
    "city": "Amman",
    "region": "Jordan",
    "tz": "Asia/Amman"
  },
  {
    "iata": "AMM",
    "city": "Amman",
    "region": "Jordan",
    "tz": "Asia/Amman"
  },
  {
    "iata": "YEY",
    "city": "Amos",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "IVA",
    "city": "Ampampamena",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "ATQ",
    "city": "Amritsar",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "AMS",
    "city": "Amsterdam",
    "region": "Netherlands",
    "tz": "Europe/Amsterdam"
  },
  {
    "iata": "AAA",
    "city": "Anaa",
    "region": "French Polynesia",
    "tz": "Pacific/Tahiti"
  },
  {
    "iata": "AAO",
    "city": "Anaco",
    "region": "Venezuela",
    "tz": "America/Caracas"
  },
  {
    "iata": "DYR",
    "city": "Anadyr",
    "region": "Russia",
    "tz": "Asia/Anadyr"
  },
  {
    "iata": "YAA",
    "city": "Anahim Lake",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "AKP",
    "city": "Anaktuvuk Pass",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "HVA",
    "city": "Analalava",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "MWK",
    "city": "Anambas Islands",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "AAQ",
    "city": "Anapa",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "ANC",
    "city": "Anchorage",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "EDF",
    "city": "Anchorage",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "MRI",
    "city": "Anchorage",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "LHD",
    "city": "Anchorage",
    "region": "United States",
    "tz": "America/Anchorage"
  },
  {
    "iata": "AOI",
    "city": "Ancona",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "ANS",
    "city": "Andahuaylas",
    "region": "Peru",
    "tz": "America/Lima"
  },
  {
    "iata": "ZWA",
    "city": "Andapa",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "UAM",
    "city": "Andersen",
    "region": "Guam",
    "tz": "Pacific/Guam"
  },
  {
    "iata": "AND",
    "city": "Andersen",
    "region": "South Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "AZN",
    "city": "Andizhan",
    "region": "Uzbekistan",
    "tz": "Asia/Tashkent"
  },
  {
    "iata": "ANX",
    "city": "Andoya",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "PYR",
    "city": "Andravida",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "COX",
    "city": "Andros",
    "region": "Bahamas",
    "tz": "America/Nassau"
  },
  {
    "iata": "ASD",
    "city": "Andros Town",
    "region": "Bahamas",
    "tz": "America/Nassau"
  },
  {
    "iata": "NGD",
    "city": "Anegada",
    "region": "British Virgin Islands",
    "tz": "America/Tortola"
  },
  {
    "iata": "AUY",
    "city": "Anelghowhat",
    "region": "Vanuatu",
    "tz": "Pacific/Efate"
  },
  {
    "iata": "CRK",
    "city": "Angeles City",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "VLY",
    "city": "Angelsey",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "ANE",
    "city": "Angers/Marc\u00e9",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "YAX",
    "city": "Angling Lake",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "AGM",
    "city": "Angmagssalik",
    "region": "Greenland",
    "tz": "America/Godthab"
  },
  {
    "iata": "ANQ",
    "city": "Angola",
    "region": "Indiana",
    "tz": "America/Indiana/Indianapolis"
  },
  {
    "iata": "AGN",
    "city": "Angoon",
    "region": "Alaska",
    "tz": "America/Juneau"
  },
  {
    "iata": "ANG",
    "city": "Angouleme",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "ANI",
    "city": "Aniak",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "AWD",
    "city": "Aniwa",
    "region": "Vanuatu",
    "tz": "Pacific/Efate"
  },
  {
    "iata": "AJN",
    "city": "Anjouan",
    "region": "Comoros",
    "tz": "Indian/Comoro"
  },
  {
    "iata": "AKA",
    "city": "Ankang",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "ANK",
    "city": "Ankara",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "ESB",
    "city": "Ankara",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "JVA",
    "city": "Ankavandra",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "IKV",
    "city": "Ankeny",
    "region": "United States",
    "tz": "America/Chicago"
  },
  {
    "iata": "ARB",
    "city": "Ann Arbor",
    "region": "Michigan",
    "tz": "America/Detroit"
  },
  {
    "iata": "AAE",
    "city": "Annaba",
    "region": "Algeria",
    "tz": "Africa/Algiers"
  },
  {
    "iata": "NAI",
    "city": "Annai",
    "region": "Guyana",
    "tz": "America/Guyana"
  },
  {
    "iata": "ANP",
    "city": "Annapolis",
    "region": "Maryland",
    "tz": "America/New_York"
  },
  {
    "iata": "NCY",
    "city": "Annecy",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "QNJ",
    "city": "Annemasse",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "ANN",
    "city": "Annette Island",
    "region": "Alaska",
    "tz": "America/Metlakatla"
  },
  {
    "iata": "ANB",
    "city": "Anniston",
    "region": "Alabama",
    "tz": "America/Chicago"
  },
  {
    "iata": "AQG",
    "city": "Anqing",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "AOG",
    "city": "Anshan",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "AVA",
    "city": "Anshun",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "ATA",
    "city": "Anta",
    "region": "Peru",
    "tz": "America/Lima"
  },
  {
    "iata": "ANM",
    "city": "Antalaha",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "AYT",
    "city": "Antalya",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "TNR",
    "city": "Antananarivo",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "ANU",
    "city": "Antigua",
    "region": "Antigua and Barbuda",
    "tz": "America/Antigua"
  },
  {
    "iata": "ANF",
    "city": "Antofagasta",
    "region": "Chile",
    "tz": "America/Santiago"
  },
  {
    "iata": "WAQ",
    "city": "Antsalova",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "ATJ",
    "city": "Antsirabe",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "DIE",
    "city": "Antsiranana",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "WAI",
    "city": "Antsohihy",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "ANR",
    "city": "Antwerp",
    "region": "Belgium",
    "tz": "Europe/Brussels"
  },
  {
    "iata": "ACJ",
    "city": "Anuradhapura",
    "region": "Sri Lanka",
    "tz": "Asia/Colombo"
  },
  {
    "iata": "ANV",
    "city": "Anvik",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "AOJ",
    "city": "Aomori",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "AOT",
    "city": "Aosta",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "AAF",
    "city": "Apalachicola",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "APK",
    "city": "Apataki",
    "region": "French Polynesia",
    "tz": "Pacific/Tahiti"
  },
  {
    "iata": "KVK",
    "city": "Apatity",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "FGI",
    "city": "Apia",
    "region": "Samoa",
    "tz": "Pacific/Apia"
  },
  {
    "iata": "API",
    "city": "Apiay",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "ATW",
    "city": "Appleton",
    "region": "Wisconsin",
    "tz": "America/Chicago"
  },
  {
    "iata": "AQJ",
    "city": "Aqaba",
    "region": "Jordan",
    "tz": "Asia/Amman"
  },
  {
    "iata": "AJU",
    "city": "Aracaju",
    "region": "Brazil",
    "tz": "America/Noronha"
  },
  {
    "iata": "ARU",
    "city": "Aracatuba",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "ARW",
    "city": "Arad",
    "region": "Romania",
    "tz": "Europe/Bucharest"
  },
  {
    "iata": "AUX",
    "city": "Araguaina",
    "region": "Brazil",
    "tz": "America/Araguaina"
  },
  {
    "iata": "ADI",
    "city": "Arandis",
    "region": "Namibia",
    "tz": "Africa/Windhoek"
  },
  {
    "iata": "APQ",
    "city": "Arapiraca",
    "region": "Brazil",
    "tz": "America/Maceio"
  },
  {
    "iata": "RAE",
    "city": "Arar",
    "region": "Saudi Arabia",
    "tz": "Asia/Riyadh"
  },
  {
    "iata": "AQA",
    "city": "Araracuara",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "ACR",
    "city": "Araracuara",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "AUC",
    "city": "Arauca",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "AAX",
    "city": "Araxa",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "AMH",
    "city": "Arba Minch",
    "region": "Ethiopia",
    "tz": "Africa/Addis_Ababa"
  },
  {
    "iata": "XAC",
    "city": "Arcachon",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "ACV",
    "city": "Arcata CA",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "YAB",
    "city": "Arctic Bay",
    "region": "Canada",
    "tz": "America/Rankin_Inlet"
  },
  {
    "iata": "ARC",
    "city": "Arctic Village",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "ADU",
    "city": "Ardabil",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "AMZ",
    "city": "Ardmore",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "ADM",
    "city": "Ardmore",
    "region": "Oklahoma",
    "tz": "America/Chicago"
  },
  {
    "iata": "ARE",
    "city": "Arecibo",
    "region": "Puerto Rico",
    "tz": "America/Puerto_Rico"
  },
  {
    "iata": "AQP",
    "city": "Arequipa",
    "region": "Peru",
    "tz": "America/Lima"
  },
  {
    "iata": "GYL",
    "city": "Argyle",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "PUG",
    "city": "Argyle",
    "region": "Australia",
    "tz": "Australia/Adelaide"
  },
  {
    "iata": "ARI",
    "city": "Arica",
    "region": "Chile",
    "tz": "America/Santiago"
  },
  {
    "iata": "AIR",
    "city": "Aripuana",
    "region": "Brazil",
    "tz": "America/Cuiaba"
  },
  {
    "iata": "AYK",
    "city": "Arkalyk",
    "region": "Kazakhstan",
    "tz": "Asia/Almaty"
  },
  {
    "iata": "ARH",
    "city": "Arkhangelsk",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "LDG",
    "city": "Arkhangelsk",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "GKY",
    "city": "Arlington",
    "region": "United States",
    "tz": "America/Chicago"
  },
  {
    "iata": "AXM",
    "city": "Armenia",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "ARM",
    "city": "Armidale",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "YYW",
    "city": "Armstrong",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "AIS",
    "city": "Arorae",
    "region": "Kiribati",
    "tz": "Pacific/Tarawa"
  },
  {
    "iata": "ACE",
    "city": "Arrecife",
    "region": "Spain",
    "tz": "Atlantic/Canary"
  },
  {
    "iata": "ATC",
    "city": "Arthur's Town",
    "region": "Bahamas",
    "tz": "America/Nassau"
  },
  {
    "iata": "ATI",
    "city": "Artigas",
    "region": "Uruguay",
    "tz": "America/Montevideo"
  },
  {
    "iata": "RUA",
    "city": "Arua",
    "region": "Uganda",
    "tz": "Africa/Kampala"
  },
  {
    "iata": "ARK",
    "city": "Arusha",
    "region": "Tanzania",
    "tz": "Africa/Dar_es_Salaam"
  },
  {
    "iata": "AXR",
    "city": "Arutua",
    "region": "French Polynesia",
    "tz": "Pacific/Tahiti"
  },
  {
    "iata": "AVK",
    "city": "Arvaikheer",
    "region": "Mongolia",
    "tz": "Asia/Ulaanbaatar"
  },
  {
    "iata": "AJR",
    "city": "Arvidsjaur",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "YIE",
    "city": "Arxan",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "AKJ",
    "city": "Asahikawa",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "AVL",
    "city": "Asheville",
    "region": "North Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "ASB",
    "city": "Ashkhabad",
    "region": "Turkmenistan",
    "tz": "Asia/Ashgabat"
  },
  {
    "iata": "ASM",
    "city": "Asmara",
    "region": "Eritrea",
    "tz": "Africa/Asmara"
  },
  {
    "iata": "ASO",
    "city": "Asosa",
    "region": "Ethiopia",
    "tz": "Africa/Addis_Ababa"
  },
  {
    "iata": "ASE",
    "city": "Aspen",
    "region": "Colorado",
    "tz": "America/Denver"
  },
  {
    "iata": "ASA",
    "city": "Assab",
    "region": "Eritrea",
    "tz": "Africa/Asmara"
  },
  {
    "iata": "AST",
    "city": "Astoria",
    "region": "Oregon",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "ASF",
    "city": "Astrakhan",
    "region": "Russia",
    "tz": "Europe/Volgograd"
  },
  {
    "iata": "JTY",
    "city": "Astypalaia",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "ASU",
    "city": "Asuncion",
    "region": "Paraguay",
    "tz": "America/Asuncion"
  },
  {
    "iata": "ASW",
    "city": "Aswan",
    "region": "Egypt",
    "tz": "Africa/Cairo"
  },
  {
    "iata": "ATZ",
    "city": "Asyut",
    "region": "Egypt",
    "tz": "Africa/Cairo"
  },
  {
    "iata": "AXK",
    "city": "Ataq",
    "region": "Yemen",
    "tz": "Asia/Aden"
  },
  {
    "iata": "ATR",
    "city": "Atar",
    "region": "Mauritania",
    "tz": "Africa/Nouakchott"
  },
  {
    "iata": "ATB",
    "city": "Atbara",
    "region": "Sudan",
    "tz": "Africa/Khartoum"
  },
  {
    "iata": "AHN",
    "city": "Athens",
    "region": "Georgia",
    "tz": "America/New_York"
  },
  {
    "iata": "ATH",
    "city": "Athens",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "HEW",
    "city": "Athens",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "MMI",
    "city": "Athens",
    "region": "Tennessee",
    "tz": "America/New_York"
  },
  {
    "iata": "YIB",
    "city": "Atikokan",
    "region": "Canada",
    "tz": "America/Atikokan"
  },
  {
    "iata": "AIU",
    "city": "Atiu Island",
    "region": "Cook Islands",
    "tz": "Pacific/Rarotonga"
  },
  {
    "iata": "AKB",
    "city": "Atka",
    "region": "Alaska",
    "tz": "America/Adak"
  },
  {
    "iata": "ATL",
    "city": "Atlanta",
    "region": "Georgia",
    "tz": "America/New_York"
  },
  {
    "iata": "FTY",
    "city": "Atlanta",
    "region": "Georgia",
    "tz": "America/New_York"
  },
  {
    "iata": "PDK",
    "city": "Atlanta",
    "region": "Georgia",
    "tz": "America/New_York"
  },
  {
    "iata": "ACY",
    "city": "Atlantic City",
    "region": "New Jersey",
    "tz": "America/New_York"
  },
  {
    "iata": "ATD",
    "city": "Atoifi",
    "region": "Solomon Islands",
    "tz": "Pacific/Guadalcanal"
  },
  {
    "iata": "ATK",
    "city": "Atqasuk",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "YAT",
    "city": "Attawapiskat",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "AOU",
    "city": "Attopeu",
    "region": "Laos",
    "tz": "Asia/Vientiane"
  },
  {
    "iata": "GUW",
    "city": "Atyrau",
    "region": "Kazakhstan",
    "tz": "Asia/Oral"
  },
  {
    "iata": "OBS",
    "city": "Aubenas-vals-lanas",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "AUO",
    "city": "Auburn",
    "region": "Alabama",
    "tz": "America/Chicago"
  },
  {
    "iata": "AKL",
    "city": "Auckland",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "AGB",
    "city": "Augsburg",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "DNL",
    "city": "Augusta",
    "region": "Georgia",
    "tz": "America/New_York"
  },
  {
    "iata": "AUG",
    "city": "Augusta",
    "region": "Maine",
    "tz": "America/New_York"
  },
  {
    "iata": "AKS",
    "city": "Auki",
    "region": "Solomon Islands",
    "tz": "Pacific/Guadalcanal"
  },
  {
    "iata": "YPJ",
    "city": "Aupaluk",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "IXU",
    "city": "Aurangabad",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "AUR",
    "city": "Aurillac",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "UAO",
    "city": "Aurora",
    "region": "United States",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "AUU",
    "city": "Aurukun",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "AUS",
    "city": "Austin",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "AUS",
    "city": "Austin",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "AUF",
    "city": "Auxerre",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "AVV",
    "city": "Avalon",
    "region": "Australia",
    "tz": "Australia/Melbourne"
  },
  {
    "iata": "RAR",
    "city": "Avarua",
    "region": "Cook Islands",
    "tz": "Pacific/Rarotonga"
  },
  {
    "iata": "AVB",
    "city": "Aviano",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "AVN",
    "city": "Avignon",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "OVD",
    "city": "Aviles",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "AVO",
    "city": "Avon Park",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "AWA",
    "city": "Awasa",
    "region": "Ethiopia",
    "tz": "Africa/Addis_Ababa"
  },
  {
    "iata": "AXU",
    "city": "Axum",
    "region": "Ethiopia",
    "tz": "Africa/Addis_Ababa"
  },
  {
    "iata": "AYP",
    "city": "Ayacucho",
    "region": "Peru",
    "tz": "America/Lima"
  },
  {
    "iata": "BNU",
    "city": "BLUMENAU",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "TRI",
    "city": "BRISTOL",
    "region": "Tennessee",
    "tz": "America/New_York"
  },
  {
    "iata": "ROR",
    "city": "Babelthuap",
    "region": "Palau",
    "tz": "Pacific/Palau"
  },
  {
    "iata": "BXB",
    "city": "Babo",
    "region": "Indonesia",
    "tz": "Asia/Jayapura"
  },
  {
    "iata": "BCM",
    "city": "Bacau",
    "region": "Romania",
    "tz": "Europe/Bucharest"
  },
  {
    "iata": "BCO",
    "city": "Baco",
    "region": "Ethiopia",
    "tz": "Africa/Addis_Ababa"
  },
  {
    "iata": "BCD",
    "city": "Bacolod",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "BJZ",
    "city": "Badajoz",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "BDD",
    "city": "Badu Island",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "BFX",
    "city": "Bafoussam",
    "region": "Cameroon",
    "tz": "Africa/Douala"
  },
  {
    "iata": "BPE",
    "city": "Bagan",
    "region": "Burma",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "NYU",
    "city": "Bagan",
    "region": "Burma",
    "tz": "Asia/Rangoon"
  },
  {
    "iata": "BGX",
    "city": "Bage",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "BGW",
    "city": "Baghdad",
    "region": "Iraq",
    "tz": "Asia/Baghdad"
  },
  {
    "iata": "IXB",
    "city": "Baghdogra",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "YBG",
    "city": "Bagotville",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "BAG",
    "city": "Baguio",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "BJR",
    "city": "Bahar Dar",
    "region": "Ethiopia",
    "tz": "Africa/Addis_Ababa"
  },
  {
    "iata": "BHV",
    "city": "Bahawalpur",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "BHI",
    "city": "Bahia Blanca",
    "region": "Argentina",
    "tz": "America/Argentina/Buenos_Aires"
  },
  {
    "iata": "BSC",
    "city": "Bahia Solano",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "BAH",
    "city": "Bahrain",
    "region": "Bahrain",
    "tz": "Asia/Bahrain"
  },
  {
    "iata": "BAY",
    "city": "Baia Mare",
    "region": "Romania",
    "tz": "Europe/Bucharest"
  },
  {
    "iata": "YBC",
    "city": "Baie Comeau",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "BGE",
    "city": "Bainbridge",
    "region": "Georgia",
    "tz": "America/New_York"
  },
  {
    "iata": "BSJ",
    "city": "Bairnsdale",
    "region": "Australia",
    "tz": "Australia/Melbourne"
  },
  {
    "iata": "BJH",
    "city": "Bajhang",
    "region": "Nepal",
    "tz": "Asia/Kathmandu"
  },
  {
    "iata": "BJU",
    "city": "Bajura",
    "region": "Nepal",
    "tz": "Asia/Kathmandu"
  },
  {
    "iata": "BKM",
    "city": "Bakalalan",
    "region": "Malaysia",
    "tz": "Asia/Kuching"
  },
  {
    "iata": "BXE",
    "city": "Bakel",
    "region": "Senegal",
    "tz": "Africa/Dakar"
  },
  {
    "iata": "BKE",
    "city": "Baker City",
    "region": "Oregon",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "YBK",
    "city": "Baker Lake",
    "region": "Canada",
    "tz": "America/Rankin_Inlet"
  },
  {
    "iata": "BFL",
    "city": "Bakersfield",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "KSH",
    "city": "Bakhtaran",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "GYD",
    "city": "Baku",
    "region": "Azerbaijan",
    "tz": "Asia/Baku"
  },
  {
    "iata": "BWO",
    "city": "Balakovo",
    "region": "Russia",
    "tz": "Europe/Volgograd"
  },
  {
    "iata": "BZI",
    "city": "Balikesir",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "EDO",
    "city": "Balikesir Korfez",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "BPN",
    "city": "Balikpapan",
    "region": "Indonesia",
    "tz": "Asia/Makassar"
  },
  {
    "iata": "BXH",
    "city": "Balkhash",
    "region": "Kazakhstan",
    "tz": "Asia/Almaty"
  },
  {
    "iata": "BAS",
    "city": "Ballalae",
    "region": "Solomon Islands",
    "tz": "Pacific/Guadalcanal"
  },
  {
    "iata": "BNK",
    "city": "Ballina Byron Bay",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "BBA",
    "city": "Balmaceda",
    "region": "Chile",
    "tz": "America/Santiago"
  },
  {
    "iata": "BWI",
    "city": "Baltimore",
    "region": "Maryland",
    "tz": "America/New_York"
  },
  {
    "iata": "MTN",
    "city": "Baltimore",
    "region": "Maryland",
    "tz": "America/New_York"
  },
  {
    "iata": "BXR",
    "city": "Bam",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "BKO",
    "city": "Bamako",
    "region": "Mali",
    "tz": "Africa/Bamako"
  },
  {
    "iata": "BMQ",
    "city": "Bamburi",
    "region": "Kenya",
    "tz": "Africa/Nairobi"
  },
  {
    "iata": "BPC",
    "city": "Bamenda",
    "region": "Cameroon",
    "tz": "Africa/Douala"
  },
  {
    "iata": "BIN",
    "city": "Bamyan",
    "region": "Afghanistan",
    "tz": "Asia/Kabul"
  },
  {
    "iata": "BTJ",
    "city": "Banda Aceh",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "BND",
    "city": "Bandar Abbas",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "TKG",
    "city": "Bandar Lampung-Sumatra Island",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "BDH",
    "city": "Bandar Lengeh",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "MRX",
    "city": "Bandar Mahshahr",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "BWN",
    "city": "Bandar Seri Begawan",
    "region": "Brunei",
    "tz": "Asia/Brunei"
  },
  {
    "iata": "BDM",
    "city": "Bandirma",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "FDU",
    "city": "Bandoundu",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Kinshasa"
  },
  {
    "iata": "BDO",
    "city": "Bandung",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "YBA",
    "city": "Banff",
    "region": "Canada",
    "tz": "America/Edmonton"
  },
  {
    "iata": "BLR",
    "city": "Bangalore",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "BPX",
    "city": "Bangda",
    "region": "China",
    "tz": "Asia/Urumqi"
  },
  {
    "iata": "BKK",
    "city": "Bangkok",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "DMK",
    "city": "Bangkok",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "BGR",
    "city": "Bangor",
    "region": "Maine",
    "tz": "America/New_York"
  },
  {
    "iata": "BGF",
    "city": "Bangui",
    "region": "Central African Republic",
    "tz": "Africa/Bangui"
  },
  {
    "iata": "BNX",
    "city": "Banja Luka",
    "region": "Bosnia and Herzegovina",
    "tz": "Europe/Sarajevo"
  },
  {
    "iata": "BDJ",
    "city": "Banjarmasin",
    "region": "Indonesia",
    "tz": "Asia/Makassar"
  },
  {
    "iata": "BJL",
    "city": "Banjul",
    "region": "Gambia",
    "tz": "Africa/Banjul"
  },
  {
    "iata": "BMO",
    "city": "Banmaw",
    "region": "Burma",
    "tz": "Asia/Rangoon"
  },
  {
    "iata": "BNG",
    "city": "Banning",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "BNP",
    "city": "Bannu",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "BYT",
    "city": "Bantry",
    "region": "Ireland",
    "tz": "Europe/Dublin"
  },
  {
    "iata": "BAV",
    "city": "Baotou",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "BHB",
    "city": "Bar Harbor",
    "region": "Maine",
    "tz": "America/New_York"
  },
  {
    "iata": "DLL",
    "city": "Baraboo",
    "region": "South Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "BCA",
    "city": "Baracoa Playa",
    "region": "Cuba",
    "tz": "America/Havana"
  },
  {
    "iata": "UPB",
    "city": "Baracoa Playa",
    "region": "Cuba",
    "tz": "America/Havana"
  },
  {
    "iata": "BRX",
    "city": "Barahona",
    "region": "Dominican Republic",
    "tz": "America/Santo_Domingo"
  },
  {
    "iata": "BCI",
    "city": "Barcaldine",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "BCN",
    "city": "Barcelona",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "BLA",
    "city": "Barcelona",
    "region": "Venezuela",
    "tz": "America/Caracas"
  },
  {
    "iata": "BAZ",
    "city": "Barcelos",
    "region": "Brazil",
    "tz": "America/Manaus"
  },
  {
    "iata": "BDU",
    "city": "Bardufoss",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "BRI",
    "city": "Bari",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "BNS",
    "city": "Barinas",
    "region": "Venezuela",
    "tz": "America/Caracas"
  },
  {
    "iata": "BBN",
    "city": "Bario",
    "region": "Malaysia",
    "tz": "Asia/Kuching"
  },
  {
    "iata": "BZL",
    "city": "Barisal",
    "region": "Bangladesh",
    "tz": "Asia/Dhaka"
  },
  {
    "iata": "BKH",
    "city": "Barking Sands",
    "region": "Hawaii",
    "tz": "Pacific/Honolulu"
  },
  {
    "iata": "BAX",
    "city": "Barnaul",
    "region": "Russia",
    "tz": "Asia/Omsk"
  },
  {
    "iata": "HYA",
    "city": "Barnstable",
    "region": "Massachusetts",
    "tz": "America/New_York"
  },
  {
    "iata": "BDQ",
    "city": "Baroda",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "BRM",
    "city": "Barquisimeto",
    "region": "Venezuela",
    "tz": "America/Caracas"
  },
  {
    "iata": "BRR",
    "city": "Barra",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "EJA",
    "city": "Barrancabermeja",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "BAQ",
    "city": "Barranquilla",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "BRA",
    "city": "Barreiras",
    "region": "Brazil",
    "tz": "America/Bahia"
  },
  {
    "iata": "BAT",
    "city": "Barretos",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "YLK",
    "city": "Barrie-Orillia",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "BRW",
    "city": "Barrow",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "BWB",
    "city": "Barrow Island",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "BWF",
    "city": "Barrow Island",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "BTI",
    "city": "Barter Island",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "BBH",
    "city": "Barth",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "GFO",
    "city": "Bartica",
    "region": "Guyana",
    "tz": "America/Guyana"
  },
  {
    "iata": "BOW",
    "city": "Bartow",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "BSU",
    "city": "Basankusu",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Kinshasa"
  },
  {
    "iata": "BSO",
    "city": "Basco",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "BSR",
    "city": "Basrah",
    "region": "Iraq",
    "tz": "Asia/Baghdad"
  },
  {
    "iata": "BBR",
    "city": "Basse Terre",
    "region": "Guadeloupe",
    "tz": "America/Guadeloupe"
  },
  {
    "iata": "SKB",
    "city": "Basse Terre",
    "region": "Saint Kitts and Nevis",
    "tz": "America/St_Kitts"
  },
  {
    "iata": "BIA",
    "city": "Bastia",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "BSG",
    "city": "Bata",
    "region": "Equatorial Guinea",
    "tz": "Africa/Malabo"
  },
  {
    "iata": "BJY",
    "city": "Batajnica",
    "region": "Serbia",
    "tz": "Europe/Belgrade"
  },
  {
    "iata": "BTH",
    "city": "Batam",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "BHS",
    "city": "Bathurst",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "ZBF",
    "city": "Bathurst",
    "region": "Canada",
    "tz": "America/Moncton"
  },
  {
    "iata": "BRT",
    "city": "Bathurst Island",
    "region": "Australia",
    "tz": "Australia/Darwin"
  },
  {
    "iata": "BAL",
    "city": "Batman",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "BLJ",
    "city": "Batna",
    "region": "Algeria",
    "tz": "Africa/Algiers"
  },
  {
    "iata": "BJF",
    "city": "Batsfjord",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "BBM",
    "city": "Battambang",
    "region": "Cambodia",
    "tz": "Asia/Phnom_Penh"
  },
  {
    "iata": "BTL",
    "city": "Battle Creek",
    "region": "Michigan",
    "tz": "America/Detroit"
  },
  {
    "iata": "BUS",
    "city": "Batumi",
    "region": "Georgia",
    "tz": "Asia/Tbilisi"
  },
  {
    "iata": "BUW",
    "city": "Bau-Bau",
    "region": "Indonesia",
    "tz": "Asia/Makassar"
  },
  {
    "iata": "BDE",
    "city": "Baudette",
    "region": "Minnesota",
    "tz": "America/Chicago"
  },
  {
    "iata": "BAU",
    "city": "Bauru",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "JTC",
    "city": "Bauru",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "BYM",
    "city": "Bayamo",
    "region": "Cuba",
    "tz": "America/Havana"
  },
  {
    "iata": "BYN",
    "city": "Bayankhongor",
    "region": "Mongolia",
    "tz": "Asia/Ulaanbaatar"
  },
  {
    "iata": "RLK",
    "city": "Bayannur",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "BYU",
    "city": "Bayreuth",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "XBE",
    "city": "Bearskin Lake",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "BFT",
    "city": "Beaufort",
    "region": "South Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "BMT",
    "city": "Beaumont",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "BPT",
    "city": "Beaumont",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "BVA",
    "city": "Beauvais",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "WBQ",
    "city": "Beaver",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "YXQ",
    "city": "Beaver Creek",
    "region": "Canada",
    "tz": "America/Whitehorse"
  },
  {
    "iata": "BFP",
    "city": "Beaver Falls",
    "region": "Pennsylvania",
    "tz": "America/New_York"
  },
  {
    "iata": "BKW",
    "city": "Beckley",
    "region": "West Virginia",
    "tz": "America/New_York"
  },
  {
    "iata": "BED",
    "city": "Bedford",
    "region": "Massachusetts",
    "tz": "America/New_York"
  },
  {
    "iata": "BEU",
    "city": "Bedourie",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "YBW",
    "city": "Bedwell Harbour",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "BEV",
    "city": "Beer-sheba",
    "region": "Israel",
    "tz": "Asia/Jerusalem"
  },
  {
    "iata": "BEI",
    "city": "Beica",
    "region": "Ethiopia",
    "tz": "Africa/Addis_Ababa"
  },
  {
    "iata": "BHY",
    "city": "Beihai",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "BHN",
    "city": "Beihan",
    "region": "Yemen",
    "tz": "Asia/Aden"
  },
  {
    "iata": "NAY",
    "city": "Beijing",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "PEK",
    "city": "Beijing",
    "region": "China",
    "tz": "Asia/Harbin"
  },
  {
    "iata": "BEW",
    "city": "Beira",
    "region": "Mozambique",
    "tz": "Africa/Maputo"
  },
  {
    "iata": "BEY",
    "city": "Beirut",
    "region": "Lebanon",
    "tz": "Asia/Beirut"
  },
  {
    "iata": "BJA",
    "city": "Bejaja",
    "region": "Algeria",
    "tz": "Africa/Algiers"
  },
  {
    "iata": "OVA",
    "city": "Bekily",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "BLG",
    "city": "Belaga",
    "region": "Malaysia",
    "tz": "Asia/Kuching"
  },
  {
    "iata": "BEL",
    "city": "Belem",
    "region": "Brazil",
    "tz": "America/Belem"
  },
  {
    "iata": "BFS",
    "city": "Belfast",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "BHD",
    "city": "Belfast",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "BOR",
    "city": "Belfort",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "IXG",
    "city": "Belgaum",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "EGO",
    "city": "Belgorod",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "BEG",
    "city": "Belgrade",
    "region": "Serbia",
    "tz": "Europe/Belgrade"
  },
  {
    "iata": "BZE",
    "city": "Belize City",
    "region": "Belize",
    "tz": "America/Belize"
  },
  {
    "iata": "ZEL",
    "city": "Bella Bella",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "QBC",
    "city": "Bella Coola",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "BEP",
    "city": "Bellary",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "BLV",
    "city": "Belleville",
    "region": "Illinois",
    "tz": "America/Chicago"
  },
  {
    "iata": "BLI",
    "city": "Bellingham",
    "region": "Washington",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "BCV",
    "city": "Belmopan",
    "region": "Belize",
    "tz": "America/Belize"
  },
  {
    "iata": "CNF",
    "city": "Belo Horizonte",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "PLU",
    "city": "Belo Horizonte",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "BMD",
    "city": "Belo sur Tsiribihina",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "EYK",
    "city": "Beloyarsky",
    "region": "Russia",
    "tz": "Asia/Yekaterinburg"
  },
  {
    "iata": "BBP",
    "city": "Bembridge",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "BJI",
    "city": "Bemidji",
    "region": "Minnesota",
    "tz": "America/Chicago"
  },
  {
    "iata": "BEB",
    "city": "Benbecula",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "BFU",
    "city": "Bengbu",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "BEN",
    "city": "Benghazi",
    "region": "Libya",
    "tz": "Africa/Tripoli"
  },
  {
    "iata": "BKS",
    "city": "Bengkulu",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "BUG",
    "city": "Benguela",
    "region": "Angola",
    "tz": "Africa/Luanda"
  },
  {
    "iata": "BNC",
    "city": "Beni",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Lubumbashi"
  },
  {
    "iata": "BEM",
    "city": "Beni Mellal",
    "region": "Morocco",
    "tz": "Africa/Bangui"
  },
  {
    "iata": "BNI",
    "city": "Benin",
    "region": "Nigeria",
    "tz": "Africa/Lagos"
  },
  {
    "iata": "SUZ",
    "city": "Benton",
    "region": "United States",
    "tz": "Pacific/Port_Moresby"
  },
  {
    "iata": "XNA",
    "city": "Bentonville",
    "region": "Arkansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "BQU",
    "city": "Bequia",
    "region": "Saint Vincent and the Grenadines",
    "tz": "America/St_Vincent"
  },
  {
    "iata": "BBO",
    "city": "Berbera",
    "region": "Somalia",
    "tz": "Africa/Mogadishu"
  },
  {
    "iata": "BBT",
    "city": "Berberati",
    "region": "Central African Republic",
    "tz": "Africa/Bangui"
  },
  {
    "iata": "ERD",
    "city": "Berdyansk",
    "region": "Ukraine",
    "tz": "Europe/Zaporozhye"
  },
  {
    "iata": "YBV",
    "city": "Berens River",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "NBB",
    "city": "Berezovo",
    "region": "Russia",
    "tz": "America/Bogota"
  },
  {
    "iata": "BGY",
    "city": "Bergamo",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "BGO",
    "city": "Bergen",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "EGC",
    "city": "Bergerac",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "BVG",
    "city": "Berlevag",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "SXF",
    "city": "Berlin",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "THF",
    "city": "Berlin",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "TXL",
    "city": "Berlin",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "BJO",
    "city": "Bermejo",
    "region": "Bolivia",
    "tz": "America/La_Paz"
  },
  {
    "iata": "BDA",
    "city": "Bermuda",
    "region": "Bermuda",
    "tz": "Atlantic/Bermuda"
  },
  {
    "iata": "BRN",
    "city": "Bern",
    "region": "Switzerland",
    "tz": "Europe/Zurich"
  },
  {
    "iata": "BEZ",
    "city": "Beru Island",
    "region": "Kiribati",
    "tz": "Pacific/Tarawa"
  },
  {
    "iata": "BPY",
    "city": "Besalampy",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "OGZ",
    "city": "Beslan",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "BET",
    "city": "Bethel",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "BTT",
    "city": "Bettles",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "BVY",
    "city": "Beverly",
    "region": "Massachusetts",
    "tz": "America/New_York"
  },
  {
    "iata": "BZR",
    "city": "Beziers",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "BWA",
    "city": "Bhairawa",
    "region": "Nepal",
    "tz": "Asia/Kathmandu"
  },
  {
    "iata": "BHR",
    "city": "Bharatpur",
    "region": "Nepal",
    "tz": "Asia/Kathmandu"
  },
  {
    "iata": "BHU",
    "city": "Bhaunagar",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "BHP",
    "city": "Bhojpur",
    "region": "Nepal",
    "tz": "Asia/Kathmandu"
  },
  {
    "iata": "BHO",
    "city": "Bhopal",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "BBI",
    "city": "Bhubaneswar",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "BHJ",
    "city": "Bhuj",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "BIK",
    "city": "Biak",
    "region": "Indonesia",
    "tz": "Asia/Jayapura"
  },
  {
    "iata": "BIQ",
    "city": "Biarritz-bayonne",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "BXP",
    "city": "Bia\u0142a Podlaska",
    "region": "Poland",
    "tz": "Europe/Warsaw"
  },
  {
    "iata": "BMX",
    "city": "Big Mountain",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "BPI",
    "city": "Big Piney",
    "region": "Wyoming",
    "tz": "America/Denver"
  },
  {
    "iata": "YTL",
    "city": "Big Trout Lake",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "BQH",
    "city": "Biggin Hill",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "BFJ",
    "city": "Bijie",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "PAB",
    "city": "Bilaspur",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "BIO",
    "city": "Bilbao",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "BIU",
    "city": "Bildudalur",
    "region": "Iceland",
    "tz": "Atlantic/Reykjavik"
  },
  {
    "iata": "BIL",
    "city": "Billings",
    "region": "Montana",
    "tz": "America/Denver"
  },
  {
    "iata": "BLL",
    "city": "Billund",
    "region": "Denmark",
    "tz": "Europe/Copenhagen"
  },
  {
    "iata": "THG",
    "city": "Biloela",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "BMU",
    "city": "Bima",
    "region": "Indonesia",
    "tz": "Asia/Makassar"
  },
  {
    "iata": "BGM",
    "city": "Binghamton",
    "region": "New York",
    "tz": "America/New_York"
  },
  {
    "iata": "BTU",
    "city": "Bintulu",
    "region": "Malaysia",
    "tz": "Asia/Kuching"
  },
  {
    "iata": "NTI",
    "city": "Bintuni",
    "region": "Indonesia",
    "tz": "Asia/Jayapura"
  },
  {
    "iata": "BIR",
    "city": "Biratnagar",
    "region": "Nepal",
    "tz": "Asia/Kathmandu"
  },
  {
    "iata": "BBJ",
    "city": "Birburg",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "BDI",
    "city": "Bird Island",
    "region": "Seychelles",
    "tz": "Indian/Mahe"
  },
  {
    "iata": "BVI",
    "city": "Birdsville",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "XBJ",
    "city": "Birjand",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "BHM",
    "city": "Birmingham",
    "region": "Alabama",
    "tz": "America/Chicago"
  },
  {
    "iata": "BHX",
    "city": "Birmingham",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "BHH",
    "city": "Bisha",
    "region": "Saudi Arabia",
    "tz": "Asia/Riyadh"
  },
  {
    "iata": "FRU",
    "city": "Bishkek",
    "region": "Kyrgyzstan",
    "tz": "Asia/Bishkek"
  },
  {
    "iata": "BIY",
    "city": "Bisho",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "BIH",
    "city": "Bishop",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "BSK",
    "city": "Biskra",
    "region": "Algeria",
    "tz": "Africa/Algiers"
  },
  {
    "iata": "BIS",
    "city": "Bismarck",
    "region": "North Dakota",
    "tz": "America/Chicago"
  },
  {
    "iata": "OXB",
    "city": "Bissau",
    "region": "Guinea-Bissau",
    "tz": "Africa/Bissau"
  },
  {
    "iata": "BMM",
    "city": "Bitam",
    "region": "Gabon",
    "tz": "Africa/Libreville"
  },
  {
    "iata": "YBI",
    "city": "Black Tickle",
    "region": "Canada",
    "tz": "America/St_Johns"
  },
  {
    "iata": "BKQ",
    "city": "Blackall",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "BBS",
    "city": "Blackbushe",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "BLK",
    "city": "Blackpool",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "BLT",
    "city": "Blackwater",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "BQS",
    "city": "Blagoveschensk",
    "region": "Russia",
    "tz": "Asia/Yakutsk"
  },
  {
    "iata": "BLZ",
    "city": "Blantyre",
    "region": "Malawi",
    "tz": "Africa/Blantyre"
  },
  {
    "iata": "BID",
    "city": "Block Island",
    "region": "Rhode Island",
    "tz": "America/New_York"
  },
  {
    "iata": "BFN",
    "city": "Bloemfontein",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "BMI",
    "city": "Bloomington",
    "region": "Illinois",
    "tz": "America/Chicago"
  },
  {
    "iata": "BMG",
    "city": "Bloomington",
    "region": "Indiana",
    "tz": "America/Indiana/Indianapolis"
  },
  {
    "iata": "BLF",
    "city": "Bluefield",
    "region": "West Virginia",
    "tz": "America/New_York"
  },
  {
    "iata": "BEF",
    "city": "Bluefields",
    "region": "Nicaragua",
    "tz": "America/Managua"
  },
  {
    "iata": "BLH",
    "city": "Blythe",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "BYH",
    "city": "Blytheville",
    "region": "Arkansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "KBS",
    "city": "Bo",
    "region": "Sierra Leone",
    "tz": "Africa/Freetown"
  },
  {
    "iata": "BVB",
    "city": "Boa Vista",
    "region": "Brazil",
    "tz": "America/Boa_Vista"
  },
  {
    "iata": "BVC",
    "city": "Boa Vista",
    "region": "Cape Verde",
    "tz": "Atlantic/Cape_Verde"
  },
  {
    "iata": "YBO",
    "city": "Bob Quinn Lake",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "BOY",
    "city": "Bobo-dioulasso",
    "region": "Burkina Faso",
    "tz": "Africa/Ouagadougou"
  },
  {
    "iata": "BCT",
    "city": "Boca Raton",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "BOC",
    "city": "Bocas Del Toro",
    "region": "Panama",
    "tz": "America/Panama"
  },
  {
    "iata": "ODO",
    "city": "Bodaibo",
    "region": "Russia",
    "tz": "Asia/Irkutsk"
  },
  {
    "iata": "BOO",
    "city": "Bodo",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "BJV",
    "city": "Bodrum",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "BXN",
    "city": "Bodrum",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "BNB",
    "city": "Boende",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Kinshasa"
  },
  {
    "iata": "BOG",
    "city": "Bogota",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "GIC",
    "city": "Boigu",
    "region": "Australia",
    "tz": "Pacific/Port_Moresby"
  },
  {
    "iata": "BYO",
    "city": "Bointo",
    "region": "Brazil",
    "tz": "America/Campo_Grande"
  },
  {
    "iata": "BOI",
    "city": "Boise",
    "region": "Idaho",
    "tz": "America/Boise"
  },
  {
    "iata": "BJB",
    "city": "Bojnourd",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "BUI",
    "city": "Bokondini-Papua Island",
    "region": "Indonesia",
    "tz": "Asia/Jayapura"
  },
  {
    "iata": "BPL",
    "city": "Bole",
    "region": "China",
    "tz": "Asia/Kashgar"
  },
  {
    "iata": "BLQ",
    "city": "Bologna",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "BZO",
    "city": "Bolzano",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "LAZ",
    "city": "Bom Jesus Da Lapa",
    "region": "Brazil",
    "tz": "America/Bahia"
  },
  {
    "iata": "BOA",
    "city": "Boma",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Kinshasa"
  },
  {
    "iata": "BZA",
    "city": "Bonanza",
    "region": "Nicaragua",
    "tz": "America/Managua"
  },
  {
    "iata": "YVB",
    "city": "Bonaventure",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "YBY",
    "city": "Bonnyville",
    "region": "Canada",
    "tz": "America/Edmonton"
  },
  {
    "iata": "BTE",
    "city": "Bonthe",
    "region": "Sierra Leone",
    "tz": "Africa/Freetown"
  },
  {
    "iata": "BOB",
    "city": "Bora Bora",
    "region": "French Polynesia",
    "tz": "Pacific/Tahiti"
  },
  {
    "iata": "RBB",
    "city": "Borba",
    "region": "Brazil",
    "tz": "America/Manaus"
  },
  {
    "iata": "BOD",
    "city": "Bordeaux",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "BMW",
    "city": "Bordj Badji Mokhtar",
    "region": "Algeria",
    "tz": "Africa/Algiers"
  },
  {
    "iata": "BMK",
    "city": "Borkum",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "BLE",
    "city": "Borlange",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "BOX",
    "city": "Borroloola",
    "region": "Australia",
    "tz": "Australia/Darwin"
  },
  {
    "iata": "BSA",
    "city": "Bosaso",
    "region": "Somalia",
    "tz": "Africa/Mogadishu"
  },
  {
    "iata": "BOS",
    "city": "Boston",
    "region": "Massachusetts",
    "tz": "America/New_York"
  },
  {
    "iata": "QCJ",
    "city": "Botucatu",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "BYK",
    "city": "Bouake",
    "region": "Cote d'Ivoire",
    "tz": "Africa/Abidjan"
  },
  {
    "iata": "QFD",
    "city": "Boufarik",
    "region": "Algeria",
    "tz": "Africa/Algiers"
  },
  {
    "iata": "WBU",
    "city": "Boulder",
    "region": "Colorado",
    "tz": "America/Denver"
  },
  {
    "iata": "BQL",
    "city": "Boulia",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "YDT",
    "city": "Boundary Bay",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "BOJ",
    "city": "Bourgas",
    "region": "Bulgaria",
    "tz": "Europe/Sofia"
  },
  {
    "iata": "BOU",
    "city": "Bourges",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "BRK",
    "city": "Bourke",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "BOH",
    "city": "Bournemouth",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "BWG",
    "city": "Bowling Green",
    "region": "Kentucky",
    "tz": "America/Chicago"
  },
  {
    "iata": "BZN",
    "city": "Bozeman",
    "region": "Montana",
    "tz": "America/Denver"
  },
  {
    "iata": "BWK",
    "city": "Brac",
    "region": "Croatia",
    "tz": "Europe/Zagreb"
  },
  {
    "iata": "BFD",
    "city": "Bradford",
    "region": "Pennsylvania",
    "tz": "America/New_York"
  },
  {
    "iata": "BSF",
    "city": "Bradshaw Field",
    "region": "Hawaii",
    "tz": "Pacific/Honolulu"
  },
  {
    "iata": "BBD",
    "city": "Brady",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "BGC",
    "city": "Braganca",
    "region": "Portugal",
    "tz": "Europe/Lisbon"
  },
  {
    "iata": "BJP",
    "city": "Braganca Paulista",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "BRD",
    "city": "Brainerd",
    "region": "Minnesota",
    "tz": "America/Chicago"
  },
  {
    "iata": "BMP",
    "city": "Brampton Island",
    "region": "Australia",
    "tz": "Australia/Lindeman"
  },
  {
    "iata": "YBR",
    "city": "Brandon",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "BSB",
    "city": "Brasilia",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "BTS",
    "city": "Bratislava",
    "region": "Slovakia",
    "tz": "Europe/Bratislava"
  },
  {
    "iata": "BTK",
    "city": "Bratsk",
    "region": "Russia",
    "tz": "Asia/Irkutsk"
  },
  {
    "iata": "BWE",
    "city": "Braunschweig",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "BZV",
    "city": "Brazzaville",
    "region": "Congo (Brazzaville)",
    "tz": "Africa/Brazzaville"
  },
  {
    "iata": "BKD",
    "city": "Breckenridge",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "BRE",
    "city": "Bremen",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "BRV",
    "city": "Bremerhaven",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "PWT",
    "city": "Bremerton",
    "region": "Washington",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "VBS",
    "city": "Brescia",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "BQT",
    "city": "Brest",
    "region": "Belarus",
    "tz": "Europe/Minsk"
  },
  {
    "iata": "BES",
    "city": "Brest",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "BVS",
    "city": "Breves",
    "region": "Brazil",
    "tz": "America/Santarem"
  },
  {
    "iata": "KTS",
    "city": "Brevig Mission",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "BGI",
    "city": "Bridgetown",
    "region": "Barbados",
    "tz": "America/Barbados"
  },
  {
    "iata": "BMC",
    "city": "Brigham City",
    "region": "Utah",
    "tz": "America/Denver"
  },
  {
    "iata": "BDS",
    "city": "Brindisi",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "BNE",
    "city": "Brisbane",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "BRS",
    "city": "Bristol",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "FZO",
    "city": "Bristol",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "BVE",
    "city": "Brive",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "BZZ",
    "city": "Brize Norton",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "BRQ",
    "city": "Brno",
    "region": "Czech Republic",
    "tz": "Europe/Prague"
  },
  {
    "iata": "YBT",
    "city": "Brochet",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "BHQ",
    "city": "Broken Hill",
    "region": "Australia",
    "tz": "Australia/Broken_Hill"
  },
  {
    "iata": "ZBM",
    "city": "Bromont",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "BNN",
    "city": "Bronnoysund",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "BME",
    "city": "Broome",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "BJC",
    "city": "Broomfield-CO",
    "region": "Colorado",
    "tz": "America/Denver"
  },
  {
    "iata": "YVM",
    "city": "Broughton Island",
    "region": "Canada",
    "tz": "America/Pangnirtung"
  },
  {
    "iata": "BRO",
    "city": "Brownsville",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "BQK",
    "city": "Brunswick",
    "region": "Georgia",
    "tz": "America/New_York"
  },
  {
    "iata": "SSI",
    "city": "Brunswick",
    "region": "Georgia",
    "tz": "America/New_York"
  },
  {
    "iata": "NHZ",
    "city": "Brunswick",
    "region": "Maine",
    "tz": "America/New_York"
  },
  {
    "iata": "BHG",
    "city": "Brus Laguna",
    "region": "Honduras",
    "tz": "America/Tegucigalpa"
  },
  {
    "iata": "BRU",
    "city": "Brussels",
    "region": "Belgium",
    "tz": "Europe/Brussels"
  },
  {
    "iata": "BQB",
    "city": "Brusselton",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "CFD",
    "city": "Bryan",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "BZK",
    "city": "Bryansk",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "BCE",
    "city": "Bryce Canyon",
    "region": "Utah",
    "tz": "America/Denver"
  },
  {
    "iata": "AAK",
    "city": "Buariki",
    "region": "Kiribati",
    "tz": "Pacific/Tarawa"
  },
  {
    "iata": "BQE",
    "city": "Bubaque",
    "region": "Guinea-Bissau",
    "tz": "Africa/Bissau"
  },
  {
    "iata": "BGA",
    "city": "Bucaramanga",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "BBU",
    "city": "Bucharest",
    "region": "Romania",
    "tz": "Europe/Bucharest"
  },
  {
    "iata": "OTP",
    "city": "Bucharest",
    "region": "Romania",
    "tz": "Europe/Bucharest"
  },
  {
    "iata": "BXK",
    "city": "Buckeye",
    "region": "Arizona",
    "tz": "America/Phoenix"
  },
  {
    "iata": "BKC",
    "city": "Buckland",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "BKF",
    "city": "Buckley",
    "region": "Alaska",
    "tz": "America/Denver"
  },
  {
    "iata": "BUD",
    "city": "Budapest",
    "region": "Hungary",
    "tz": "Europe/Budapest"
  },
  {
    "iata": "BUN",
    "city": "Buenaventura",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "AEP",
    "city": "Buenos Aires",
    "region": "Argentina",
    "tz": "America/Argentina/Buenos_Aires"
  },
  {
    "iata": "EZE",
    "city": "Buenos Aires",
    "region": "Argentina",
    "tz": "America/Argentina/Buenos_Aires"
  },
  {
    "iata": "BUF",
    "city": "Buffalo",
    "region": "New York",
    "tz": "America/New_York"
  },
  {
    "iata": "YVT",
    "city": "Buffalo Narrows",
    "region": "Canada",
    "tz": "America/Regina"
  },
  {
    "iata": "UUA",
    "city": "Bugulma",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "BJM",
    "city": "Bujumbura",
    "region": "Burundi",
    "tz": "Africa/Bujumbura"
  },
  {
    "iata": "BUA",
    "city": "Buka Island",
    "region": "Papua New Guinea",
    "tz": "Pacific/Port_Moresby"
  },
  {
    "iata": "BKY",
    "city": "Bukavu/kavumu",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Lubumbashi"
  },
  {
    "iata": "BHK",
    "city": "Bukhara",
    "region": "Uzbekistan",
    "tz": "Asia/Samarkand"
  },
  {
    "iata": "BKZ",
    "city": "Bukoba",
    "region": "Tanzania",
    "tz": "Africa/Dar_es_Salaam"
  },
  {
    "iata": "BUQ",
    "city": "Bulawayo",
    "region": "Zimbabwe",
    "tz": "Africa/Harare"
  },
  {
    "iata": "UGA",
    "city": "Bulgan",
    "region": "Mongolia",
    "tz": "Asia/Ulaanbaatar"
  },
  {
    "iata": "BMB",
    "city": "Bumba",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Kinshasa"
  },
  {
    "iata": "BUY",
    "city": "Bunbury",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "BDB",
    "city": "Bundaberg",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "BUX",
    "city": "Bunia",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Lubumbashi"
  },
  {
    "iata": "BXO",
    "city": "Buochs",
    "region": "Switzerland",
    "tz": "Europe/Zurich"
  },
  {
    "iata": "BMV",
    "city": "Buonmethuot",
    "region": "Vietnam",
    "tz": "Asia/Ho_Chi_Minh"
  },
  {
    "iata": "BUO",
    "city": "Burao",
    "region": "Somalia",
    "tz": "Africa/Mogadishu"
  },
  {
    "iata": "BUR",
    "city": "Burbank",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "RGS",
    "city": "Burgos",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "BFV",
    "city": "Buri Ram",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "BUC",
    "city": "Burketown",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "BYI",
    "city": "Burley",
    "region": "Idaho",
    "tz": "America/Boise"
  },
  {
    "iata": "BRL",
    "city": "Burlington",
    "region": "Iowa",
    "tz": "America/Chicago"
  },
  {
    "iata": "BUU",
    "city": "Burlington",
    "region": "United States",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "BTV",
    "city": "Burlington",
    "region": "Vermont",
    "tz": "America/New_York"
  },
  {
    "iata": "BWT",
    "city": "Burnie",
    "region": "Australia",
    "tz": "Australia/Hobart"
  },
  {
    "iata": "KJI",
    "city": "Burqin",
    "region": "China",
    "tz": "Asia/Urumqi"
  },
  {
    "iata": "BTZ",
    "city": "Bursa",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "YDB",
    "city": "Burwash",
    "region": "Canada",
    "tz": "America/Whitehorse"
  },
  {
    "iata": "PUS",
    "city": "Busan",
    "region": "South Korea",
    "tz": "Asia/Seoul"
  },
  {
    "iata": "AGS",
    "city": "Bush Field",
    "region": "Georgia",
    "tz": "America/New_York"
  },
  {
    "iata": "BUZ",
    "city": "Bushehr",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "USU",
    "city": "Busuanga",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "BBG",
    "city": "Butaritari",
    "region": "Kiribati",
    "tz": "Pacific/Tarawa"
  },
  {
    "iata": "BTM",
    "city": "Butte",
    "region": "Montana",
    "tz": "America/Denver"
  },
  {
    "iata": "BXU",
    "city": "Butuan",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "BZC",
    "city": "Buzios",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "BZG",
    "city": "Bydgoszcz",
    "region": "Poland",
    "tz": "Europe/Warsaw"
  },
  {
    "iata": "CBH",
    "city": "B\u00e9char",
    "region": "Algeria",
    "tz": "Africa/Algiers"
  },
  {
    "iata": "HEI",
    "city": "B\u00fcsum",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "CAH",
    "city": "Ca Mau",
    "region": "Vietnam",
    "tz": "Asia/Ho_Chi_Minh"
  },
  {
    "iata": "LHC",
    "city": "Caballococha",
    "region": "Tennessee",
    "tz": "America/Lima"
  },
  {
    "iata": "CAB",
    "city": "Cabinda",
    "region": "Angola",
    "tz": "Africa/Luanda"
  },
  {
    "iata": "CFB",
    "city": "Cabo Frio",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "CFC",
    "city": "Cacador",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "CCX",
    "city": "Caceres",
    "region": "Brazil",
    "tz": "America/Cuiaba"
  },
  {
    "iata": "OAL",
    "city": "Cacoal",
    "region": "Brazil",
    "tz": "America/Porto_Velho"
  },
  {
    "iata": "CFR",
    "city": "Caen",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "CAG",
    "city": "Cagliari",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "CNS",
    "city": "Cairns",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "CAI",
    "city": "Cairo",
    "region": "Egypt",
    "tz": "Africa/Cairo"
  },
  {
    "iata": "CJA",
    "city": "Cajamarca",
    "region": "Peru",
    "tz": "America/Lima"
  },
  {
    "iata": "CBQ",
    "city": "Calabar",
    "region": "Nigeria",
    "tz": "Africa/Lagos"
  },
  {
    "iata": "CQF",
    "city": "Calais",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "CJC",
    "city": "Calama",
    "region": "Chile",
    "tz": "America/Santiago"
  },
  {
    "iata": "CYP",
    "city": "Calbayog City",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "CLV",
    "city": "Caldas Novas",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "CDW",
    "city": "Caldwell",
    "region": "New Jersey",
    "tz": "America/New_York"
  },
  {
    "iata": "CXL",
    "city": "Calexico",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "YYC",
    "city": "Calgary",
    "region": "Canada",
    "tz": "America/Edmonton"
  },
  {
    "iata": "CLO",
    "city": "Cali",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "CCJ",
    "city": "Calicut",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "CLY",
    "city": "Calvi",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "CMW",
    "city": "Camaguey",
    "region": "Cuba",
    "tz": "America/Havana"
  },
  {
    "iata": "CBG",
    "city": "Cambridge",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "YCB",
    "city": "Cambridge Bay",
    "region": "Canada",
    "tz": "America/Cambridge_Bay"
  },
  {
    "iata": "CDU",
    "city": "Camden",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "CDN",
    "city": "Camden",
    "region": "South Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "CGM",
    "city": "Camiguin",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "VOK",
    "city": "Camp Douglas",
    "region": "Wisconsin",
    "tz": "America/Chicago"
  },
  {
    "iata": "ADW",
    "city": "Camp Springs",
    "region": "Maryland",
    "tz": "America/New_York"
  },
  {
    "iata": "YBL",
    "city": "Campbell River",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "CAL",
    "city": "Campbeltown",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "CPE",
    "city": "Campeche",
    "region": "Mexico",
    "tz": "America/Merida"
  },
  {
    "iata": "CPV",
    "city": "Campina Grande",
    "region": "Brazil",
    "tz": "America/Recife"
  },
  {
    "iata": "CPQ",
    "city": "Campinas",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "VCP",
    "city": "Campinas",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "CGR",
    "city": "Campo Grande",
    "region": "Brazil",
    "tz": "America/Campo_Grande"
  },
  {
    "iata": "CAW",
    "city": "Campos",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "VCA",
    "city": "Can Tho",
    "region": "Vietnam",
    "tz": "Asia/Ho_Chi_Minh"
  },
  {
    "iata": "CAJ",
    "city": "Canaima",
    "region": "Venezuela",
    "tz": "America/Caracas"
  },
  {
    "iata": "CKZ",
    "city": "Canakkale",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "CBR",
    "city": "Canberra",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "CUN",
    "city": "Cancun",
    "region": "Mexico",
    "tz": "America/Cancun"
  },
  {
    "iata": "DCF",
    "city": "Canefield",
    "region": "Dominica",
    "tz": "America/Dominica"
  },
  {
    "iata": "CEQ",
    "city": "Cannes",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "CIW",
    "city": "Canouan Island",
    "region": "Saint Vincent and the Grenadines",
    "tz": "America/St_Vincent"
  },
  {
    "iata": "CIS",
    "city": "Canton Island",
    "region": "Kiribati",
    "tz": "Pacific/Enderbury"
  },
  {
    "iata": "CAP",
    "city": "Cap Haitien",
    "region": "Haiti",
    "tz": "America/Port-au-Prince"
  },
  {
    "iata": "CSK",
    "city": "Cap Skiring",
    "region": "Senegal",
    "tz": "Africa/Dakar"
  },
  {
    "iata": "YTE",
    "city": "Cape Dorset",
    "region": "Canada",
    "tz": "America/Iqaluit"
  },
  {
    "iata": "CGI",
    "city": "Cape Girardeau",
    "region": "Missouri",
    "tz": "America/Chicago"
  },
  {
    "iata": "LUR",
    "city": "Cape Lisburne",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "EHM",
    "city": "Cape Newenham",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "CZF",
    "city": "Cape Romanzof",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "CPT",
    "city": "Cape Town",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "CPB",
    "city": "Capurgana",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "CCS",
    "city": "Caracas",
    "region": "Venezuela",
    "tz": "America/Caracas"
  },
  {
    "iata": "CSB",
    "city": "Caransebes",
    "region": "Romania",
    "tz": "Europe/Bucharest"
  },
  {
    "iata": "CAF",
    "city": "Carauari",
    "region": "Brazil",
    "tz": "America/Manaus"
  },
  {
    "iata": "CRQ",
    "city": "Caravelas",
    "region": "Brazil",
    "tz": "America/Bahia"
  },
  {
    "iata": "MDH",
    "city": "Carbondale/Murphysboro",
    "region": "Illinois",
    "tz": "America/Chicago"
  },
  {
    "iata": "CCF",
    "city": "Carcassonne",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "CWL",
    "city": "Cardiff",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "APO",
    "city": "Carepa",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "CAR",
    "city": "Caribou",
    "region": "Maine",
    "tz": "America/New_York"
  },
  {
    "iata": "CAX",
    "city": "Carlisle",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "CLD",
    "city": "Carlsbad",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "CNM",
    "city": "Carlsbad",
    "region": "New Mexico",
    "tz": "America/Denver"
  },
  {
    "iata": "CVQ",
    "city": "Carnarvon",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "CLN",
    "city": "Carolina",
    "region": "Brazil",
    "tz": "America/Araguaina"
  },
  {
    "iata": "PLD",
    "city": "Carrillo",
    "region": "Costa Rica",
    "tz": "America/Costa_Rica"
  },
  {
    "iata": "CTG",
    "city": "Cartagena",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "CRC",
    "city": "Cartago",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "YRF",
    "city": "Cartwright",
    "region": "Canada",
    "tz": "America/Goose_Bay"
  },
  {
    "iata": "CAU",
    "city": "Caruaru",
    "region": "Brazil",
    "tz": "America/Recife"
  },
  {
    "iata": "CUP",
    "city": "Carupano",
    "region": "Venezuela",
    "tz": "America/Caracas"
  },
  {
    "iata": "CGZ",
    "city": "Casa Grande",
    "region": "Arizona",
    "tz": "America/Phoenix"
  },
  {
    "iata": "CMN",
    "city": "Casablanca",
    "region": "Morocco",
    "tz": "Africa/Casablanca"
  },
  {
    "iata": "CAC",
    "city": "Cascavel",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "CPR",
    "city": "Casper",
    "region": "Wyoming",
    "tz": "America/Denver"
  },
  {
    "iata": "CDT",
    "city": "Castell\u00f3n de la Plana",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "YCG",
    "city": "Castlegar",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "DCM",
    "city": "Castres",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "SLU",
    "city": "Castries",
    "region": "Saint Lucia",
    "tz": "America/St_Lucia"
  },
  {
    "iata": "MHC",
    "city": "Castro",
    "region": "Chile",
    "tz": "America/Santiago"
  },
  {
    "iata": "YAC",
    "city": "Cat Lake",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "CTC",
    "city": "Catamarca",
    "region": "Argentina",
    "tz": "America/Argentina/Catamarca"
  },
  {
    "iata": "CTA",
    "city": "Catania",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "CRM",
    "city": "Catarman",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "MPH",
    "city": "Caticlan",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "CBT",
    "city": "Catumbela",
    "region": "Angola",
    "tz": "Africa/Luanda"
  },
  {
    "iata": "CYZ",
    "city": "Cauayan",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "CAQ",
    "city": "Caucasia",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "CXJ",
    "city": "Caxias Do Sul",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "CAY",
    "city": "Cayenne",
    "region": "French Guiana",
    "tz": "America/Cayenne"
  },
  {
    "iata": "CYB",
    "city": "Cayman Brac",
    "region": "Cayman Islands",
    "tz": "America/Cayman"
  },
  {
    "iata": "CYO",
    "city": "Cayo Largo del Sur",
    "region": "Cuba",
    "tz": "America/Havana"
  },
  {
    "iata": "BWW",
    "city": "Cayo Santa Maria",
    "region": "Cuba",
    "tz": "America/Havana"
  },
  {
    "iata": "CEB",
    "city": "Cebu",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "CDC",
    "city": "Cedar City",
    "region": "Utah",
    "tz": "America/Denver"
  },
  {
    "iata": "CID",
    "city": "Cedar Rapids",
    "region": "Iowa",
    "tz": "America/Chicago"
  },
  {
    "iata": "CED",
    "city": "Ceduna",
    "region": "Australia",
    "tz": "Australia/Adelaide"
  },
  {
    "iata": "RVR",
    "city": "Ceiba",
    "region": "Utah",
    "tz": "America/Denver"
  },
  {
    "iata": "CYW",
    "city": "Celaya",
    "region": "Mexico",
    "tz": "America/Mexico_City"
  },
  {
    "iata": "ZCN",
    "city": "Celle",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "CEM",
    "city": "Central",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "YCE",
    "city": "Centralia",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "CES",
    "city": "Cessnock",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "CHH",
    "city": "Chachapoyas",
    "region": "Peru",
    "tz": "America/Lima"
  },
  {
    "iata": "CDR",
    "city": "Chadron",
    "region": "Nebraska",
    "tz": "America/Denver"
  },
  {
    "iata": "CCN",
    "city": "Chaghcharan",
    "region": "Afghanistan",
    "tz": "Asia/Kabul"
  },
  {
    "iata": "ZBR",
    "city": "Chah Bahar",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "WCH",
    "city": "Chaiten",
    "region": "Chile",
    "tz": "America/Santiago"
  },
  {
    "iata": "CIK",
    "city": "Chalkyitsik",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "XCD",
    "city": "Chalon",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "XCR",
    "city": "Chalons",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "CMF",
    "city": "Chambery",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "CMI",
    "city": "Champaign",
    "region": "Illinois",
    "tz": "America/Chicago"
  },
  {
    "iata": "IXC",
    "city": "Chandigarh",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "BDP",
    "city": "Chandragarhi",
    "region": "Nepal",
    "tz": "Asia/Kathmandu"
  },
  {
    "iata": "CSX",
    "city": "Changcha",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "CGQ",
    "city": "Changchun",
    "region": "China",
    "tz": "Asia/Harbin"
  },
  {
    "iata": "CGD",
    "city": "Changde",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "CNI",
    "city": "Changhai",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "CHX",
    "city": "Changuinola",
    "region": "Panama",
    "tz": "America/Panama"
  },
  {
    "iata": "CIH",
    "city": "Changzhi",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "CZX",
    "city": "Changzhou",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "CHQ",
    "city": "Chania",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "CNU",
    "city": "Chanute",
    "region": "Kansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "CHG",
    "city": "Chaoyang",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "XAP",
    "city": "Chapeco",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "YLD",
    "city": "Chapleau",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "CRZ",
    "city": "Chardzhou",
    "region": "Turkmenistan",
    "tz": "Asia/Ashgabat"
  },
  {
    "iata": "CRL",
    "city": "Charleroi",
    "region": "Belgium",
    "tz": "Europe/Brussels"
  },
  {
    "iata": "CCY",
    "city": "Charles City",
    "region": "Iowa",
    "tz": "America/Chicago"
  },
  {
    "iata": "CHS",
    "city": "Charleston",
    "region": "South Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "CRW",
    "city": "Charleston",
    "region": "West Virginia",
    "tz": "America/New_York"
  },
  {
    "iata": "NEV",
    "city": "Charlestown",
    "region": "Saint Kitts and Nevis",
    "tz": "America/St_Kitts"
  },
  {
    "iata": "YML",
    "city": "Charlevoix",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "CTL",
    "city": "Charlieville",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "YCL",
    "city": "Charlo",
    "region": "Canada",
    "tz": "America/Moncton"
  },
  {
    "iata": "CLT",
    "city": "Charlotte",
    "region": "North Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "SPB",
    "city": "Charlotte Amalie",
    "region": "Virgin Islands",
    "tz": "America/St_Thomas"
  },
  {
    "iata": "CHO",
    "city": "Charlottesville VA",
    "region": "Virginia",
    "tz": "America/New_York"
  },
  {
    "iata": "YYG",
    "city": "Charlottetown",
    "region": "Canada",
    "tz": "America/Halifax"
  },
  {
    "iata": "CHR",
    "city": "Chateauroux",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "YCH",
    "city": "Chatham",
    "region": "Canada",
    "tz": "America/Moncton"
  },
  {
    "iata": "CHT",
    "city": "Chatham Island",
    "region": "New Zealand",
    "tz": "Pacific/Chatham"
  },
  {
    "iata": "CWT",
    "city": "Chatsworth",
    "region": "United States",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "CHA",
    "city": "Chattanooga",
    "region": "Tennessee",
    "tz": "America/New_York"
  },
  {
    "iata": "CNR",
    "city": "Cha\u00f1aral",
    "region": "Chile",
    "tz": "America/Santiago"
  },
  {
    "iata": "CSY",
    "city": "Cheboksary",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "CYF",
    "city": "Chefornak",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "CJU",
    "city": "Cheju",
    "region": "South Korea",
    "tz": "Asia/Seoul"
  },
  {
    "iata": "CEK",
    "city": "Chelyabinsk",
    "region": "Russia",
    "tz": "Asia/Yekaterinburg"
  },
  {
    "iata": "NCN",
    "city": "Chenega",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "CTU",
    "city": "Chengdu",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "CER",
    "city": "Cherbourg",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "CEE",
    "city": "Cherepovets",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "CKC",
    "city": "Cherkassy",
    "region": "Ukraine",
    "tz": "Europe/Kiev"
  },
  {
    "iata": "CEJ",
    "city": "Chernigov",
    "region": "Ukraine",
    "tz": "Europe/Kiev"
  },
  {
    "iata": "CWC",
    "city": "Chernovtsk",
    "region": "Ukraine",
    "tz": "Europe/Uzhgorod"
  },
  {
    "iata": "CYX",
    "city": "Cherskiy",
    "region": "Russia",
    "tz": "Asia/Magadan"
  },
  {
    "iata": "YCS",
    "city": "Chesterfield Inlet",
    "region": "Canada",
    "tz": "America/Rankin_Inlet"
  },
  {
    "iata": "CTM",
    "city": "Chetumal",
    "region": "Mexico",
    "tz": "America/Merida"
  },
  {
    "iata": "YCQ",
    "city": "Chetwynd",
    "region": "Canada",
    "tz": "America/Dawson_Creek"
  },
  {
    "iata": "VAK",
    "city": "Chevak",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "YHR",
    "city": "Chevery",
    "region": "Canada",
    "tz": "America/Blanc-Sablon"
  },
  {
    "iata": "CYS",
    "city": "Cheyenne",
    "region": "Wyoming",
    "tz": "America/Denver"
  },
  {
    "iata": "CNX",
    "city": "Chiang Mai",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "CEI",
    "city": "Chiang Rai",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "CYI",
    "city": "Chiayi",
    "region": "Taiwan",
    "tz": "Asia/Taipei"
  },
  {
    "iata": "YMT",
    "city": "Chibougamau",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "MDW",
    "city": "Chicago",
    "region": "Illinois",
    "tz": "America/Chicago"
  },
  {
    "iata": "ORD",
    "city": "Chicago",
    "region": "Illinois",
    "tz": "America/Chicago"
  },
  {
    "iata": "UGN",
    "city": "Chicago",
    "region": "Illinois",
    "tz": "America/Chicago"
  },
  {
    "iata": "PWK",
    "city": "Chicago-Wheeling",
    "region": "Illinois",
    "tz": "America/Chicago"
  },
  {
    "iata": "CZA",
    "city": "Chichen Itza",
    "region": "Mexico",
    "tz": "America/Merida"
  },
  {
    "iata": "CIX",
    "city": "Chiclayo",
    "region": "Peru",
    "tz": "America/Lima"
  },
  {
    "iata": "CIC",
    "city": "Chico",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "CEF",
    "city": "Chicopee Falls",
    "region": "Massachusetts",
    "tz": "America/New_York"
  },
  {
    "iata": "CIF",
    "city": "Chifeng",
    "region": "China",
    "tz": "Asia/Harbin"
  },
  {
    "iata": "CUU",
    "city": "Chihuahua",
    "region": "Mexico",
    "tz": "America/Chihuahua"
  },
  {
    "iata": "CDS",
    "city": "Childress",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "CCH",
    "city": "Chile Chico",
    "region": "Chile",
    "tz": "America/Santiago"
  },
  {
    "iata": "YCW",
    "city": "Chilliwack",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "CHM",
    "city": "Chimbote",
    "region": "Peru",
    "tz": "America/Lima"
  },
  {
    "iata": "CIT",
    "city": "Chimkent",
    "region": "Kazakhstan",
    "tz": "Asia/Almaty"
  },
  {
    "iata": "VPY",
    "city": "Chimoio",
    "region": "Mozambique",
    "tz": "Africa/Maputo"
  },
  {
    "iata": "NID",
    "city": "China Lake",
    "region": "United States",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "CCL",
    "city": "Chinchilla",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "CNO",
    "city": "Chino",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "JKH",
    "city": "Chios",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "CIP",
    "city": "Chipata",
    "region": "Zambia",
    "tz": "Africa/Lusaka"
  },
  {
    "iata": "BFO",
    "city": "Chiredzi",
    "region": "Zimbabwe",
    "tz": "Africa/Harare"
  },
  {
    "iata": "YKU",
    "city": "Chisasibi",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "KIV",
    "city": "Chisinau",
    "region": "Moldova",
    "tz": "Europe/Chisinau"
  },
  {
    "iata": "HTA",
    "city": "Chita",
    "region": "Russia",
    "tz": "Asia/Yakutsk"
  },
  {
    "iata": "SPK",
    "city": "Chitose",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "CJL",
    "city": "Chitral",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "CTD",
    "city": "Chitr\u00e9",
    "region": "Panama",
    "tz": "America/Panama"
  },
  {
    "iata": "CGP",
    "city": "Chittagong",
    "region": "Bangladesh",
    "tz": "Asia/Dhaka"
  },
  {
    "iata": "COQ",
    "city": "Choibalsan",
    "region": "Mongolia",
    "tz": "Asia/Choibalsan"
  },
  {
    "iata": "CHY",
    "city": "Choiseul Bay",
    "region": "Solomon Islands",
    "tz": "Pacific/Guadalcanal"
  },
  {
    "iata": "CKH",
    "city": "Chokurdah",
    "region": "Russia",
    "tz": "Asia/Magadan"
  },
  {
    "iata": "CET",
    "city": "Cholet",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "CJJ",
    "city": "Chongju",
    "region": "South Korea",
    "tz": "Asia/Seoul"
  },
  {
    "iata": "CKG",
    "city": "Chongqing",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "CHC",
    "city": "Christchurch",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "XCH",
    "city": "Christmas Island",
    "region": "Christmas Island",
    "tz": "Indian/Christmas"
  },
  {
    "iata": "VCL",
    "city": "Chu Lai",
    "region": "Vietnam",
    "tz": "Asia/Ho_Chi_Minh"
  },
  {
    "iata": "CCZ",
    "city": "Chub Cay",
    "region": "Bahamas",
    "tz": "America/Nassau"
  },
  {
    "iata": "CJM",
    "city": "Chumphon",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "YYQ",
    "city": "Churchill",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "ZUM",
    "city": "Churchill Falls",
    "region": "Canada",
    "tz": "America/Goose_Bay"
  },
  {
    "iata": "TKK",
    "city": "Chuuk",
    "region": "Micronesia",
    "tz": "Pacific/Chuuk"
  },
  {
    "iata": "ICI",
    "city": "Cicia",
    "region": "Fiji",
    "tz": "Pacific/Fiji"
  },
  {
    "iata": "AVI",
    "city": "Ciego De Avila",
    "region": "Cuba",
    "tz": "America/Havana"
  },
  {
    "iata": "CFG",
    "city": "Cienfuegos",
    "region": "Cuba",
    "tz": "America/Havana"
  },
  {
    "iata": "CXP",
    "city": "Cilacap",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "CVG",
    "city": "Cincinnati",
    "region": "Kentucky",
    "tz": "America/New_York"
  },
  {
    "iata": "LUK",
    "city": "Cincinnati",
    "region": "Ohio",
    "tz": "America/New_York"
  },
  {
    "iata": "IRC",
    "city": "Circle",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "CBN",
    "city": "Cirebon",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "CBL",
    "city": "Ciudad Bolivar",
    "region": "Venezuela",
    "tz": "America/Caracas"
  },
  {
    "iata": "CUA",
    "city": "Ciudad Constituci\u00f3n",
    "region": "Mexico",
    "tz": "America/Mazatlan"
  },
  {
    "iata": "CME",
    "city": "Ciudad Del Carmen",
    "region": "Mexico",
    "tz": "America/Merida"
  },
  {
    "iata": "CJS",
    "city": "Ciudad Juarez",
    "region": "Mexico",
    "tz": "America/Ojinaga"
  },
  {
    "iata": "CEN",
    "city": "Ciudad Obregon",
    "region": "Mexico",
    "tz": "America/Hermosillo"
  },
  {
    "iata": "CQM",
    "city": "Ciudad Real",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "CVM",
    "city": "Ciudad Victoria",
    "region": "Mexico",
    "tz": "America/Monterrey"
  },
  {
    "iata": "AGT",
    "city": "Ciudad del Este",
    "region": "Paraguay",
    "tz": "America/Asuncion"
  },
  {
    "iata": "NKT",
    "city": "Cizre",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "GBZ",
    "city": "Claris",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "CKB",
    "city": "Clarksburg",
    "region": "West Virginia",
    "tz": "America/New_York"
  },
  {
    "iata": "CKV",
    "city": "Clarksville",
    "region": "Tennessee",
    "tz": "America/Chicago"
  },
  {
    "iata": "CLW",
    "city": "Clearwater",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "CEU",
    "city": "Clemson",
    "region": "South Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "CMQ",
    "city": "Clermont",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "CFE",
    "city": "Clermont-Ferrand",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "BKL",
    "city": "Cleveland",
    "region": "Ohio",
    "tz": "America/New_York"
  },
  {
    "iata": "CLE",
    "city": "Cleveland",
    "region": "Ohio",
    "tz": "America/New_York"
  },
  {
    "iata": "CWI",
    "city": "Clinton",
    "region": "Iowa",
    "tz": "America/Chicago"
  },
  {
    "iata": "CNJ",
    "city": "Cloncurry",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "KFE",
    "city": "Cloudbreak",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "CVN",
    "city": "Clovis",
    "region": "New Mexico",
    "tz": "America/Denver"
  },
  {
    "iata": "CVS",
    "city": "Clovis",
    "region": "New Mexico",
    "tz": "America/Denver"
  },
  {
    "iata": "CMK",
    "city": "Club Makokola",
    "region": "Malawi",
    "tz": "Africa/Blantyre"
  },
  {
    "iata": "CLJ",
    "city": "Cluj-napoca",
    "region": "Romania",
    "tz": "Europe/Bucharest"
  },
  {
    "iata": "YCY",
    "city": "Clyde River",
    "region": "Canada",
    "tz": "America/Iqaluit"
  },
  {
    "iata": "CIZ",
    "city": "Coari",
    "region": "Brazil",
    "tz": "America/Manaus"
  },
  {
    "iata": "CTH",
    "city": "Coatesville",
    "region": "Pennsylvania",
    "tz": "America/New_York"
  },
  {
    "iata": "CBV",
    "city": "Coban",
    "region": "Guatemala",
    "tz": "America/Guatemala"
  },
  {
    "iata": "CAZ",
    "city": "Cobar",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "CIJ",
    "city": "Cobija",
    "region": "Bolivia",
    "tz": "America/La_Paz"
  },
  {
    "iata": "OCC",
    "city": "Coca",
    "region": "Ecuador",
    "tz": "America/Guayaquil"
  },
  {
    "iata": "CBB",
    "city": "Cochabamba",
    "region": "Bolivia",
    "tz": "America/La_Paz"
  },
  {
    "iata": "YCN",
    "city": "Cochrane",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "LGR",
    "city": "Cochrane",
    "region": "Chile",
    "tz": "America/Santiago"
  },
  {
    "iata": "CSO",
    "city": "Cochstedt",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "ZSA",
    "city": "Cockburn Town",
    "region": "Bahamas",
    "tz": "America/Nassau"
  },
  {
    "iata": "GDT",
    "city": "Cockburn Town",
    "region": "Turks and Caicos Islands",
    "tz": "America/Grand_Turk"
  },
  {
    "iata": "COF",
    "city": "Coco Beach",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "CNC",
    "city": "Coconut Island",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "CCK",
    "city": "Cocos Keeling Island",
    "region": "Cocos (Keeling) Islands",
    "tz": "Indian/Cocos"
  },
  {
    "iata": "BBQ",
    "city": "Codrington",
    "region": "Antigua and Barbuda",
    "tz": "America/Antigua"
  },
  {
    "iata": "COD",
    "city": "Cody",
    "region": "Wyoming",
    "tz": "America/Denver"
  },
  {
    "iata": "CUQ",
    "city": "Coen",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "COE",
    "city": "Coeur d'Alene",
    "region": "Idaho",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "CFS",
    "city": "Coff's Harbour",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "CFV",
    "city": "Coffeyville",
    "region": "Kansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "CNG",
    "city": "Cognac",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "CJB",
    "city": "Coimbatore",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "CDB",
    "city": "Cold Bay",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "PML",
    "city": "Cold Bay",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "YOD",
    "city": "Cold Lake",
    "region": "Canada",
    "tz": "America/Edmonton"
  },
  {
    "iata": "CLQ",
    "city": "Colima",
    "region": "Mexico",
    "tz": "America/Mexico_City"
  },
  {
    "iata": "CLL",
    "city": "College Station",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "CMR",
    "city": "Colmar",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "CGN",
    "city": "Cologne",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "CMB",
    "city": "Colombo",
    "region": "Sri Lanka",
    "tz": "Asia/Colombo"
  },
  {
    "iata": "RML",
    "city": "Colombo",
    "region": "Sri Lanka",
    "tz": "Asia/Colombo"
  },
  {
    "iata": "CBM",
    "city": "Colombus",
    "region": "Mississippi",
    "tz": "America/Chicago"
  },
  {
    "iata": "CRI",
    "city": "Colonel Hill",
    "region": "Bahamas",
    "tz": "America/Nassau"
  },
  {
    "iata": "CYR",
    "city": "Colonia",
    "region": "Uruguay",
    "tz": "America/Montevideo"
  },
  {
    "iata": "CSA",
    "city": "Colonsay",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "COS",
    "city": "Colorado Springs",
    "region": "Colorado",
    "tz": "America/Denver"
  },
  {
    "iata": "COU",
    "city": "Columbia",
    "region": "Missouri",
    "tz": "America/Chicago"
  },
  {
    "iata": "CAE",
    "city": "Columbia",
    "region": "South Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "CUB",
    "city": "Columbia",
    "region": "South Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "CSG",
    "city": "Columbus",
    "region": "Georgia",
    "tz": "America/New_York"
  },
  {
    "iata": "CLU",
    "city": "Columbus",
    "region": "Indiana",
    "tz": "America/Indiana/Indianapolis"
  },
  {
    "iata": "CMH",
    "city": "Columbus",
    "region": "Ohio",
    "tz": "America/New_York"
  },
  {
    "iata": "LCK",
    "city": "Columbus",
    "region": "Ohio",
    "tz": "America/New_York"
  },
  {
    "iata": "OSU",
    "city": "Columbus",
    "region": "Ohio",
    "tz": "America/New_York"
  },
  {
    "iata": "OLU",
    "city": "Columbus",
    "region": "United States",
    "tz": "America/Chicago"
  },
  {
    "iata": "TZR",
    "city": "Columbus",
    "region": "United States",
    "tz": "Europe/Budapest"
  },
  {
    "iata": "GTR",
    "city": "Columbus Mississippi",
    "region": "Mississippi",
    "tz": "America/Chicago"
  },
  {
    "iata": "YCK",
    "city": "Colville Lake",
    "region": "Canada",
    "tz": "America/Yellowknife"
  },
  {
    "iata": "ONX",
    "city": "Col\u00f3n",
    "region": "Panama",
    "tz": "America/Panama"
  },
  {
    "iata": "XPL",
    "city": "Comayagua",
    "region": "Honduras",
    "tz": "America/Tegucigalpa"
  },
  {
    "iata": "CIY",
    "city": "Comiso",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "CRD",
    "city": "Comodoro Rivadavia",
    "region": "Argentina",
    "tz": "America/Argentina/Catamarca"
  },
  {
    "iata": "YQQ",
    "city": "Comox",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "CKY",
    "city": "Conakry",
    "region": "Guinea",
    "tz": "Africa/Conakry"
  },
  {
    "iata": "CDJ",
    "city": "Conceicao Do Araguaia",
    "region": "Brazil",
    "tz": "America/Araguaina"
  },
  {
    "iata": "CCP",
    "city": "Concepcion",
    "region": "Chile",
    "tz": "America/Santiago"
  },
  {
    "iata": "CIO",
    "city": "Conception",
    "region": "Paraguay",
    "tz": "America/Asuncion"
  },
  {
    "iata": "CCR",
    "city": "Concord",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "CON",
    "city": "Concord NH",
    "region": "New Hampshire",
    "tz": "America/New_York"
  },
  {
    "iata": "COC",
    "city": "Concordia",
    "region": "Argentina",
    "tz": "America/Argentina/Cordoba"
  },
  {
    "iata": "CCI",
    "city": "Concordia",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "COG",
    "city": "Condoto",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "CFO",
    "city": "Confresa",
    "region": "Brazil",
    "tz": "America/Cuiaba"
  },
  {
    "iata": "QCY",
    "city": "Coningsby",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "NOC",
    "city": "Connaught",
    "region": "Ireland",
    "tz": "Europe/Dublin"
  },
  {
    "iata": "CXO",
    "city": "Conroe",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "VCS",
    "city": "Conson",
    "region": "Vietnam",
    "tz": "Asia/Ho_Chi_Minh"
  },
  {
    "iata": "CND",
    "city": "Constanta",
    "region": "Romania",
    "tz": "Europe/Bucharest"
  },
  {
    "iata": "CZL",
    "city": "Constantine",
    "region": "Algeria",
    "tz": "Africa/Algiers"
  },
  {
    "iata": "COZ",
    "city": "Constanza",
    "region": "Dominican Republic",
    "tz": "America/Santo_Domingo"
  },
  {
    "iata": "CPD",
    "city": "Coober Pedy",
    "region": "Australia",
    "tz": "Australia/Adelaide"
  },
  {
    "iata": "COH",
    "city": "Cooch-behar",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "CDA",
    "city": "Cooinda",
    "region": "Australia",
    "tz": "Australia/Darwin"
  },
  {
    "iata": "CTN",
    "city": "Cooktown",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "OOL",
    "city": "Coolangatta",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "OOM",
    "city": "Cooma",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "COJ",
    "city": "Coonabarabran",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "CNB",
    "city": "Coonamble",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "CJF",
    "city": "Coondewanna",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "CPH",
    "city": "Copenhagen",
    "region": "Denmark",
    "tz": "Europe/Copenhagen"
  },
  {
    "iata": "RKE",
    "city": "Copenhagen",
    "region": "Denmark",
    "tz": "Europe/Copenhagen"
  },
  {
    "iata": "CPO",
    "city": "Copiapo",
    "region": "Chile",
    "tz": "America/Santiago"
  },
  {
    "iata": "YCO",
    "city": "Coppermine",
    "region": "Canada",
    "tz": "America/Cambridge_Bay"
  },
  {
    "iata": "YZS",
    "city": "Coral Harbour",
    "region": "Canada",
    "tz": "America/Atikokan"
  },
  {
    "iata": "COR",
    "city": "Cordoba",
    "region": "Argentina",
    "tz": "America/Argentina/Cordoba"
  },
  {
    "iata": "ODB",
    "city": "Cordoba",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "CDV",
    "city": "Cordova",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "ORK",
    "city": "Cork",
    "region": "Ireland",
    "tz": "Europe/Dublin"
  },
  {
    "iata": "RNI",
    "city": "Corn Island",
    "region": "Nicaragua",
    "tz": "America/Managua"
  },
  {
    "iata": "YCC",
    "city": "Cornwall",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "CZE",
    "city": "Coro",
    "region": "Venezuela",
    "tz": "America/Caracas"
  },
  {
    "iata": "YCT",
    "city": "Coronation",
    "region": "Canada",
    "tz": "America/Edmonton"
  },
  {
    "iata": "CZU",
    "city": "Corozal",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "CRP",
    "city": "Corpus Christi",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "NGP",
    "city": "Corpus Christi",
    "region": "United States",
    "tz": "America/Chicago"
  },
  {
    "iata": "CNQ",
    "city": "Corrientes",
    "region": "Argentina",
    "tz": "America/Argentina/Cordoba"
  },
  {
    "iata": "CEZ",
    "city": "Cortez",
    "region": "Colorado",
    "tz": "America/Denver"
  },
  {
    "iata": "CMG",
    "city": "Corumba",
    "region": "Brazil",
    "tz": "America/Campo_Grande"
  },
  {
    "iata": "CVO",
    "city": "Corvallis",
    "region": "Oregon",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "CVU",
    "city": "Corvo",
    "region": "Portugal",
    "tz": "Atlantic/Azores"
  },
  {
    "iata": "CBO",
    "city": "Cotabato",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "OTR",
    "city": "Coto 47",
    "region": "Costa Rica",
    "tz": "America/Costa_Rica"
  },
  {
    "iata": "COO",
    "city": "Cotonou",
    "region": "Benin",
    "tz": "Africa/Porto-Novo"
  },
  {
    "iata": "COT",
    "city": "Cotulla",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "CBF",
    "city": "Council Bluffs",
    "region": "Iowa",
    "tz": "America/Chicago"
  },
  {
    "iata": "CVF",
    "city": "Courcheval",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "CVT",
    "city": "Coventry",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "CVE",
    "city": "Cove\u00f1as",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "CXB",
    "city": "Cox's Bazar",
    "region": "Bangladesh",
    "tz": "Asia/Dhaka"
  },
  {
    "iata": "GXQ",
    "city": "Coyhaique",
    "region": "Chile",
    "tz": "America/Santiago"
  },
  {
    "iata": "CZM",
    "city": "Cozumel",
    "region": "Mexico",
    "tz": "America/Cancun"
  },
  {
    "iata": "CCV",
    "city": "Craig Cove",
    "region": "Vanuatu",
    "tz": "Pacific/Efate"
  },
  {
    "iata": "CRA",
    "city": "Craiova",
    "region": "Romania",
    "tz": "Europe/Bucharest"
  },
  {
    "iata": "YXC",
    "city": "Cranbrook",
    "region": "Canada",
    "tz": "America/Edmonton"
  },
  {
    "iata": "CSF",
    "city": "Creil",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "CEC",
    "city": "Crescent City",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "CEW",
    "city": "Crestview",
    "region": "Florida",
    "tz": "America/Chicago"
  },
  {
    "iata": "EGI",
    "city": "Crestview",
    "region": "Florida",
    "tz": "America/Chicago"
  },
  {
    "iata": "CCM",
    "city": "Criciuma",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "CTY",
    "city": "Cross City",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "YCR",
    "city": "Cross Lake",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "CSV",
    "city": "Crossville",
    "region": "Tennessee",
    "tz": "America/Chicago"
  },
  {
    "iata": "CRV",
    "city": "Crotone",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "CZS",
    "city": "Cruzeiro do Sul",
    "region": "Brazil",
    "tz": "America/Eirunepe"
  },
  {
    "iata": "MIC",
    "city": "Crystal",
    "region": "United States",
    "tz": "America/Chicago"
  },
  {
    "iata": "CGC",
    "city": "Crystal River",
    "region": "United States",
    "tz": "Pacific/Port_Moresby"
  },
  {
    "iata": "FXO",
    "city": "Cuamba",
    "region": "Mozambique",
    "tz": "Africa/Maputo"
  },
  {
    "iata": "CUC",
    "city": "Cucuta",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "CDP",
    "city": "Cuddapah",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "CUE",
    "city": "Cuenca",
    "region": "Ecuador",
    "tz": "America/Guayaquil"
  },
  {
    "iata": "CVJ",
    "city": "Cuernavaca",
    "region": "Mexico",
    "tz": "America/Mexico_City"
  },
  {
    "iata": "CGB",
    "city": "Cuiaba",
    "region": "Brazil",
    "tz": "America/Cuiaba"
  },
  {
    "iata": "CPX",
    "city": "Culebra Island",
    "region": "Puerto Rico",
    "tz": "America/Puerto_Rico"
  },
  {
    "iata": "CUL",
    "city": "Culiacan",
    "region": "Mexico",
    "tz": "America/Mazatlan"
  },
  {
    "iata": "CUM",
    "city": "Cumana",
    "region": "Venezuela",
    "tz": "America/Caracas"
  },
  {
    "iata": "CBE",
    "city": "Cumberland",
    "region": "Maryland",
    "tz": "America/New_York"
  },
  {
    "iata": "CCC",
    "city": "Cunagua",
    "region": "Cuba",
    "tz": "America/Havana"
  },
  {
    "iata": "CUF",
    "city": "Cuneo",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "CMA",
    "city": "Cunnamulla",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "BFH",
    "city": "Curitiba",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "CWB",
    "city": "Curitiba",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "CTB",
    "city": "Cutbank",
    "region": "Montana",
    "tz": "America/Denver"
  },
  {
    "iata": "NDZ",
    "city": "Cuxhaven",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "CYU",
    "city": "Cuyo",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "CUZ",
    "city": "Cuzco",
    "region": "Peru",
    "tz": "America/Lima"
  },
  {
    "iata": "JNX",
    "city": "Cyclades Islands",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "TKI",
    "city": "DALLAS",
    "region": "Alaska",
    "tz": "America/Metlakatla"
  },
  {
    "iata": "DRV",
    "city": "DHARAVANDHOO",
    "region": "Maldives",
    "tz": "Indian/Maldives"
  },
  {
    "iata": "DBB",
    "city": "Dabaa City",
    "region": "Egypt",
    "tz": "Africa/Cairo"
  },
  {
    "iata": "DAG",
    "city": "Daggett",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "DKR",
    "city": "Dakar",
    "region": "Senegal",
    "tz": "Africa/Dakar"
  },
  {
    "iata": "VIL",
    "city": "Dakhla",
    "region": "Western Sahara",
    "tz": "Africa/El_Aaiun"
  },
  {
    "iata": "DLM",
    "city": "Dalaman",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "DLZ",
    "city": "Dalanzadgad",
    "region": "Mongolia",
    "tz": "Asia/Ulaanbaatar"
  },
  {
    "iata": "DLI",
    "city": "Dalat",
    "region": "Vietnam",
    "tz": "Asia/Ho_Chi_Minh"
  },
  {
    "iata": "DBA",
    "city": "Dalbandin",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "DHT",
    "city": "Dalhart",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "DLU",
    "city": "Dali",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "DLC",
    "city": "Dalian",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "DAL",
    "city": "Dallas",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "RBD",
    "city": "Dallas",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "DFW",
    "city": "Dallas-Fort Worth",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "DJO",
    "city": "Daloa",
    "region": "Cote d'Ivoire",
    "tz": "Africa/Abidjan"
  },
  {
    "iata": "DNN",
    "city": "Dalton",
    "region": "Georgia",
    "tz": "America/New_York"
  },
  {
    "iata": "NMB",
    "city": "Daman",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "DAM",
    "city": "Damascus",
    "region": "Syria",
    "tz": "Asia/Damascus"
  },
  {
    "iata": "DMM",
    "city": "Dammam",
    "region": "Saudi Arabia",
    "tz": "Asia/Riyadh"
  },
  {
    "iata": "DAD",
    "city": "Danang",
    "region": "Vietnam",
    "tz": "Asia/Ho_Chi_Minh"
  },
  {
    "iata": "DXR",
    "city": "Danbury",
    "region": "Connecticut",
    "tz": "America/New_York"
  },
  {
    "iata": "DNP",
    "city": "Dang",
    "region": "Nepal",
    "tz": "Asia/Kathmandu"
  },
  {
    "iata": "DNV",
    "city": "Danville",
    "region": "Illinois",
    "tz": "America/Chicago"
  },
  {
    "iata": "DAN",
    "city": "Danville",
    "region": "Virginia",
    "tz": "America/New_York"
  },
  {
    "iata": "DAR",
    "city": "Dar Es Salaam",
    "region": "Tanzania",
    "tz": "Africa/Dar_es_Salaam"
  },
  {
    "iata": "UDG",
    "city": "Darlington",
    "region": "United States",
    "tz": "America/New_York"
  },
  {
    "iata": "NLF",
    "city": "Darnley Island",
    "region": "Australia",
    "tz": "Pacific/Port_Moresby"
  },
  {
    "iata": "DAU",
    "city": "Daru",
    "region": "Papua New Guinea",
    "tz": "Pacific/Port_Moresby"
  },
  {
    "iata": "DRW",
    "city": "Darwin",
    "region": "Australia",
    "tz": "Australia/Darwin"
  },
  {
    "iata": "SRY",
    "city": "Dasht-e-naz",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "TAZ",
    "city": "Dasoguz",
    "region": "Turkmenistan",
    "tz": "Asia/Ashgabat"
  },
  {
    "iata": "DTD",
    "city": "Datadawai-Borneo Island",
    "region": "Indonesia",
    "tz": "Asia/Makassar"
  },
  {
    "iata": "DAT",
    "city": "Datong",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "DGP",
    "city": "Daugavpils",
    "region": "Latvia",
    "tz": "Europe/Riga"
  },
  {
    "iata": "YDN",
    "city": "Dauphin",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "DVO",
    "city": "Davao",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "DAV",
    "city": "David",
    "region": "Panama",
    "tz": "America/Panama"
  },
  {
    "iata": "DWA",
    "city": "Davis-Woodland-Winters",
    "region": "United States",
    "tz": "Africa/Blantyre"
  },
  {
    "iata": "DWD",
    "city": "Dawadmi",
    "region": "Saudi Arabia",
    "tz": "Asia/Riyadh"
  },
  {
    "iata": "TVY",
    "city": "Dawei",
    "region": "Burma",
    "tz": "Asia/Rangoon"
  },
  {
    "iata": "YDA",
    "city": "Dawson",
    "region": "Canada",
    "tz": "America/Whitehorse"
  },
  {
    "iata": "YDQ",
    "city": "Dawson Creek",
    "region": "Canada",
    "tz": "America/Dawson_Creek"
  },
  {
    "iata": "DYG",
    "city": "Dayong",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "DAY",
    "city": "Dayton",
    "region": "Ohio",
    "tz": "America/New_York"
  },
  {
    "iata": "FFO",
    "city": "Dayton",
    "region": "Ohio",
    "tz": "America/New_York"
  },
  {
    "iata": "MGY",
    "city": "Dayton",
    "region": "Ohio",
    "tz": "America/New_York"
  },
  {
    "iata": "DAB",
    "city": "Daytona Beach",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "DAX",
    "city": "Dazhou",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "DHR",
    "city": "De Kooy",
    "region": "Netherlands",
    "tz": "Europe/Amsterdam"
  },
  {
    "iata": "LGI",
    "city": "Dead Man's Cay",
    "region": "Bahamas",
    "tz": "America/Nassau"
  },
  {
    "iata": "SCC",
    "city": "Deadhorse",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "YDL",
    "city": "Dease Lake",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "DOL",
    "city": "Deauville",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "DBM",
    "city": "Debre Marqos",
    "region": "Ethiopia",
    "tz": "Africa/Addis_Ababa"
  },
  {
    "iata": "DBT",
    "city": "Debre Tabor",
    "region": "Ethiopia",
    "tz": "Africa/Addis_Ababa"
  },
  {
    "iata": "QHR",
    "city": "Debre Zeyit",
    "region": "Ethiopia",
    "tz": "Africa/Addis_Ababa"
  },
  {
    "iata": "DEB",
    "city": "Debrecen",
    "region": "Hungary",
    "tz": "Europe/Budapest"
  },
  {
    "iata": "DEC",
    "city": "Decatur",
    "region": "Illinois",
    "tz": "America/Chicago"
  },
  {
    "iata": "DCI",
    "city": "Decimomannu",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "YDF",
    "city": "Deer Lake",
    "region": "Canada",
    "tz": "America/St_Johns"
  },
  {
    "iata": "YVZ",
    "city": "Deer Lake",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "DRG",
    "city": "Deering",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "DED",
    "city": "Dehra Dun",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "DEZ",
    "city": "Deire Zor",
    "region": "Syria",
    "tz": "Asia/Damascus"
  },
  {
    "iata": "BJX",
    "city": "Del Bajio",
    "region": "Mexico",
    "tz": "America/Mexico_City"
  },
  {
    "iata": "DLF",
    "city": "Del Rio",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "DRT",
    "city": "Del Rio",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "DEL",
    "city": "Delhi",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "YWJ",
    "city": "Deline",
    "region": "Canada",
    "tz": "America/Yellowknife"
  },
  {
    "iata": "DTA",
    "city": "Delta",
    "region": "Utah",
    "tz": "America/Denver"
  },
  {
    "iata": "BIG",
    "city": "Delta Junction",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "DEM",
    "city": "Dembidollo",
    "region": "Ethiopia",
    "tz": "Africa/Addis_Ababa"
  },
  {
    "iata": "DMN",
    "city": "Deming",
    "region": "New Mexico",
    "tz": "America/Denver"
  },
  {
    "iata": "DNQ",
    "city": "Deniliquin",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "DNZ",
    "city": "Denizli",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "DPS",
    "city": "Denpasar",
    "region": "Indonesia",
    "tz": "Asia/Makassar"
  },
  {
    "iata": "APA",
    "city": "Denver",
    "region": "Colorado",
    "tz": "America/Denver"
  },
  {
    "iata": "DEN",
    "city": "Denver",
    "region": "Colorado",
    "tz": "America/Denver"
  },
  {
    "iata": "FTG",
    "city": "Denver",
    "region": "United States",
    "tz": "America/Denver"
  },
  {
    "iata": "DEA",
    "city": "Dera Ghazi Khan",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "DSK",
    "city": "Dera Ismael Khan",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "DCN",
    "city": "Derby",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "DRB",
    "city": "Derby",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "DRI",
    "city": "Deridder",
    "region": "Louisiana",
    "tz": "America/Chicago"
  },
  {
    "iata": "DSM",
    "city": "Des Moines",
    "region": "Iowa",
    "tz": "America/Chicago"
  },
  {
    "iata": "DES",
    "city": "Desroches",
    "region": "Seychelles",
    "tz": "Indian/Mahe"
  },
  {
    "iata": "DSE",
    "city": "Dessie",
    "region": "Ethiopia",
    "tz": "Africa/Addis_Ababa"
  },
  {
    "iata": "DET",
    "city": "Detroit",
    "region": "Michigan",
    "tz": "America/Detroit"
  },
  {
    "iata": "DTW",
    "city": "Detroit",
    "region": "Michigan",
    "tz": "America/Detroit"
  },
  {
    "iata": "YIP",
    "city": "Detroit",
    "region": "Michigan",
    "tz": "America/Detroit"
  },
  {
    "iata": "DVL",
    "city": "Devils Lake",
    "region": "North Dakota",
    "tz": "America/Chicago"
  },
  {
    "iata": "DPO",
    "city": "Devonport",
    "region": "Australia",
    "tz": "Australia/Hobart"
  },
  {
    "iata": "DHA",
    "city": "Dhahran",
    "region": "Saudi Arabia",
    "tz": "Asia/Riyadh"
  },
  {
    "iata": "DAC",
    "city": "Dhaka",
    "region": "Bangladesh",
    "tz": "Asia/Dhaka"
  },
  {
    "iata": "DBD",
    "city": "Dhanbad",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "DHI",
    "city": "Dhangarhi",
    "region": "Nepal",
    "tz": "Asia/Kathmandu"
  },
  {
    "iata": "DTI",
    "city": "Diamantina",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "DMT",
    "city": "Diamantino",
    "region": "Brazil",
    "tz": "America/Cuiaba"
  },
  {
    "iata": "DIK",
    "city": "Dickinson",
    "region": "North Dakota",
    "tz": "America/Denver"
  },
  {
    "iata": "DIN",
    "city": "Dienbienphu",
    "region": "Vietnam",
    "tz": "Asia/Ho_Chi_Minh"
  },
  {
    "iata": "YDG",
    "city": "Digby",
    "region": "Canada",
    "tz": "America/Halifax"
  },
  {
    "iata": "DIJ",
    "city": "Dijon",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "DKS",
    "city": "Dikson",
    "region": "Russia",
    "tz": "Asia/Krasnoyarsk"
  },
  {
    "iata": "DIL",
    "city": "Dili",
    "region": "East Timor",
    "tz": "Asia/Dili"
  },
  {
    "iata": "DLG",
    "city": "Dillingham",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "HDH",
    "city": "Dillingham",
    "region": "Hawaii",
    "tz": "Pacific/Honolulu"
  },
  {
    "iata": "DLY",
    "city": "Dillon's Bay",
    "region": "Vanuatu",
    "tz": "Pacific/Efate"
  },
  {
    "iata": "DMU",
    "city": "Dimapur",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "DNR",
    "city": "Dinard",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "DPL",
    "city": "Dipolog",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "DIR",
    "city": "Dire Dawa",
    "region": "Ethiopia",
    "tz": "Africa/Addis_Ababa"
  },
  {
    "iata": "DIU",
    "city": "Diu",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "DIQ",
    "city": "Divinopolis",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "DIY",
    "city": "Diyabakir",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "DJG",
    "city": "Djanet",
    "region": "Algeria",
    "tz": "Africa/Algiers"
  },
  {
    "iata": "QDJ",
    "city": "Djelfa",
    "region": "Algeria",
    "tz": "Africa/Algiers"
  },
  {
    "iata": "DJE",
    "city": "Djerba",
    "region": "Tunisia",
    "tz": "Africa/Tunis"
  },
  {
    "iata": "JIB",
    "city": "Djibouti",
    "region": "Djibouti",
    "tz": "Africa/Djibouti"
  },
  {
    "iata": "DNK",
    "city": "Dnepropetrovsk",
    "region": "Ukraine",
    "tz": "Europe/Zaporozhye"
  },
  {
    "iata": "DDC",
    "city": "Dodge City",
    "region": "Kansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "DOD",
    "city": "Dodoma",
    "region": "Tanzania",
    "tz": "Africa/Dar_es_Salaam"
  },
  {
    "iata": "YDO",
    "city": "Dolbeau-St-F\u00e9licien",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "DLE",
    "city": "Dole",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "DOP",
    "city": "Dolpa",
    "region": "Nepal",
    "tz": "Asia/Kathmandu"
  },
  {
    "iata": "DOM",
    "city": "Dominica",
    "region": "Dominica",
    "tz": "America/Dominica"
  },
  {
    "iata": "DOK",
    "city": "Donetsk",
    "region": "Ukraine",
    "tz": "Europe/Zaporozhye"
  },
  {
    "iata": "CFN",
    "city": "Dongloe",
    "region": "Ireland",
    "tz": "Europe/Dublin"
  },
  {
    "iata": "DOG",
    "city": "Dongola",
    "region": "Sudan",
    "tz": "Africa/Khartoum"
  },
  {
    "iata": "DSN",
    "city": "Dongsheng",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "DMD",
    "city": "Doomadgee",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "DTM",
    "city": "Dortmund",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "DHN",
    "city": "Dothan",
    "region": "Alabama",
    "tz": "America/Chicago"
  },
  {
    "iata": "DLA",
    "city": "Douala",
    "region": "Cameroon",
    "tz": "Africa/Douala"
  },
  {
    "iata": "DQH",
    "city": "Douglas",
    "region": "Alaska",
    "tz": "America/Juneau"
  },
  {
    "iata": "DGL",
    "city": "Douglas",
    "region": "Arizona",
    "tz": "America/Phoenix"
  },
  {
    "iata": "DUG",
    "city": "Douglas",
    "region": "Arizona",
    "tz": "America/Phoenix"
  },
  {
    "iata": "DOU",
    "city": "Dourados",
    "region": "Brazil",
    "tz": "America/Campo_Grande"
  },
  {
    "iata": "DOV",
    "city": "Dover",
    "region": "Delaware",
    "tz": "America/Indiana/Indianapolis"
  },
  {
    "iata": "DYL",
    "city": "Doylestown",
    "region": "Pennsylvania",
    "tz": "America/New_York"
  },
  {
    "iata": "DRS",
    "city": "Dresden",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "DRJ",
    "city": "Drietabbetje",
    "region": "Suriname",
    "tz": "America/Paramaribo"
  },
  {
    "iata": "YHD",
    "city": "Dryden",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "DUJ",
    "city": "Du Bois",
    "region": "Pennsylvania",
    "tz": "America/New_York"
  },
  {
    "iata": "DWC",
    "city": "Dubai",
    "region": "United Arab Emirates",
    "tz": "Asia/Dubai"
  },
  {
    "iata": "DXB",
    "city": "Dubai",
    "region": "United Arab Emirates",
    "tz": "Asia/Dubai"
  },
  {
    "iata": "DBO",
    "city": "Dubbo",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "DBN",
    "city": "Dublin",
    "region": "Georgia",
    "tz": "America/New_York"
  },
  {
    "iata": "DUB",
    "city": "Dublin",
    "region": "Ireland",
    "tz": "Europe/Dublin"
  },
  {
    "iata": "DBV",
    "city": "Dubrovnik",
    "region": "Croatia",
    "tz": "Europe/Zagreb"
  },
  {
    "iata": "DBQ",
    "city": "Dubuque IA",
    "region": "Iowa",
    "tz": "America/Chicago"
  },
  {
    "iata": "DUS",
    "city": "Duesseldorf",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "DLK",
    "city": "Dulkaninna",
    "region": "Australia",
    "tz": "Australia/Adelaide"
  },
  {
    "iata": "DLH",
    "city": "Duluth",
    "region": "Minnesota",
    "tz": "America/Chicago"
  },
  {
    "iata": "DGT",
    "city": "Dumaguete",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "DUM",
    "city": "Dumai",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "DUC",
    "city": "Duncan",
    "region": "Oklahoma",
    "tz": "America/Chicago"
  },
  {
    "iata": "DND",
    "city": "Dundee",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "DUE",
    "city": "Dundo",
    "region": "Angola",
    "tz": "Africa/Luanda"
  },
  {
    "iata": "DUD",
    "city": "Dunedin",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "DNH",
    "city": "Dunhuang",
    "region": "China",
    "tz": "Asia/Urumqi"
  },
  {
    "iata": "DKI",
    "city": "Dunk Island",
    "region": "Australia",
    "tz": "Australia/Lindeman"
  },
  {
    "iata": "DKK",
    "city": "Dunkirk",
    "region": "New York",
    "tz": "America/New_York"
  },
  {
    "iata": "DRO",
    "city": "Durango",
    "region": "Colorado",
    "tz": "America/Denver"
  },
  {
    "iata": "DGO",
    "city": "Durango",
    "region": "Mexico",
    "tz": "America/Monterrey"
  },
  {
    "iata": "DUR",
    "city": "Durban",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "VIR",
    "city": "Durban",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "RDP",
    "city": "Durgapur",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "DYU",
    "city": "Dushanbe",
    "region": "Tajikistan",
    "tz": "Asia/Dushanbe"
  },
  {
    "iata": "QFO",
    "city": "Duxford",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "DZA",
    "city": "Dzaoudzi",
    "region": "Mayotte",
    "tz": "Indian/Mayotte"
  },
  {
    "iata": "DMB",
    "city": "Dzhambul",
    "region": "Kazakhstan",
    "tz": "Asia/Almaty"
  },
  {
    "iata": "YEL",
    "city": "ELLIOT LAKE",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "EAA",
    "city": "Eagle",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "EGV",
    "city": "Eagle River",
    "region": "Wisconsin",
    "tz": "America/Chicago"
  },
  {
    "iata": "YXR",
    "city": "Earlton",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "ELS",
    "city": "East London",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "EMA",
    "city": "East Midlands",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "IPC",
    "city": "Easter Island",
    "region": "Chile",
    "tz": "Pacific/Easter"
  },
  {
    "iata": "ZEM",
    "city": "Eastmain River",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "ESN",
    "city": "Easton",
    "region": "Maryland",
    "tz": "America/New_York"
  },
  {
    "iata": "MMT",
    "city": "Eastover",
    "region": "South Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "ESD",
    "city": "Eastsound",
    "region": "Washington",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "EAU",
    "city": "Eau Claire",
    "region": "Wisconsin",
    "tz": "America/Chicago"
  },
  {
    "iata": "EOI",
    "city": "Eday",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "FCM",
    "city": "Eden Prairie",
    "region": "Minnesota",
    "tz": "America/Chicago"
  },
  {
    "iata": "EDI",
    "city": "Edinburgh",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "YEG",
    "city": "Edmonton",
    "region": "Canada",
    "tz": "America/Edmonton"
  },
  {
    "iata": "YXD",
    "city": "Edmonton",
    "region": "Canada",
    "tz": "America/Edmonton"
  },
  {
    "iata": "YET",
    "city": "Edson",
    "region": "Canada",
    "tz": "America/Edmonton"
  },
  {
    "iata": "EDW",
    "city": "Edwards Afb",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "EEK",
    "city": "Eek",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "EGX",
    "city": "Egegik",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "QEF",
    "city": "Egelsbach",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "EGS",
    "city": "Egilsstadir",
    "region": "Iceland",
    "tz": "Atlantic/Reykjavik"
  },
  {
    "iata": "EIN",
    "city": "Eindhoven",
    "region": "Netherlands",
    "tz": "Europe/Amsterdam"
  },
  {
    "iata": "ERN",
    "city": "Eirunepe",
    "region": "Brazil",
    "tz": "America/Manaus"
  },
  {
    "iata": "YOA",
    "city": "Ekati",
    "region": "Canada",
    "tz": "America/Yellowknife"
  },
  {
    "iata": "EUN",
    "city": "El Aai\u00fan",
    "region": "Western Sahara",
    "tz": "Africa/El_Aaiun"
  },
  {
    "iata": "AAC",
    "city": "El Arish",
    "region": "Egypt",
    "tz": "Africa/Cairo"
  },
  {
    "iata": "NDR",
    "city": "El Aroui",
    "region": "Morocco",
    "tz": "Africa/Casablanca"
  },
  {
    "iata": "EBG",
    "city": "El Bagre",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "EHL",
    "city": "El Bolson",
    "region": "Argentina",
    "tz": "America/Argentina/Salta"
  },
  {
    "iata": "EBM",
    "city": "El Borma",
    "region": "Tunisia",
    "tz": "Africa/Tunis"
  },
  {
    "iata": "SEE",
    "city": "El Cajon",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "FTE",
    "city": "El Calafate",
    "region": "Argentina",
    "tz": "America/Argentina/Rio_Gallegos"
  },
  {
    "iata": "ING",
    "city": "El Calafate",
    "region": "Argentina",
    "tz": "America/Argentina/Rio_Gallegos"
  },
  {
    "iata": "NJK",
    "city": "El Centro",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "ELD",
    "city": "El Dorado",
    "region": "Arkansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "ELF",
    "city": "El Fasher",
    "region": "Sudan",
    "tz": "Africa/Khartoum"
  },
  {
    "iata": "ELG",
    "city": "El Golea",
    "region": "Algeria",
    "tz": "Africa/Algiers"
  },
  {
    "iata": "EMT",
    "city": "El Monte",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "EBD",
    "city": "El Obeid",
    "region": "Sudan",
    "tz": "Africa/Khartoum"
  },
  {
    "iata": "BIF",
    "city": "El Paso",
    "region": "Texas",
    "tz": "America/Denver"
  },
  {
    "iata": "ELP",
    "city": "El Paso",
    "region": "Texas",
    "tz": "America/Denver"
  },
  {
    "iata": "ESR",
    "city": "El Salvador",
    "region": "Chile",
    "tz": "America/Santiago"
  },
  {
    "iata": "VIG",
    "city": "El Vig\u00eda",
    "region": "Venezuela",
    "tz": "America/Caracas"
  },
  {
    "iata": "ABT",
    "city": "El-baha",
    "region": "Saudi Arabia",
    "tz": "Asia/Riyadh"
  },
  {
    "iata": "ELT",
    "city": "El-tor",
    "region": "Egypt",
    "tz": "Africa/Cairo"
  },
  {
    "iata": "ETH",
    "city": "Elat",
    "region": "Israel",
    "tz": "Asia/Jerusalem"
  },
  {
    "iata": "EZS",
    "city": "Elazig",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "ELC",
    "city": "Elcho Island",
    "region": "Australia",
    "tz": "Australia/Darwin"
  },
  {
    "iata": "EDL",
    "city": "Eldoret",
    "region": "Kenya",
    "tz": "Africa/Nairobi"
  },
  {
    "iata": "ELV",
    "city": "Elfin Cove",
    "region": "Alaska",
    "tz": "America/Juneau"
  },
  {
    "iata": "ELI",
    "city": "Elim",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "ESL",
    "city": "Elista",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "EKI",
    "city": "Elkhart",
    "region": "Indiana",
    "tz": "America/Indiana/Indianapolis"
  },
  {
    "iata": "EKN",
    "city": "Elkins",
    "region": "West Virginia",
    "tz": "America/New_York"
  },
  {
    "iata": "EKO",
    "city": "Elko",
    "region": "Nevada",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "ELM",
    "city": "Elmira",
    "region": "New York",
    "tz": "America/New_York"
  },
  {
    "iata": "LYU",
    "city": "Ely",
    "region": "Minnesota",
    "tz": "America/Chicago"
  },
  {
    "iata": "ELY",
    "city": "Ely",
    "region": "Nevada",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "EME",
    "city": "Emden",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "EMD",
    "city": "Emerald",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "EML",
    "city": "Emmen",
    "region": "Switzerland",
    "tz": "Europe/Zurich"
  },
  {
    "iata": "EMK",
    "city": "Emmonak",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "EMV",
    "city": "Emporia",
    "region": "United States",
    "tz": "America/New_York"
  },
  {
    "iata": "ENE",
    "city": "Ende",
    "region": "Indonesia",
    "tz": "Asia/Makassar"
  },
  {
    "iata": "END",
    "city": "Enid",
    "region": "Oklahoma",
    "tz": "America/Chicago"
  },
  {
    "iata": "ENT",
    "city": "Eniwetok Atoll",
    "region": "Marshall Islands",
    "tz": "Pacific/Pohnpei"
  },
  {
    "iata": "ENK",
    "city": "Enniskillen",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "ENF",
    "city": "Enontekio",
    "region": "Finland",
    "tz": "Europe/Helsinki"
  },
  {
    "iata": "ENS",
    "city": "Enschede",
    "region": "Netherlands",
    "tz": "Europe/Amsterdam"
  },
  {
    "iata": "ESE",
    "city": "Ensenada",
    "region": "Mexico",
    "tz": "America/Tijuana"
  },
  {
    "iata": "ENH",
    "city": "Enshi",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "EBB",
    "city": "Entebbe",
    "region": "Uganda",
    "tz": "Africa/Kampala"
  },
  {
    "iata": "EDN",
    "city": "Enterprise",
    "region": "United States",
    "tz": "Asia/Vladivostok"
  },
  {
    "iata": "ENU",
    "city": "Enugu",
    "region": "Nigeria",
    "tz": "Africa/Lagos"
  },
  {
    "iata": "EPL",
    "city": "Epinal",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "ERH",
    "city": "Er-rachidia",
    "region": "Morocco",
    "tz": "Africa/Casablanca"
  },
  {
    "iata": "EBL",
    "city": "Erbil",
    "region": "Iraq",
    "tz": "Asia/Baghdad"
  },
  {
    "iata": "ERM",
    "city": "Erechim",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "ERL",
    "city": "Erenhot",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "ERF",
    "city": "Erfurt",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "ERI",
    "city": "Erie",
    "region": "Pennsylvania",
    "tz": "America/New_York"
  },
  {
    "iata": "ERC",
    "city": "Erzincan",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "ERZ",
    "city": "Erzurum",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "EBJ",
    "city": "Esbjerg",
    "region": "Denmark",
    "tz": "Europe/Copenhagen"
  },
  {
    "iata": "ESC",
    "city": "Escanaba",
    "region": "Michigan",
    "tz": "America/Detroit"
  },
  {
    "iata": "IFN",
    "city": "Esfahan",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "YEK",
    "city": "Eskimo Point",
    "region": "Canada",
    "tz": "America/Rankin_Inlet"
  },
  {
    "iata": "ESK",
    "city": "Eskisehir",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "AOE",
    "city": "Eskissehir",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "ESM",
    "city": "Esmeraldas",
    "region": "Ecuador",
    "tz": "America/Guayaquil"
  },
  {
    "iata": "EPR",
    "city": "Esperance",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "EQS",
    "city": "Esquel",
    "region": "Argentina",
    "tz": "America/Argentina/Catamarca"
  },
  {
    "iata": "ESU",
    "city": "Essadouira",
    "region": "Morocco",
    "tz": "Africa/Casablanca"
  },
  {
    "iata": "ESS",
    "city": "Essen",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "YEN",
    "city": "Estevan",
    "region": "Canada",
    "tz": "America/Regina"
  },
  {
    "iata": "EUA",
    "city": "Eua Island",
    "region": "Tonga",
    "tz": "Pacific/Tongatapu"
  },
  {
    "iata": "EUF",
    "city": "Eufala",
    "region": "Alabama",
    "tz": "America/Chicago"
  },
  {
    "iata": "EUG",
    "city": "Eugene",
    "region": "Oregon",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "EKA",
    "city": "Eureka",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "YEU",
    "city": "Eureka",
    "region": "Canada",
    "tz": "America/Rankin_Inlet"
  },
  {
    "iata": "EVW",
    "city": "Evanston",
    "region": "Wyoming",
    "tz": "America/Denver"
  },
  {
    "iata": "EVV",
    "city": "Evansville",
    "region": "Indiana",
    "tz": "America/Chicago"
  },
  {
    "iata": "PAE",
    "city": "Everett",
    "region": "Washington",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "EXT",
    "city": "Exeter",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "VDB",
    "city": "Fagernes",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "FIE",
    "city": "Fair Isle",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "EIL",
    "city": "Fairbanks",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "FAI",
    "city": "Fairbanks",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "SUU",
    "city": "Fairfield",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "FFD",
    "city": "Fairford",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "LYP",
    "city": "Faisalabad",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "FBD",
    "city": "Faizabad",
    "region": "Afghanistan",
    "tz": "Asia/Kabul"
  },
  {
    "iata": "FAJ",
    "city": "Fajardo",
    "region": "Puerto Rico",
    "tz": "America/Puerto_Rico"
  },
  {
    "iata": "FKQ",
    "city": "Fak Fak",
    "region": "Indonesia",
    "tz": "Asia/Jayapura"
  },
  {
    "iata": "FAV",
    "city": "Fakarava",
    "region": "French Polynesia",
    "tz": "Pacific/Tahiti"
  },
  {
    "iata": "APW",
    "city": "Faleolo",
    "region": "Samoa",
    "tz": "Pacific/Apia"
  },
  {
    "iata": "KFP",
    "city": "False Pass",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "RVA",
    "city": "Farafangana",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "FAH",
    "city": "Farah",
    "region": "Afghanistan",
    "tz": "Asia/Kabul"
  },
  {
    "iata": "FAA",
    "city": "Faranah",
    "region": "Guinea",
    "tz": "Africa/Conakry"
  },
  {
    "iata": "FAR",
    "city": "Fargo",
    "region": "North Dakota",
    "tz": "America/Chicago"
  },
  {
    "iata": "FMN",
    "city": "Farmington",
    "region": "New Mexico",
    "tz": "America/Denver"
  },
  {
    "iata": "FAB",
    "city": "Farnborough",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "ZFA",
    "city": "Faro",
    "region": "Canada",
    "tz": "America/Whitehorse"
  },
  {
    "iata": "FAO",
    "city": "Faro",
    "region": "Portugal",
    "tz": "Europe/Lisbon"
  },
  {
    "iata": "FAN",
    "city": "Farsund",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "FYT",
    "city": "Faya-largeau",
    "region": "Chad",
    "tz": "Africa/Ndjamena"
  },
  {
    "iata": "FYV",
    "city": "Fayetteville",
    "region": "Arkansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "FAY",
    "city": "Fayetteville",
    "region": "North Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "TTT",
    "city": "Fengnin",
    "region": "Taiwan",
    "tz": "Asia/Taipei"
  },
  {
    "iata": "FRE",
    "city": "Fera Island",
    "region": "Solomon Islands",
    "tz": "Pacific/Guadalcanal"
  },
  {
    "iata": "FEG",
    "city": "Fergana",
    "region": "Uzbekistan",
    "tz": "Asia/Tashkent"
  },
  {
    "iata": "FEN",
    "city": "Fernando Do Noronha",
    "region": "Brazil",
    "tz": "America/Noronha"
  },
  {
    "iata": "FEZ",
    "city": "Fes",
    "region": "Morocco",
    "tz": "Africa/Casablanca"
  },
  {
    "iata": "WFI",
    "city": "Fianarantsoa",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "FSC",
    "city": "Figari",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "FDY",
    "city": "Findley",
    "region": "Ohio",
    "tz": "America/New_York"
  },
  {
    "iata": "FIG",
    "city": "Fira",
    "region": "Guinea",
    "tz": "Africa/Conakry"
  },
  {
    "iata": "FIT",
    "city": "Fitchburg",
    "region": "United States",
    "tz": "America/New_York"
  },
  {
    "iata": "FTI",
    "city": "Fiti\\\\'uta",
    "region": "American Samoa",
    "tz": "Pacific/Pago_Pago"
  },
  {
    "iata": "FIZ",
    "city": "Fitzroy Crossing",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "FLG",
    "city": "Flagstaff",
    "region": "Arizona",
    "tz": "America/Phoenix"
  },
  {
    "iata": "FLF",
    "city": "Flensburg",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "YFO",
    "city": "Flin Flon",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "FLS",
    "city": "Flinders Island",
    "region": "Australia",
    "tz": "Australia/Hobart"
  },
  {
    "iata": "FNT",
    "city": "Flint",
    "region": "Michigan",
    "tz": "America/Detroit"
  },
  {
    "iata": "FLR",
    "city": "Florence",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "FLO",
    "city": "Florence",
    "region": "South Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "FLA",
    "city": "Florencia",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "FRS",
    "city": "Flores",
    "region": "Guatemala",
    "tz": "America/Guatemala"
  },
  {
    "iata": "FLW",
    "city": "Flores",
    "region": "Portugal",
    "tz": "Atlantic/Azores"
  },
  {
    "iata": "FLB",
    "city": "Floriano",
    "region": "Brazil",
    "tz": "America/Fortaleza"
  },
  {
    "iata": "FLN",
    "city": "Florianopolis",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "FRO",
    "city": "Floro",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "FOG",
    "city": "Foggia",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "FLD",
    "city": "Fond du Lac",
    "region": "Wisconsin",
    "tz": "America/Chicago"
  },
  {
    "iata": "ZFD",
    "city": "Fond-Du-Lac",
    "region": "Canada",
    "tz": "America/Regina"
  },
  {
    "iata": "FBA",
    "city": "Fonte Boa",
    "region": "Brazil",
    "tz": "America/Manaus"
  },
  {
    "iata": "FRB",
    "city": "Forbes",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "FRL",
    "city": "Forli",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "FMA",
    "city": "Formosa",
    "region": "Argentina",
    "tz": "America/Argentina/Cordoba"
  },
  {
    "iata": "YFA",
    "city": "Fort Albany",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "DAA",
    "city": "Fort Belvoir",
    "region": "Virginia",
    "tz": "America/New_York"
  },
  {
    "iata": "LSF",
    "city": "Fort Benning",
    "region": "Georgia",
    "tz": "America/New_York"
  },
  {
    "iata": "POB",
    "city": "Fort Bragg",
    "region": "North Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "FCS",
    "city": "Fort Carson",
    "region": "Colorado",
    "tz": "America/Denver"
  },
  {
    "iata": "YPY",
    "city": "Fort Chipewyan",
    "region": "Canada",
    "tz": "America/Edmonton"
  },
  {
    "iata": "FNL",
    "city": "Fort Collins",
    "region": "Colorado",
    "tz": "America/Denver"
  },
  {
    "iata": "FOD",
    "city": "Fort Dodge",
    "region": "Iowa",
    "tz": "America/Chicago"
  },
  {
    "iata": "FAF",
    "city": "Fort Eustis",
    "region": "Virginia",
    "tz": "America/New_York"
  },
  {
    "iata": "YAG",
    "city": "Fort Frances",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "YGH",
    "city": "Fort Good Hope",
    "region": "Canada",
    "tz": "America/Yellowknife"
  },
  {
    "iata": "YFH",
    "city": "Fort Hope",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "FHU",
    "city": "Fort Huachuca",
    "region": "Arizona",
    "tz": "America/Phoenix"
  },
  {
    "iata": "BYS",
    "city": "Fort Irwin",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "FTK",
    "city": "Fort Knox",
    "region": "Kentucky",
    "tz": "America/New_York"
  },
  {
    "iata": "FLL",
    "city": "Fort Lauderdale",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "FXE",
    "city": "Fort Lauderdale",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "FLV",
    "city": "Fort Leavenworth",
    "region": "Kansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "TBN",
    "city": "Fort Leonardwood",
    "region": "Missouri",
    "tz": "America/Chicago"
  },
  {
    "iata": "GRF",
    "city": "Fort Lewis",
    "region": "Washington",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "YJF",
    "city": "Fort Liard",
    "region": "Canada",
    "tz": "America/Yellowknife"
  },
  {
    "iata": "YMM",
    "city": "Fort Mcmurray",
    "region": "Canada",
    "tz": "America/Edmonton"
  },
  {
    "iata": "ZFM",
    "city": "Fort Mcpherson",
    "region": "Canada",
    "tz": "America/Yellowknife"
  },
  {
    "iata": "FME",
    "city": "Fort Meade",
    "region": "Maryland",
    "tz": "America/Phoenix"
  },
  {
    "iata": "FMY",
    "city": "Fort Myers",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "RSW",
    "city": "Fort Myers",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "YYE",
    "city": "Fort Nelson",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "OAR",
    "city": "Fort Ord",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "FRP",
    "city": "Fort Pierce",
    "region": "United States",
    "tz": "America/Juneau"
  },
  {
    "iata": "YFR",
    "city": "Fort Resolution",
    "region": "Canada",
    "tz": "America/Yellowknife"
  },
  {
    "iata": "FRN",
    "city": "Fort Richardson",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "FRI",
    "city": "Fort Riley",
    "region": "Kansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "OZR",
    "city": "Fort Rucker/Ozark",
    "region": "Alabama",
    "tz": "America/Chicago"
  },
  {
    "iata": "YXJ",
    "city": "Fort Saint John",
    "region": "Canada",
    "tz": "America/Dawson_Creek"
  },
  {
    "iata": "YER",
    "city": "Fort Severn",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "FSI",
    "city": "Fort Sill",
    "region": "Oklahoma",
    "tz": "America/Chicago"
  },
  {
    "iata": "YFS",
    "city": "Fort Simpson",
    "region": "Canada",
    "tz": "America/Yellowknife"
  },
  {
    "iata": "FSM",
    "city": "Fort Smith",
    "region": "Arkansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "YSM",
    "city": "Fort Smith",
    "region": "Canada",
    "tz": "America/Yellowknife"
  },
  {
    "iata": "FST",
    "city": "Fort Stockton",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "FBK",
    "city": "Fort Wainwright",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "FWA",
    "city": "Fort Wayne",
    "region": "Indiana",
    "tz": "America/Indiana/Indianapolis"
  },
  {
    "iata": "SMD",
    "city": "Fort Wayne IN",
    "region": "Indiana",
    "tz": "America/Indiana/Indianapolis"
  },
  {
    "iata": "AFW",
    "city": "Fort Worth",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "FTW",
    "city": "Fort Worth",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "FYU",
    "city": "Fort Yukon",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "FDF",
    "city": "Fort-de-france",
    "region": "Martinique",
    "tz": "America/Martinique"
  },
  {
    "iata": "FOR",
    "city": "Fortaleza",
    "region": "Brazil",
    "tz": "America/Fortaleza"
  },
  {
    "iata": "FOM",
    "city": "Foumban",
    "region": "Cameroon",
    "tz": "Africa/Douala"
  },
  {
    "iata": "IGU",
    "city": "Foz Do Iguacu",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "FRC",
    "city": "Franca",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "MVB",
    "city": "Franceville",
    "region": "Gabon",
    "tz": "Africa/Libreville"
  },
  {
    "iata": "FBE",
    "city": "Francisco Beltrao",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "FRW",
    "city": "Francistown",
    "region": "Botswana",
    "tz": "Africa/Gaborone"
  },
  {
    "iata": "FFT",
    "city": "Frankfort",
    "region": "Kentucky",
    "tz": "America/New_York"
  },
  {
    "iata": "FRA",
    "city": "Frankfurt",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "FKL",
    "city": "Franklin",
    "region": "Pennsylvania",
    "tz": "America/New_York"
  },
  {
    "iata": "OKB",
    "city": "Fraser Island",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "FBG",
    "city": "Fredericksburg",
    "region": "North Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "YFC",
    "city": "Fredericton",
    "region": "Canada",
    "tz": "America/Moncton"
  },
  {
    "iata": "FPO",
    "city": "Freeport",
    "region": "Bahamas",
    "tz": "America/Nassau"
  },
  {
    "iata": "FNA",
    "city": "Freetown",
    "region": "Sierra Leone",
    "tz": "Africa/Freetown"
  },
  {
    "iata": "HGS",
    "city": "Freetown",
    "region": "Sierra Leone",
    "tz": "Africa/Freetown"
  },
  {
    "iata": "FRJ",
    "city": "Frejus",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "FET",
    "city": "Fremont",
    "region": "Nebraska",
    "tz": "America/Chicago"
  },
  {
    "iata": "WFK",
    "city": "Frenchville",
    "region": "Maine",
    "tz": "America/New_York"
  },
  {
    "iata": "FAT",
    "city": "Fresno",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "FRD",
    "city": "Friday Harbor",
    "region": "Washington",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "FDH",
    "city": "Friedrichshafen",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "IZG",
    "city": "Fryeburg",
    "region": "United States",
    "tz": "America/New_York"
  },
  {
    "iata": "FEL",
    "city": "Fuerstenfeldbruck",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "FUE",
    "city": "Fuerteventura",
    "region": "Spain",
    "tz": "Atlantic/Canary"
  },
  {
    "iata": "FJR",
    "city": "Fujeirah",
    "region": "United Arab Emirates",
    "tz": "Asia/Dubai"
  },
  {
    "iata": "FUJ",
    "city": "Fukue",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "FUK",
    "city": "Fukuoka",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "FKS",
    "city": "Fukushima",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "FUL",
    "city": "Fullerton",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "FUN",
    "city": "Funafuti",
    "region": "Tuvalu",
    "tz": "Pacific/Funafuti"
  },
  {
    "iata": "FNC",
    "city": "Funchal",
    "region": "Portugal",
    "tz": "Atlantic/Madeira"
  },
  {
    "iata": "FNR",
    "city": "Funter Bay",
    "region": "Alaska",
    "tz": "America/Juneau"
  },
  {
    "iata": "FTA",
    "city": "Futuna Island",
    "region": "Vanuatu",
    "tz": "Pacific/Efate"
  },
  {
    "iata": "FUT",
    "city": "Futuna Island",
    "region": "Wallis and Futuna",
    "tz": "Pacific/Wallis"
  },
  {
    "iata": "FUG",
    "city": "Fuyang",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "FYJ",
    "city": "Fuyuan",
    "region": "China",
    "tz": "Asia/Harbin"
  },
  {
    "iata": "FOC",
    "city": "Fuzhou",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "GBE",
    "city": "Gaberone",
    "region": "Botswana",
    "tz": "Africa/Gaborone"
  },
  {
    "iata": "GAE",
    "city": "Gabes",
    "region": "Tunisia",
    "tz": "Africa/Tunis"
  },
  {
    "iata": "GAD",
    "city": "Gadsden",
    "region": "Alabama",
    "tz": "America/Chicago"
  },
  {
    "iata": "GAF",
    "city": "Gafsa",
    "region": "Tunisia",
    "tz": "Africa/Tunis"
  },
  {
    "iata": "GNV",
    "city": "Gainesville",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "GVL",
    "city": "Gainesville",
    "region": "Georgia",
    "tz": "America/New_York"
  },
  {
    "iata": "GAI",
    "city": "Gaithersburg",
    "region": "Maryland",
    "tz": "America/New_York"
  },
  {
    "iata": "GPS",
    "city": "Galapagos",
    "region": "Ecuador",
    "tz": "Pacific/Galapagos"
  },
  {
    "iata": "GLK",
    "city": "Galcaio",
    "region": "Somalia",
    "tz": "Africa/Mogadishu"
  },
  {
    "iata": "GAL",
    "city": "Galena",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "GBG",
    "city": "Galesburg",
    "region": "Illinois",
    "tz": "America/Chicago"
  },
  {
    "iata": "GQQ",
    "city": "Galion",
    "region": "Ohio",
    "tz": "America/New_York"
  },
  {
    "iata": "GEV",
    "city": "Gallivare",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "GUP",
    "city": "Gallup",
    "region": "New Mexico",
    "tz": "America/Denver"
  },
  {
    "iata": "GOY",
    "city": "Galoya",
    "region": "Sri Lanka",
    "tz": "Asia/Colombo"
  },
  {
    "iata": "GLS",
    "city": "Galveston",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "GWY",
    "city": "Galway",
    "region": "Ireland",
    "tz": "Europe/Dublin"
  },
  {
    "iata": "GAM",
    "city": "Gambell",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "GMB",
    "city": "Gambella",
    "region": "Ethiopia",
    "tz": "Africa/Addis_Ababa"
  },
  {
    "iata": "YRA",
    "city": "Gam\u00e8t\u00ec",
    "region": "Canada",
    "tz": "America/Yellowknife"
  },
  {
    "iata": "GAN",
    "city": "Gan Island",
    "region": "Maldives",
    "tz": "Indian/Maldives"
  },
  {
    "iata": "YQX",
    "city": "Gander",
    "region": "Canada",
    "tz": "America/St_Johns"
  },
  {
    "iata": "YGG",
    "city": "Ganges",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "KVD",
    "city": "Ganja",
    "region": "Azerbaijan",
    "tz": "Asia/Baku"
  },
  {
    "iata": "KOW",
    "city": "Ganzhou",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "GAQ",
    "city": "Gao",
    "region": "Mali",
    "tz": "Africa/Bamako"
  },
  {
    "iata": "GCK",
    "city": "Garden City",
    "region": "Kansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "GAS",
    "city": "Garissa",
    "region": "Kenya",
    "tz": "Africa/Nairobi"
  },
  {
    "iata": "GOU",
    "city": "Garoua",
    "region": "Cameroon",
    "tz": "Africa/Douala"
  },
  {
    "iata": "GYY",
    "city": "Gary",
    "region": "Indiana",
    "tz": "America/Chicago"
  },
  {
    "iata": "GLJ",
    "city": "Garz\u00f3n",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "MRQ",
    "city": "Gasan",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "YGP",
    "city": "Gaspe",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "ELQ",
    "city": "Gassim",
    "region": "Saudi Arabia",
    "tz": "Asia/Riyadh"
  },
  {
    "iata": "YND",
    "city": "Gatineau",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "ZGU",
    "city": "Gaua Island",
    "region": "Vanuatu",
    "tz": "Pacific/Efate"
  },
  {
    "iata": "GVX",
    "city": "Gavle",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "GAY",
    "city": "Gaya",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "GAH",
    "city": "Gayndah",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "GZA",
    "city": "Gaza",
    "region": "Palestine",
    "tz": "Asia/Gaza"
  },
  {
    "iata": "GZT",
    "city": "Gaziantep",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "BDT",
    "city": "Gbadolite",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Kinshasa"
  },
  {
    "iata": "GBK",
    "city": "Gbangbatok",
    "region": "Sierra Leone",
    "tz": "Africa/Freetown"
  },
  {
    "iata": "GDN",
    "city": "Gdansk",
    "region": "Poland",
    "tz": "Europe/Warsaw"
  },
  {
    "iata": "GEX",
    "city": "Geelong",
    "region": "Australia",
    "tz": "Australia/Melbourne"
  },
  {
    "iata": "GKE",
    "city": "Geilenkirchen",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "GDZ",
    "city": "Gelendzhik",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "GLU",
    "city": "Gelephu",
    "region": "Bhutan",
    "tz": "Asia/Thimphu"
  },
  {
    "iata": "GMA",
    "city": "Gemena",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Kinshasa"
  },
  {
    "iata": "EGN",
    "city": "Geneina",
    "region": "Sudan",
    "tz": "Africa/Khartoum"
  },
  {
    "iata": "GVA",
    "city": "Geneva",
    "region": "Switzerland",
    "tz": "Europe/Zurich"
  },
  {
    "iata": "GOA",
    "city": "Genoa",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "GRJ",
    "city": "George",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "GCM",
    "city": "Georgetown",
    "region": "Cayman Islands",
    "tz": "America/Cayman"
  },
  {
    "iata": "GED",
    "city": "Georgetown",
    "region": "Delaware",
    "tz": "America/New_York"
  },
  {
    "iata": "GEO",
    "city": "Georgetown",
    "region": "Guyana",
    "tz": "America/Guyana"
  },
  {
    "iata": "OGL",
    "city": "Georgetown",
    "region": "Guyana",
    "tz": "America/Guyana"
  },
  {
    "iata": "GGE",
    "city": "Georgetown",
    "region": "South Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "MNI",
    "city": "Geralds",
    "region": "Montserrat",
    "tz": "America/Montserrat"
  },
  {
    "iata": "GET",
    "city": "Geraldton",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "YGQ",
    "city": "Geraldton",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "GRO",
    "city": "Gerona",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "LTD",
    "city": "Ghadames",
    "region": "Libya",
    "tz": "Africa/Tripoli"
  },
  {
    "iata": "GNZ",
    "city": "Ghanzi",
    "region": "Botswana",
    "tz": "Africa/Gaborone"
  },
  {
    "iata": "GHA",
    "city": "Ghardaia",
    "region": "Algeria",
    "tz": "Africa/Algiers"
  },
  {
    "iata": "GHT",
    "city": "Ghat",
    "region": "Libya",
    "tz": "Africa/Tripoli"
  },
  {
    "iata": "MUW",
    "city": "Ghriss",
    "region": "Algeria",
    "tz": "Africa/Algiers"
  },
  {
    "iata": "GIB",
    "city": "Gibraltar",
    "region": "Gibraltar",
    "tz": "Europe/Gibraltar"
  },
  {
    "iata": "GHF",
    "city": "Giebelstadt",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "GIL",
    "city": "Gilgit",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "YGX",
    "city": "Gillam",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "GCC",
    "city": "Gillette",
    "region": "Wyoming",
    "tz": "America/Denver"
  },
  {
    "iata": "YGM",
    "city": "Gimli",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "PNP",
    "city": "Girua",
    "region": "Papua New Guinea",
    "tz": "Pacific/Port_Moresby"
  },
  {
    "iata": "GIS",
    "city": "Gisborne",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "GYI",
    "city": "Gisenyi",
    "region": "Rwanda",
    "tz": "Africa/Kigali"
  },
  {
    "iata": "GIZ",
    "city": "Gizan",
    "region": "Saudi Arabia",
    "tz": "Asia/Riyadh"
  },
  {
    "iata": "GZO",
    "city": "Gizo",
    "region": "Solomon Islands",
    "tz": "Pacific/Guadalcanal"
  },
  {
    "iata": "YHK",
    "city": "Gjoa Haven",
    "region": "Canada",
    "tz": "America/Cambridge_Bay"
  },
  {
    "iata": "GJR",
    "city": "Gjogur",
    "region": "Iceland",
    "tz": "Atlantic/Reykjavik"
  },
  {
    "iata": "GLT",
    "city": "Gladstone",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "GDW",
    "city": "Gladwin",
    "region": "Michigan",
    "tz": "America/Detroit"
  },
  {
    "iata": "GGW",
    "city": "Glasgow",
    "region": "Montana",
    "tz": "America/Denver"
  },
  {
    "iata": "GLA",
    "city": "Glasgow",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "GLI",
    "city": "Glen Innes",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "GEU",
    "city": "Glendale",
    "region": "United States",
    "tz": "America/Phoenix"
  },
  {
    "iata": "GDV",
    "city": "Glendive",
    "region": "Montana",
    "tz": "America/Denver"
  },
  {
    "iata": "GOI",
    "city": "Goa",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "GOB",
    "city": "Goba",
    "region": "Ethiopia",
    "tz": "Africa/Addis_Ababa"
  },
  {
    "iata": "GGS",
    "city": "Gobernador Gregores",
    "region": "Argentina",
    "tz": "America/Argentina/Rio_Gallegos"
  },
  {
    "iata": "GDE",
    "city": "Gode",
    "region": "Ethiopia",
    "tz": "Africa/Addis_Ababa"
  },
  {
    "iata": "YGD",
    "city": "Goderich",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "YGO",
    "city": "Gods Lake Narrows",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "ZGI",
    "city": "Gods River",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "GOH",
    "city": "Godthaab",
    "region": "Greenland",
    "tz": "America/Godthab"
  },
  {
    "iata": "GYN",
    "city": "Goiania",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "GSB",
    "city": "Goldsboro",
    "region": "North Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "GLF",
    "city": "Golfito",
    "region": "Costa Rica",
    "tz": "America/Costa_Rica"
  },
  {
    "iata": "GOQ",
    "city": "Golmud",
    "region": "China",
    "tz": "Asia/Urumqi"
  },
  {
    "iata": "GLO",
    "city": "Golouchestershire",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "GLV",
    "city": "Golovin",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "GOM",
    "city": "Goma",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Lubumbashi"
  },
  {
    "iata": "GME",
    "city": "Gomel",
    "region": "Belarus",
    "tz": "Europe/Minsk"
  },
  {
    "iata": "GDQ",
    "city": "Gondar",
    "region": "Ethiopia",
    "tz": "Africa/Addis_Ababa"
  },
  {
    "iata": "GLD",
    "city": "Goodland",
    "region": "Kansas",
    "tz": "America/Denver"
  },
  {
    "iata": "YYR",
    "city": "Goose Bay",
    "region": "Canada",
    "tz": "America/Goose_Bay"
  },
  {
    "iata": "GOP",
    "city": "Gorakhpur",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "GOR",
    "city": "Gore",
    "region": "Ethiopia",
    "tz": "Africa/Addis_Ababa"
  },
  {
    "iata": "YZE",
    "city": "Gore Bay",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "GBT",
    "city": "Gorgan",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "GOZ",
    "city": "Gorna Orechovica",
    "region": "Bulgaria",
    "tz": "Europe/Sofia"
  },
  {
    "iata": "RGK",
    "city": "Gorno-Altaysk",
    "region": "Russia",
    "tz": "Asia/Omsk"
  },
  {
    "iata": "GKA",
    "city": "Goroka",
    "region": "Papua New Guinea",
    "tz": "Pacific/Port_Moresby"
  },
  {
    "iata": "GTO",
    "city": "Gorontalo",
    "region": "Indonesia",
    "tz": "Asia/Makassar"
  },
  {
    "iata": "GOT",
    "city": "Gothenborg",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "GSE",
    "city": "Gothenborg",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "GUL",
    "city": "Goulburn",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "GOV",
    "city": "Gove",
    "region": "Australia",
    "tz": "Australia/Darwin"
  },
  {
    "iata": "GVR",
    "city": "Governador Valadares",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "GHB",
    "city": "Governor's Harbor",
    "region": "Bahamas",
    "tz": "America/Nassau"
  },
  {
    "iata": "GZM",
    "city": "Gozo",
    "region": "Malta",
    "tz": "Europe/Malta"
  },
  {
    "iata": "GRW",
    "city": "Graciosa Island",
    "region": "Portugal",
    "tz": "Atlantic/Azores"
  },
  {
    "iata": "GFN",
    "city": "Grafton",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "LPA",
    "city": "Gran Canaria",
    "region": "Spain",
    "tz": "Atlantic/Canary"
  },
  {
    "iata": "GRX",
    "city": "Granada",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "GBJ",
    "city": "Grand Bourg",
    "region": "Guadeloupe",
    "tz": "America/Guadeloupe"
  },
  {
    "iata": "GCN",
    "city": "Grand Canyon",
    "region": "Arizona",
    "tz": "America/Phoenix"
  },
  {
    "iata": "ZGF",
    "city": "Grand Forks",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "GFK",
    "city": "Grand Forks",
    "region": "North Dakota",
    "tz": "America/Chicago"
  },
  {
    "iata": "GRI",
    "city": "Grand Island",
    "region": "Nebraska",
    "tz": "America/Chicago"
  },
  {
    "iata": "GJT",
    "city": "Grand Junction",
    "region": "Colorado",
    "tz": "America/Denver"
  },
  {
    "iata": "GRM",
    "city": "Grand Marais",
    "region": "Minnesota",
    "tz": "America/Chicago"
  },
  {
    "iata": "GRR",
    "city": "Grand Rapids",
    "region": "Michigan",
    "tz": "America/Detroit"
  },
  {
    "iata": "GPZ",
    "city": "Grand Rapids MN",
    "region": "Minnesota",
    "tz": "America/Chicago"
  },
  {
    "iata": "DSD",
    "city": "Grande Anse",
    "region": "Guadeloupe",
    "tz": "America/Guadeloupe"
  },
  {
    "iata": "YQU",
    "city": "Grande Prairie",
    "region": "Canada",
    "tz": "America/Edmonton"
  },
  {
    "iata": "MWH",
    "city": "Grant County Airport",
    "region": "Washington",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "GNT",
    "city": "Grants",
    "region": "New Mexico",
    "tz": "America/Denver"
  },
  {
    "iata": "GRZ",
    "city": "Graz",
    "region": "Austria",
    "tz": "Europe/Vienna"
  },
  {
    "iata": "GGT",
    "city": "Great Exuma",
    "region": "Bahamas",
    "tz": "America/Nassau"
  },
  {
    "iata": "GTF",
    "city": "Great Falls",
    "region": "Montana",
    "tz": "America/Denver"
  },
  {
    "iata": "GKL",
    "city": "Great Keppel Island",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "GXY",
    "city": "Greeley",
    "region": "Colorado",
    "tz": "America/Denver"
  },
  {
    "iata": "GRB",
    "city": "Green Bay",
    "region": "Wisconsin",
    "tz": "America/Chicago"
  },
  {
    "iata": "GNI",
    "city": "Green Island",
    "region": "Taiwan",
    "tz": "Asia/Taipei"
  },
  {
    "iata": "GSO",
    "city": "Greensboro",
    "region": "North Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "GVT",
    "city": "Greenvile",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "CPA",
    "city": "Greenville",
    "region": "Liberia",
    "tz": "Africa/Monrovia"
  },
  {
    "iata": "GLH",
    "city": "Greenville",
    "region": "Mississippi",
    "tz": "America/Chicago"
  },
  {
    "iata": "PGV",
    "city": "Greenville",
    "region": "North Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "GMU",
    "city": "Greenville",
    "region": "South Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "GSP",
    "city": "Greenville",
    "region": "South Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "YZX",
    "city": "Greenwood",
    "region": "Canada",
    "tz": "America/Halifax"
  },
  {
    "iata": "GWO",
    "city": "Greenwood",
    "region": "Mississippi",
    "tz": "America/Chicago"
  },
  {
    "iata": "GNB",
    "city": "Grenoble",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "GFF",
    "city": "Griffith",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "YGZ",
    "city": "Grise Fiord",
    "region": "Canada",
    "tz": "America/Iqaluit"
  },
  {
    "iata": "GRQ",
    "city": "Groningen",
    "region": "Netherlands",
    "tz": "Europe/Amsterdam"
  },
  {
    "iata": "GTE",
    "city": "Groote Eylandt",
    "region": "Australia",
    "tz": "Australia/Darwin"
  },
  {
    "iata": "GFY",
    "city": "Grootfontein",
    "region": "Namibia",
    "tz": "Africa/Windhoek"
  },
  {
    "iata": "GRS",
    "city": "Grosseto",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "GON",
    "city": "Groton CT",
    "region": "Connecticut",
    "tz": "America/New_York"
  },
  {
    "iata": "TAR",
    "city": "Grottaglie",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "GRV",
    "city": "Grozny",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "GRY",
    "city": "Gr\u00edmsey",
    "region": "Iceland",
    "tz": "Atlantic/Reykjavik"
  },
  {
    "iata": "GDL",
    "city": "Guadalajara",
    "region": "Mexico",
    "tz": "America/Mexico_City"
  },
  {
    "iata": "GHU",
    "city": "Gualeguaychu",
    "region": "Argentina",
    "tz": "America/Argentina/Cordoba"
  },
  {
    "iata": "GJA",
    "city": "Guanaja",
    "region": "Honduras",
    "tz": "America/Tegucigalpa"
  },
  {
    "iata": "GNM",
    "city": "Guanambi",
    "region": "Brazil",
    "tz": "America/Bahia"
  },
  {
    "iata": "GUQ",
    "city": "Guanare",
    "region": "Venezuela",
    "tz": "America/Caracas"
  },
  {
    "iata": "LHK",
    "city": "Guanghua",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "GYS",
    "city": "Guangyuan",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "CAN",
    "city": "Guangzhou",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "GAO",
    "city": "Guantanamo",
    "region": "Cuba",
    "tz": "America/Havana"
  },
  {
    "iata": "GPI",
    "city": "Guapi",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "CSC",
    "city": "Guapiles",
    "region": "Costa Rica",
    "tz": "America/Costa_Rica"
  },
  {
    "iata": "GUZ",
    "city": "Guarapari",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "GPB",
    "city": "Guarapuava",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "GUA",
    "city": "Guatemala City",
    "region": "Guatemala",
    "tz": "America/Guatemala"
  },
  {
    "iata": "PZO",
    "city": "Guayana",
    "region": "Venezuela",
    "tz": "America/Caracas"
  },
  {
    "iata": "GYE",
    "city": "Guayaquil",
    "region": "Ecuador",
    "tz": "America/Guayaquil"
  },
  {
    "iata": "GYA",
    "city": "Guayaramer\u00edn",
    "region": "Bolivia",
    "tz": "America/La_Paz"
  },
  {
    "iata": "GYM",
    "city": "Guaymas",
    "region": "Mexico",
    "tz": "America/Hermosillo"
  },
  {
    "iata": "ELU",
    "city": "Guemar",
    "region": "Algeria",
    "tz": "Africa/Algiers"
  },
  {
    "iata": "GCI",
    "city": "Guernsey",
    "region": "Guernsey",
    "tz": "Europe/Guernsey"
  },
  {
    "iata": "GUB",
    "city": "Guerrero Negro",
    "region": "Mexico",
    "tz": "America/Santa_Isabel"
  },
  {
    "iata": "GUT",
    "city": "Guetersloh",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "KWL",
    "city": "Guilin",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "GUI",
    "city": "Guiria",
    "region": "Venezuela",
    "tz": "America/Caracas"
  },
  {
    "iata": "KWE",
    "city": "Guiyang",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "GPT",
    "city": "Gulfport",
    "region": "Mississippi",
    "tz": "America/Chicago"
  },
  {
    "iata": "GKN",
    "city": "Gulkana",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "ULU",
    "city": "Gulu",
    "region": "Uganda",
    "tz": "Africa/Kampala"
  },
  {
    "iata": "GUC",
    "city": "Gunnison",
    "region": "Colorado",
    "tz": "America/Denver"
  },
  {
    "iata": "GNS",
    "city": "Gunung Sitoli",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "URY",
    "city": "Guriat",
    "region": "Saudi Arabia",
    "tz": "Asia/Riyadh"
  },
  {
    "iata": "GUR",
    "city": "Gurney",
    "region": "Papua New Guinea",
    "tz": "Pacific/Port_Moresby"
  },
  {
    "iata": "GRP",
    "city": "Gurupi",
    "region": "Brazil",
    "tz": "America/Araguaina"
  },
  {
    "iata": "QUS",
    "city": "Gusau",
    "region": "Nigeria",
    "tz": "Africa/Lagos"
  },
  {
    "iata": "SBH",
    "city": "Gustavia",
    "region": "France",
    "tz": "America/St_Barthelemy"
  },
  {
    "iata": "GST",
    "city": "Gustavus",
    "region": "Alaska",
    "tz": "America/Juneau"
  },
  {
    "iata": "GAU",
    "city": "Guwahati",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "GUY",
    "city": "Guymon",
    "region": "Oklahoma",
    "tz": "America/Chicago"
  },
  {
    "iata": "GYU",
    "city": "Guyuan",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "GWD",
    "city": "Gwadar",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "GWL",
    "city": "Gwalior",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "GWE",
    "city": "Gwert",
    "region": "Zimbabwe",
    "tz": "Africa/Harare"
  },
  {
    "iata": "MQT",
    "city": "Gwinn",
    "region": "Michigan",
    "tz": "America/Detroit"
  },
  {
    "iata": "LWN",
    "city": "Gyumri",
    "region": "Armenia",
    "tz": "Asia/Yerevan"
  },
  {
    "iata": "QGY",
    "city": "Gy\u0151r",
    "region": "Hungary",
    "tz": "Europe/Budapest"
  },
  {
    "iata": "HAQ",
    "city": "Haa Dhaalu Atoll",
    "region": "Maldives",
    "tz": "Indian/Maldives"
  },
  {
    "iata": "HAC",
    "city": "Hachijojima",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "AQI",
    "city": "Hafr Al-batin",
    "region": "Saudi Arabia",
    "tz": "Asia/Riyadh"
  },
  {
    "iata": "HGR",
    "city": "Hagerstown",
    "region": "Maryland",
    "tz": "America/New_York"
  },
  {
    "iata": "HFS",
    "city": "Hagfors",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "HHN",
    "city": "Hahn",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "HFA",
    "city": "Haifa",
    "region": "Israel",
    "tz": "Asia/Jerusalem"
  },
  {
    "iata": "HAK",
    "city": "Haikou",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "HAS",
    "city": "Hail",
    "region": "Saudi Arabia",
    "tz": "Asia/Riyadh"
  },
  {
    "iata": "HLD",
    "city": "Hailar",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "SUN",
    "city": "Hailey",
    "region": "Idaho",
    "tz": "America/Boise"
  },
  {
    "iata": "HNS",
    "city": "Haines",
    "region": "Alaska",
    "tz": "America/Juneau"
  },
  {
    "iata": "YHT",
    "city": "Haines Junction",
    "region": "Canada",
    "tz": "America/Whitehorse"
  },
  {
    "iata": "HPH",
    "city": "Haiphong",
    "region": "Vietnam",
    "tz": "Asia/Ho_Chi_Minh"
  },
  {
    "iata": "YKO",
    "city": "Hakkari",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "HKD",
    "city": "Hakodate",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "HAF",
    "city": "Half Moon Bay",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "YAW",
    "city": "Halifax",
    "region": "Canada",
    "tz": "America/Halifax"
  },
  {
    "iata": "YHZ",
    "city": "Halifax",
    "region": "Canada",
    "tz": "America/Halifax"
  },
  {
    "iata": "YUX",
    "city": "Hall Beach",
    "region": "Canada",
    "tz": "America/Iqaluit"
  },
  {
    "iata": "KEV",
    "city": "Halli",
    "region": "Finland",
    "tz": "Europe/Helsinki"
  },
  {
    "iata": "HCQ",
    "city": "Halls Creek",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "HAD",
    "city": "Halmstad",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "HDM",
    "city": "Hamadan",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "HMR",
    "city": "Hamar",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "HAM",
    "city": "Hamburg",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "XFW",
    "city": "Hamburg",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "DSO",
    "city": "Hamhung",
    "region": "North Korea",
    "tz": "Asia/Pyongyang"
  },
  {
    "iata": "HMI",
    "city": "Hami",
    "region": "China",
    "tz": "Asia/Urumqi"
  },
  {
    "iata": "HLT",
    "city": "Hamilton",
    "region": "Australia",
    "tz": "Australia/Melbourne"
  },
  {
    "iata": "YHM",
    "city": "Hamilton",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "HLZ",
    "city": "Hamilton",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "HTI",
    "city": "Hamilton Island",
    "region": "Australia",
    "tz": "Australia/Lindeman"
  },
  {
    "iata": "HFT",
    "city": "Hammerfest",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "LFI",
    "city": "Hampton",
    "region": "Virginia",
    "tz": "America/New_York"
  },
  {
    "iata": "HNM",
    "city": "Hana",
    "region": "Hawaii",
    "tz": "Pacific/Honolulu"
  },
  {
    "iata": "HNA",
    "city": "Hanamaki",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "CMX",
    "city": "Hancock",
    "region": "Michigan",
    "tz": "America/Detroit"
  },
  {
    "iata": "HDG",
    "city": "Handan",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "HGH",
    "city": "Hangzhou",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "HAJ",
    "city": "Hannover",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "HAN",
    "city": "Hanoi",
    "region": "Vietnam",
    "tz": "Asia/Ho_Chi_Minh"
  },
  {
    "iata": "HZG",
    "city": "Hanzhong",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "HOI",
    "city": "Hao Island",
    "region": "French Polynesia",
    "tz": "Pacific/Tahiti"
  },
  {
    "iata": "HRE",
    "city": "Harare",
    "region": "Zimbabwe",
    "tz": "Africa/Harare"
  },
  {
    "iata": "HRB",
    "city": "Harbin",
    "region": "China",
    "tz": "Asia/Harbin"
  },
  {
    "iata": "HGA",
    "city": "Hargeisa",
    "region": "Somalia",
    "tz": "Africa/Mogadishu"
  },
  {
    "iata": "HRL",
    "city": "Harlingen",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "MDT",
    "city": "Harrisburg",
    "region": "Pennsylvania",
    "tz": "America/New_York"
  },
  {
    "iata": "CXY",
    "city": "Harrisburg",
    "region": "United States",
    "tz": "America/Nassau"
  },
  {
    "iata": "HRO",
    "city": "Harrison",
    "region": "Arkansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "HFD",
    "city": "Hartford",
    "region": "Connecticut",
    "tz": "America/New_York"
  },
  {
    "iata": "HVS",
    "city": "Hartsville",
    "region": "South Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "HME",
    "city": "Hassi Messaoud",
    "region": "Algeria",
    "tz": "Africa/Algiers"
  },
  {
    "iata": "HAA",
    "city": "Hasvik",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "HDY",
    "city": "Hat Yai",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "HTY",
    "city": "Hatay",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "HBG",
    "city": "Hattiesburg",
    "region": "Mississippi",
    "tz": "America/Chicago"
  },
  {
    "iata": "PIB",
    "city": "Hattiesburg/Laurel",
    "region": "Mississippi",
    "tz": "America/Chicago"
  },
  {
    "iata": "HAU",
    "city": "Haugesund",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "HAV",
    "city": "Havana",
    "region": "Cuba",
    "tz": "America/Havana"
  },
  {
    "iata": "HVR",
    "city": "Havre",
    "region": "Montana",
    "tz": "America/Denver"
  },
  {
    "iata": "YGV",
    "city": "Havre-Saint-Pierre",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "CEG",
    "city": "Hawarden",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "HHR",
    "city": "Hawthorne",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "YHY",
    "city": "Hay River",
    "region": "Canada",
    "tz": "America/Yellowknife"
  },
  {
    "iata": "HDN",
    "city": "Hayden",
    "region": "Colorado",
    "tz": "America/Denver"
  },
  {
    "iata": "HIS",
    "city": "Hayman Island",
    "region": "Australia",
    "tz": "Australia/Lindeman"
  },
  {
    "iata": "HYS",
    "city": "Hays",
    "region": "Kansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "HWD",
    "city": "Hayward",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "HYR",
    "city": "Hayward",
    "region": "Wisconsin",
    "tz": "America/Chicago"
  },
  {
    "iata": "HZL",
    "city": "Hazleton",
    "region": "Pennsylvania",
    "tz": "America/New_York"
  },
  {
    "iata": "HKB",
    "city": "Healy",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "YHF",
    "city": "Hearst",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "HCJ",
    "city": "Hechi",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "HFE",
    "city": "Hefei",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "HEH",
    "city": "Heho",
    "region": "Burma",
    "tz": "Asia/Rangoon"
  },
  {
    "iata": "HEK",
    "city": "Heihe",
    "region": "China",
    "tz": "Asia/Harbin"
  },
  {
    "iata": "HLN",
    "city": "Helena",
    "region": "Montana",
    "tz": "America/Denver"
  },
  {
    "iata": "HGL",
    "city": "Helgoland",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "HEL",
    "city": "Helsinki",
    "region": "Finland",
    "tz": "Europe/Helsinki"
  },
  {
    "iata": "HEM",
    "city": "Helsinki",
    "region": "Finland",
    "tz": "Europe/Helsinki"
  },
  {
    "iata": "HMV",
    "city": "Hemavan",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "HSH",
    "city": "Henderson",
    "region": "Nevada",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "HCN",
    "city": "Hengchun",
    "region": "Taiwan",
    "tz": "Asia/Taipei"
  },
  {
    "iata": "HNY",
    "city": "Hengyang",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "HER",
    "city": "Heraklion",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "HEA",
    "city": "Herat",
    "region": "Afghanistan",
    "tz": "Asia/Kabul"
  },
  {
    "iata": "HDF",
    "city": "Heringsdorf",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "HMO",
    "city": "Hermosillo",
    "region": "Mexico",
    "tz": "America/Hermosillo"
  },
  {
    "iata": "HVB",
    "city": "Hervey Bay",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "UVF",
    "city": "Hewandorra",
    "region": "Saint Lucia",
    "tz": "America/St_Lucia"
  },
  {
    "iata": "HIB",
    "city": "Hibbing",
    "region": "Minnesota",
    "tz": "America/Chicago"
  },
  {
    "iata": "HKY",
    "city": "Hickory",
    "region": "North Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "VDE",
    "city": "Hierro",
    "region": "Spain",
    "tz": "Atlantic/Canary"
  },
  {
    "iata": "YOJ",
    "city": "High Level",
    "region": "Canada",
    "tz": "America/Edmonton"
  },
  {
    "iata": "HIO",
    "city": "Hillsboro",
    "region": "Oregon",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "INJ",
    "city": "Hillsboro",
    "region": "United States",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "ITO",
    "city": "Hilo",
    "region": "Hawaii",
    "tz": "Pacific/Honolulu"
  },
  {
    "iata": "HHH",
    "city": "Hilton Head Island",
    "region": "South Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "HIJ",
    "city": "Hiroshima",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "HIW",
    "city": "Hiroshima",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "AUQ",
    "city": "Hiva-oa",
    "region": "French Polynesia",
    "tz": "Pacific/Marquesas"
  },
  {
    "iata": "SGN",
    "city": "Ho Chi Minh City",
    "region": "Vietnam",
    "tz": "Asia/Ho_Chi_Minh"
  },
  {
    "iata": "HBA",
    "city": "Hobart",
    "region": "Australia",
    "tz": "Australia/Hobart"
  },
  {
    "iata": "HBR",
    "city": "Hobart",
    "region": "Oklahoma",
    "tz": "America/Chicago"
  },
  {
    "iata": "HOB",
    "city": "Hobbs",
    "region": "New Mexico",
    "tz": "America/Denver"
  },
  {
    "iata": "HOD",
    "city": "Hodeidah",
    "region": "Yemen",
    "tz": "Asia/Aden"
  },
  {
    "iata": "HDS",
    "city": "Hoedspruit",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "HOQ",
    "city": "Hof",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "HFN",
    "city": "Hofn",
    "region": "Iceland",
    "tz": "Atlantic/Reykjavik"
  },
  {
    "iata": "HET",
    "city": "Hohhot",
    "region": "China",
    "tz": "Asia/Harbin"
  },
  {
    "iata": "HKK",
    "city": "Hokitika",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "HOA",
    "city": "Hola",
    "region": "Kenya",
    "tz": "Africa/Nairobi"
  },
  {
    "iata": "HDE",
    "city": "Holdredge",
    "region": "Nebraska",
    "tz": "America/Chicago"
  },
  {
    "iata": "HOG",
    "city": "Holguin",
    "region": "Cuba",
    "tz": "America/Havana"
  },
  {
    "iata": "BIV",
    "city": "Holland",
    "region": "United States",
    "tz": "Africa/Bangui"
  },
  {
    "iata": "HWO",
    "city": "Hollywood",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "YHI",
    "city": "Holman Island",
    "region": "Canada",
    "tz": "America/Yellowknife"
  },
  {
    "iata": "HCR",
    "city": "Holy Cross",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "HOM",
    "city": "Homer",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "HST",
    "city": "Homestead",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "HDO",
    "city": "Hondo",
    "region": "United States",
    "tz": "America/Chicago"
  },
  {
    "iata": "HKG",
    "city": "Hong Kong",
    "region": "Hong Kong",
    "tz": "Asia/Hong_Kong"
  },
  {
    "iata": "HIR",
    "city": "Honiara",
    "region": "Solomon Islands",
    "tz": "Pacific/Guadalcanal"
  },
  {
    "iata": "BEQ",
    "city": "Honington",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "HVG",
    "city": "Honningsvag",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "HNL",
    "city": "Honolulu",
    "region": "Hawaii",
    "tz": "Pacific/Honolulu"
  },
  {
    "iata": "HOK",
    "city": "Hooker Creek",
    "region": "Australia",
    "tz": "Australia/Darwin"
  },
  {
    "iata": "HNH",
    "city": "Hoonah",
    "region": "Alaska",
    "tz": "America/Juneau"
  },
  {
    "iata": "HPB",
    "city": "Hooper Bay",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "YHE",
    "city": "Hope",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "YHO",
    "city": "Hopedale",
    "region": "Canada",
    "tz": "America/Goose_Bay"
  },
  {
    "iata": "HOP",
    "city": "Hopkinsville",
    "region": "Kentucky",
    "tz": "America/Chicago"
  },
  {
    "iata": "HQM",
    "city": "Hoquiam",
    "region": "Washington",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "HID",
    "city": "Horn Island",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "YHN",
    "city": "Hornepayne",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "HSM",
    "city": "Horsham",
    "region": "Australia",
    "tz": "Australia/Melbourne"
  },
  {
    "iata": "HOR",
    "city": "Horta",
    "region": "Portugal",
    "tz": "Atlantic/Azores"
  },
  {
    "iata": "HKN",
    "city": "Hoskins",
    "region": "Papua New Guinea",
    "tz": "Pacific/Port_Moresby"
  },
  {
    "iata": "HOT",
    "city": "Hot Springs",
    "region": "Arkansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "HTN",
    "city": "Hotan",
    "region": "China",
    "tz": "Asia/Kashgar"
  },
  {
    "iata": "HTL",
    "city": "Houghton Lake",
    "region": "Michigan",
    "tz": "America/Detroit"
  },
  {
    "iata": "HUL",
    "city": "Houlton",
    "region": "Maine",
    "tz": "America/New_York"
  },
  {
    "iata": "AAP",
    "city": "Houston",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "DWH",
    "city": "Houston",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "EFD",
    "city": "Houston",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "HOU",
    "city": "Houston",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "IAH",
    "city": "Houston",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "IWS",
    "city": "Houston",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "BLB",
    "city": "Howard",
    "region": "Panama",
    "tz": "America/Panama"
  },
  {
    "iata": "GNA",
    "city": "Hrodna",
    "region": "Belarus",
    "tz": "Europe/Minsk"
  },
  {
    "iata": "HUH",
    "city": "Huahine Island",
    "region": "French Polynesia",
    "tz": "Pacific/Tahiti"
  },
  {
    "iata": "HUN",
    "city": "Hualien",
    "region": "Taiwan",
    "tz": "Asia/Taipei"
  },
  {
    "iata": "NOV",
    "city": "Huambo",
    "region": "Angola",
    "tz": "Africa/Luanda"
  },
  {
    "iata": "TXN",
    "city": "Huangshan",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "HYN",
    "city": "Huangyan",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "HUX",
    "city": "Huatulco",
    "region": "Mexico",
    "tz": "America/Mexico_City"
  },
  {
    "iata": "OUI",
    "city": "Huay Xai",
    "region": "Laos",
    "tz": "Asia/Vientiane"
  },
  {
    "iata": "HBX",
    "city": "Hubli",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "HUV",
    "city": "Hudiksvall",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "YHB",
    "city": "Hudson Bay",
    "region": "Canada",
    "tz": "America/Regina"
  },
  {
    "iata": "HUI",
    "city": "Hue",
    "region": "Vietnam",
    "tz": "Asia/Ho_Chi_Minh"
  },
  {
    "iata": "HSK",
    "city": "Huesca",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "HGD",
    "city": "Hughenden",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "HUS",
    "city": "Hughes",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "HLF",
    "city": "Hultsfred",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "HUW",
    "city": "Humaita",
    "region": "Brazil",
    "tz": "America/Manaus"
  },
  {
    "iata": "HUY",
    "city": "Humberside",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "HUE",
    "city": "Humera",
    "region": "Ethiopia",
    "tz": "Africa/Addis_Ababa"
  },
  {
    "iata": "SVN",
    "city": "Hunter Aaf",
    "region": "Georgia",
    "tz": "America/New_York"
  },
  {
    "iata": "HTS",
    "city": "Huntington",
    "region": "West Virginia",
    "tz": "America/New_York"
  },
  {
    "iata": "HSV",
    "city": "Huntsville",
    "region": "Alabama",
    "tz": "America/Chicago"
  },
  {
    "iata": "HTV",
    "city": "Huntsville",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "HRG",
    "city": "Hurghada",
    "region": "Egypt",
    "tz": "Africa/Cairo"
  },
  {
    "iata": "HON",
    "city": "Huron",
    "region": "South Dakota",
    "tz": "America/Chicago"
  },
  {
    "iata": "HZK",
    "city": "Husavik",
    "region": "Iceland",
    "tz": "Atlantic/Reykjavik"
  },
  {
    "iata": "HSL",
    "city": "Huslia",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "HUT",
    "city": "Hutchinson",
    "region": "Kansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "HUU",
    "city": "Hu\u00e1nuco",
    "region": "Peru",
    "tz": "America/Lima"
  },
  {
    "iata": "HYG",
    "city": "Hydaburg",
    "region": "Alaska",
    "tz": "America/Metlakatla"
  },
  {
    "iata": "BPM",
    "city": "Hyderabad",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "HDD",
    "city": "Hyderabad",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "TLN",
    "city": "Hyeres",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "HYV",
    "city": "Hyvinkaa",
    "region": "Finland",
    "tz": "Europe/Helsinki"
  },
  {
    "iata": "IAS",
    "city": "Iasi",
    "region": "Romania",
    "tz": "Europe/Bucharest"
  },
  {
    "iata": "IBA",
    "city": "Ibadan",
    "region": "Nigeria",
    "tz": "Africa/Lagos"
  },
  {
    "iata": "IBE",
    "city": "Ibague",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "IBR",
    "city": "Ibaraki",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "IBZ",
    "city": "Ibiza",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "IDA",
    "city": "Idaho Falls",
    "region": "Idaho",
    "tz": "America/Boise"
  },
  {
    "iata": "IEJ",
    "city": "Ie",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "IFU",
    "city": "Ifuru",
    "region": "Maldives",
    "tz": "Indian/Maldives"
  },
  {
    "iata": "IAA",
    "city": "Igarka",
    "region": "Russia",
    "tz": "Asia/Krasnoyarsk"
  },
  {
    "iata": "IGG",
    "city": "Igiugig",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "YGT",
    "city": "Igloolik",
    "region": "Canada",
    "tz": "America/Iqaluit"
  },
  {
    "iata": "ZUC",
    "city": "Ignace",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "IGR",
    "city": "Iguazu Falls",
    "region": "Argentina",
    "tz": "America/Argentina/Cordoba"
  },
  {
    "iata": "JIK",
    "city": "Ikaria",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "IKL",
    "city": "Ikela",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Kinshasa"
  },
  {
    "iata": "IKI",
    "city": "Iki",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "IIL",
    "city": "Ilam",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "PFR",
    "city": "Ilebo",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Lubumbashi"
  },
  {
    "iata": "YGR",
    "city": "Iles De La Madeleine",
    "region": "Canada",
    "tz": "America/Halifax"
  },
  {
    "iata": "ILF",
    "city": "Ilford",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "IOS",
    "city": "Ilheus",
    "region": "Brazil",
    "tz": "America/Bahia"
  },
  {
    "iata": "ILI",
    "city": "Iliamna",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "VVZ",
    "city": "Illizi",
    "region": "Algeria",
    "tz": "Africa/Algiers"
  },
  {
    "iata": "ILO",
    "city": "Iloilo",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "ILR",
    "city": "Ilorin",
    "region": "Nigeria",
    "tz": "Africa/Lagos"
  },
  {
    "iata": "JAV",
    "city": "Ilulissat",
    "region": "Greenland",
    "tz": "America/Godthab"
  },
  {
    "iata": "IMB",
    "city": "Imbaimadai",
    "region": "Guyana",
    "tz": "America/Guyana"
  },
  {
    "iata": "IMM",
    "city": "Immokalee",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "QOW",
    "city": "Imo",
    "region": "Nigeria",
    "tz": "Africa/Lagos"
  },
  {
    "iata": "IMP",
    "city": "Imperatriz",
    "region": "Brazil",
    "tz": "America/Araguaina"
  },
  {
    "iata": "IPL",
    "city": "Imperial",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "IMF",
    "city": "Imphal",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "INZ",
    "city": "In Salah",
    "region": "Algeria",
    "tz": "Africa/Algiers"
  },
  {
    "iata": "IDP",
    "city": "Independence",
    "region": "Kansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "UTO",
    "city": "Indian Mountains",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "INS",
    "city": "Indian Springs",
    "region": "Nevada",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "IND",
    "city": "Indianapolis",
    "region": "Indiana",
    "tz": "America/Indiana/Indianapolis"
  },
  {
    "iata": "IDR",
    "city": "Indore",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "NNR",
    "city": "Indreabhan",
    "region": "Ireland",
    "tz": "Europe/Dublin"
  },
  {
    "iata": "IGB",
    "city": "Ingeniero Jacobacci",
    "region": "Argentina",
    "tz": "America/Argentina/Salta"
  },
  {
    "iata": "IGS",
    "city": "Ingolstadt",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "INH",
    "city": "Inhambane",
    "region": "Mozambique",
    "tz": "Africa/Maputo"
  },
  {
    "iata": "IOR",
    "city": "Inis Mor",
    "region": "Ireland",
    "tz": "Europe/Dublin"
  },
  {
    "iata": "INQ",
    "city": "Inisheer",
    "region": "Ireland",
    "tz": "Europe/Dublin"
  },
  {
    "iata": "IIA",
    "city": "Inishmaan",
    "region": "Ireland",
    "tz": "Europe/Dublin"
  },
  {
    "iata": "IFL",
    "city": "Innisfail",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "INN",
    "city": "Innsbruck",
    "region": "Austria",
    "tz": "Europe/Vienna"
  },
  {
    "iata": "INO",
    "city": "Inongo",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Kinshasa"
  },
  {
    "iata": "INA",
    "city": "Inta",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "INL",
    "city": "International Falls",
    "region": "Minnesota",
    "tz": "America/Chicago"
  },
  {
    "iata": "YPH",
    "city": "Inukjuak",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "YEV",
    "city": "Inuvik",
    "region": "Canada",
    "tz": "America/Inuvik"
  },
  {
    "iata": "IVC",
    "city": "Invercargill",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "IVR",
    "city": "Inverell",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "INV",
    "city": "Inverness",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "IYK",
    "city": "Inyokern",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "IOA",
    "city": "Ioannina",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "IOW",
    "city": "Iowa City",
    "region": "Iowa",
    "tz": "America/Chicago"
  },
  {
    "iata": "IPN",
    "city": "Ipatinga",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "IPI",
    "city": "Ipiales",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "IPH",
    "city": "Ipoh",
    "region": "Malaysia",
    "tz": "Asia/Kuala_Lumpur"
  },
  {
    "iata": "IPA",
    "city": "Ipota",
    "region": "Vanuatu",
    "tz": "Pacific/Efate"
  },
  {
    "iata": "YFB",
    "city": "Iqaluit",
    "region": "Canada",
    "tz": "America/Iqaluit"
  },
  {
    "iata": "IQQ",
    "city": "Iquique",
    "region": "Chile",
    "tz": "America/Santiago"
  },
  {
    "iata": "IQT",
    "city": "Iquitos",
    "region": "Peru",
    "tz": "America/Lima"
  },
  {
    "iata": "IRI",
    "city": "Iringa",
    "region": "Tanzania",
    "tz": "Africa/Dar_es_Salaam"
  },
  {
    "iata": "IKT",
    "city": "Irkutsk",
    "region": "Russia",
    "tz": "Asia/Irkutsk"
  },
  {
    "iata": "IMT",
    "city": "Iron Mountain",
    "region": "Michigan",
    "tz": "America/Menominee"
  },
  {
    "iata": "IFJ",
    "city": "Isafjordur",
    "region": "Iceland",
    "tz": "Atlantic/Reykjavik"
  },
  {
    "iata": "ISG",
    "city": "Ishigaki",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "IRD",
    "city": "Ishurdi",
    "region": "Bangladesh",
    "tz": "Asia/Dhaka"
  },
  {
    "iata": "IRP",
    "city": "Isiro",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Lubumbashi"
  },
  {
    "iata": "ISJ",
    "city": "Isla Mujeres",
    "region": "Mexico",
    "tz": "America/Cancun"
  },
  {
    "iata": "ISB",
    "city": "Islamabad",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "YIV",
    "city": "Island Lake",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "ILY",
    "city": "Islay",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "IOM",
    "city": "Isle Of Man",
    "region": "Isle of Man",
    "tz": "Europe/Isle_of_Man"
  },
  {
    "iata": "ISP",
    "city": "Islip",
    "region": "New York",
    "tz": "America/New_York"
  },
  {
    "iata": "ISE",
    "city": "Isparta",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "IST",
    "city": "Istanbul",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "SAW",
    "city": "Istanbul",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "ITB",
    "city": "Itaituba",
    "region": "Brazil",
    "tz": "America/Manaus"
  },
  {
    "iata": "ITP",
    "city": "Itaperuna",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "ITH",
    "city": "Ithaca",
    "region": "New York",
    "tz": "America/New_York"
  },
  {
    "iata": "OBY",
    "city": "Ittoqqortoormiit",
    "region": "Greenland",
    "tz": "America/Scoresbysund"
  },
  {
    "iata": "IVL",
    "city": "Ivalo",
    "region": "Finland",
    "tz": "Europe/Helsinki"
  },
  {
    "iata": "IFO",
    "city": "Ivano-Frankivsk",
    "region": "Ukraine",
    "tz": "Europe/Uzhgorod"
  },
  {
    "iata": "IWA",
    "city": "Ivanovo",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "YIK",
    "city": "Ivujivik",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "IWJ",
    "city": "Iwami",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "IWO",
    "city": "Iwojima",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "IJK",
    "city": "Izhevsk",
    "region": "Russia",
    "tz": "Europe/Samara"
  },
  {
    "iata": "ADB",
    "city": "Izmir",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "IGL",
    "city": "Izmir",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "IZO",
    "city": "Izumo",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "JLR",
    "city": "Jabalpur",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "JAB",
    "city": "Jabiru",
    "region": "Australia",
    "tz": "Australia/Darwin"
  },
  {
    "iata": "JAC",
    "city": "Jacksn Hole",
    "region": "Wyoming",
    "tz": "America/Denver"
  },
  {
    "iata": "JXN",
    "city": "Jackson",
    "region": "Michigan",
    "tz": "America/Detroit"
  },
  {
    "iata": "JAN",
    "city": "Jackson",
    "region": "Mississippi",
    "tz": "America/Chicago"
  },
  {
    "iata": "MKL",
    "city": "Jackson",
    "region": "Tennessee",
    "tz": "America/Chicago"
  },
  {
    "iata": "LRF",
    "city": "Jacksonville",
    "region": "Arkansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "CRG",
    "city": "Jacksonville",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "JAX",
    "city": "Jacksonville",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "OAJ",
    "city": "Jacksonville NC",
    "region": "North Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "JAF",
    "city": "Jaffna",
    "region": "Sri Lanka",
    "tz": "Asia/Colombo"
  },
  {
    "iata": "JAI",
    "city": "Jaipur",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "JSA",
    "city": "Jaisalmer",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "BUT",
    "city": "Jakar",
    "region": "Bhutan",
    "tz": "Asia/Thimphu"
  },
  {
    "iata": "CGK",
    "city": "Jakarta",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "HLP",
    "city": "Jakarta",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "PCB",
    "city": "Jakarta",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "JAA",
    "city": "Jalalabad",
    "region": "Afghanistan",
    "tz": "Asia/Kabul"
  },
  {
    "iata": "JAL",
    "city": "Jalapa",
    "region": "Mexico",
    "tz": "America/Mexico_City"
  },
  {
    "iata": "DJB",
    "city": "Jambi",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "JHW",
    "city": "Jamestown",
    "region": "New York",
    "tz": "America/New_York"
  },
  {
    "iata": "JMS",
    "city": "Jamestown",
    "region": "North Dakota",
    "tz": "America/Chicago"
  },
  {
    "iata": "IXJ",
    "city": "Jammu",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "JGA",
    "city": "Jamnagar",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "IXW",
    "city": "Jamshedpur",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "JVL",
    "city": "Janesville",
    "region": "Wisconsin",
    "tz": "America/Chicago"
  },
  {
    "iata": "JQE",
    "city": "Jaqu\u00e9",
    "region": "Panama",
    "tz": "America/Panama"
  },
  {
    "iata": "DJJ",
    "city": "Jayapura",
    "region": "Indonesia",
    "tz": "Asia/Jayapura"
  },
  {
    "iata": "JED",
    "city": "Jeddah",
    "region": "Saudi Arabia",
    "tz": "Asia/Riyadh"
  },
  {
    "iata": "JEF",
    "city": "Jefferson City",
    "region": "Missouri",
    "tz": "America/Chicago"
  },
  {
    "iata": "ZJG",
    "city": "Jenpeg",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "JEE",
    "city": "Jeremie",
    "region": "Haiti",
    "tz": "America/Port-au-Prince"
  },
  {
    "iata": "XRY",
    "city": "Jerez",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "JER",
    "city": "Jersey",
    "region": "Jersey",
    "tz": "Europe/Jersey"
  },
  {
    "iata": "JSR",
    "city": "Jessore",
    "region": "Bangladesh",
    "tz": "Asia/Dhaka"
  },
  {
    "iata": "CHN",
    "city": "Jhunju",
    "region": "South Korea",
    "tz": "Asia/Seoul"
  },
  {
    "iata": "JPR",
    "city": "Ji-Paran\u00e1",
    "region": "Brazil",
    "tz": "America/Porto_Velho"
  },
  {
    "iata": "JMU",
    "city": "Jiamusi",
    "region": "China",
    "tz": "Asia/Harbin"
  },
  {
    "iata": "JGN",
    "city": "Jiayuguan",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "GJL",
    "city": "Jijel",
    "region": "Algeria",
    "tz": "Africa/Algiers"
  },
  {
    "iata": "JIJ",
    "city": "Jijiga",
    "region": "Ethiopia",
    "tz": "Africa/Addis_Ababa"
  },
  {
    "iata": "JIM",
    "city": "Jimma",
    "region": "Ethiopia",
    "tz": "Africa/Addis_Ababa"
  },
  {
    "iata": "TNA",
    "city": "Jinan",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "JDZ",
    "city": "Jingdezhen",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "JHG",
    "city": "Jinghonggasa",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "JNG",
    "city": "Jining",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "JNZ",
    "city": "Jinzhou",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "JIU",
    "city": "Jiujiang",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "JZH",
    "city": "Jiuzhaigou",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "JIW",
    "city": "Jiwani",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "JXA",
    "city": "Jixi",
    "region": "China",
    "tz": "Asia/Harbin"
  },
  {
    "iata": "JCB",
    "city": "Joacaba",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "JPA",
    "city": "Joao Pessoa",
    "region": "Brazil",
    "tz": "America/Recife"
  },
  {
    "iata": "JDH",
    "city": "Jodhpur",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "JKG",
    "city": "Joenkoeping",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "JOE",
    "city": "Joensuu",
    "region": "Finland",
    "tz": "Europe/Helsinki"
  },
  {
    "iata": "GCJ",
    "city": "Johannesburg",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "HLA",
    "city": "Johannesburg",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "JNB",
    "city": "Johannesburg",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "QRA",
    "city": "Johannesburg",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "JON",
    "city": "Johnston Island",
    "region": "Johnston Atoll",
    "tz": "Pacific/Johnston"
  },
  {
    "iata": "JST",
    "city": "Johnstown",
    "region": "Pennsylvania",
    "tz": "America/New_York"
  },
  {
    "iata": "JHB",
    "city": "Johor Bahru",
    "region": "Malaysia",
    "tz": "Asia/Kuala_Lumpur"
  },
  {
    "iata": "JOI",
    "city": "Joinville",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "JOT",
    "city": "Joliet",
    "region": "Illinois",
    "tz": "America/Chicago"
  },
  {
    "iata": "JOL",
    "city": "Jolo",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "JMO",
    "city": "Jomsom",
    "region": "Nepal",
    "tz": "Asia/Kathmandu"
  },
  {
    "iata": "JBR",
    "city": "Jonesboro",
    "region": "Arkansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "JLN",
    "city": "Joplin",
    "region": "Missouri",
    "tz": "America/Chicago"
  },
  {
    "iata": "JRH",
    "city": "Jorhat",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "JOS",
    "city": "Jos",
    "region": "Nigeria",
    "tz": "Africa/Lagos"
  },
  {
    "iata": "JSM",
    "city": "Jose de San Martin",
    "region": "Argentina",
    "tz": "America/Argentina/Catamarca"
  },
  {
    "iata": "JJI",
    "city": "Juanjui",
    "region": "Peru",
    "tz": "America/Lima"
  },
  {
    "iata": "JUA",
    "city": "Juara",
    "region": "Brazil",
    "tz": "America/Cuiaba"
  },
  {
    "iata": "JDO",
    "city": "Juazeiro Do Norte",
    "region": "Brazil",
    "tz": "America/Fortaleza"
  },
  {
    "iata": "JUB",
    "city": "Juba",
    "region": "South Sudan",
    "tz": "Africa/Juba"
  },
  {
    "iata": "JIA",
    "city": "Juina",
    "region": "Brazil",
    "tz": "America/Cuiaba"
  },
  {
    "iata": "JUI",
    "city": "Juist",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "JDF",
    "city": "Juiz De Fora",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "IZA",
    "city": "Juiz de Fora",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "JUJ",
    "city": "Jujuy",
    "region": "Argentina",
    "tz": "America/Argentina/Jujuy"
  },
  {
    "iata": "JCK",
    "city": "Julia Creek",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "JUL",
    "city": "Juliaca",
    "region": "Peru",
    "tz": "America/Lima"
  },
  {
    "iata": "JUM",
    "city": "Jumla",
    "region": "Nepal",
    "tz": "Asia/Kathmandu"
  },
  {
    "iata": "JNU",
    "city": "Juneau",
    "region": "Alaska",
    "tz": "America/Juneau"
  },
  {
    "iata": "JRN",
    "city": "Juruena",
    "region": "Brazil",
    "tz": "America/Cuiaba"
  },
  {
    "iata": "JWA",
    "city": "Jwaneng",
    "region": "Botswana",
    "tz": "Africa/Gaborone"
  },
  {
    "iata": "JYV",
    "city": "Jyvaskyla",
    "region": "Finland",
    "tz": "Europe/Helsinki"
  },
  {
    "iata": "KDM",
    "city": "Kaadedhdhoo",
    "region": "Maldives",
    "tz": "Indian/Maldives"
  },
  {
    "iata": "KBN",
    "city": "Kabinda",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Lubumbashi"
  },
  {
    "iata": "ABK",
    "city": "Kabri Dehar",
    "region": "Ethiopia",
    "tz": "Africa/Addis_Ababa"
  },
  {
    "iata": "KBL",
    "city": "Kabul",
    "region": "Afghanistan",
    "tz": "Asia/Kabul"
  },
  {
    "iata": "DNA",
    "city": "Kadena",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "KAD",
    "city": "Kaduna",
    "region": "Nigeria",
    "tz": "Africa/Lagos"
  },
  {
    "iata": "KED",
    "city": "Kaedi",
    "region": "Mauritania",
    "tz": "Africa/Nouakchott"
  },
  {
    "iata": "KGE",
    "city": "Kagau Island",
    "region": "Solomon Islands",
    "tz": "Pacific/Guadalcanal"
  },
  {
    "iata": "KOJ",
    "city": "Kagoshima",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "KCM",
    "city": "Kahramanmaras",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "OGG",
    "city": "Kahului",
    "region": "Hawaii",
    "tz": "Pacific/Honolulu"
  },
  {
    "iata": "KAI",
    "city": "Kaieteur",
    "region": "Guyana",
    "tz": "America/Guyana"
  },
  {
    "iata": "KBZ",
    "city": "Kaikoura",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "IXH",
    "city": "Kailashahar",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "KJH",
    "city": "Kaili",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "KNG",
    "city": "Kaimana",
    "region": "Indonesia",
    "tz": "Asia/Jayapura"
  },
  {
    "iata": "AIZ",
    "city": "Kaiser Lake Ozark",
    "region": "Missouri",
    "tz": "America/Chicago"
  },
  {
    "iata": "KAT",
    "city": "Kaitaia",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "KAJ",
    "city": "Kajaani",
    "region": "Finland",
    "tz": "Europe/Helsinki"
  },
  {
    "iata": "KLX",
    "city": "Kalamata",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "AZO",
    "city": "Kalamazoo",
    "region": "Michigan",
    "tz": "America/Detroit"
  },
  {
    "iata": "KAX",
    "city": "Kalbarri",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "FMI",
    "city": "Kalemie",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Lubumbashi"
  },
  {
    "iata": "KMV",
    "city": "Kalemyo",
    "region": "Myanmar",
    "tz": "Asia/Rangoon"
  },
  {
    "iata": "KGI",
    "city": "Kalgoorlie",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "KLO",
    "city": "Kalibo",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "KGD",
    "city": "Kaliningrad",
    "region": "Russia",
    "tz": "Europe/Kaliningrad"
  },
  {
    "iata": "FCA",
    "city": "Kalispell",
    "region": "Montana",
    "tz": "America/Denver"
  },
  {
    "iata": "KFG",
    "city": "Kalkgurung",
    "region": "Australia",
    "tz": "Australia/Darwin"
  },
  {
    "iata": "KLR",
    "city": "Kalkmar",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "KLG",
    "city": "Kalskag",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "KAL",
    "city": "Kaltag",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "KLF",
    "city": "Kaluga",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "JKL",
    "city": "Kalymnos",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "KAR",
    "city": "Kamarang",
    "region": "Guyana",
    "tz": "America/Guyana"
  },
  {
    "iata": "KME",
    "city": "Kamembe",
    "region": "Rwanda",
    "tz": "Africa/Kigali"
  },
  {
    "iata": "KMN",
    "city": "Kamina Base",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Lubumbashi"
  },
  {
    "iata": "KAC",
    "city": "Kamishly",
    "region": "Syria",
    "tz": "Asia/Damascus"
  },
  {
    "iata": "YKA",
    "city": "Kamloops",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "ATG",
    "city": "Kamra",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "MUE",
    "city": "Kamuela",
    "region": "Hawaii",
    "tz": "Pacific/Honolulu"
  },
  {
    "iata": "KGA",
    "city": "Kananga",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Lubumbashi"
  },
  {
    "iata": "KMQ",
    "city": "Kanazawa",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "KDH",
    "city": "Kandahar",
    "region": "Afghanistan",
    "tz": "Asia/Kabul"
  },
  {
    "iata": "IXY",
    "city": "Kandla",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "NGF",
    "city": "Kaneohe Bay",
    "region": "Hawaii",
    "tz": "Pacific/Honolulu"
  },
  {
    "iata": "KNR",
    "city": "Kangan",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "XGR",
    "city": "Kangiqsualujjuaq",
    "region": "Canada",
    "tz": "America/Iqaluit"
  },
  {
    "iata": "YWB",
    "city": "Kangiqsujuaq",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "YKG",
    "city": "Kangirsuk",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "KAG",
    "city": "Kangnung",
    "region": "South Korea",
    "tz": "Asia/Seoul"
  },
  {
    "iata": "DHM",
    "city": "Kangra",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "IKK",
    "city": "Kankakee",
    "region": "Illinois",
    "tz": "America/Chicago"
  },
  {
    "iata": "KAN",
    "city": "Kano",
    "region": "Nigeria",
    "tz": "Africa/Lagos"
  },
  {
    "iata": "KNU",
    "city": "Kanpur",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "MCI",
    "city": "Kansas City",
    "region": "Missouri",
    "tz": "America/Chicago"
  },
  {
    "iata": "MKC",
    "city": "Kansas City",
    "region": "Missouri",
    "tz": "America/Chicago"
  },
  {
    "iata": "KAZ",
    "city": "Kao",
    "region": "Indonesia",
    "tz": "Asia/Jayapura"
  },
  {
    "iata": "KHH",
    "city": "Kaohsiung",
    "region": "Taiwan",
    "tz": "Asia/Taipei"
  },
  {
    "iata": "KLC",
    "city": "Kaolack",
    "region": "Senegal",
    "tz": "Africa/Dakar"
  },
  {
    "iata": "YYU",
    "city": "Kapuskasing",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "KHI",
    "city": "Karachi",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "KGF",
    "city": "Karaganda",
    "region": "Kazakhstan",
    "tz": "Asia/Almaty"
  },
  {
    "iata": "PYK",
    "city": "Karaj",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "KRY",
    "city": "Karamay",
    "region": "China",
    "tz": "Asia/Urumqi"
  },
  {
    "iata": "KDL",
    "city": "Kardla",
    "region": "Estonia",
    "tz": "Europe/Tallinn"
  },
  {
    "iata": "KAB",
    "city": "Kariba",
    "region": "Zimbabwe",
    "tz": "Africa/Harare"
  },
  {
    "iata": "KWB",
    "city": "Karimunjawa",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "KLV",
    "city": "Karlovy Vary",
    "region": "Czech Republic",
    "tz": "Europe/Prague"
  },
  {
    "iata": "FKB",
    "city": "Karlsruhe/Baden-Baden",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "KSD",
    "city": "Karlstad",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "KYK",
    "city": "Karluk",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "KGJ",
    "city": "Karonga",
    "region": "Malawi",
    "tz": "Africa/Blantyre"
  },
  {
    "iata": "AOK",
    "city": "Karpathos",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "KTA",
    "city": "Karratha",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "KSY",
    "city": "Kars",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "KRB",
    "city": "Karumba",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "KRP",
    "city": "Karup",
    "region": "Denmark",
    "tz": "Europe/Copenhagen"
  },
  {
    "iata": "ZKB",
    "city": "Kasaba Bay",
    "region": "Zambia",
    "tz": "Africa/Lusaka"
  },
  {
    "iata": "XKS",
    "city": "Kasabonika",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "KAA",
    "city": "Kasama",
    "region": "Zambia",
    "tz": "Africa/Lusaka"
  },
  {
    "iata": "BBK",
    "city": "Kasane",
    "region": "Botswana",
    "tz": "Africa/Gaborone"
  },
  {
    "iata": "ZKE",
    "city": "Kashechewan",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "KHG",
    "city": "Kashi",
    "region": "China",
    "tz": "Asia/Kashgar"
  },
  {
    "iata": "KUK",
    "city": "Kasigluk",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "KGN",
    "city": "Kasongo",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Kinshasa"
  },
  {
    "iata": "KSJ",
    "city": "Kasos",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "KSL",
    "city": "Kassala",
    "region": "Sudan",
    "tz": "Africa/Khartoum"
  },
  {
    "iata": "KSF",
    "city": "Kassel",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "KFS",
    "city": "Kastamonu",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "KZS",
    "city": "Kastelorizo",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "KSO",
    "city": "Kastoria",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "KTR",
    "city": "Katherine",
    "region": "Australia",
    "tz": "Australia/Darwin"
  },
  {
    "iata": "KTM",
    "city": "Kathmandu",
    "region": "Nepal",
    "tz": "Asia/Kathmandu"
  },
  {
    "iata": "KTW",
    "city": "Katowice",
    "region": "Poland",
    "tz": "Europe/Warsaw"
  },
  {
    "iata": "KAU",
    "city": "Kauhava",
    "region": "Finland",
    "tz": "Europe/Helsinki"
  },
  {
    "iata": "KKR",
    "city": "Kaukura Atoll",
    "region": "French Polynesia",
    "tz": "Pacific/Tahiti"
  },
  {
    "iata": "KUN",
    "city": "Kaunas",
    "region": "Lithuania",
    "tz": "Europe/Vilnius"
  },
  {
    "iata": "KVA",
    "city": "Kavala",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "KVG",
    "city": "Kavieng",
    "region": "Papua New Guinea",
    "tz": "Pacific/Port_Moresby"
  },
  {
    "iata": "KAW",
    "city": "Kawthoung",
    "region": "Burma",
    "tz": "Asia/Rangoon"
  },
  {
    "iata": "KYS",
    "city": "Kayes",
    "region": "Mali",
    "tz": "Africa/Bamako"
  },
  {
    "iata": "ASR",
    "city": "Kayseri",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "KZN",
    "city": "Kazan",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "KGG",
    "city": "Kedougou",
    "region": "Senegal",
    "tz": "Africa/Dakar"
  },
  {
    "iata": "EEN",
    "city": "Keene",
    "region": "New Hampshire",
    "tz": "America/New_York"
  },
  {
    "iata": "KMP",
    "city": "Keetmanshoop",
    "region": "Namibia",
    "tz": "Africa/Windhoek"
  },
  {
    "iata": "KEW",
    "city": "Keewaywin",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "EFL",
    "city": "Keffallinia",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "KEF",
    "city": "Keflavik",
    "region": "Iceland",
    "tz": "Atlantic/Reykjavik"
  },
  {
    "iata": "ZKG",
    "city": "Kegaska",
    "region": "Canada",
    "tz": "America/Blanc-Sablon"
  },
  {
    "iata": "YLW",
    "city": "Kelowna",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "KES",
    "city": "Kelsey",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "KLS",
    "city": "Kelso",
    "region": "Washington",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "KEM",
    "city": "Kemi",
    "region": "Finland",
    "tz": "Europe/Helsinki"
  },
  {
    "iata": "KEJ",
    "city": "Kemorovo",
    "region": "Russia",
    "tz": "Asia/Novokuznetsk"
  },
  {
    "iata": "EMP",
    "city": "Kempten",
    "region": "Kansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "ENA",
    "city": "Kenai",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "TMB",
    "city": "Kendall-tamiami",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "KDI",
    "city": "Kendari",
    "region": "Indonesia",
    "tz": "Asia/Makassar"
  },
  {
    "iata": "KEN",
    "city": "Kenema",
    "region": "Sierra Leone",
    "tz": "Africa/Freetown"
  },
  {
    "iata": "KET",
    "city": "Kengtung",
    "region": "Burma",
    "tz": "Asia/Rangoon"
  },
  {
    "iata": "YQK",
    "city": "Kenora",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "ENW",
    "city": "Kenosha",
    "region": "Wisconsin",
    "tz": "America/Chicago"
  },
  {
    "iata": "NNA",
    "city": "Kentira",
    "region": "Morocco",
    "tz": "Africa/Casablanca"
  },
  {
    "iata": "EOK",
    "city": "Keokuk",
    "region": "Iowa",
    "tz": "America/Chicago"
  },
  {
    "iata": "KJP",
    "city": "Kerama",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "KHC",
    "city": "Kerch",
    "region": "Ukraine",
    "tz": "Europe/Simferopol"
  },
  {
    "iata": "KMA",
    "city": "Kerema",
    "region": "Papua New Guinea",
    "tz": "Pacific/Port_Moresby"
  },
  {
    "iata": "KEY",
    "city": "Kericho",
    "region": "Kenya",
    "tz": "Africa/Nairobi"
  },
  {
    "iata": "KKE",
    "city": "Kerikeri",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "CFU",
    "city": "Kerkyra/corfu",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "KER",
    "city": "Kerman",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "ERV",
    "city": "Kerrville",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "KIR",
    "city": "Kerry",
    "region": "Ireland",
    "tz": "Europe/Dublin"
  },
  {
    "iata": "KTE",
    "city": "Kerteh",
    "region": "Malaysia",
    "tz": "Asia/Kuala_Lumpur"
  },
  {
    "iata": "IXK",
    "city": "Keshod",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "KTG",
    "city": "Ketapang",
    "region": "Indonesia",
    "tz": "Asia/Pontianak"
  },
  {
    "iata": "KTN",
    "city": "Ketchikan",
    "region": "Alaska",
    "tz": "America/Sitka"
  },
  {
    "iata": "YKJ",
    "city": "Key Lake",
    "region": "Canada",
    "tz": "America/Regina"
  },
  {
    "iata": "EYW",
    "city": "Key West",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "NQX",
    "city": "Key West",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "KHV",
    "city": "Khabarovsk",
    "region": "Russia",
    "tz": "Asia/Vladivostok"
  },
  {
    "iata": "HJR",
    "city": "Khajuraho",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "PGU",
    "city": "Khalije Fars",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "KHM",
    "city": "Khamti",
    "region": "Burma",
    "tz": "Asia/Rangoon"
  },
  {
    "iata": "KSQ",
    "city": "Khanabad",
    "region": "Uzbekistan",
    "tz": "Asia/Samarkand"
  },
  {
    "iata": "HMA",
    "city": "Khanty-Mansiysk",
    "region": "Russia",
    "tz": "Asia/Yekaterinburg"
  },
  {
    "iata": "HRK",
    "city": "Kharkov",
    "region": "Ukraine",
    "tz": "Europe/Zaporozhye"
  },
  {
    "iata": "KRT",
    "city": "Khartoum",
    "region": "Sudan",
    "tz": "Africa/Khartoum"
  },
  {
    "iata": "KHS",
    "city": "Khasab",
    "region": "Oman",
    "tz": "Asia/Muscat"
  },
  {
    "iata": "HTG",
    "city": "Khatanga",
    "region": "Russia",
    "tz": "Asia/Krasnoyarsk"
  },
  {
    "iata": "KHE",
    "city": "Kherson",
    "region": "Ukraine",
    "tz": "Europe/Simferopol"
  },
  {
    "iata": "HMJ",
    "city": "Khmeinitskiy",
    "region": "Ukraine",
    "tz": "Europe/Kiev"
  },
  {
    "iata": "KKC",
    "city": "Khon Kaen",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "KHD",
    "city": "Khorram Abad",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "KHT",
    "city": "Khost",
    "region": "Afghanistan",
    "tz": "Asia/Kabul"
  },
  {
    "iata": "HVD",
    "city": "Khovd",
    "region": "Mongolia",
    "tz": "Asia/Hovd"
  },
  {
    "iata": "LBD",
    "city": "Khudzhand",
    "region": "Tajikistan",
    "tz": "Asia/Dushanbe"
  },
  {
    "iata": "KDD",
    "city": "Khuzdar",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "KHW",
    "city": "Khwai River",
    "region": "Botswana",
    "tz": "Africa/Gaborone"
  },
  {
    "iata": "IAN",
    "city": "Kiana",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "KEL",
    "city": "Kiel",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "KIE",
    "city": "Kieta",
    "region": "Papua New Guinea",
    "tz": "Pacific/Bougainville"
  },
  {
    "iata": "IEV",
    "city": "Kiev",
    "region": "Ukraine",
    "tz": "Europe/Kiev"
  },
  {
    "iata": "KBP",
    "city": "Kiev",
    "region": "Ukraine",
    "tz": "Europe/Kiev"
  },
  {
    "iata": "KFA",
    "city": "Kiffa",
    "region": "Mauritania",
    "tz": "Africa/Nouakchott"
  },
  {
    "iata": "KGL",
    "city": "Kigali",
    "region": "Rwanda",
    "tz": "Africa/Kigali"
  },
  {
    "iata": "TKQ",
    "city": "Kigoma",
    "region": "Tanzania",
    "tz": "Africa/Dar_es_Salaam"
  },
  {
    "iata": "KKX",
    "city": "Kikai",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "KRI",
    "city": "Kikori",
    "region": "Papua New Guinea",
    "tz": "Pacific/Port_Moresby"
  },
  {
    "iata": "KKW",
    "city": "Kikwit",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Kinshasa"
  },
  {
    "iata": "ILU",
    "city": "Kilaguni",
    "region": "Kenya",
    "tz": "Africa/Nairobi"
  },
  {
    "iata": "KIO",
    "city": "Kili Island",
    "region": "Marshall Islands",
    "tz": "Pacific/Majuro"
  },
  {
    "iata": "JRO",
    "city": "Kilimanjaro",
    "region": "Tanzania",
    "tz": "Africa/Dar_es_Salaam"
  },
  {
    "iata": "FFA",
    "city": "Kill Devil Hills",
    "region": "North Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "GRK",
    "city": "Killeen",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "KIM",
    "city": "Kimberley",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "YLC",
    "city": "Kimmirut",
    "region": "Canada",
    "tz": "America/Iqaluit"
  },
  {
    "iata": "YKY",
    "city": "Kindersley",
    "region": "Canada",
    "tz": "America/Regina"
  },
  {
    "iata": "KND",
    "city": "Kindu",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Lubumbashi"
  },
  {
    "iata": "KVC",
    "city": "King Cove",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "KNS",
    "city": "King Island",
    "region": "Australia",
    "tz": "Australia/Currie"
  },
  {
    "iata": "HBT",
    "city": "King Khalid Mil.city",
    "region": "Saudi Arabia",
    "tz": "Asia/Riyadh"
  },
  {
    "iata": "AKN",
    "city": "King Salmon",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "KIF",
    "city": "Kingfisher Lake",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "KGC",
    "city": "Kingscote",
    "region": "Australia",
    "tz": "Australia/Adelaide"
  },
  {
    "iata": "YGK",
    "city": "Kingston",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "KIN",
    "city": "Kingston",
    "region": "Jamaica",
    "tz": "America/Jamaica"
  },
  {
    "iata": "KTP",
    "city": "Kingston",
    "region": "Jamaica",
    "tz": "America/Jamaica"
  },
  {
    "iata": "SVD",
    "city": "Kingstown",
    "region": "Saint Vincent and the Grenadines",
    "tz": "America/St_Vincent"
  },
  {
    "iata": "FSS",
    "city": "Kinloss",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "KNH",
    "city": "Kinmen",
    "region": "Taiwan",
    "tz": "Asia/Taipei"
  },
  {
    "iata": "FIH",
    "city": "Kinshasa",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Kinshasa"
  },
  {
    "iata": "NLO",
    "city": "Kinshasa",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Kinshasa"
  },
  {
    "iata": "ISO",
    "city": "Kinston",
    "region": "North Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "KPN",
    "city": "Kipnuk",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "IRA",
    "city": "Kirakira",
    "region": "Solomon Islands",
    "tz": "Pacific/Guadalcanal"
  },
  {
    "iata": "KRZ",
    "city": "Kiri",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Kinshasa"
  },
  {
    "iata": "CXI",
    "city": "Kiritimati",
    "region": "Kiribati",
    "tz": "Pacific/Kiritimati"
  },
  {
    "iata": "KKN",
    "city": "Kirkenes",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "YKX",
    "city": "Kirkland Lake",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "IRK",
    "city": "Kirksville",
    "region": "Missouri",
    "tz": "America/Chicago"
  },
  {
    "iata": "KIK",
    "city": "Kirkuk",
    "region": "Iraq",
    "tz": "Asia/Baghdad"
  },
  {
    "iata": "KOI",
    "city": "Kirkwall",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "KVX",
    "city": "Kirov",
    "region": "Russia",
    "tz": "Europe/Volgograd"
  },
  {
    "iata": "KGO",
    "city": "Kirovograd",
    "region": "Ukraine",
    "tz": "Europe/Zaporozhye"
  },
  {
    "iata": "KRN",
    "city": "Kiruna",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "FKI",
    "city": "Kisangani",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Lubumbashi"
  },
  {
    "iata": "KIH",
    "city": "Kish Island",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "KMU",
    "city": "Kismayu",
    "region": "Somalia",
    "tz": "Africa/Mogadishu"
  },
  {
    "iata": "KSI",
    "city": "Kissidougou",
    "region": "Guinea",
    "tz": "Africa/Conakry"
  },
  {
    "iata": "ISM",
    "city": "Kissimmee",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "KIS",
    "city": "Kisumu",
    "region": "Kenya",
    "tz": "Africa/Nairobi"
  },
  {
    "iata": "KTD",
    "city": "Kitadaito",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "KKJ",
    "city": "Kitakyushu",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "KTL",
    "city": "Kitale",
    "region": "Kenya",
    "tz": "Africa/Nairobi"
  },
  {
    "iata": "KIT",
    "city": "Kithira",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "KTT",
    "city": "Kittila",
    "region": "Finland",
    "tz": "Europe/Helsinki"
  },
  {
    "iata": "UNG",
    "city": "Kiunga",
    "region": "Papua New Guinea",
    "tz": "Pacific/Port_Moresby"
  },
  {
    "iata": "KVL",
    "city": "Kivalina",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "KLU",
    "city": "Klagenfurt",
    "region": "Austria",
    "tz": "Europe/Vienna"
  },
  {
    "iata": "LMT",
    "city": "Klamath Falls",
    "region": "Oregon",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "KLW",
    "city": "Klawock",
    "region": "Alaska",
    "tz": "America/Juneau"
  },
  {
    "iata": "KLZ",
    "city": "Kleinsee",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "SZL",
    "city": "Knobnoster",
    "region": "Missouri",
    "tz": "America/Chicago"
  },
  {
    "iata": "TYS",
    "city": "Knoxville",
    "region": "Tennessee",
    "tz": "America/New_York"
  },
  {
    "iata": "DKX",
    "city": "Knoxville",
    "region": "United States",
    "tz": "America/New_York"
  },
  {
    "iata": "USM",
    "city": "Ko Samui",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "UKB",
    "city": "Kobe",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "OBU",
    "city": "Kobuk",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "COK",
    "city": "Kochi",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "KCZ",
    "city": "Kochi",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "ADQ",
    "city": "Kodiak",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "KGP",
    "city": "Kogalym",
    "region": "Russia",
    "tz": "Asia/Yekaterinburg"
  },
  {
    "iata": "KCT",
    "city": "Koggala",
    "region": "Sri Lanka",
    "tz": "Asia/Colombo"
  },
  {
    "iata": "OKK",
    "city": "Kokomo",
    "region": "Indiana",
    "tz": "America/Indiana/Indianapolis"
  },
  {
    "iata": "KOX",
    "city": "Kokonau",
    "region": "Indonesia",
    "tz": "Asia/Jayapura"
  },
  {
    "iata": "KOV",
    "city": "Kokshetau",
    "region": "Kazakhstan",
    "tz": "Asia/Almaty"
  },
  {
    "iata": "KLH",
    "city": "Kolhapur",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "KGK",
    "city": "Koliganek",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "CCU",
    "city": "Kolkata",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "KWZ",
    "city": "Kolwezi",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Lubumbashi"
  },
  {
    "iata": "KXK",
    "city": "Komsomolsk-on-Amur",
    "region": "Russia",
    "tz": "Asia/Vladivostok"
  },
  {
    "iata": "KOA",
    "city": "Kona",
    "region": "Hawaii",
    "tz": "Pacific/Honolulu"
  },
  {
    "iata": "KNQ",
    "city": "Kone",
    "region": "New Caledonia",
    "tz": "Pacific/Noumea"
  },
  {
    "iata": "KKH",
    "city": "Kongiganak",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "KOO",
    "city": "Kongolo",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Lubumbashi"
  },
  {
    "iata": "KYA",
    "city": "Konya",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "GKK",
    "city": "Kooddoo",
    "region": "Maldives",
    "tz": "Indian/Maldives"
  },
  {
    "iata": "HGO",
    "city": "Korhogo",
    "region": "Cote d'Ivoire",
    "tz": "Africa/Abidjan"
  },
  {
    "iata": "KRL",
    "city": "Korla",
    "region": "China",
    "tz": "Asia/Urumqi"
  },
  {
    "iata": "KXF",
    "city": "Koro Island",
    "region": "Fiji",
    "tz": "Pacific/Fiji"
  },
  {
    "iata": "KGS",
    "city": "Kos",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "KSC",
    "city": "Kosice",
    "region": "Slovakia",
    "tz": "Europe/Bratislava"
  },
  {
    "iata": "KSA",
    "city": "Kosrae",
    "region": "Micronesia",
    "tz": "Pacific/Kosrae"
  },
  {
    "iata": "KSN",
    "city": "Kostanay",
    "region": "Kazakhstan",
    "tz": "Asia/Qyzylorda"
  },
  {
    "iata": "KMW",
    "city": "Kostroma",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "OSZ",
    "city": "Koszalin",
    "region": "Poland",
    "tz": "Europe/Warsaw"
  },
  {
    "iata": "KTU",
    "city": "Kota",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "KBR",
    "city": "Kota Bahru",
    "region": "Malaysia",
    "tz": "Asia/Kuala_Lumpur"
  },
  {
    "iata": "BKI",
    "city": "Kota Kinabalu",
    "region": "Malaysia",
    "tz": "Asia/Kuching"
  },
  {
    "iata": "KSZ",
    "city": "Kotlas",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "KOT",
    "city": "Kotlik",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "OTZ",
    "city": "Kotzebue",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "KOU",
    "city": "Koulamoutou",
    "region": "Gabon",
    "tz": "Africa/Libreville"
  },
  {
    "iata": "KOC",
    "city": "Koumac",
    "region": "New Caledonia",
    "tz": "Pacific/Noumea"
  },
  {
    "iata": "KWM",
    "city": "Kowanyama",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "KKA",
    "city": "Koyuk",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "KYU",
    "city": "Koyukuk",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "KZI",
    "city": "Kozani",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "KBV",
    "city": "Krabi",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "KRK",
    "city": "Krakow",
    "region": "Poland",
    "tz": "Europe/Warsaw"
  },
  {
    "iata": "BON",
    "city": "Kralendijk",
    "region": "Netherlands Antilles",
    "tz": "America/Kralendijk"
  },
  {
    "iata": "KRQ",
    "city": "Kramatorsk",
    "region": "Ukraine",
    "tz": "Europe/Zaporozhye"
  },
  {
    "iata": "KRF",
    "city": "Kramfors",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "KRR",
    "city": "Krasnodar",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "KRW",
    "city": "Krasnovodsk",
    "region": "Turkmenistan",
    "tz": "Asia/Ashgabat"
  },
  {
    "iata": "KJA",
    "city": "Krasnoyarsk",
    "region": "Russia",
    "tz": "Asia/Krasnoyarsk"
  },
  {
    "iata": "KTI",
    "city": "Kratie",
    "region": "Cambodia",
    "tz": "Asia/Phnom_Penh"
  },
  {
    "iata": "KRS",
    "city": "Kristiansand",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "KID",
    "city": "Kristianstad",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "KSU",
    "city": "Kristiansund",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "KWG",
    "city": "Krivoy Rog",
    "region": "Ukraine",
    "tz": "Europe/Zaporozhye"
  },
  {
    "iata": "KOK",
    "city": "Kruunupyy",
    "region": "Finland",
    "tz": "Europe/Helsinki"
  },
  {
    "iata": "KUL",
    "city": "Kuala Lumpur",
    "region": "Malaysia",
    "tz": "Asia/Kuala_Lumpur"
  },
  {
    "iata": "SZB",
    "city": "Kuala Lumpur",
    "region": "Malaysia",
    "tz": "Asia/Kuala_Lumpur"
  },
  {
    "iata": "TGG",
    "city": "Kuala Terengganu",
    "region": "Malaysia",
    "tz": "Asia/Kuala_Lumpur"
  },
  {
    "iata": "KUA",
    "city": "Kuantan",
    "region": "Malaysia",
    "tz": "Asia/Kuala_Lumpur"
  },
  {
    "iata": "KUG",
    "city": "Kubin",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "KCH",
    "city": "Kuching",
    "region": "Malaysia",
    "tz": "Asia/Kuching"
  },
  {
    "iata": "KUD",
    "city": "Kudat",
    "region": "Malaysia",
    "tz": "Asia/Kuching"
  },
  {
    "iata": "AKF",
    "city": "Kufra",
    "region": "Libya",
    "tz": "Africa/Tripoli"
  },
  {
    "iata": "SVP",
    "city": "Kuito",
    "region": "Angola",
    "tz": "Africa/Luanda"
  },
  {
    "iata": "KUU",
    "city": "Kulu",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "TJU",
    "city": "Kulyab",
    "region": "Tajikistan",
    "tz": "Asia/Dushanbe"
  },
  {
    "iata": "KMJ",
    "city": "Kumamoto",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "KMS",
    "city": "Kumasi",
    "region": "Ghana",
    "tz": "Africa/Accra"
  },
  {
    "iata": "UEO",
    "city": "Kumejima",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "CMU",
    "city": "Kundiawa",
    "region": "Papua New Guinea",
    "tz": "Pacific/Port_Moresby"
  },
  {
    "iata": "UND",
    "city": "Kunduz",
    "region": "Afghanistan",
    "tz": "Asia/Kabul"
  },
  {
    "iata": "KMG",
    "city": "Kunming",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "KUV",
    "city": "Kunsan",
    "region": "South Korea",
    "tz": "Asia/Seoul"
  },
  {
    "iata": "KNX",
    "city": "Kununurra",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "KUO",
    "city": "Kuopio",
    "region": "Finland",
    "tz": "Europe/Helsinki"
  },
  {
    "iata": "KOE",
    "city": "Kupang",
    "region": "Indonesia",
    "tz": "Asia/Makassar"
  },
  {
    "iata": "UUK",
    "city": "Kuparuk",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "KCA",
    "city": "Kuqa",
    "region": "China",
    "tz": "Asia/Urumqi"
  },
  {
    "iata": "URE",
    "city": "Kuressaare",
    "region": "Estonia",
    "tz": "Europe/Tallinn"
  },
  {
    "iata": "KRO",
    "city": "Kurgan",
    "region": "Russia",
    "tz": "Asia/Yekaterinburg"
  },
  {
    "iata": "KUC",
    "city": "Kuria",
    "region": "Kiribati",
    "tz": "Pacific/Tarawa"
  },
  {
    "iata": "URS",
    "city": "Kursk",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "KMH",
    "city": "Kuruman",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "KUH",
    "city": "Kushiro",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "KZR",
    "city": "Kutahya",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "KUT",
    "city": "Kutaisi",
    "region": "Georgia",
    "tz": "Asia/Tbilisi"
  },
  {
    "iata": "YGW",
    "city": "Kuujjuarapik",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "KAO",
    "city": "Kuusamo",
    "region": "Finland",
    "tz": "Europe/Helsinki"
  },
  {
    "iata": "KWI",
    "city": "Kuwait",
    "region": "Kuwait",
    "tz": "Asia/Kuwait"
  },
  {
    "iata": "KWA",
    "city": "Kwajalein",
    "region": "Marshall Islands",
    "tz": "Pacific/Kwajalein"
  },
  {
    "iata": "KWJ",
    "city": "Kwangju",
    "region": "South Korea",
    "tz": "Asia/Seoul"
  },
  {
    "iata": "KWT",
    "city": "Kwethluk",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "KWK",
    "city": "Kwigillingok",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "KYP",
    "city": "Kyaukpyu",
    "region": "Burma",
    "tz": "Asia/Rangoon"
  },
  {
    "iata": "KYZ",
    "city": "Kyzyl",
    "region": "Russia",
    "tz": "Asia/Krasnoyarsk"
  },
  {
    "iata": "KZO",
    "city": "Kzyl-Orda",
    "region": "Kazakhstan",
    "tz": "Asia/Qyzylorda"
  },
  {
    "iata": "LCE",
    "city": "La Ceiba",
    "region": "Honduras",
    "tz": "America/Tegucigalpa"
  },
  {
    "iata": "LCL",
    "city": "La Coloma",
    "region": "Cuba",
    "tz": "America/Havana"
  },
  {
    "iata": "LCG",
    "city": "La Coruna",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "LSE",
    "city": "La Crosse",
    "region": "Wisconsin",
    "tz": "America/Chicago"
  },
  {
    "iata": "PAL",
    "city": "La Dorada",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "FON",
    "city": "La Fortuna/San Carlos",
    "region": "Costa Rica",
    "tz": "America/Costa_Rica"
  },
  {
    "iata": "LFR",
    "city": "La Fria",
    "region": "Venezuela",
    "tz": "America/Caracas"
  },
  {
    "iata": "GMZ",
    "city": "La Gomera",
    "region": "Spain",
    "tz": "Atlantic/Canary"
  },
  {
    "iata": "LGD",
    "city": "La Grande",
    "region": "Oregon",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "YGL",
    "city": "La Grande Riviere",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "YAH",
    "city": "La Grande-4",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "JBQ",
    "city": "La Isabela",
    "region": "Dominican Republic",
    "tz": "America/Santo_Domingo"
  },
  {
    "iata": "LHX",
    "city": "La Junta",
    "region": "United States",
    "tz": "America/Denver"
  },
  {
    "iata": "LTT",
    "city": "La M\u00f4le",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "PLP",
    "city": "La Palma",
    "region": "Panama",
    "tz": "America/Panama"
  },
  {
    "iata": "LPB",
    "city": "La Paz",
    "region": "Bolivia",
    "tz": "America/La_Paz"
  },
  {
    "iata": "LAP",
    "city": "La Paz",
    "region": "Mexico",
    "tz": "America/Mazatlan"
  },
  {
    "iata": "LPD",
    "city": "La Pedrera",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "LPG",
    "city": "La Plata",
    "region": "Argentina",
    "tz": "America/Argentina/Buenos_Aires"
  },
  {
    "iata": "IRJ",
    "city": "La Rioja",
    "region": "Argentina",
    "tz": "America/Argentina/La_Rioja"
  },
  {
    "iata": "EDM",
    "city": "La Roche-sur-yon",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "LRH",
    "city": "La Rochelle",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "LRM",
    "city": "La Romana",
    "region": "Dominican Republic",
    "tz": "America/Santo_Domingo"
  },
  {
    "iata": "YVC",
    "city": "La Ronge",
    "region": "Canada",
    "tz": "America/Regina"
  },
  {
    "iata": "LSC",
    "city": "La Serena",
    "region": "Chile",
    "tz": "America/Santiago"
  },
  {
    "iata": "ZLT",
    "city": "La Tabati\u00e8re",
    "region": "Canada",
    "tz": "America/Blanc-Sablon"
  },
  {
    "iata": "LOH",
    "city": "La Toma (Catamayo)",
    "region": "Ecuador",
    "tz": "America/Guayaquil"
  },
  {
    "iata": "POC",
    "city": "La Verne",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "LGC",
    "city": "LaGrange",
    "region": "Georgia",
    "tz": "America/New_York"
  },
  {
    "iata": "RLG",
    "city": "Laage",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "KDO",
    "city": "Laamu Atoll",
    "region": "Maldives",
    "tz": "Indian/Maldives"
  },
  {
    "iata": "LEK",
    "city": "Labe",
    "region": "Guinea",
    "tz": "Africa/Conakry"
  },
  {
    "iata": "LBR",
    "city": "Labrea",
    "region": "Brazil",
    "tz": "America/Manaus"
  },
  {
    "iata": "LBU",
    "city": "Labuan",
    "region": "Malaysia",
    "tz": "Asia/Kuching"
  },
  {
    "iata": "LBJ",
    "city": "Labuhan Bajo",
    "region": "Indonesia",
    "tz": "Asia/Makassar"
  },
  {
    "iata": "XLB",
    "city": "Lac Brochet",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "CGY",
    "city": "Ladag",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "LAY",
    "city": "Ladysmith",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "LAF",
    "city": "Lafayette",
    "region": "Indiana",
    "tz": "America/Indiana/Indianapolis"
  },
  {
    "iata": "LFT",
    "city": "Lafayette",
    "region": "Louisiana",
    "tz": "America/Chicago"
  },
  {
    "iata": "LOO",
    "city": "Laghouat",
    "region": "Algeria",
    "tz": "Africa/Algiers"
  },
  {
    "iata": "LOS",
    "city": "Lagos",
    "region": "Nigeria",
    "tz": "Africa/Lagos"
  },
  {
    "iata": "LDU",
    "city": "Lahad Datu",
    "region": "Malaysia",
    "tz": "Asia/Kuching"
  },
  {
    "iata": "JHM",
    "city": "Lahania-kapalua",
    "region": "Hawaii",
    "tz": "Pacific/Honolulu"
  },
  {
    "iata": "LHE",
    "city": "Lahore",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "LHA",
    "city": "Lahr",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "TER",
    "city": "Lajes (terceira Island)",
    "region": "Portugal",
    "tz": "Atlantic/Azores"
  },
  {
    "iata": "LCH",
    "city": "Lake Charles",
    "region": "Louisiana",
    "tz": "America/Chicago"
  },
  {
    "iata": "LCQ",
    "city": "Lake City",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "LEL",
    "city": "Lake Evella",
    "region": "Australia",
    "tz": "Australia/Darwin"
  },
  {
    "iata": "HII",
    "city": "Lake Havasu City",
    "region": "Arizona",
    "tz": "America/Phoenix"
  },
  {
    "iata": "BEO",
    "city": "Lake Macquarie",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "LKY",
    "city": "Lake Manyara",
    "region": "Tanzania",
    "tz": "Africa/Dar_es_Salaam"
  },
  {
    "iata": "MHM",
    "city": "Lake Minchumina",
    "region": "United States",
    "tz": "America/Anchorage"
  },
  {
    "iata": "LKP",
    "city": "Lake Placid",
    "region": "New York",
    "tz": "America/New_York"
  },
  {
    "iata": "LKB",
    "city": "Lakeba Island",
    "region": "Fiji",
    "tz": "Pacific/Fiji"
  },
  {
    "iata": "NEL",
    "city": "Lakehurst",
    "region": "New Jersey",
    "tz": "America/New_York"
  },
  {
    "iata": "LAL",
    "city": "Lakeland",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "LKL",
    "city": "Lakselv",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "LLI",
    "city": "Lalibella",
    "region": "Ethiopia",
    "tz": "Africa/Addis_Ababa"
  },
  {
    "iata": "LPM",
    "city": "Lamap",
    "region": "Vanuatu",
    "tz": "Pacific/Efate"
  },
  {
    "iata": "LAA",
    "city": "Lamar",
    "region": "Colorado",
    "tz": "America/Denver"
  },
  {
    "iata": "LBQ",
    "city": "Lambarene",
    "region": "Gabon",
    "tz": "Africa/Libreville"
  },
  {
    "iata": "LBS",
    "city": "Lambasa",
    "region": "Fiji",
    "tz": "Pacific/Fiji"
  },
  {
    "iata": "LNB",
    "city": "Lamen Bay",
    "region": "Vanuatu",
    "tz": "Pacific/Efate"
  },
  {
    "iata": "SUF",
    "city": "Lamezia",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "LDN",
    "city": "Lamidanda",
    "region": "Nepal",
    "tz": "Asia/Kathmandu"
  },
  {
    "iata": "LPT",
    "city": "Lampang",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "LMP",
    "city": "Lampedusa",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "LAU",
    "city": "Lamu",
    "region": "Kenya",
    "tz": "Africa/Nairobi"
  },
  {
    "iata": "LNY",
    "city": "Lanai",
    "region": "Hawaii",
    "tz": "Pacific/Honolulu"
  },
  {
    "iata": "WJF",
    "city": "Lancaster",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "LNS",
    "city": "Lancaster",
    "region": "Pennsylvania",
    "tz": "America/New_York"
  },
  {
    "iata": "LEQ",
    "city": "Land's End",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "LGO",
    "city": "Langeoog",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "LUV",
    "city": "Langgur-Kei Islands",
    "region": "Indonesia",
    "tz": "Asia/Jayapura"
  },
  {
    "iata": "LGK",
    "city": "Langkawi",
    "region": "Malaysia",
    "tz": "Asia/Kuala_Lumpur"
  },
  {
    "iata": "LLK",
    "city": "Lankaran",
    "region": "Azerbaijan",
    "tz": "Asia/Baku"
  },
  {
    "iata": "LAI",
    "city": "Lannion",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "YLH",
    "city": "Lansdowne House",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "LAN",
    "city": "Lansing",
    "region": "Michigan",
    "tz": "America/Detroit"
  },
  {
    "iata": "KYD",
    "city": "Lanyu",
    "region": "Taiwan",
    "tz": "Asia/Taipei"
  },
  {
    "iata": "LHW",
    "city": "Lanzhou",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "LAO",
    "city": "Laoag",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "LPP",
    "city": "Lappeenranta",
    "region": "Finland",
    "tz": "Europe/Helsinki"
  },
  {
    "iata": "LRR",
    "city": "Lar",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "LAR",
    "city": "Laramie",
    "region": "Wyoming",
    "tz": "America/Denver"
  },
  {
    "iata": "LRD",
    "city": "Laredo",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "LRA",
    "city": "Larissa",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "LCA",
    "city": "Larnaca",
    "region": "Cyprus",
    "tz": "Asia/Nicosia"
  },
  {
    "iata": "KLN",
    "city": "Larsen Bay",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "LRU",
    "city": "Las Cruces",
    "region": "New Mexico",
    "tz": "America/Denver"
  },
  {
    "iata": "LHS",
    "city": "Las Heras",
    "region": "Argentina",
    "tz": "America/Argentina/Rio_Gallegos"
  },
  {
    "iata": "VTU",
    "city": "Las Tunas",
    "region": "Cuba",
    "tz": "America/Havana"
  },
  {
    "iata": "LAS",
    "city": "Las Vegas",
    "region": "Nevada",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "LSV",
    "city": "Las Vegas",
    "region": "Nevada",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "VGT",
    "city": "Las Vegas",
    "region": "Nevada",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "LVS",
    "city": "Las Vegas",
    "region": "New Mexico",
    "tz": "America/Denver"
  },
  {
    "iata": "QLA",
    "city": "Lasham",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "LSH",
    "city": "Lashio",
    "region": "Burma",
    "tz": "Asia/Rangoon"
  },
  {
    "iata": "BST",
    "city": "Lashkar Gah",
    "region": "Afghanistan",
    "tz": "Asia/Kabul"
  },
  {
    "iata": "LTX",
    "city": "Latacunga",
    "region": "Ecuador",
    "tz": "America/Guayaquil"
  },
  {
    "iata": "LTK",
    "city": "Latakia",
    "region": "Syria",
    "tz": "Asia/Damascus"
  },
  {
    "iata": "QLT",
    "city": "Latina",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "LBE",
    "city": "Latrobe",
    "region": "Pennsylvania",
    "tz": "America/New_York"
  },
  {
    "iata": "LST",
    "city": "Launceston",
    "region": "Australia",
    "tz": "Australia/Hobart"
  },
  {
    "iata": "QLS",
    "city": "Lausanne",
    "region": "Switzerland",
    "tz": "Europe/Zurich"
  },
  {
    "iata": "LVA",
    "city": "Laval",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "LVO",
    "city": "Laverton",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "LWY",
    "city": "Lawas",
    "region": "Malaysia",
    "tz": "Asia/Kuching"
  },
  {
    "iata": "LWC",
    "city": "Lawrence",
    "region": "Kansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "LWM",
    "city": "Lawrence",
    "region": "Massachusetts",
    "tz": "America/New_York"
  },
  {
    "iata": "LZU",
    "city": "Lawrenceville",
    "region": "Georgia",
    "tz": "America/New_York"
  },
  {
    "iata": "LAW",
    "city": "Lawton",
    "region": "Oklahoma",
    "tz": "America/Chicago"
  },
  {
    "iata": "LZC",
    "city": "Lazard Cardenas",
    "region": "Mexico",
    "tz": "America/Mexico_City"
  },
  {
    "iata": "CTT",
    "city": "Le Castellet",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "LEH",
    "city": "Le Havre",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "LME",
    "city": "Le Mans",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "LPY",
    "city": "Le Puy",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "LTQ",
    "city": "Le Tourquet",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "YLR",
    "city": "Leaf Rapids",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "LEA",
    "city": "Learmonth",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "LEB",
    "city": "Lebanon",
    "region": "New Hampshire",
    "tz": "America/New_York"
  },
  {
    "iata": "LCC",
    "city": "Lecce",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "LBA",
    "city": "Leeds",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "LEE",
    "city": "Leesburg",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "JYO",
    "city": "Leesburg",
    "region": "United States",
    "tz": "America/New_York"
  },
  {
    "iata": "LWR",
    "city": "Leeuwarden",
    "region": "Netherlands",
    "tz": "Europe/Amsterdam"
  },
  {
    "iata": "LGP",
    "city": "Legazpi",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "IXL",
    "city": "Leh",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "LGH",
    "city": "Leigh Creek",
    "region": "Australia",
    "tz": "Australia/Adelaide"
  },
  {
    "iata": "LER",
    "city": "Leinster",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "LEJ",
    "city": "Leipzig",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "LKN",
    "city": "Leknes",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "LEY",
    "city": "Lelystad",
    "region": "Netherlands",
    "tz": "Europe/Amsterdam"
  },
  {
    "iata": "NLC",
    "city": "Lemoore",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "ULK",
    "city": "Lensk",
    "region": "Russia",
    "tz": "Asia/Yakutsk"
  },
  {
    "iata": "LEC",
    "city": "Len\u00e7\u00f3is",
    "region": "Brazil",
    "tz": "America/Bahia"
  },
  {
    "iata": "LEN",
    "city": "Leon",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "LNO",
    "city": "Leonora",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "LRS",
    "city": "Leros",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "LWK",
    "city": "Lerwick",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "LSS",
    "city": "Les Saintes",
    "region": "Guadeloupe",
    "tz": "America/Guadeloupe"
  },
  {
    "iata": "YQL",
    "city": "Lethbridge",
    "region": "Canada",
    "tz": "America/Edmonton"
  },
  {
    "iata": "LTM",
    "city": "Lethem",
    "region": "Guyana",
    "tz": "America/Guyana"
  },
  {
    "iata": "LET",
    "city": "Leticia",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "ADX",
    "city": "Leuchars",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "LEV",
    "city": "Levuka",
    "region": "Fiji",
    "tz": "Pacific/Fiji"
  },
  {
    "iata": "LWB",
    "city": "Lewisburg",
    "region": "West Virginia",
    "tz": "America/New_York"
  },
  {
    "iata": "LWS",
    "city": "Lewiston",
    "region": "Idaho",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "LEW",
    "city": "Lewiston",
    "region": "Maine",
    "tz": "America/New_York"
  },
  {
    "iata": "LWT",
    "city": "Lewistown",
    "region": "Montana",
    "tz": "America/Denver"
  },
  {
    "iata": "LXN",
    "city": "Lexington",
    "region": "Nebraska",
    "tz": "America/Chicago"
  },
  {
    "iata": "LEX",
    "city": "Lexington KY",
    "region": "Kentucky",
    "tz": "America/New_York"
  },
  {
    "iata": "LXA",
    "city": "Lhasa",
    "region": "China",
    "tz": "Asia/Urumqi"
  },
  {
    "iata": "LSW",
    "city": "Lhok Seumawe-Sumatra Island",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "LYG",
    "city": "Lianyungang",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "LBL",
    "city": "Liberal",
    "region": "Kansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "LIR",
    "city": "Liberia",
    "region": "Costa Rica",
    "tz": "America/Costa_Rica"
  },
  {
    "iata": "LBV",
    "city": "Libreville",
    "region": "Gabon",
    "tz": "Africa/Libreville"
  },
  {
    "iata": "VXC",
    "city": "Lichinga",
    "region": "Mozambique",
    "tz": "Africa/Maputo"
  },
  {
    "iata": "LDK",
    "city": "Lidkoping",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "LGG",
    "city": "Liege",
    "region": "Belgium",
    "tz": "Europe/Brussels"
  },
  {
    "iata": "LPX",
    "city": "Liepaja",
    "region": "Latvia",
    "tz": "Europe/Riga"
  },
  {
    "iata": "LIF",
    "city": "Lifou",
    "region": "New Caledonia",
    "tz": "Pacific/Noumea"
  },
  {
    "iata": "HPA",
    "city": "Lifuka",
    "region": "Tonga",
    "tz": "Pacific/Tongatapu"
  },
  {
    "iata": "LHG",
    "city": "Lightning Ridge",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "LIH",
    "city": "Lihue",
    "region": "Hawaii",
    "tz": "Pacific/Honolulu"
  },
  {
    "iata": "LJG",
    "city": "Lijiang",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "LIX",
    "city": "Likoma Island",
    "region": "Malawi",
    "tz": "Africa/Blantyre"
  },
  {
    "iata": "IXI",
    "city": "Lilabari",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "LIL",
    "city": "Lille",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "LLW",
    "city": "Lilongwe",
    "region": "Malawi",
    "tz": "Africa/Blantyre"
  },
  {
    "iata": "AOH",
    "city": "Lima",
    "region": "Ohio",
    "tz": "America/New_York"
  },
  {
    "iata": "LIM",
    "city": "Lima",
    "region": "Peru",
    "tz": "America/Lima"
  },
  {
    "iata": "LMN",
    "city": "Limbang",
    "region": "Malaysia",
    "tz": "Asia/Kuching"
  },
  {
    "iata": "LMR",
    "city": "Lime Acres",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "LXS",
    "city": "Limnos",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "LIG",
    "city": "Limoges",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "LIO",
    "city": "Limon",
    "region": "Costa Rica",
    "tz": "America/Costa_Rica"
  },
  {
    "iata": "LNK",
    "city": "Lincoln",
    "region": "Nebraska",
    "tz": "America/Chicago"
  },
  {
    "iata": "LND",
    "city": "Lindau",
    "region": "Wyoming",
    "tz": "America/Denver"
  },
  {
    "iata": "LDJ",
    "city": "Linden",
    "region": "New Jersey",
    "tz": "America/New_York"
  },
  {
    "iata": "LDI",
    "city": "Lindi",
    "region": "Tanzania",
    "tz": "Africa/Dar_es_Salaam"
  },
  {
    "iata": "LPI",
    "city": "Linkoeping",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "LIP",
    "city": "Lins",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "HRT",
    "city": "Linton-on-ouse",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "LYI",
    "city": "Linyi",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "LNZ",
    "city": "Linz",
    "region": "Austria",
    "tz": "Europe/Vienna"
  },
  {
    "iata": "LPK",
    "city": "Lipetsk",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "HZH",
    "city": "Liping",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "LIQ",
    "city": "Lisala",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Kinshasa"
  },
  {
    "iata": "LIS",
    "city": "Lisbon",
    "region": "Portugal",
    "tz": "Europe/Lisbon"
  },
  {
    "iata": "LSY",
    "city": "Lismore",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "LYB",
    "city": "Little Cayman",
    "region": "Cayman Islands",
    "tz": "America/Cayman"
  },
  {
    "iata": "ZGR",
    "city": "Little Grand Rapids",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "LIT",
    "city": "Little Rock",
    "region": "Arkansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "LZH",
    "city": "Liuzhou",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "LVK",
    "city": "Livermore",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "LPL",
    "city": "Liverpool",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "LVM",
    "city": "Livingston-Montana",
    "region": "Montana",
    "tz": "America/Denver"
  },
  {
    "iata": "LVI",
    "city": "Livingstone",
    "region": "Zambia",
    "tz": "Africa/Lusaka"
  },
  {
    "iata": "LZR",
    "city": "Lizard Island",
    "region": "Australia",
    "tz": "Australia/Lindeman"
  },
  {
    "iata": "LJU",
    "city": "Ljubljana",
    "region": "Slovenia",
    "tz": "Europe/Ljubljana"
  },
  {
    "iata": "ILD",
    "city": "Lleida",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "YLL",
    "city": "Lloydminster",
    "region": "Canada",
    "tz": "America/Edmonton"
  },
  {
    "iata": "ZJI",
    "city": "Locarno",
    "region": "Switzerland",
    "tz": "Europe/Zurich"
  },
  {
    "iata": "LHV",
    "city": "Lock Haven",
    "region": "Pennsylvania",
    "tz": "America/New_York"
  },
  {
    "iata": "IRG",
    "city": "Lockhart River",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "LOT",
    "city": "Lockport",
    "region": "Illinois",
    "tz": "America/Chicago"
  },
  {
    "iata": "LJA",
    "city": "Lodja",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Lubumbashi"
  },
  {
    "iata": "LOK",
    "city": "Lodwar",
    "region": "Kenya",
    "tz": "Africa/Nairobi"
  },
  {
    "iata": "LCJ",
    "city": "Lodz",
    "region": "Poland",
    "tz": "Europe/Warsaw"
  },
  {
    "iata": "LOE",
    "city": "Loei",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "LGU",
    "city": "Logan",
    "region": "Utah",
    "tz": "America/Denver"
  },
  {
    "iata": "RJL",
    "city": "Logro\u00f1o-Agoncillo",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "TOH",
    "city": "Loh/Linua",
    "region": "Vanuatu",
    "tz": "Pacific/Efate"
  },
  {
    "iata": "LIW",
    "city": "Loikaw",
    "region": "Burma",
    "tz": "Asia/Rangoon"
  },
  {
    "iata": "LKG",
    "city": "Lokichoggio",
    "region": "Kenya",
    "tz": "Africa/Nairobi"
  },
  {
    "iata": "LFW",
    "city": "Lome",
    "region": "Togo",
    "tz": "Africa/Lome"
  },
  {
    "iata": "LPC",
    "city": "Lompoc",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "VBG",
    "city": "Lompoc",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "YXU",
    "city": "London",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "LOZ",
    "city": "London",
    "region": "Kentucky",
    "tz": "America/New_York"
  },
  {
    "iata": "LCY",
    "city": "London",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "LGW",
    "city": "London",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "LHR",
    "city": "London",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "LTN",
    "city": "London",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "STN",
    "city": "London",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "LDY",
    "city": "Londonderry",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "LDB",
    "city": "Londrina",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "LNR",
    "city": "Lone Rock",
    "region": "Wisconsin",
    "tz": "America/Chicago"
  },
  {
    "iata": "LKH",
    "city": "Long Akah",
    "region": "Malaysia",
    "tz": "Asia/Kuching"
  },
  {
    "iata": "LPU",
    "city": "Long Apung-Borneo Island",
    "region": "Indonesia",
    "tz": "Asia/Makassar"
  },
  {
    "iata": "LBW",
    "city": "Long Bawan-Borneo Island",
    "region": "Indonesia",
    "tz": "Asia/Makassar"
  },
  {
    "iata": "LGB",
    "city": "Long Beach",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "LGL",
    "city": "Long Datih",
    "region": "Malaysia",
    "tz": "Asia/Kuching"
  },
  {
    "iata": "ODN",
    "city": "Long Seridan",
    "region": "Malaysia",
    "tz": "Asia/Kuching"
  },
  {
    "iata": "LOD",
    "city": "Longana",
    "region": "Vanuatu",
    "tz": "Pacific/Efate"
  },
  {
    "iata": "LRE",
    "city": "Longreach",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "GGG",
    "city": "Longview",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "LCX",
    "city": "Longyan",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "LNE",
    "city": "Lonorore",
    "region": "Vanuatu",
    "tz": "Pacific/Efate"
  },
  {
    "iata": "LPS",
    "city": "Lopez",
    "region": "Washington",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "LPR",
    "city": "Lorain-Elyria",
    "region": "United States",
    "tz": "America/New_York"
  },
  {
    "iata": "LDH",
    "city": "Lord Howe Island",
    "region": "Australia",
    "tz": "Australia/Lord_Howe"
  },
  {
    "iata": "LTO",
    "city": "Loreto",
    "region": "Mexico",
    "tz": "America/Hermosillo"
  },
  {
    "iata": "LRT",
    "city": "Lorient",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "LAM",
    "city": "Los Alamos",
    "region": "New Mexico",
    "tz": "America/Denver"
  },
  {
    "iata": "LAX",
    "city": "Los Angeles",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "WHP",
    "city": "Los Angeles",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "LSQ",
    "city": "Los Angeles",
    "region": "Chile",
    "tz": "America/Santiago"
  },
  {
    "iata": "LMM",
    "city": "Los Mochis",
    "region": "Mexico",
    "tz": "America/Mazatlan"
  },
  {
    "iata": "LRV",
    "city": "Los Roques",
    "region": "Venezuela",
    "tz": "America/Caracas"
  },
  {
    "iata": "LMO",
    "city": "Lossiemouth",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "DIS",
    "city": "Loubomo",
    "region": "Congo (Brazzaville)",
    "tz": "Africa/Brazzaville"
  },
  {
    "iata": "ARA",
    "city": "Louisiana",
    "region": "Louisiana",
    "tz": "America/Chicago"
  },
  {
    "iata": "LOU",
    "city": "Louisville",
    "region": "Kentucky",
    "tz": "America/Kentucky/Louisville"
  },
  {
    "iata": "SDF",
    "city": "Louisville",
    "region": "Kentucky",
    "tz": "America/Kentucky/Louisville"
  },
  {
    "iata": "YBX",
    "city": "Lourdes-De-Blanc-Sablon",
    "region": "Canada",
    "tz": "America/Blanc-Sablon"
  },
  {
    "iata": "LOL",
    "city": "Lovelock",
    "region": "Nevada",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "LAD",
    "city": "Luanda",
    "region": "Angola",
    "tz": "Africa/Luanda"
  },
  {
    "iata": "LXG",
    "city": "Luang Namtha",
    "region": "Laos",
    "tz": "Asia/Vientiane"
  },
  {
    "iata": "LPQ",
    "city": "Luang Prabang",
    "region": "Laos",
    "tz": "Asia/Vientiane"
  },
  {
    "iata": "LBX",
    "city": "Lubang",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "SDD",
    "city": "Lubango",
    "region": "Angola",
    "tz": "Africa/Luanda"
  },
  {
    "iata": "LBB",
    "city": "Lubbock",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "FBM",
    "city": "Lubumashi",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Lubumbashi"
  },
  {
    "iata": "LBZ",
    "city": "Lucapa",
    "region": "Angola",
    "tz": "Africa/Luanda"
  },
  {
    "iata": "LVR",
    "city": "Lucas do Rio Verde",
    "region": "Brazil",
    "tz": "America/Cuiaba"
  },
  {
    "iata": "LKO",
    "city": "Lucknow",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "LUD",
    "city": "Luderitz",
    "region": "Namibia",
    "tz": "Africa/Windhoek"
  },
  {
    "iata": "LUH",
    "city": "Ludhiaha",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "LDM",
    "city": "Ludington",
    "region": "Michigan",
    "tz": "America/Detroit"
  },
  {
    "iata": "LBC",
    "city": "Luebeck",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "LUO",
    "city": "Luena",
    "region": "Angola",
    "tz": "Africa/Luanda"
  },
  {
    "iata": "LFK",
    "city": "Lufkin",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "LUG",
    "city": "Lugano",
    "region": "Switzerland",
    "tz": "Europe/Zurich"
  },
  {
    "iata": "VSG",
    "city": "Lugansk",
    "region": "Ukraine",
    "tz": "Europe/Zaporozhye"
  },
  {
    "iata": "LUA",
    "city": "Lukla",
    "region": "Nepal",
    "tz": "Asia/Kathmandu"
  },
  {
    "iata": "LLA",
    "city": "Lulea",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "LBT",
    "city": "Lumberton",
    "region": "North Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "LYA",
    "city": "Luoyang",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "LUN",
    "city": "Lusaka",
    "region": "Zambia",
    "tz": "Africa/Lusaka"
  },
  {
    "iata": "YSG",
    "city": "Lutselk'e",
    "region": "Canada",
    "tz": "America/Yellowknife"
  },
  {
    "iata": "LUW",
    "city": "Luwuk",
    "region": "Indonesia",
    "tz": "Asia/Makassar"
  },
  {
    "iata": "LUX",
    "city": "Luxemburg",
    "region": "Luxembourg",
    "tz": "Europe/Luxembourg"
  },
  {
    "iata": "LUM",
    "city": "Luxi",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "LXR",
    "city": "Luxor",
    "region": "Egypt",
    "tz": "Africa/Cairo"
  },
  {
    "iata": "LZO",
    "city": "Luzhou",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "LWO",
    "city": "Lvov",
    "region": "Ukraine",
    "tz": "Europe/Uzhgorod"
  },
  {
    "iata": "LYC",
    "city": "Lycksele",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "LYX",
    "city": "Lydd",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "LYM",
    "city": "Lympne",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "LYH",
    "city": "Lynchburg",
    "region": "Virginia",
    "tz": "America/New_York"
  },
  {
    "iata": "LYE",
    "city": "Lyneham",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "YYL",
    "city": "Lynn Lake",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "LYN",
    "city": "Lyon",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "LYS",
    "city": "Lyon",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "SSY",
    "city": "M'banza-congo",
    "region": "Angola",
    "tz": "Africa/Luanda"
  },
  {
    "iata": "MGM",
    "city": "MONTGOMERY",
    "region": "Alabama",
    "tz": "America/Chicago"
  },
  {
    "iata": "VAM",
    "city": "Maamigili",
    "region": "Maldives",
    "tz": "Indian/Maldives"
  },
  {
    "iata": "MST",
    "city": "Maastricht",
    "region": "Netherlands",
    "tz": "Europe/Amsterdam"
  },
  {
    "iata": "USI",
    "city": "Mabaruma",
    "region": "Guyana",
    "tz": "America/Guyana"
  },
  {
    "iata": "UBB",
    "city": "Mabuiag Island",
    "region": "Australia",
    "tz": "Australia/Lindeman"
  },
  {
    "iata": "MCP",
    "city": "Macapa",
    "region": "Brazil",
    "tz": "America/Belem"
  },
  {
    "iata": "XMS",
    "city": "Macas",
    "region": "Ecuador",
    "tz": "America/Guayaquil"
  },
  {
    "iata": "MFM",
    "city": "Macau",
    "region": "Macau",
    "tz": "Asia/Macau"
  },
  {
    "iata": "MEA",
    "city": "Maca\u00e9",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "MCZ",
    "city": "Maceio",
    "region": "Brazil",
    "tz": "America/Maceio"
  },
  {
    "iata": "MCH",
    "city": "Machala",
    "region": "Ecuador",
    "tz": "America/Guayaquil"
  },
  {
    "iata": "MKY",
    "city": "Mackay",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "YZY",
    "city": "Mackenzie British Columbia",
    "region": "Canada",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "MMV",
    "city": "Mackminnville",
    "region": "United States",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "QNX",
    "city": "Macon",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "MCN",
    "city": "Macon",
    "region": "Georgia",
    "tz": "America/New_York"
  },
  {
    "iata": "WRB",
    "city": "Macon",
    "region": "Georgia",
    "tz": "America/New_York"
  },
  {
    "iata": "MNM",
    "city": "Macon",
    "region": "Michigan",
    "tz": "America/Menominee"
  },
  {
    "iata": "MAG",
    "city": "Madang",
    "region": "Papua New Guinea",
    "tz": "Pacific/Port_Moresby"
  },
  {
    "iata": "MAE",
    "city": "Madera",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "MED",
    "city": "Madinah",
    "region": "Saudi Arabia",
    "tz": "Asia/Riyadh"
  },
  {
    "iata": "MSN",
    "city": "Madison",
    "region": "Wisconsin",
    "tz": "America/Chicago"
  },
  {
    "iata": "MAA",
    "city": "Madras",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "MAD",
    "city": "Madrid",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "TOJ",
    "city": "Madrid",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "IXM",
    "city": "Madurai",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "HGN",
    "city": "Mae Hong Son",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "MWF",
    "city": "Maewo Island",
    "region": "Vanuatu",
    "tz": "Pacific/Efate"
  },
  {
    "iata": "MBD",
    "city": "Mafeking",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "MFA",
    "city": "Mafia Island",
    "region": "Tanzania",
    "tz": "Africa/Dar_es_Salaam"
  },
  {
    "iata": "OMF",
    "city": "Mafraq",
    "region": "Jordan",
    "tz": "Asia/Amman"
  },
  {
    "iata": "GDX",
    "city": "Magadan",
    "region": "Russia",
    "tz": "Asia/Magadan"
  },
  {
    "iata": "MGN",
    "city": "Magangue",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "IGT",
    "city": "Magas",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "MQF",
    "city": "Magnetiogorsk",
    "region": "Russia",
    "tz": "Asia/Yekaterinburg"
  },
  {
    "iata": "MWQ",
    "city": "Magwe",
    "region": "Burma",
    "tz": "Asia/Rangoon"
  },
  {
    "iata": "MJN",
    "city": "Mahajanga",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "MHA",
    "city": "Mahdia",
    "region": "Guyana",
    "tz": "America/Guyana"
  },
  {
    "iata": "SEZ",
    "city": "Mahe",
    "region": "Seychelles",
    "tz": "Indian/Mahe"
  },
  {
    "iata": "MNK",
    "city": "Maiana",
    "region": "Kiribati",
    "tz": "Pacific/Tarawa"
  },
  {
    "iata": "MIU",
    "city": "Maiduguri",
    "region": "Nigeria",
    "tz": "Africa/Lagos"
  },
  {
    "iata": "MMZ",
    "city": "Maimama",
    "region": "Afghanistan",
    "tz": "Asia/Kabul"
  },
  {
    "iata": "MXT",
    "city": "Maintirano",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "MMO",
    "city": "Maio",
    "region": "Cape Verde",
    "tz": "Atlantic/Cape_Verde"
  },
  {
    "iata": "MTL",
    "city": "Maitland",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "MAJ",
    "city": "Majuro",
    "region": "Marshall Islands",
    "tz": "Pacific/Majuro"
  },
  {
    "iata": "MQX",
    "city": "Makale",
    "region": "Ethiopia",
    "tz": "Africa/Addis_Ababa"
  },
  {
    "iata": "TTR",
    "city": "Makale",
    "region": "Indonesia",
    "tz": "Asia/Makassar"
  },
  {
    "iata": "MKP",
    "city": "Makemo",
    "region": "French Polynesia",
    "tz": "Pacific/Tahiti"
  },
  {
    "iata": "MCX",
    "city": "Makhachkala",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "MTK",
    "city": "Makin",
    "region": "Kiribati",
    "tz": "Pacific/Tarawa"
  },
  {
    "iata": "YMN",
    "city": "Makkovik",
    "region": "Canada",
    "tz": "America/Goose_Bay"
  },
  {
    "iata": "MKU",
    "city": "Makokou",
    "region": "Gabon",
    "tz": "Africa/Libreville"
  },
  {
    "iata": "MZG",
    "city": "Makung",
    "region": "Taiwan",
    "tz": "Asia/Taipei"
  },
  {
    "iata": "MDI",
    "city": "Makurdi",
    "region": "Nigeria",
    "tz": "Africa/Lagos"
  },
  {
    "iata": "SSG",
    "city": "Malabo",
    "region": "Equatorial Guinea",
    "tz": "Africa/Malabo"
  },
  {
    "iata": "MKZ",
    "city": "Malacca",
    "region": "Malaysia",
    "tz": "Asia/Kuala_Lumpur"
  },
  {
    "iata": "AGP",
    "city": "Malaga",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "MAK",
    "city": "Malakal",
    "region": "Sudan",
    "tz": "Africa/Juba"
  },
  {
    "iata": "AAM",
    "city": "Malamala",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "MLG",
    "city": "Malang",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "MEG",
    "city": "Malanje",
    "region": "Angola",
    "tz": "Africa/Luanda"
  },
  {
    "iata": "LGS",
    "city": "Malargue",
    "region": "Argentina",
    "tz": "America/Argentina/Mendoza"
  },
  {
    "iata": "MLX",
    "city": "Malatya",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "MDO",
    "city": "Maldonado",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "MLE",
    "city": "Male",
    "region": "Maldives",
    "tz": "Indian/Maldives"
  },
  {
    "iata": "SWJ",
    "city": "Malekula Island",
    "region": "Vanuatu",
    "tz": "Pacific/Efate"
  },
  {
    "iata": "LSZ",
    "city": "Mali Losinj",
    "region": "Croatia",
    "tz": "Europe/Zagreb"
  },
  {
    "iata": "MYD",
    "city": "Malindi",
    "region": "Kenya",
    "tz": "Africa/Nairobi"
  },
  {
    "iata": "XMC",
    "city": "Mallacoota",
    "region": "Australia",
    "tz": "Australia/Melbourne"
  },
  {
    "iata": "MMX",
    "city": "Malmoe",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "PTF",
    "city": "Malolo Lailai Island",
    "region": "Fiji",
    "tz": "Pacific/Fiji"
  },
  {
    "iata": "MLA",
    "city": "Malta",
    "region": "Malta",
    "tz": "Europe/Malta"
  },
  {
    "iata": "MMH",
    "city": "Mammoth Lakes",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "MJC",
    "city": "Man",
    "region": "Cote d'Ivoire",
    "tz": "Africa/Abidjan"
  },
  {
    "iata": "MNF",
    "city": "Mana Island",
    "region": "Fiji",
    "tz": "Pacific/Fiji"
  },
  {
    "iata": "MDC",
    "city": "Manado",
    "region": "Indonesia",
    "tz": "Asia/Makassar"
  },
  {
    "iata": "MGA",
    "city": "Managua",
    "region": "Nicaragua",
    "tz": "America/Managua"
  },
  {
    "iata": "WVK",
    "city": "Manakara",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "WMR",
    "city": "Mananara",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "NGX",
    "city": "Manang",
    "region": "Nepal",
    "tz": "Asia/Kathmandu"
  },
  {
    "iata": "MNJ",
    "city": "Mananjary",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "TEU",
    "city": "Manapouri",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "MNZ",
    "city": "Manassas",
    "region": "Virginia",
    "tz": "America/New_York"
  },
  {
    "iata": "MAO",
    "city": "Manaus",
    "region": "Brazil",
    "tz": "America/Manaus"
  },
  {
    "iata": "PLL",
    "city": "Manaus",
    "region": "Brazil",
    "tz": "America/Manaus"
  },
  {
    "iata": "MAN",
    "city": "Manchester",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "MHT",
    "city": "Manchester NH",
    "region": "New Hampshire",
    "tz": "America/New_York"
  },
  {
    "iata": "MDL",
    "city": "Mandalay",
    "region": "Burma",
    "tz": "Asia/Rangoon"
  },
  {
    "iata": "WMA",
    "city": "Mandritsara",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "MGS",
    "city": "Mangaia Island",
    "region": "Cook Islands",
    "tz": "Pacific/Rarotonga"
  },
  {
    "iata": "IXE",
    "city": "Mangalore",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "MHK",
    "city": "Manhattan",
    "region": "Kansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "MNX",
    "city": "Manicore",
    "region": "Brazil",
    "tz": "America/Manaus"
  },
  {
    "iata": "XMH",
    "city": "Manihi",
    "region": "French Polynesia",
    "tz": "Pacific/Tahiti"
  },
  {
    "iata": "MHX",
    "city": "Manihiki Island",
    "region": "Cook Islands",
    "tz": "Pacific/Rarotonga"
  },
  {
    "iata": "JSU",
    "city": "Maniitsoq",
    "region": "Greenland",
    "tz": "America/Godthab"
  },
  {
    "iata": "MNL",
    "city": "Manila",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "MNG",
    "city": "Maningrida",
    "region": "Australia",
    "tz": "Australia/Darwin"
  },
  {
    "iata": "MBL",
    "city": "Manistee",
    "region": "Michigan",
    "tz": "America/Detroit"
  },
  {
    "iata": "YMG",
    "city": "Manitouwadge",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "YEM",
    "city": "Manitowaning",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "YMW",
    "city": "Maniwaki",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "MZL",
    "city": "Manizales",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "MJA",
    "city": "Manja",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "MKT",
    "city": "Mankato",
    "region": "United States",
    "tz": "America/Chicago"
  },
  {
    "iata": "MLY",
    "city": "Manley Hot Springs",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "MHG",
    "city": "Mannheim",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "KMO",
    "city": "Manokotak",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "MKW",
    "city": "Manokwari",
    "region": "Indonesia",
    "tz": "Asia/Jayapura"
  },
  {
    "iata": "MFD",
    "city": "Mansfield",
    "region": "Ohio",
    "tz": "America/New_York"
  },
  {
    "iata": "MSE",
    "city": "Manston",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "MEC",
    "city": "Manta",
    "region": "Ecuador",
    "tz": "America/Guayaquil"
  },
  {
    "iata": "MEO",
    "city": "Manteo",
    "region": "North Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "MZO",
    "city": "Manzanillo",
    "region": "Cuba",
    "tz": "America/Havana"
  },
  {
    "iata": "ZLO",
    "city": "Manzanillo",
    "region": "Mexico",
    "tz": "America/Mexico_City"
  },
  {
    "iata": "MTS",
    "city": "Manzini",
    "region": "Swaziland",
    "tz": "Africa/Mbabane"
  },
  {
    "iata": "MPM",
    "city": "Maputo",
    "region": "Mozambique",
    "tz": "Africa/Maputo"
  },
  {
    "iata": "MDQ",
    "city": "Mar Del Plata",
    "region": "Argentina",
    "tz": "America/Argentina/Buenos_Aires"
  },
  {
    "iata": "MAB",
    "city": "Maraba",
    "region": "Brazil",
    "tz": "America/Araguaina"
  },
  {
    "iata": "MAR",
    "city": "Maracaibo",
    "region": "Venezuela",
    "tz": "America/Caracas"
  },
  {
    "iata": "MYC",
    "city": "Maracay",
    "region": "Venezuela",
    "tz": "America/Caracas"
  },
  {
    "iata": "MFQ",
    "city": "Maradi",
    "region": "Niger",
    "tz": "Africa/Niamey"
  },
  {
    "iata": "ACP",
    "city": "Maragheh",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "MZK",
    "city": "Marakei",
    "region": "Kiribati",
    "tz": "Pacific/Tarawa"
  },
  {
    "iata": "MZJ",
    "city": "Marana",
    "region": "Arizona",
    "tz": "America/Phoenix"
  },
  {
    "iata": "MQZ",
    "city": "Marand\u00faa",
    "region": "Colombia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "YSP",
    "city": "Marathon",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "MTH",
    "city": "Marathon",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "RUS",
    "city": "Marau",
    "region": "Solomon Islands",
    "tz": "Pacific/Guadalcanal"
  },
  {
    "iata": "MRK",
    "city": "Marco Island Airport",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "MQM",
    "city": "Mardin",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "MEE",
    "city": "Mare",
    "region": "New Caledonia",
    "tz": "Pacific/Noumea"
  },
  {
    "iata": "MRF",
    "city": "Marfa",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "MGH",
    "city": "Margate",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "KNF",
    "city": "Marham",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "MBX",
    "city": "Maribor",
    "region": "Slovenia",
    "tz": "Europe/Ljubljana"
  },
  {
    "iata": "MHQ",
    "city": "Mariehamn",
    "region": "Finland",
    "tz": "Europe/Mariehamn"
  },
  {
    "iata": "MGE",
    "city": "Marietta",
    "region": "Georgia",
    "tz": "America/New_York"
  },
  {
    "iata": "EBA",
    "city": "Marina Di Campo",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "MGF",
    "city": "Maringa",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "MWA",
    "city": "Marion",
    "region": "Illinois",
    "tz": "America/Chicago"
  },
  {
    "iata": "MPY",
    "city": "Maripasoula",
    "region": "French Guiana",
    "tz": "America/Cayenne"
  },
  {
    "iata": "RMY",
    "city": "Mariposa",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "MPW",
    "city": "Mariupol International",
    "region": "Ukraine",
    "tz": "Europe/Zaporozhye"
  },
  {
    "iata": "KVM",
    "city": "Markovo",
    "region": "Russia",
    "tz": "Asia/Anadyr"
  },
  {
    "iata": "WMN",
    "city": "Maroantsetra",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "MCY",
    "city": "Maroochydore",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "MVR",
    "city": "Maroua",
    "region": "Cameroon",
    "tz": "Africa/Douala"
  },
  {
    "iata": "RAK",
    "city": "Marrakech",
    "region": "Morocco",
    "tz": "Africa/Casablanca"
  },
  {
    "iata": "RMF",
    "city": "Marsa Alam",
    "region": "Egypt",
    "tz": "Africa/Cairo"
  },
  {
    "iata": "MRS",
    "city": "Marseille",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "MHH",
    "city": "Marsh Harbor",
    "region": "Bahamas",
    "tz": "America/Nassau"
  },
  {
    "iata": "MLL",
    "city": "Marshall",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "MFI",
    "city": "Marshfield",
    "region": "Wisconsin",
    "tz": "America/Chicago"
  },
  {
    "iata": "MRB",
    "city": "Martinsburg",
    "region": "West Virginia",
    "tz": "America/New_York"
  },
  {
    "iata": "MUR",
    "city": "Marudi",
    "region": "Malaysia",
    "tz": "Asia/Kuching"
  },
  {
    "iata": "MYP",
    "city": "Mary",
    "region": "Turkmenistan",
    "tz": "Asia/Ashgabat"
  },
  {
    "iata": "YMH",
    "city": "Mary's Harbour",
    "region": "Canada",
    "tz": "America/St_Johns"
  },
  {
    "iata": "MBH",
    "city": "Maryborough",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "BAB",
    "city": "Marysville",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "MRT",
    "city": "Marysville",
    "region": "United States",
    "tz": "Australia/Darwin"
  },
  {
    "iata": "MII",
    "city": "Mar\u00edlia",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "MRE",
    "city": "Masai Mara",
    "region": "Kenya",
    "tz": "Africa/Nairobi"
  },
  {
    "iata": "MBT",
    "city": "Masbate",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "MSU",
    "city": "Maseru",
    "region": "Lesotho",
    "tz": "Africa/Maseru"
  },
  {
    "iata": "MHD",
    "city": "Mashhad",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "MSH",
    "city": "Masirah",
    "region": "Oman",
    "tz": "Asia/Muscat"
  },
  {
    "iata": "MCW",
    "city": "Mason City",
    "region": "Iowa",
    "tz": "America/Chicago"
  },
  {
    "iata": "MSW",
    "city": "Massawa",
    "region": "Eritrea",
    "tz": "Africa/Asmara"
  },
  {
    "iata": "MSS",
    "city": "Massena",
    "region": "New York",
    "tz": "America/New_York"
  },
  {
    "iata": "ZMT",
    "city": "Masset",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "MRO",
    "city": "Masterton",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "MVZ",
    "city": "Masvingo",
    "region": "Zimbabwe",
    "tz": "Africa/Harare"
  },
  {
    "iata": "MAT",
    "city": "Matadi",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Kinshasa"
  },
  {
    "iata": "YNM",
    "city": "Matagami",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "MVT",
    "city": "Mataiva",
    "region": "French Polynesia",
    "tz": "Pacific/Tahiti"
  },
  {
    "iata": "MAX",
    "city": "Matam",
    "region": "Senegal",
    "tz": "Africa/Dakar"
  },
  {
    "iata": "MAM",
    "city": "Matamoros",
    "region": "Mexico",
    "tz": "America/Matamoros"
  },
  {
    "iata": "YME",
    "city": "Matane",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "AMI",
    "city": "Mataram",
    "region": "Indonesia",
    "tz": "Asia/Makassar"
  },
  {
    "iata": "TVU",
    "city": "Matei",
    "region": "Fiji",
    "tz": "Pacific/Fiji"
  },
  {
    "iata": "LZN",
    "city": "Matsu Islands",
    "region": "Taiwan",
    "tz": "Asia/Taipei"
  },
  {
    "iata": "MFK",
    "city": "Matsu Islands",
    "region": "Taiwan",
    "tz": "Asia/Taipei"
  },
  {
    "iata": "MMJ",
    "city": "Matsumoto",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "MYJ",
    "city": "Matsuyama",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "HRI",
    "city": "Mattala",
    "region": "Sri Lanka",
    "tz": "Asia/Colombo"
  },
  {
    "iata": "IGA",
    "city": "Matthew Town",
    "region": "Bahamas",
    "tz": "America/Nassau"
  },
  {
    "iata": "MUN",
    "city": "Maturin",
    "region": "Venezuela",
    "tz": "America/Caracas"
  },
  {
    "iata": "MBZ",
    "city": "Maues",
    "region": "Brazil",
    "tz": "America/Manaus"
  },
  {
    "iata": "MUK",
    "city": "Mauke Island",
    "region": "Cook Islands",
    "tz": "Pacific/Rarotonga"
  },
  {
    "iata": "MOF",
    "city": "Maumere",
    "region": "Indonesia",
    "tz": "Asia/Makassar"
  },
  {
    "iata": "MUB",
    "city": "Maun",
    "region": "Botswana",
    "tz": "Africa/Gaborone"
  },
  {
    "iata": "MAU",
    "city": "Maupiti",
    "region": "French Polynesia",
    "tz": "Pacific/Tahiti"
  },
  {
    "iata": "MNU",
    "city": "Mawlamyine",
    "region": "Burma",
    "tz": "Asia/Rangoon"
  },
  {
    "iata": "MYG",
    "city": "Mayaguana",
    "region": "Bahamas",
    "tz": "America/Nassau"
  },
  {
    "iata": "MAZ",
    "city": "Mayaguez",
    "region": "Puerto Rico",
    "tz": "America/Puerto_Rico"
  },
  {
    "iata": "YMA",
    "city": "Mayo",
    "region": "Canada",
    "tz": "America/Whitehorse"
  },
  {
    "iata": "MYB",
    "city": "Mayumba",
    "region": "Gabon",
    "tz": "Africa/Libreville"
  },
  {
    "iata": "MZR",
    "city": "Mazar-i-sharif",
    "region": "Afghanistan",
    "tz": "Asia/Kabul"
  },
  {
    "iata": "MZT",
    "city": "Mazatlan",
    "region": "Mexico",
    "tz": "America/Mazatlan"
  },
  {
    "iata": "MBU",
    "city": "Mbambanakira",
    "region": "Solomon Islands",
    "tz": "Pacific/Guadalcanal"
  },
  {
    "iata": "MDK",
    "city": "Mbandaka",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Kinshasa"
  },
  {
    "iata": "MBI",
    "city": "Mbeya",
    "region": "Tanzania",
    "tz": "Africa/Dar_es_Salaam"
  },
  {
    "iata": "MJM",
    "city": "Mbuji-mayi",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Lubumbashi"
  },
  {
    "iata": "MCB",
    "city": "Mc Comb",
    "region": "Mississippi",
    "tz": "America/Chicago"
  },
  {
    "iata": "MPR",
    "city": "Mc Pherson",
    "region": "United States",
    "tz": "America/Chicago"
  },
  {
    "iata": "MCV",
    "city": "McArthur River Mine",
    "region": "Australia",
    "tz": "Australia/Darwin"
  },
  {
    "iata": "MYL",
    "city": "McCall",
    "region": "Idaho",
    "tz": "America/Boise"
  },
  {
    "iata": "MCK",
    "city": "McCook",
    "region": "Nebraska",
    "tz": "America/Chicago"
  },
  {
    "iata": "MCL",
    "city": "McKinley Park",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "MLC",
    "city": "Mcalester",
    "region": "Oklahoma",
    "tz": "America/Chicago"
  },
  {
    "iata": "MFE",
    "city": "Mcallen",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "MCG",
    "city": "Mcgrath",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "MEJ",
    "city": "Meade",
    "region": "Pennsylvania",
    "tz": "America/New_York"
  },
  {
    "iata": "YLJ",
    "city": "Meadow Lake",
    "region": "Canada",
    "tz": "America/Regina"
  },
  {
    "iata": "KNO",
    "city": "Medan",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "MES",
    "city": "Medan",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "EOH",
    "city": "Medellin",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "MFR",
    "city": "Medford",
    "region": "Oregon",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "YXH",
    "city": "Medicine Hat",
    "region": "Canada",
    "tz": "America/Edmonton"
  },
  {
    "iata": "MKR",
    "city": "Meekatharra",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "MEY",
    "city": "Meghauli",
    "region": "Nepal",
    "tz": "Asia/Kathmandu"
  },
  {
    "iata": "MEH",
    "city": "Mehamn",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "MXZ",
    "city": "Meixian",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "MKS",
    "city": "Mekane Selam",
    "region": "Ethiopia",
    "tz": "Africa/Addis_Ababa"
  },
  {
    "iata": "MEK",
    "city": "Meknes",
    "region": "Morocco",
    "tz": "Africa/Casablanca"
  },
  {
    "iata": "MYU",
    "city": "Mekoryuk",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "MBW",
    "city": "Melbourne",
    "region": "Australia",
    "tz": "Australia/Melbourne"
  },
  {
    "iata": "MEB",
    "city": "Melbourne",
    "region": "Australia",
    "tz": "Australia/Melbourne"
  },
  {
    "iata": "MEL",
    "city": "Melbourne",
    "region": "Australia",
    "tz": "Australia/Melbourne"
  },
  {
    "iata": "MLB",
    "city": "Melbourne",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "MLN",
    "city": "Melilla",
    "region": "Spain",
    "tz": "Africa/Ceuta"
  },
  {
    "iata": "MLZ",
    "city": "Melo",
    "region": "Uruguay",
    "tz": "America/Montevideo"
  },
  {
    "iata": "MNA",
    "city": "Melonguane",
    "region": "Indonesia",
    "tz": "Asia/Makassar"
  },
  {
    "iata": "MMB",
    "city": "Memanbetsu",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "FMM",
    "city": "Memmingen",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "MEM",
    "city": "Memphis",
    "region": "Tennessee",
    "tz": "America/Chicago"
  },
  {
    "iata": "MEN",
    "city": "Mende",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "MDU",
    "city": "Mendi",
    "region": "Papua New Guinea",
    "tz": "Pacific/Port_Moresby"
  },
  {
    "iata": "MDZ",
    "city": "Mendoza",
    "region": "Argentina",
    "tz": "America/Argentina/Mendoza"
  },
  {
    "iata": "HTT",
    "city": "Mengnai",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "SPP",
    "city": "Menongue",
    "region": "Angola",
    "tz": "Africa/Luanda"
  },
  {
    "iata": "MAH",
    "city": "Menorca",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "MKQ",
    "city": "Merauke",
    "region": "Indonesia",
    "tz": "Asia/Jayapura"
  },
  {
    "iata": "MCE",
    "city": "Merced",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "MER",
    "city": "Merced",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "DRA",
    "city": "Mercury",
    "region": "Nevada",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "MID",
    "city": "Merida",
    "region": "Mexico",
    "tz": "America/Merida"
  },
  {
    "iata": "MRD",
    "city": "Merida",
    "region": "Venezuela",
    "tz": "America/Caracas"
  },
  {
    "iata": "MEI",
    "city": "Meridian",
    "region": "Mississippi",
    "tz": "America/Chicago"
  },
  {
    "iata": "MIM",
    "city": "Merimbula",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "MWE",
    "city": "Merowe",
    "region": "Sudan",
    "tz": "Africa/Khartoum"
  },
  {
    "iata": "MUH",
    "city": "Mersa-matruh",
    "region": "Egypt",
    "tz": "Africa/Cairo"
  },
  {
    "iata": "MZH",
    "city": "Merzifon",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "AZA",
    "city": "Mesa",
    "region": "Arizona",
    "tz": "America/Phoenix"
  },
  {
    "iata": "ETZ",
    "city": "Metz",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "MZM",
    "city": "Metz",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "MXL",
    "city": "Mexicali",
    "region": "Mexico",
    "tz": "America/Tijuana"
  },
  {
    "iata": "MEX",
    "city": "Mexico City",
    "region": "Mexico",
    "tz": "America/Mexico_City"
  },
  {
    "iata": "MFU",
    "city": "Mfuwe",
    "region": "Zambia",
    "tz": "Africa/Lusaka"
  },
  {
    "iata": "MIA",
    "city": "Miami",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "OPF",
    "city": "Miami",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "TNT",
    "city": "Miami",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "ZVA",
    "city": "Miandrivazo",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "MIG",
    "city": "Mianyang",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "MGC",
    "city": "Michigan City",
    "region": "Indiana",
    "tz": "America/Chicago"
  },
  {
    "iata": "MDS",
    "city": "Middle Caicos",
    "region": "Turks and Caicos Islands",
    "tz": "America/Grand_Turk"
  },
  {
    "iata": "MAF",
    "city": "Midland",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "MDY",
    "city": "Midway",
    "region": "Midway Islands",
    "tz": "Pacific/Midway"
  },
  {
    "iata": "YGJ",
    "city": "Miho",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "MIK",
    "city": "Mikkeli",
    "region": "Finland",
    "tz": "Europe/Helsinki"
  },
  {
    "iata": "LIN",
    "city": "Milan",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "MXP",
    "city": "Milano",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "MHZ",
    "city": "Mildenhall",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "MQL",
    "city": "Mildura",
    "region": "Australia",
    "tz": "Australia/Melbourne"
  },
  {
    "iata": "MLS",
    "city": "Miles City",
    "region": "Montana",
    "tz": "America/Denver"
  },
  {
    "iata": "MFN",
    "city": "Milford Sound",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "MIJ",
    "city": "Mili Island",
    "region": "Marshall Islands",
    "tz": "Pacific/Majuro"
  },
  {
    "iata": "MGT",
    "city": "Milingimbi",
    "region": "Australia",
    "tz": "Australia/Darwin"
  },
  {
    "iata": "MLJ",
    "city": "Milledgeville",
    "region": "United States",
    "tz": "America/New_York"
  },
  {
    "iata": "NQA",
    "city": "Millington",
    "region": "Tennessee",
    "tz": "America/Chicago"
  },
  {
    "iata": "MLT",
    "city": "Millinocket",
    "region": "United States",
    "tz": "America/New_York"
  },
  {
    "iata": "MIV",
    "city": "Millville",
    "region": "New Jersey",
    "tz": "America/New_York"
  },
  {
    "iata": "MLO",
    "city": "Milos",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "NSE",
    "city": "Milton",
    "region": "Florida",
    "tz": "America/Chicago"
  },
  {
    "iata": "MKE",
    "city": "Milwaukee",
    "region": "Wisconsin",
    "tz": "America/Chicago"
  },
  {
    "iata": "MWC",
    "city": "Milwaukee",
    "region": "Wisconsin",
    "tz": "America/Chicago"
  },
  {
    "iata": "MQH",
    "city": "Minacu",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "MMD",
    "city": "Minami Daito",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "MTT",
    "city": "Minatitlan",
    "region": "Mexico",
    "tz": "America/Mexico_City"
  },
  {
    "iata": "MWL",
    "city": "Mineral Wells",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "MRV",
    "city": "Mineralnye Vody",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "NHD",
    "city": "Minhad AB",
    "region": "United Arab Emirates",
    "tz": "Asia/Dubai"
  },
  {
    "iata": "MXJ",
    "city": "Minna",
    "region": "Nigeria",
    "tz": "Africa/Lagos"
  },
  {
    "iata": "MSP",
    "city": "Minneapolis",
    "region": "Minnesota",
    "tz": "America/Chicago"
  },
  {
    "iata": "ARV",
    "city": "Minocqua - Woodruff",
    "region": "Wisconsin",
    "tz": "America/Chicago"
  },
  {
    "iata": "MIB",
    "city": "Minot",
    "region": "North Dakota",
    "tz": "America/Chicago"
  },
  {
    "iata": "MOT",
    "city": "Minot",
    "region": "North Dakota",
    "tz": "America/Chicago"
  },
  {
    "iata": "MHP",
    "city": "Minsk",
    "region": "Belarus",
    "tz": "Europe/Minsk"
  },
  {
    "iata": "MSQ",
    "city": "Minsk 2",
    "region": "Belarus",
    "tz": "Europe/Minsk"
  },
  {
    "iata": "MQC",
    "city": "Miquelon",
    "region": "Saint Pierre and Miquelon",
    "tz": "America/Miquelon"
  },
  {
    "iata": "NKX",
    "city": "Miramar",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "MYY",
    "city": "Miri",
    "region": "Malaysia",
    "tz": "Asia/Kuching"
  },
  {
    "iata": "MJZ",
    "city": "Mirnyj",
    "region": "Russia",
    "tz": "Asia/Yakutsk"
  },
  {
    "iata": "MPD",
    "city": "Mirpur Khas",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "MSJ",
    "city": "Misawa",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "MIS",
    "city": "Misima Island",
    "region": "Papua New Guinea",
    "tz": "Pacific/Port_Moresby"
  },
  {
    "iata": "MSO",
    "city": "Missoula",
    "region": "Montana",
    "tz": "America/Denver"
  },
  {
    "iata": "MOI",
    "city": "Mitiaro Island",
    "region": "Cook Islands",
    "tz": "Pacific/Rarotonga"
  },
  {
    "iata": "MVP",
    "city": "Mitu",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "MYE",
    "city": "Miyakejima",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "MMY",
    "city": "Miyako",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "KMI",
    "city": "Miyazaki",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "MTF",
    "city": "Mizan Teferi",
    "region": "Ethiopia",
    "tz": "Africa/Addis_Ababa"
  },
  {
    "iata": "MOA",
    "city": "Moa",
    "region": "Cuba",
    "tz": "America/Havana"
  },
  {
    "iata": "CNY",
    "city": "Moab",
    "region": "Utah",
    "tz": "America/Denver"
  },
  {
    "iata": "MFJ",
    "city": "Moala",
    "region": "Fiji",
    "tz": "Pacific/Fiji"
  },
  {
    "iata": "BFM",
    "city": "Mobile",
    "region": "Alabama",
    "tz": "America/Chicago"
  },
  {
    "iata": "MOB",
    "city": "Mobile",
    "region": "Alabama",
    "tz": "America/Chicago"
  },
  {
    "iata": "MBG",
    "city": "Mobridge",
    "region": "South Dakota",
    "tz": "America/Chicago"
  },
  {
    "iata": "MSZ",
    "city": "Mocamedes",
    "region": "Angola",
    "tz": "Africa/Luanda"
  },
  {
    "iata": "MZB",
    "city": "Mocimboa Da Praia",
    "region": "Mozambique",
    "tz": "Africa/Maputo"
  },
  {
    "iata": "MVF",
    "city": "Mocord",
    "region": "Brazil",
    "tz": "America/Fortaleza"
  },
  {
    "iata": "MOD",
    "city": "Modesto",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "MGL",
    "city": "Moenchengladbach",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "MJD",
    "city": "Moenjodaro",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "MGQ",
    "city": "Mogadishu",
    "region": "Somalia",
    "tz": "Africa/Mogadishu"
  },
  {
    "iata": "MVQ",
    "city": "Mogilev",
    "region": "Belarus",
    "tz": "Europe/Minsk"
  },
  {
    "iata": "DIB",
    "city": "Mohanbari",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "OHE",
    "city": "Mohe County",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "SQO",
    "city": "Mohed",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "NWA",
    "city": "Moheli",
    "region": "Comoros",
    "tz": "Indian/Comoro"
  },
  {
    "iata": "MHV",
    "city": "Mojave",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "MPK",
    "city": "Mokpo",
    "region": "South Korea",
    "tz": "Asia/Seoul"
  },
  {
    "iata": "OKU",
    "city": "Mokuti Lodge",
    "region": "Namibia",
    "tz": "Africa/Windhoek"
  },
  {
    "iata": "MOL",
    "city": "Molde",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "MLI",
    "city": "Moline",
    "region": "Illinois",
    "tz": "America/Chicago"
  },
  {
    "iata": "LUP",
    "city": "Molokai",
    "region": "Hawaii",
    "tz": "Pacific/Honolulu"
  },
  {
    "iata": "MKK",
    "city": "Molokai",
    "region": "Hawaii",
    "tz": "Pacific/Honolulu"
  },
  {
    "iata": "MBA",
    "city": "Mombasa",
    "region": "Kenya",
    "tz": "Africa/Nairobi"
  },
  {
    "iata": "MAS",
    "city": "Momote",
    "region": "Papua New Guinea",
    "tz": "Pacific/Port_Moresby"
  },
  {
    "iata": "MIR",
    "city": "Monastir",
    "region": "Tunisia",
    "tz": "Africa/Tunis"
  },
  {
    "iata": "MBE",
    "city": "Monbetsu",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "LOV",
    "city": "Monclova",
    "region": "Mexico",
    "tz": "America/Monterrey"
  },
  {
    "iata": "YQM",
    "city": "Moncton",
    "region": "Canada",
    "tz": "America/Moncton"
  },
  {
    "iata": "MOG",
    "city": "Mong Hsat",
    "region": "Burma",
    "tz": "Asia/Rangoon"
  },
  {
    "iata": "MLU",
    "city": "Monroe",
    "region": "Louisiana",
    "tz": "America/Chicago"
  },
  {
    "iata": "MLW",
    "city": "Monrovia",
    "region": "Liberia",
    "tz": "Africa/Monrovia"
  },
  {
    "iata": "ROB",
    "city": "Monrovia",
    "region": "Liberia",
    "tz": "Africa/Monrovia"
  },
  {
    "iata": "YYY",
    "city": "Mont Joli",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "YTM",
    "city": "Mont-Tremblant",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "MTP",
    "city": "Montauk",
    "region": "United States",
    "tz": "America/New_York"
  },
  {
    "iata": "MBJ",
    "city": "Montego Bay",
    "region": "Jamaica",
    "tz": "America/Jamaica"
  },
  {
    "iata": "MRY",
    "city": "Monterey",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "MTR",
    "city": "Monteria",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "MTY",
    "city": "Monterrey",
    "region": "Mexico",
    "tz": "America/Monterrey"
  },
  {
    "iata": "NTR",
    "city": "Monterrey",
    "region": "Mexico",
    "tz": "America/Monterrey"
  },
  {
    "iata": "MOC",
    "city": "Montes Claros",
    "region": "Brazil",
    "tz": "America/Bahia"
  },
  {
    "iata": "MVD",
    "city": "Montevideo",
    "region": "Uruguay",
    "tz": "America/Montevideo"
  },
  {
    "iata": "MXF",
    "city": "Montgomery",
    "region": "Alabama",
    "tz": "America/Chicago"
  },
  {
    "iata": "MGJ",
    "city": "Montgomery",
    "region": "New York",
    "tz": "America/New_York"
  },
  {
    "iata": "LLQ",
    "city": "Monticello",
    "region": "United States",
    "tz": "America/Chicago"
  },
  {
    "iata": "MCU",
    "city": "Montlucon-gueret",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "MPV",
    "city": "Montpelier",
    "region": "Vermont",
    "tz": "America/New_York"
  },
  {
    "iata": "MPL",
    "city": "Montpellier",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "YHU",
    "city": "Montreal",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "YMX",
    "city": "Montreal",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "YUL",
    "city": "Montreal",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "MTJ",
    "city": "Montrose CO",
    "region": "Colorado",
    "tz": "America/Denver"
  },
  {
    "iata": "MOO",
    "city": "Moomba",
    "region": "Australia",
    "tz": "Australia/Adelaide"
  },
  {
    "iata": "MOZ",
    "city": "Moorea",
    "region": "French Polynesia",
    "tz": "Pacific/Tahiti"
  },
  {
    "iata": "YMJ",
    "city": "Moose Jaw",
    "region": "Canada",
    "tz": "America/Regina"
  },
  {
    "iata": "YMO",
    "city": "Moosonee",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "MZI",
    "city": "Mopti",
    "region": "Mali",
    "tz": "Africa/Bamako"
  },
  {
    "iata": "MXX",
    "city": "Mora",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "TVA",
    "city": "Morafenobe",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "MOV",
    "city": "Moranbah",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "MRZ",
    "city": "Moree",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "MLM",
    "city": "Morelia",
    "region": "Mexico",
    "tz": "America/Mexico_City"
  },
  {
    "iata": "MRN",
    "city": "Morganton",
    "region": "United States",
    "tz": "America/New_York"
  },
  {
    "iata": "MGW",
    "city": "Morgantown",
    "region": "West Virginia",
    "tz": "America/New_York"
  },
  {
    "iata": "MXN",
    "city": "Morlaix",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "ONG",
    "city": "Mornington Island",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "MXH",
    "city": "Moro",
    "region": "Papua New Guinea",
    "tz": "Pacific/Port_Moresby"
  },
  {
    "iata": "MXM",
    "city": "Morombe",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "MOQ",
    "city": "Morondava",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "HAH",
    "city": "Moroni",
    "region": "Comoros",
    "tz": "Indian/Comoro"
  },
  {
    "iata": "YVA",
    "city": "Moroni",
    "region": "Comoros",
    "tz": "Indian/Comoro"
  },
  {
    "iata": "OTI",
    "city": "Morotai Island",
    "region": "Indonesia",
    "tz": "Asia/Jayapura"
  },
  {
    "iata": "MMU",
    "city": "Morristown",
    "region": "New Jersey",
    "tz": "America/New_York"
  },
  {
    "iata": "MVL",
    "city": "Morrisville",
    "region": "Vermont",
    "tz": "America/New_York"
  },
  {
    "iata": "MYA",
    "city": "Moruya",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "TGN",
    "city": "Morwell",
    "region": "Australia",
    "tz": "Australia/Melbourne"
  },
  {
    "iata": "BKA",
    "city": "Moscow",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "DME",
    "city": "Moscow",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "SVO",
    "city": "Moscow",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "VKO",
    "city": "Moscow",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "MJF",
    "city": "Mosjoen",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "OMO",
    "city": "Mostar",
    "region": "Bosnia and Herzegovina",
    "tz": "Europe/Sarajevo"
  },
  {
    "iata": "MZP",
    "city": "Motueka",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "MJL",
    "city": "Mouila",
    "region": "Gabon",
    "tz": "Africa/Libreville"
  },
  {
    "iata": "XMU",
    "city": "Moulins",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "MQQ",
    "city": "Moundou",
    "region": "Chad",
    "tz": "Africa/Ndjamena"
  },
  {
    "iata": "MTC",
    "city": "Mount Clemens",
    "region": "Michigan",
    "tz": "America/Detroit"
  },
  {
    "iata": "MON",
    "city": "Mount Cook",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "MGB",
    "city": "Mount Gambier",
    "region": "Australia",
    "tz": "Australia/Adelaide"
  },
  {
    "iata": "HGU",
    "city": "Mount Hagen",
    "region": "Papua New Guinea",
    "tz": "Pacific/Port_Moresby"
  },
  {
    "iata": "MHU",
    "city": "Mount Hotham",
    "region": "Australia",
    "tz": "Australia/Melbourne"
  },
  {
    "iata": "ISA",
    "city": "Mount Isa",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "WME",
    "city": "Mount Keith",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "MMG",
    "city": "Mount Magnet",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "LRO",
    "city": "Mount Pleasant",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "MPN",
    "city": "Mount Pleasant",
    "region": "Falkland Islands",
    "tz": "Atlantic/Stanley"
  },
  {
    "iata": "MPO",
    "city": "Mount Pocono",
    "region": "United States",
    "tz": "America/New_York"
  },
  {
    "iata": "WMH",
    "city": "Mountain Home",
    "region": "Arkansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "MUO",
    "city": "Mountain Home",
    "region": "Idaho",
    "tz": "America/Boise"
  },
  {
    "iata": "NUQ",
    "city": "Mountain View",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "MOU",
    "city": "Mountain Village",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "OYL",
    "city": "Moyale",
    "region": "Kenya",
    "tz": "Africa/Nairobi"
  },
  {
    "iata": "MPA",
    "city": "Mpacha",
    "region": "Namibia",
    "tz": "Africa/Windhoek"
  },
  {
    "iata": "MQP",
    "city": "Mpumalanga",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "MYW",
    "city": "Mtwara",
    "region": "Tanzania",
    "tz": "Africa/Dar_es_Salaam"
  },
  {
    "iata": "MWX",
    "city": "Muan",
    "region": "South Korea",
    "tz": "Asia/Seoul"
  },
  {
    "iata": "MNB",
    "city": "Muanda",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Kinshasa"
  },
  {
    "iata": "ODY",
    "city": "Muang Xay",
    "region": "Laos",
    "tz": "Asia/Vientiane"
  },
  {
    "iata": "MVS",
    "city": "Mucuri",
    "region": "Brazil",
    "tz": "America/Bahia"
  },
  {
    "iata": "MDG",
    "city": "Mudanjiang",
    "region": "China",
    "tz": "Asia/Harbin"
  },
  {
    "iata": "DGE",
    "city": "Mudgee",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "MUI",
    "city": "Muir",
    "region": "United States",
    "tz": "America/New_York"
  },
  {
    "iata": "MKM",
    "city": "Mukah",
    "region": "Malaysia",
    "tz": "Asia/Kuching"
  },
  {
    "iata": "RIY",
    "city": "Mukalla",
    "region": "Yemen",
    "tz": "Asia/Aden"
  },
  {
    "iata": "BSL",
    "city": "Mulhouse",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "LII",
    "city": "Mulia",
    "region": "Indonesia",
    "tz": "Asia/Jayapura"
  },
  {
    "iata": "MUX",
    "city": "Multan",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "MZV",
    "city": "Mulu",
    "region": "Malaysia",
    "tz": "Asia/Kuching"
  },
  {
    "iata": "BOM",
    "city": "Mumbai",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "MIE",
    "city": "Muncie",
    "region": "Indiana",
    "tz": "America/Indiana/Indianapolis"
  },
  {
    "iata": "MUA",
    "city": "Munda",
    "region": "Solomon Islands",
    "tz": "Pacific/Guadalcanal"
  },
  {
    "iata": "MUC",
    "city": "Munich",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "FMO",
    "city": "Munster",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "MJV",
    "city": "Murcia",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "MXV",
    "city": "Muren",
    "region": "Mongolia",
    "tz": "Asia/Ulaanbaatar"
  },
  {
    "iata": "MMK",
    "city": "Murmansk",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "MYI",
    "city": "Murray Island",
    "region": "Australia",
    "tz": "Australia/Lindeman"
  },
  {
    "iata": "RBK",
    "city": "Murrieta-Temecula",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "MSR",
    "city": "Mus",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "MCT",
    "city": "Muscat",
    "region": "Oman",
    "tz": "Asia/Muscat"
  },
  {
    "iata": "MSL",
    "city": "Muscle Shoals",
    "region": "Alabama",
    "tz": "America/Chicago"
  },
  {
    "iata": "MEZ",
    "city": "Musina",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "MKG",
    "city": "Muskegon",
    "region": "Michigan",
    "tz": "America/Detroit"
  },
  {
    "iata": "MKO",
    "city": "Muskogee",
    "region": "United States",
    "tz": "America/Chicago"
  },
  {
    "iata": "YQA",
    "city": "Muskoka",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "MSA",
    "city": "Muskrat Dam",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "MUZ",
    "city": "Musoma",
    "region": "Tanzania",
    "tz": "Africa/Dar_es_Salaam"
  },
  {
    "iata": "MQS",
    "city": "Mustique",
    "region": "Saint Vincent and the Grenadines",
    "tz": "America/St_Vincent"
  },
  {
    "iata": "MFG",
    "city": "Muzaffarabad",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "MWZ",
    "city": "Mwanza",
    "region": "Tanzania",
    "tz": "Africa/Dar_es_Salaam"
  },
  {
    "iata": "MGZ",
    "city": "Myeik",
    "region": "Burma",
    "tz": "Asia/Rangoon"
  },
  {
    "iata": "MYT",
    "city": "Myitkyina",
    "region": "Burma",
    "tz": "Asia/Rangoon"
  },
  {
    "iata": "JMK",
    "city": "Mykonos",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "MYR",
    "city": "Myrtle Beach",
    "region": "South Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "MYQ",
    "city": "Mysore",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "MJT",
    "city": "Mytilini",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "MVA",
    "city": "Myvatn",
    "region": "Iceland",
    "tz": "Atlantic/Reykjavik"
  },
  {
    "iata": "ZZU",
    "city": "Mzuzu",
    "region": "Malawi",
    "tz": "Africa/Blantyre"
  },
  {
    "iata": "NDJ",
    "city": "N'djamena",
    "region": "Chad",
    "tz": "Africa/Ndjamena"
  },
  {
    "iata": "NGE",
    "city": "N'gaoundere",
    "region": "Cameroon",
    "tz": "Africa/Douala"
  },
  {
    "iata": "NPE",
    "city": "NAPIER",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "NYE",
    "city": "NYERI",
    "region": "Kenya",
    "tz": "Africa/Nairobi"
  },
  {
    "iata": "NBX",
    "city": "Nabire",
    "region": "Indonesia",
    "tz": "Asia/Jayapura"
  },
  {
    "iata": "MNC",
    "city": "Nacala",
    "region": "Mozambique",
    "tz": "Africa/Maputo"
  },
  {
    "iata": "NYM",
    "city": "Nadym",
    "region": "Russia",
    "tz": "Asia/Yekaterinburg"
  },
  {
    "iata": "LAE",
    "city": "Nadzab",
    "region": "Papua New Guinea",
    "tz": "Pacific/Port_Moresby"
  },
  {
    "iata": "WNP",
    "city": "Naga",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "MEQ",
    "city": "Nagan Raya",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "NGS",
    "city": "Nagasaki",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "NGO",
    "city": "Nagoya",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "NKM",
    "city": "Nagoya",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "NAG",
    "city": "Nagpur",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "NAH",
    "city": "Naha",
    "region": "Indonesia",
    "tz": "Asia/Makassar"
  },
  {
    "iata": "YDP",
    "city": "Nain",
    "region": "Canada",
    "tz": "America/Goose_Bay"
  },
  {
    "iata": "PGH",
    "city": "Nainital",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "NBO",
    "city": "Nairobi",
    "region": "Kenya",
    "tz": "Africa/Nairobi"
  },
  {
    "iata": "WIL",
    "city": "Nairobi",
    "region": "Kenya",
    "tz": "Africa/Nairobi"
  },
  {
    "iata": "NJF",
    "city": "Najaf",
    "region": "Iraq",
    "tz": "Asia/Baghdad"
  },
  {
    "iata": "SHB",
    "city": "Nakashibetsu",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "NAJ",
    "city": "Nakhchivan",
    "region": "Azerbaijan",
    "tz": "Asia/Baku"
  },
  {
    "iata": "KOP",
    "city": "Nakhon Phanom",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "NAK",
    "city": "Nakhon Ratchasima",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "NST",
    "city": "Nakhon Si Thammarat",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "YQN",
    "city": "Nakina",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "NAL",
    "city": "Nalchik",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "NMA",
    "city": "Namangan",
    "region": "Uzbekistan",
    "tz": "Asia/Tashkent"
  },
  {
    "iata": "APL",
    "city": "Nampula",
    "region": "Mozambique",
    "tz": "Africa/Maputo"
  },
  {
    "iata": "OSY",
    "city": "Namsos",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "NNT",
    "city": "Nan",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "YCD",
    "city": "Nanaimo",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "ZNA",
    "city": "Nanaimo",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "KHN",
    "city": "Nanchang",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "NAO",
    "city": "Nanchong",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "ENC",
    "city": "Nancy",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "PBP",
    "city": "Nandayure",
    "region": "Costa Rica",
    "tz": "America/Costa_Rica"
  },
  {
    "iata": "NDC",
    "city": "Nanded",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "NAN",
    "city": "Nandi",
    "region": "Fiji",
    "tz": "Pacific/Fiji"
  },
  {
    "iata": "YSR",
    "city": "Nanisivik",
    "region": "Canada",
    "tz": "America/Iqaluit"
  },
  {
    "iata": "NKG",
    "city": "Nanjing",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "SHM",
    "city": "Nanki-shirahama",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "NNG",
    "city": "Nanning",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "JNN",
    "city": "Nanortalik",
    "region": "Greenland",
    "tz": "America/Godthab"
  },
  {
    "iata": "NTE",
    "city": "Nantes",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "ACK",
    "city": "Nantucket",
    "region": "Massachusetts",
    "tz": "America/New_York"
  },
  {
    "iata": "NNY",
    "city": "Nanyang",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "NYK",
    "city": "Nanyuki",
    "region": "Kenya",
    "tz": "Africa/Nairobi"
  },
  {
    "iata": "APC",
    "city": "Napa",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "WNA",
    "city": "Napakiak",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "PKA",
    "city": "Napaskiak",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "APF",
    "city": "Naples",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "NAP",
    "city": "Naples",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "NAW",
    "city": "Narathiwat",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "NAA",
    "city": "Narrabri",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "NRA",
    "city": "Narrandera",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "JNS",
    "city": "Narsaq",
    "region": "Greenland",
    "tz": "America/Godthab"
  },
  {
    "iata": "UAK",
    "city": "Narssarssuaq",
    "region": "Greenland",
    "tz": "America/Godthab"
  },
  {
    "iata": "NVK",
    "city": "Narvik",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "NNM",
    "city": "Naryan-Mar",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "ASH",
    "city": "Nashua",
    "region": "New Hampshire",
    "tz": "America/New_York"
  },
  {
    "iata": "BNA",
    "city": "Nashville",
    "region": "Tennessee",
    "tz": "America/Chicago"
  },
  {
    "iata": "ISK",
    "city": "Nasik Road",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "NAS",
    "city": "Nassau",
    "region": "Bahamas",
    "tz": "America/Nassau"
  },
  {
    "iata": "PID",
    "city": "Nassau",
    "region": "Bahamas",
    "tz": "America/Nassau"
  },
  {
    "iata": "NAT",
    "city": "Natal",
    "region": "Brazil",
    "tz": "America/Recife"
  },
  {
    "iata": "YNA",
    "city": "Natashquan",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "INU",
    "city": "Nauru",
    "region": "Nauru",
    "tz": "Pacific/Nauru"
  },
  {
    "iata": "SUV",
    "city": "Nausori",
    "region": "Fiji",
    "tz": "Pacific/Fiji"
  },
  {
    "iata": "NVT",
    "city": "Navegantes",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "NVI",
    "city": "Navoi",
    "region": "Uzbekistan",
    "tz": "Asia/Samarkand"
  },
  {
    "iata": "WNS",
    "city": "Nawabshah",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "NYT",
    "city": "Naypyidaw",
    "region": "Burma",
    "tz": "Asia/Rangoon"
  },
  {
    "iata": "NZC",
    "city": "Nazca",
    "region": "Peru",
    "tz": "America/Lima"
  },
  {
    "iata": "NLA",
    "city": "Ndola",
    "region": "Zambia",
    "tz": "Africa/Lusaka"
  },
  {
    "iata": "VOL",
    "city": "Nea Anghialos",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "NEC",
    "city": "Necochea",
    "region": "Argentina",
    "tz": "America/Argentina/Buenos_Aires"
  },
  {
    "iata": "EED",
    "city": "Needles",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "CNP",
    "city": "Neerlerit Inaat",
    "region": "Greenland",
    "tz": "America/Scoresbysund"
  },
  {
    "iata": "NFG",
    "city": "Nefteyugansk",
    "region": "Russia",
    "tz": "Asia/Yekaterinburg"
  },
  {
    "iata": "GXG",
    "city": "Negage",
    "region": "Angola",
    "tz": "Africa/Luanda"
  },
  {
    "iata": "NEG",
    "city": "Negril",
    "region": "Jamaica",
    "tz": "America/Jamaica"
  },
  {
    "iata": "NVA",
    "city": "Neiva",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "EAM",
    "city": "Nejran",
    "region": "Saudi Arabia",
    "tz": "Asia/Riyadh"
  },
  {
    "iata": "NSN",
    "city": "Nelson",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "NLG",
    "city": "Nelson Lagoon",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "NLP",
    "city": "Nelspruit",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "EMN",
    "city": "Nema",
    "region": "Mauritania",
    "tz": "Africa/Nouakchott"
  },
  {
    "iata": "YNS",
    "city": "Nemiscau",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "ENN",
    "city": "Nenana",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "KEP",
    "city": "Nepalgunj",
    "region": "Nepal",
    "tz": "Asia/Kathmandu"
  },
  {
    "iata": "CNN",
    "city": "Neryungri",
    "region": "Russia",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "FNB",
    "city": "Neubrandenburg",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "EUM",
    "city": "Neumuenster",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "NQN",
    "city": "Neuquen",
    "region": "Argentina",
    "tz": "America/Argentina/Salta"
  },
  {
    "iata": "NVS",
    "city": "Nevers",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "NAV",
    "city": "Nevsehir",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "EWB",
    "city": "New Bedford",
    "region": "Massachusetts",
    "tz": "America/New_York"
  },
  {
    "iata": "EWN",
    "city": "New Bern",
    "region": "North Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "HVN",
    "city": "New Haven",
    "region": "Connecticut",
    "tz": "America/New_York"
  },
  {
    "iata": "MSY",
    "city": "New Orleans",
    "region": "Louisiana",
    "tz": "America/Chicago"
  },
  {
    "iata": "NBG",
    "city": "New Orleans",
    "region": "Louisiana",
    "tz": "America/Chicago"
  },
  {
    "iata": "NEW",
    "city": "New Orleans",
    "region": "Louisiana",
    "tz": "America/Chicago"
  },
  {
    "iata": "PHD",
    "city": "New Philadelpha",
    "region": "Ohio",
    "tz": "America/New_York"
  },
  {
    "iata": "NPL",
    "city": "New Plymouth",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "RNH",
    "city": "New Richmond",
    "region": "United States",
    "tz": "America/Chicago"
  },
  {
    "iata": "KNW",
    "city": "New Stuyahok",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "JFK",
    "city": "New York",
    "region": "New York",
    "tz": "America/New_York"
  },
  {
    "iata": "LGA",
    "city": "New York",
    "region": "New York",
    "tz": "America/New_York"
  },
  {
    "iata": "EWR",
    "city": "Newark",
    "region": "New Jersey",
    "tz": "America/New_York"
  },
  {
    "iata": "ERY",
    "city": "Newberry",
    "region": "United States",
    "tz": "America/Detroit"
  },
  {
    "iata": "SWF",
    "city": "Newburgh",
    "region": "New York",
    "tz": "America/New_York"
  },
  {
    "iata": "NTL",
    "city": "Newcastle",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "NCS",
    "city": "Newcastle",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "NCL",
    "city": "Newcastle",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "ZNE",
    "city": "Newman",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "CCO",
    "city": "Newnan",
    "region": "United States",
    "tz": "America/Bogota"
  },
  {
    "iata": "ONP",
    "city": "Newport",
    "region": "Oregon",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "PHF",
    "city": "Newport News",
    "region": "Virginia",
    "tz": "America/New_York"
  },
  {
    "iata": "NQY",
    "city": "Newquai",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "EWK",
    "city": "Newton",
    "region": "Kansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "NGI",
    "city": "Ngau",
    "region": "Fiji",
    "tz": "Pacific/Fiji"
  },
  {
    "iata": "CXR",
    "city": "Nha Trang",
    "region": "Vietnam",
    "tz": "Asia/Ho_Chi_Minh"
  },
  {
    "iata": "NHA",
    "city": "Nhatrang",
    "region": "Vietnam",
    "tz": "Asia/Ho_Chi_Minh"
  },
  {
    "iata": "IAG",
    "city": "Niagara Falls",
    "region": "New York",
    "tz": "America/New_York"
  },
  {
    "iata": "NIM",
    "city": "Niamey",
    "region": "Niger",
    "tz": "Africa/Niamey"
  },
  {
    "iata": "LRL",
    "city": "Niatougou",
    "region": "Togo",
    "tz": "Africa/Lome"
  },
  {
    "iata": "NIU",
    "city": "Niau",
    "region": "French Polynesia",
    "tz": "Pacific/Tahiti"
  },
  {
    "iata": "NCE",
    "city": "Nice",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "ECN",
    "city": "Nicosia",
    "region": "Cyprus",
    "tz": "Asia/Nicosia"
  },
  {
    "iata": "TMU",
    "city": "Nicoya",
    "region": "Costa Rica",
    "tz": "America/Costa_Rica"
  },
  {
    "iata": "TNO",
    "city": "Nicoya",
    "region": "Costa Rica",
    "tz": "America/Costa_Rica"
  },
  {
    "iata": "ICK",
    "city": "Nieuw Nickerie",
    "region": "Suriname",
    "tz": "America/Paramaribo"
  },
  {
    "iata": "NME",
    "city": "Nightmute",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "KIJ",
    "city": "Niigata",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "NIB",
    "city": "Nikolai",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "NLV",
    "city": "Nikolayev",
    "region": "Ukraine",
    "tz": "Europe/Kiev"
  },
  {
    "iata": "NLI",
    "city": "Nikolayevsk-na-Amure Airport",
    "region": "Russia",
    "tz": "Asia/Vladivostok"
  },
  {
    "iata": "NIG",
    "city": "Nikunau",
    "region": "Kiribati",
    "tz": "Pacific/Tarawa"
  },
  {
    "iata": "FNI",
    "city": "Nimes",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "NGB",
    "city": "Ninbo",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "NIO",
    "city": "Nioki",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Kinshasa"
  },
  {
    "iata": "NIT",
    "city": "Niort",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "INI",
    "city": "Nis",
    "region": "Serbia",
    "tz": "Europe/Belgrade"
  },
  {
    "iata": "NTT",
    "city": "Niuatoputapu",
    "region": "Tonga",
    "tz": "Pacific/Tongatapu"
  },
  {
    "iata": "NBC",
    "city": "Nizhnekamsk",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "NJC",
    "city": "Nizhnevartovsk",
    "region": "Russia",
    "tz": "Asia/Yekaterinburg"
  },
  {
    "iata": "GOJ",
    "city": "Nizhniy Novgorod",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "WTK",
    "city": "Noatak",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "OLS",
    "city": "Nogales",
    "region": "Arizona",
    "tz": "America/Phoenix"
  },
  {
    "iata": "NOG",
    "city": "Nogales",
    "region": "Mexico",
    "tz": "America/Hermosillo"
  },
  {
    "iata": "OME",
    "city": "Nome",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "NNL",
    "city": "Nondalton",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "NON",
    "city": "Nonouti",
    "region": "Kiribati",
    "tz": "Pacific/Tarawa"
  },
  {
    "iata": "ORV",
    "city": "Noorvik",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "NRD",
    "city": "Norderney",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "NOR",
    "city": "Nordfjordur",
    "region": "Iceland",
    "tz": "Atlantic/Reykjavik"
  },
  {
    "iata": "NGU",
    "city": "Norfolk",
    "region": "Virginia",
    "tz": "America/New_York"
  },
  {
    "iata": "ORF",
    "city": "Norfolk",
    "region": "Virginia",
    "tz": "America/New_York"
  },
  {
    "iata": "OFK",
    "city": "Norfolk  Nebraska",
    "region": "Nebraska",
    "tz": "America/Chicago"
  },
  {
    "iata": "NLK",
    "city": "Norfolk Island",
    "region": "Norfolk Island",
    "tz": "Pacific/Norfolk"
  },
  {
    "iata": "NSK",
    "city": "Norilsk",
    "region": "Russia",
    "tz": "Asia/Krasnoyarsk"
  },
  {
    "iata": "YVQ",
    "city": "Norman Wells",
    "region": "Canada",
    "tz": "America/Yellowknife"
  },
  {
    "iata": "NTN",
    "city": "Normanton",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "NRK",
    "city": "Norrkoeping",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "NUS",
    "city": "Norsup",
    "region": "Vanuatu",
    "tz": "Pacific/Efate"
  },
  {
    "iata": "YQW",
    "city": "North Battleford",
    "region": "Canada",
    "tz": "America/Regina"
  },
  {
    "iata": "YYB",
    "city": "North Bay",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "OTH",
    "city": "North Bend",
    "region": "Oregon",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "NCA",
    "city": "North Caicos",
    "region": "Turks and Caicos Islands",
    "tz": "America/Grand_Turk"
  },
  {
    "iata": "OBN",
    "city": "North Connel",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "ELH",
    "city": "North Eleuthera",
    "region": "Bahamas",
    "tz": "America/Nassau"
  },
  {
    "iata": "CRE",
    "city": "North Myrtle Beach",
    "region": "South Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "LBF",
    "city": "North Platte",
    "region": "Nebraska",
    "tz": "America/Chicago"
  },
  {
    "iata": "NRL",
    "city": "North Ronaldsay",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "YNO",
    "city": "North Spirit Lake",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "NHT",
    "city": "Northolt",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "ORT",
    "city": "Northway",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "YNE",
    "city": "Norway House",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "NWI",
    "city": "Norwich",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "OWD",
    "city": "Norwood",
    "region": "Massachusetts",
    "tz": "America/New_York"
  },
  {
    "iata": "NOB",
    "city": "Nosara Beach",
    "region": "Costa Rica",
    "tz": "America/Costa_Rica"
  },
  {
    "iata": "NSH",
    "city": "Noshahr",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "NOS",
    "city": "Nosy-be",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "NTB",
    "city": "Notodden",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "NQT",
    "city": "Nottingham",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "NDB",
    "city": "Nouadhibou",
    "region": "Mauritania",
    "tz": "Africa/Nouakchott"
  },
  {
    "iata": "NKC",
    "city": "Nouakschott",
    "region": "Mauritania",
    "tz": "Africa/Nouakchott"
  },
  {
    "iata": "GEA",
    "city": "Noumea",
    "region": "New Caledonia",
    "tz": "Pacific/Noumea"
  },
  {
    "iata": "NOU",
    "city": "Noumea",
    "region": "New Caledonia",
    "tz": "Pacific/Noumea"
  },
  {
    "iata": "QNV",
    "city": "Nova Iguacu",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "NVP",
    "city": "Novo Aripuana",
    "region": "Brazil",
    "tz": "America/Manaus"
  },
  {
    "iata": "NOZ",
    "city": "Novokuznetsk",
    "region": "Russia",
    "tz": "Asia/Novokuznetsk"
  },
  {
    "iata": "OVB",
    "city": "Novosibirsk",
    "region": "Russia",
    "tz": "Asia/Novosibirsk"
  },
  {
    "iata": "NUX",
    "city": "Novy Urengoy",
    "region": "Russia",
    "tz": "Asia/Yekaterinburg"
  },
  {
    "iata": "NOA",
    "city": "Nowra",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "NOJ",
    "city": "Noyabrsk",
    "region": "Russia",
    "tz": "Asia/Yekaterinburg"
  },
  {
    "iata": "NUE",
    "city": "Nuernberg",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "GER",
    "city": "Nueva Gerona",
    "region": "Cuba",
    "tz": "America/Havana"
  },
  {
    "iata": "NLD",
    "city": "Nuevo Laredo",
    "region": "Mexico",
    "tz": "America/Matamoros"
  },
  {
    "iata": "NUI",
    "city": "Nuiqsut",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "NHV",
    "city": "Nuku Hiva",
    "region": "French Polynesia",
    "tz": "Pacific/Tahiti"
  },
  {
    "iata": "NCU",
    "city": "Nukus",
    "region": "Uzbekistan",
    "tz": "Asia/Samarkand"
  },
  {
    "iata": "NUL",
    "city": "Nulato",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "SUS",
    "city": "Null",
    "region": "Missouri",
    "tz": "America/Chicago"
  },
  {
    "iata": "NNX",
    "city": "Nunukan-Nunukan Island",
    "region": "Indonesia",
    "tz": "Asia/Makassar"
  },
  {
    "iata": "NQU",
    "city": "Nuqu\u00ed",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "NYA",
    "city": "Nyagan",
    "region": "Russia",
    "tz": "Asia/Yekaterinburg"
  },
  {
    "iata": "UYL",
    "city": "Nyala",
    "region": "Sudan",
    "tz": "Africa/Khartoum"
  },
  {
    "iata": "LZY",
    "city": "Nyingchi",
    "region": "China",
    "tz": "Asia/Urumqi"
  },
  {
    "iata": "NZA",
    "city": "Nzagi",
    "region": "Angola",
    "tz": "Africa/Luanda"
  },
  {
    "iata": "SUT",
    "city": "Oak Island",
    "region": "United States",
    "tz": "Africa/Dar_es_Salaam"
  },
  {
    "iata": "OKY",
    "city": "Oakey",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "OAK",
    "city": "Oakland",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "OEL",
    "city": "Oakley",
    "region": "United States",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "OAM",
    "city": "Oamaru",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "OAX",
    "city": "Oaxaca",
    "region": "Mexico",
    "tz": "America/Mexico_City"
  },
  {
    "iata": "OBF",
    "city": "Oberpfaffenhofen",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "OBO",
    "city": "Obihiro",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "OBC",
    "city": "Obock",
    "region": "Djibouti",
    "tz": "Africa/Djibouti"
  },
  {
    "iata": "OCF",
    "city": "Ocala",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "OCV",
    "city": "Ocana",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "OCA",
    "city": "Ocean Reef Club Airport",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "NTU",
    "city": "Oceana",
    "region": "Virginia",
    "tz": "America/New_York"
  },
  {
    "iata": "OCJ",
    "city": "Ocho Rios",
    "region": "Jamaica",
    "tz": "America/Jamaica"
  },
  {
    "iata": "ONJ",
    "city": "Odate Noshiro",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "ODE",
    "city": "Odense",
    "region": "Denmark",
    "tz": "Europe/Copenhagen"
  },
  {
    "iata": "ODS",
    "city": "Odessa",
    "region": "Ukraine",
    "tz": "Europe/Simferopol"
  },
  {
    "iata": "ODH",
    "city": "Odiham",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "HIF",
    "city": "Ogden",
    "region": "Utah",
    "tz": "America/Denver"
  },
  {
    "iata": "OGD",
    "city": "Ogden",
    "region": "Utah",
    "tz": "America/Denver"
  },
  {
    "iata": "OGS",
    "city": "Ogdensburg",
    "region": "New York",
    "tz": "America/New_York"
  },
  {
    "iata": "YOG",
    "city": "Ogoki Post",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "OHD",
    "city": "Ohrid",
    "region": "Macedonia",
    "tz": "Europe/Skopje"
  },
  {
    "iata": "OIT",
    "city": "Oita",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "OKF",
    "city": "Okaukuejo",
    "region": "Namibia",
    "tz": "Africa/Windhoek"
  },
  {
    "iata": "OKJ",
    "city": "Okayama",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "OBE",
    "city": "Okeechobee",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "OHO",
    "city": "Okhotsk",
    "region": "Russia",
    "tz": "Asia/Vladivostok"
  },
  {
    "iata": "OKI",
    "city": "Oki Island",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "OKA",
    "city": "Okinawa",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "OKC",
    "city": "Oklahoma City",
    "region": "Oklahoma",
    "tz": "America/Chicago"
  },
  {
    "iata": "TIK",
    "city": "Oklahoma City",
    "region": "Oklahoma",
    "tz": "America/Chicago"
  },
  {
    "iata": "OKN",
    "city": "Okondja",
    "region": "Gabon",
    "tz": "Africa/Libreville"
  },
  {
    "iata": "OKL",
    "city": "Oksibil",
    "region": "Indonesia",
    "tz": "Asia/Jayapura"
  },
  {
    "iata": "OIR",
    "city": "Okushiri",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "JCI",
    "city": "Olathe",
    "region": "Kansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "OJC",
    "city": "Olathe",
    "region": "Kansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "OLB",
    "city": "Olbia",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "YOC",
    "city": "Old Crow",
    "region": "Canada",
    "tz": "America/Whitehorse"
  },
  {
    "iata": "ULG",
    "city": "Olgii",
    "region": "Mongolia",
    "tz": "Asia/Hovd"
  },
  {
    "iata": "OLV",
    "city": "Olive Branch",
    "region": "Mississippi",
    "tz": "America/Chicago"
  },
  {
    "iata": "SFS",
    "city": "Olongapo City",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "OLZ",
    "city": "Olpoi",
    "region": "Vanuatu",
    "tz": "Asia/Yakutsk"
  },
  {
    "iata": "OLM",
    "city": "Olympia",
    "region": "Washington",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "OLP",
    "city": "Olympic Dam",
    "region": "Australia",
    "tz": "Australia/Adelaide"
  },
  {
    "iata": "OFF",
    "city": "Omaha",
    "region": "Nebraska",
    "tz": "America/Chicago"
  },
  {
    "iata": "OMA",
    "city": "Omaha",
    "region": "Nebraska",
    "tz": "America/Chicago"
  },
  {
    "iata": "OMB",
    "city": "Omboue Hospial",
    "region": "Gabon",
    "tz": "Africa/Libreville"
  },
  {
    "iata": "OMS",
    "city": "Omsk",
    "region": "Russia",
    "tz": "Asia/Omsk"
  },
  {
    "iata": "OND",
    "city": "Ondangwa",
    "region": "Namibia",
    "tz": "Africa/Windhoek"
  },
  {
    "iata": "VPE",
    "city": "Ondjiva",
    "region": "Angola",
    "tz": "Africa/Luanda"
  },
  {
    "iata": "ONS",
    "city": "Onslow",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "ONT",
    "city": "Ontario",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "UPP",
    "city": "Opolu",
    "region": "Hawaii",
    "tz": "Pacific/Honolulu"
  },
  {
    "iata": "OMR",
    "city": "Oradea",
    "region": "Romania",
    "tz": "Europe/Bucharest"
  },
  {
    "iata": "ORN",
    "city": "Oran",
    "region": "Algeria",
    "tz": "Africa/Algiers"
  },
  {
    "iata": "TAF",
    "city": "Oran",
    "region": "Algeria",
    "tz": "Africa/Algiers"
  },
  {
    "iata": "ORA",
    "city": "Oran",
    "region": "Argentina",
    "tz": "America/Argentina/Salta"
  },
  {
    "iata": "OAG",
    "city": "Orange",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "OGB",
    "city": "Orangeburg",
    "region": "South Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "OMD",
    "city": "Oranjemund",
    "region": "Namibia",
    "tz": "Africa/Windhoek"
  },
  {
    "iata": "AUA",
    "city": "Oranjestad",
    "region": "Aruba",
    "tz": "America/Aruba"
  },
  {
    "iata": "EUX",
    "city": "Oranjestad",
    "region": "Netherlands Antilles",
    "tz": "America/Kralendijk"
  },
  {
    "iata": "ORP",
    "city": "Orapa",
    "region": "Botswana",
    "tz": "Africa/Gaborone"
  },
  {
    "iata": "ORB",
    "city": "Orebro",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "REN",
    "city": "Orenburg",
    "region": "Russia",
    "tz": "Asia/Yekaterinburg"
  },
  {
    "iata": "ORJ",
    "city": "Orinduik",
    "region": "Guyana",
    "tz": "America/Guyana"
  },
  {
    "iata": "FNU",
    "city": "Oristano",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "ORX",
    "city": "Oriximina",
    "region": "Brazil",
    "tz": "America/Santarem"
  },
  {
    "iata": "TMT",
    "city": "Oriximina",
    "region": "Brazil",
    "tz": "America/Santarem"
  },
  {
    "iata": "OLA",
    "city": "Orland",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "MCO",
    "city": "Orlando",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "ORL",
    "city": "Orlando",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "ORE",
    "city": "Orleans",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "ORW",
    "city": "Ormara Raik",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "OMC",
    "city": "Ormoc City",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "OMN",
    "city": "Ormond Beach",
    "region": "United States",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "OER",
    "city": "Ornskoldsvik",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "OSW",
    "city": "Orsk",
    "region": "Russia",
    "tz": "Asia/Yekaterinburg"
  },
  {
    "iata": "ITM",
    "city": "Osaka",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "KIX",
    "city": "Osaka",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "OSN",
    "city": "Osan",
    "region": "South Korea",
    "tz": "Asia/Seoul"
  },
  {
    "iata": "OSC",
    "city": "Oscoda",
    "region": "Michigan",
    "tz": "America/Detroit"
  },
  {
    "iata": "OSS",
    "city": "Osh",
    "region": "Kyrgyzstan",
    "tz": "Asia/Bishkek"
  },
  {
    "iata": "YOO",
    "city": "Oshawa",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "OIM",
    "city": "Oshima",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "OSH",
    "city": "Oshkosh",
    "region": "Wisconsin",
    "tz": "America/Chicago"
  },
  {
    "iata": "OSI",
    "city": "Osijek",
    "region": "Croatia",
    "tz": "Europe/Zagreb"
  },
  {
    "iata": "OSK",
    "city": "Oskarshamn",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "OSL",
    "city": "Oslo",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "ZOS",
    "city": "Osorno",
    "region": "Chile",
    "tz": "America/Santiago"
  },
  {
    "iata": "OST",
    "city": "Ostend",
    "region": "Belgium",
    "tz": "Europe/Brussels"
  },
  {
    "iata": "OSR",
    "city": "Ostrava",
    "region": "Czech Republic",
    "tz": "Europe/Prague"
  },
  {
    "iata": "YOW",
    "city": "Ottawa",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "YRO",
    "city": "Ottawa",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "OTM",
    "city": "Ottumwa",
    "region": "Iowa",
    "tz": "America/Chicago"
  },
  {
    "iata": "OTU",
    "city": "Otu",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "OUA",
    "city": "Ouagadougou",
    "region": "Burkina Faso",
    "tz": "Africa/Ouagadougou"
  },
  {
    "iata": "OGX",
    "city": "Ouargla",
    "region": "Algeria",
    "tz": "Africa/Algiers"
  },
  {
    "iata": "OZZ",
    "city": "Ouarzazate",
    "region": "Morocco",
    "tz": "Africa/Casablanca"
  },
  {
    "iata": "DUH",
    "city": "Oudtshoorn",
    "region": "South Africa",
    "tz": "America/Detroit"
  },
  {
    "iata": "OUE",
    "city": "Ouesso",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Brazzaville"
  },
  {
    "iata": "OUD",
    "city": "Oujda",
    "region": "Morocco",
    "tz": "Africa/Casablanca"
  },
  {
    "iata": "OUL",
    "city": "Oulu",
    "region": "Finland",
    "tz": "Europe/Helsinki"
  },
  {
    "iata": "OIA",
    "city": "Ourilandia do Norte",
    "region": "Brazil",
    "tz": "America/Santarem"
  },
  {
    "iata": "UVE",
    "city": "Ouvea",
    "region": "New Caledonia",
    "tz": "Pacific/Noumea"
  },
  {
    "iata": "VDA",
    "city": "Ovda",
    "region": "Israel",
    "tz": "Asia/Jerusalem"
  },
  {
    "iata": "FTX",
    "city": "Owando",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Brazzaville"
  },
  {
    "iata": "YOS",
    "city": "Owen Sound",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "OWB",
    "city": "Owensboro",
    "region": "Kentucky",
    "tz": "America/Chicago"
  },
  {
    "iata": "OXC",
    "city": "Oxford",
    "region": "Connecticut",
    "tz": "America/New_York"
  },
  {
    "iata": "UOX",
    "city": "Oxford",
    "region": "Mississippi",
    "tz": "America/Chicago"
  },
  {
    "iata": "OXF",
    "city": "Oxford",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "YOH",
    "city": "Oxford House",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "OXR",
    "city": "Oxnard",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "OYE",
    "city": "Oyem",
    "region": "Gabon",
    "tz": "Africa/Libreville"
  },
  {
    "iata": "OZC",
    "city": "Ozamis",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "PAH",
    "city": "PADUCAH",
    "region": "Kentucky",
    "tz": "America/Chicago"
  },
  {
    "iata": "PKB",
    "city": "PARKERSBURG",
    "region": "West Virginia",
    "tz": "America/New_York"
  },
  {
    "iata": "PBJ",
    "city": "Paama Island",
    "region": "Vanuatu",
    "tz": "Pacific/Efate"
  },
  {
    "iata": "JFR",
    "city": "Paamiut",
    "region": "Greenland",
    "tz": "America/Godthab"
  },
  {
    "iata": "PCA",
    "city": "Pachuca",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "PDG",
    "city": "Padang",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "PAD",
    "city": "Paderborn",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "PAG",
    "city": "Pagadian",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "PGA",
    "city": "Page",
    "region": "Arizona",
    "tz": "America/Phoenix"
  },
  {
    "iata": "PPG",
    "city": "Pago Pago",
    "region": "American Samoa",
    "tz": "Pacific/Pago_Pago"
  },
  {
    "iata": "PHK",
    "city": "Pahokee",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "PYY",
    "city": "Pai",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "PJA",
    "city": "Pajala",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "PKK",
    "city": "Pakhokku",
    "region": "Burma",
    "tz": "Asia/Rangoon"
  },
  {
    "iata": "PKZ",
    "city": "Pakse",
    "region": "Laos",
    "tz": "Asia/Vientiane"
  },
  {
    "iata": "PAF",
    "city": "Pakuba",
    "region": "Uganda",
    "tz": "Africa/Kampala"
  },
  {
    "iata": "PSX",
    "city": "Palacios",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "PLQ",
    "city": "Palanga",
    "region": "Lithuania",
    "tz": "Europe/Vilnius"
  },
  {
    "iata": "PKY",
    "city": "Palangkaraya",
    "region": "Indonesia",
    "tz": "Asia/Pontianak"
  },
  {
    "iata": "PLM",
    "city": "Palembang",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "PMO",
    "city": "Palermo",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "PMK",
    "city": "Palm Island",
    "region": "Australia",
    "tz": "Australia/Lindeman"
  },
  {
    "iata": "PSP",
    "city": "Palm Springs",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "TRM",
    "city": "Palm Springs",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "UDD",
    "city": "Palm Springs",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "PMI",
    "city": "Palma de Mallorca",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "PMZ",
    "city": "Palmar Sur",
    "region": "Costa Rica",
    "tz": "America/Costa_Rica"
  },
  {
    "iata": "PMW",
    "city": "Palmas",
    "region": "Brazil",
    "tz": "America/Araguaina"
  },
  {
    "iata": "PMD",
    "city": "Palmdale",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "PAQ",
    "city": "Palmer",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "PMR",
    "city": "Palmerston North",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "PMS",
    "city": "Palmyra",
    "region": "Syria",
    "tz": "Asia/Damascus"
  },
  {
    "iata": "PAO",
    "city": "Palo Alto",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "OEM",
    "city": "Paloemeu",
    "region": "Suriname",
    "tz": "America/Paramaribo"
  },
  {
    "iata": "PLW",
    "city": "Palu",
    "region": "Indonesia",
    "tz": "Asia/Makassar"
  },
  {
    "iata": "PNA",
    "city": "Pamplona",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "PAC",
    "city": "Panama",
    "region": "Panama",
    "tz": "America/Panama"
  },
  {
    "iata": "ECP",
    "city": "Panama City",
    "region": "Florida",
    "tz": "America/Chicago"
  },
  {
    "iata": "PAM",
    "city": "Panama City",
    "region": "Florida",
    "tz": "America/Chicago"
  },
  {
    "iata": "PTY",
    "city": "Panama City",
    "region": "Panama",
    "tz": "America/Panama"
  },
  {
    "iata": "PFN",
    "city": "Panama City",
    "region": "United States",
    "tz": "America/Chicago"
  },
  {
    "iata": "PNV",
    "city": "Panevezys",
    "region": "Lithuania",
    "tz": "Europe/Vilnius"
  },
  {
    "iata": "PGK",
    "city": "Pangkal Pinang",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "PKN",
    "city": "Pangkalan Bun",
    "region": "Indonesia",
    "tz": "Asia/Pontianak"
  },
  {
    "iata": "PKG",
    "city": "Pangkor Island",
    "region": "Malaysia",
    "tz": "Asia/Kuala_Lumpur"
  },
  {
    "iata": "YXP",
    "city": "Pangnirtung",
    "region": "Canada",
    "tz": "America/Pangnirtung"
  },
  {
    "iata": "PJG",
    "city": "Panjgur",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "PNL",
    "city": "Pantelleria",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "PPW",
    "city": "Papa Westray",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "PPT",
    "city": "Papeete",
    "region": "French Polynesia",
    "tz": "Pacific/Tahiti"
  },
  {
    "iata": "PFO",
    "city": "Paphos",
    "region": "Cyprus",
    "tz": "Asia/Nicosia"
  },
  {
    "iata": "PBO",
    "city": "Paraburdoo",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "PAJ",
    "city": "Parachinar",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "LSP",
    "city": "Paraguana",
    "region": "Venezuela",
    "tz": "America/Caracas"
  },
  {
    "iata": "ORG",
    "city": "Paramaribo",
    "region": "Suriname",
    "tz": "America/Paramaribo"
  },
  {
    "iata": "PRA",
    "city": "Parana",
    "region": "Argentina",
    "tz": "America/Argentina/Cordoba"
  },
  {
    "iata": "PPQ",
    "city": "Paraparaumu",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "CKS",
    "city": "Parauapebas",
    "region": "Brazil",
    "tz": "America/Araguaina"
  },
  {
    "iata": "SZW",
    "city": "Parchim",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "PED",
    "city": "Pardubice",
    "region": "Czech Republic",
    "tz": "Europe/Prague"
  },
  {
    "iata": "PIN",
    "city": "Parintins",
    "region": "Brazil",
    "tz": "America/Manaus"
  },
  {
    "iata": "CDG",
    "city": "Paris",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "LBG",
    "city": "Paris",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "ORY",
    "city": "Paris",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "PRX",
    "city": "Paris",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "PKE",
    "city": "Parkes",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "PMF",
    "city": "Parma",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "EPU",
    "city": "Parnu",
    "region": "Estonia",
    "tz": "Europe/Tallinn"
  },
  {
    "iata": "PAS",
    "city": "Paros",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "YPD",
    "city": "Parry Sound",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "PFQ",
    "city": "Parsabad",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "PSC",
    "city": "Pasco",
    "region": "Washington",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "IXT",
    "city": "Pasighat",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "PSI",
    "city": "Pasni",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "AOL",
    "city": "Paso De Los Libres",
    "region": "Argentina",
    "tz": "America/Argentina/Cordoba"
  },
  {
    "iata": "PRB",
    "city": "Paso Robles",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "PFB",
    "city": "Passo Fundo",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "PTZ",
    "city": "Pastaza",
    "region": "Ecuador",
    "tz": "America/Guayaquil"
  },
  {
    "iata": "PSO",
    "city": "Pasto",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "BSX",
    "city": "Pathein",
    "region": "Burma",
    "tz": "Asia/Rangoon"
  },
  {
    "iata": "PAT",
    "city": "Patina",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "POJ",
    "city": "Patos de Minas",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "GPA",
    "city": "Patras",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "PFJ",
    "city": "Patreksfjordur",
    "region": "Iceland",
    "tz": "Atlantic/Reykjavik"
  },
  {
    "iata": "PAN",
    "city": "Pattani",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "UTP",
    "city": "Pattaya",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "NHK",
    "city": "Patuxent River",
    "region": "Maryland",
    "tz": "America/New_York"
  },
  {
    "iata": "PUF",
    "city": "Pau",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "YPC",
    "city": "Paulatuk",
    "region": "Canada",
    "tz": "America/Yellowknife"
  },
  {
    "iata": "PAV",
    "city": "Paulo Alfonso",
    "region": "Brazil",
    "tz": "America/Maceio"
  },
  {
    "iata": "PWQ",
    "city": "Pavlodar",
    "region": "Kazakhstan",
    "tz": "Asia/Almaty"
  },
  {
    "iata": "QPG",
    "city": "Paya Lebar",
    "region": "Singapore",
    "tz": "Asia/Singapore"
  },
  {
    "iata": "PDU",
    "city": "Paysandu",
    "region": "Uruguay",
    "tz": "America/Montevideo"
  },
  {
    "iata": "PZA",
    "city": "Paz De Ariporo",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "YPE",
    "city": "Peace River",
    "region": "Canada",
    "tz": "America/Edmonton"
  },
  {
    "iata": "YPO",
    "city": "Peawanuck",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "PEX",
    "city": "Pechora",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "PEQ",
    "city": "Pecos",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "PJC",
    "city": "Pedro Juan Caballero",
    "region": "Paraguay",
    "tz": "America/Asuncion"
  },
  {
    "iata": "PEF",
    "city": "Peenemunde",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "PKU",
    "city": "Pekanbaru",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "PLN",
    "city": "Pellston",
    "region": "Michigan",
    "tz": "America/Detroit"
  },
  {
    "iata": "YBB",
    "city": "Pelly Bay",
    "region": "Canada",
    "tz": "America/Cambridge_Bay"
  },
  {
    "iata": "PET",
    "city": "Pelotas",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "POL",
    "city": "Pemba",
    "region": "Mozambique",
    "tz": "Africa/Maputo"
  },
  {
    "iata": "PMA",
    "city": "Pemba",
    "region": "Tanzania",
    "tz": "Africa/Dar_es_Salaam"
  },
  {
    "iata": "PMB",
    "city": "Pembina",
    "region": "North Dakota",
    "tz": "America/Chicago"
  },
  {
    "iata": "YTA",
    "city": "Pembroke",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "PEN",
    "city": "Penang",
    "region": "Malaysia",
    "tz": "Asia/Kuala_Lumpur"
  },
  {
    "iata": "PNY",
    "city": "Pendicherry",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "PDT",
    "city": "Pendleton",
    "region": "Oregon",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "PEA",
    "city": "Penneshaw",
    "region": "Australia",
    "tz": "Australia/Adelaide"
  },
  {
    "iata": "PYE",
    "city": "Penrhyn Island",
    "region": "Cook Islands",
    "tz": "Pacific/Rarotonga"
  },
  {
    "iata": "NPA",
    "city": "Pensacola",
    "region": "Florida",
    "tz": "America/Chicago"
  },
  {
    "iata": "PNS",
    "city": "Pensacola",
    "region": "Florida",
    "tz": "America/Chicago"
  },
  {
    "iata": "SSR",
    "city": "Pentecost Island",
    "region": "Vanuatu",
    "tz": "Pacific/Efate"
  },
  {
    "iata": "YYF",
    "city": "Penticton",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "PEZ",
    "city": "Penza",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "PIA",
    "city": "Peoria",
    "region": "Illinois",
    "tz": "America/Chicago"
  },
  {
    "iata": "PEI",
    "city": "Pereira",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "PGX",
    "city": "Perigueux",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "PMQ",
    "city": "Perito Moreno",
    "region": "Argentina",
    "tz": "America/Argentina/Rio_Gallegos"
  },
  {
    "iata": "PEE",
    "city": "Perm",
    "region": "Russia",
    "tz": "Asia/Yekaterinburg"
  },
  {
    "iata": "PGF",
    "city": "Perpignan",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "KPV",
    "city": "Perryville",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "JAD",
    "city": "Perth",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "PER",
    "city": "Perth",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "PSL",
    "city": "Perth",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "VYS",
    "city": "Peru",
    "region": "Illinois",
    "tz": "America/Chicago"
  },
  {
    "iata": "GUS",
    "city": "Peru",
    "region": "Indiana",
    "tz": "America/Indiana/Indianapolis"
  },
  {
    "iata": "PEG",
    "city": "Perugia",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "PSR",
    "city": "Pescara",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "PEW",
    "city": "Peshawar",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "YWA",
    "city": "Petawawa",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "YPQ",
    "city": "Peterborough",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "PSG",
    "city": "Petersburg",
    "region": "Alaska",
    "tz": "America/Juneau"
  },
  {
    "iata": "PTB",
    "city": "Petersburg",
    "region": "Virginia",
    "tz": "America/New_York"
  },
  {
    "iata": "PNZ",
    "city": "Petrolina",
    "region": "Brazil",
    "tz": "America/Bahia"
  },
  {
    "iata": "PPK",
    "city": "Petropavlosk",
    "region": "Kazakhstan",
    "tz": "Asia/Almaty"
  },
  {
    "iata": "PKC",
    "city": "Petropavlovsk",
    "region": "Russia",
    "tz": "Asia/Kamchatka"
  },
  {
    "iata": "PES",
    "city": "Petrozavodsk",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "PWE",
    "city": "Pevek",
    "region": "Russia",
    "tz": "Asia/Anadyr"
  },
  {
    "iata": "PHW",
    "city": "Phalaborwa",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "PHA",
    "city": "Phan Rang",
    "region": "Vietnam",
    "tz": "Asia/Ho_Chi_Minh"
  },
  {
    "iata": "PPL",
    "city": "Phaplu",
    "region": "Nepal",
    "tz": "Asia/Kathmandu"
  },
  {
    "iata": "PHY",
    "city": "Phetchabun",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "BBX",
    "city": "Philadelphia",
    "region": "Pennsylvania",
    "tz": "America/New_York"
  },
  {
    "iata": "PHL",
    "city": "Philadelphia",
    "region": "Pennsylvania",
    "tz": "America/New_York"
  },
  {
    "iata": "PHL",
    "city": "Philadelphia",
    "region": "Pennsylvania",
    "tz": "America/New_York"
  },
  {
    "iata": "PNE",
    "city": "Philadelphia",
    "region": "Pennsylvania",
    "tz": "America/New_York"
  },
  {
    "iata": "SXM",
    "city": "Philipsburg",
    "region": "Netherlands Antilles",
    "tz": "America/Lower_Princes"
  },
  {
    "iata": "PZL",
    "city": "Phinda",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "PHS",
    "city": "Phitsanulok",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "PNH",
    "city": "Phnom-penh",
    "region": "Cambodia",
    "tz": "Asia/Phnom_Penh"
  },
  {
    "iata": "DVT",
    "city": "Phoenix",
    "region": "Arizona",
    "tz": "America/Phoenix"
  },
  {
    "iata": "LUF",
    "city": "Phoenix",
    "region": "Arizona",
    "tz": "America/Phoenix"
  },
  {
    "iata": "PHX",
    "city": "Phoenix",
    "region": "Arizona",
    "tz": "America/Phoenix"
  },
  {
    "iata": "XKH",
    "city": "Phon Savan",
    "region": "Laos",
    "tz": "Asia/Vientiane"
  },
  {
    "iata": "PRH",
    "city": "Phrae",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "UIH",
    "city": "Phucat",
    "region": "Vietnam",
    "tz": "Asia/Ho_Chi_Minh"
  },
  {
    "iata": "HKT",
    "city": "Phuket",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "PQC",
    "city": "Phuquoc",
    "region": "Vietnam",
    "tz": "Asia/Ho_Chi_Minh"
  },
  {
    "iata": "YPL",
    "city": "Pickle Lake",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "PIX",
    "city": "Pico",
    "region": "Portugal",
    "tz": "Atlantic/Azores"
  },
  {
    "iata": "PCS",
    "city": "Picos",
    "region": "Brazil",
    "tz": "America/Fortaleza"
  },
  {
    "iata": "PCN",
    "city": "Picton",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "PDS",
    "city": "Piedras Negras",
    "region": "Mexico",
    "tz": "America/Matamoros"
  },
  {
    "iata": "PIR",
    "city": "Pierre",
    "region": "South Dakota",
    "tz": "America/Chicago"
  },
  {
    "iata": "PZY",
    "city": "Piestany",
    "region": "Slovakia",
    "tz": "Europe/Bratislava"
  },
  {
    "iata": "PZB",
    "city": "Pietermaritzburg",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "YPM",
    "city": "Pikangikum",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "PBX",
    "city": "Pikeville",
    "region": "United States",
    "tz": "America/Cuiaba"
  },
  {
    "iata": "PIW",
    "city": "Pikwitonei",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "NTY",
    "city": "Pilanesberg",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "PIP",
    "city": "Pilot Point",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "SNJ",
    "city": "Pinar Del Rio",
    "region": "Cuba",
    "tz": "America/Havana"
  },
  {
    "iata": "WPC",
    "city": "Pincher Creek",
    "region": "Canada",
    "tz": "America/Edmonton"
  },
  {
    "iata": "PBF",
    "city": "Pine Bluff",
    "region": "Arkansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "PIM",
    "city": "Pine Mountain",
    "region": "Georgia",
    "tz": "America/New_York"
  },
  {
    "iata": "PWY",
    "city": "Pinedale",
    "region": "Wyoming",
    "tz": "America/Denver"
  },
  {
    "iata": "SOP",
    "city": "Pinehurst-Southern Pines",
    "region": "North Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "PIF",
    "city": "Pingtung",
    "region": "Taiwan",
    "tz": "Asia/Taipei"
  },
  {
    "iata": "QPS",
    "city": "Piracununga",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "GPN",
    "city": "Pirlangimpi",
    "region": "Australia",
    "tz": "Australia/Darwin"
  },
  {
    "iata": "PSA",
    "city": "Pisa",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "PIO",
    "city": "Pisco",
    "region": "Peru",
    "tz": "America/Lima"
  },
  {
    "iata": "AGC",
    "city": "Pittsburgh",
    "region": "Pennsylvania",
    "tz": "America/New_York"
  },
  {
    "iata": "PIT",
    "city": "Pittsburgh",
    "region": "Pennsylvania",
    "tz": "America/New_York"
  },
  {
    "iata": "PIU",
    "city": "Piura",
    "region": "Peru",
    "tz": "America/Lima"
  },
  {
    "iata": "MRU",
    "city": "Plaisance",
    "region": "Mauritius",
    "tz": "Indian/Mauritius"
  },
  {
    "iata": "PBG",
    "city": "Plattsburgh",
    "region": "New York",
    "tz": "America/New_York"
  },
  {
    "iata": "PXU",
    "city": "Pleiku",
    "region": "Vietnam",
    "tz": "Asia/Ho_Chi_Minh"
  },
  {
    "iata": "PDV",
    "city": "Plovdiv",
    "region": "Bulgaria",
    "tz": "Europe/Sofia"
  },
  {
    "iata": "PYM",
    "city": "Plymouth",
    "region": "Massachusetts",
    "tz": "America/New_York"
  },
  {
    "iata": "PLH",
    "city": "Plymouth",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "PIH",
    "city": "Pocatello",
    "region": "Idaho",
    "tz": "America/Boise"
  },
  {
    "iata": "BCL",
    "city": "Pococi",
    "region": "Costa Rica",
    "tz": "America/Costa_Rica"
  },
  {
    "iata": "POO",
    "city": "Pocos De Caldas",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "TGD",
    "city": "Podgorica",
    "region": "Montenegro",
    "tz": "Europe/Podgorica"
  },
  {
    "iata": "KPO",
    "city": "Pohang",
    "region": "South Korea",
    "tz": "Asia/Seoul"
  },
  {
    "iata": "PNI",
    "city": "Pohnpei",
    "region": "Micronesia",
    "tz": "Pacific/Pohnpei"
  },
  {
    "iata": "STG",
    "city": "Point Barrow",
    "region": "Alaska",
    "tz": "America/Adak"
  },
  {
    "iata": "PIZ",
    "city": "Point Lay",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "NTD",
    "city": "Point Mugu",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "GND",
    "city": "Point Salines",
    "region": "Grenada",
    "tz": "America/Grenada"
  },
  {
    "iata": "PTP",
    "city": "Pointe-a-pitre",
    "region": "Guadeloupe",
    "tz": "America/Guadeloupe"
  },
  {
    "iata": "PNR",
    "city": "Pointe-noire",
    "region": "Congo (Brazzaville)",
    "tz": "Africa/Brazzaville"
  },
  {
    "iata": "YNL",
    "city": "Points North Landing",
    "region": "Canada",
    "tz": "America/Regina"
  },
  {
    "iata": "PIS",
    "city": "Poitiers",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "PKR",
    "city": "Pokhara",
    "region": "Nepal",
    "tz": "Asia/Kathmandu"
  },
  {
    "iata": "HIM",
    "city": "Polonnaruwa Town",
    "region": "Sri Lanka",
    "tz": "Asia/Colombo"
  },
  {
    "iata": "PLV",
    "city": "Poltava",
    "region": "Ukraine",
    "tz": "Europe/Zaporozhye"
  },
  {
    "iata": "PMP",
    "city": "Pompano Beach",
    "region": "United States",
    "tz": "Pacific/Port_Moresby"
  },
  {
    "iata": "PNC",
    "city": "Ponca City",
    "region": "Oklahoma",
    "tz": "America/Chicago"
  },
  {
    "iata": "PSE",
    "city": "Ponce",
    "region": "Puerto Rico",
    "tz": "America/Puerto_Rico"
  },
  {
    "iata": "YIO",
    "city": "Pond Inlet",
    "region": "Canada",
    "tz": "America/Iqaluit"
  },
  {
    "iata": "PDL",
    "city": "Ponta Delgada",
    "region": "Portugal",
    "tz": "Atlantic/Azores"
  },
  {
    "iata": "PMG",
    "city": "Ponta Pora",
    "region": "Brazil",
    "tz": "America/Campo_Grande"
  },
  {
    "iata": "PTK",
    "city": "Pontiac",
    "region": "Michigan",
    "tz": "America/Detroit"
  },
  {
    "iata": "PNK",
    "city": "Pontianak",
    "region": "Indonesia",
    "tz": "Asia/Pontianak"
  },
  {
    "iata": "POX",
    "city": "Pontoise",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "PPN",
    "city": "Popayan",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "YHP",
    "city": "Poplar Hill",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "XPP",
    "city": "Poplar River",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "TAT",
    "city": "Poprad",
    "region": "Slovakia",
    "tz": "Europe/Bratislava"
  },
  {
    "iata": "PBD",
    "city": "Porbandar",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "POR",
    "city": "Pori",
    "region": "Finland",
    "tz": "Europe/Helsinki"
  },
  {
    "iata": "PMV",
    "city": "Porlamar",
    "region": "Venezuela",
    "tz": "America/Caracas"
  },
  {
    "iata": "EDR",
    "city": "Pormpuraaw",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "NOW",
    "city": "Port Angeles",
    "region": "United States",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "CLM",
    "city": "Port Angeles",
    "region": "Washington",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "POT",
    "city": "Port Antonio",
    "region": "Jamaica",
    "tz": "America/Jamaica"
  },
  {
    "iata": "WPB",
    "city": "Port Berg\u00e9",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "IXZ",
    "city": "Port Blair",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "PLZ",
    "city": "Port Elizabeth",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "POG",
    "city": "Port Gentil",
    "region": "Gabon",
    "tz": "Africa/Libreville"
  },
  {
    "iata": "YZT",
    "city": "Port Hardy",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "PHC",
    "city": "Port Hartcourt",
    "region": "Nigeria",
    "tz": "Africa/Lagos"
  },
  {
    "iata": "YPS",
    "city": "Port Hawkesbury",
    "region": "Canada",
    "tz": "America/Halifax"
  },
  {
    "iata": "PHE",
    "city": "Port Hedland",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "PTH",
    "city": "Port Heiden",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "YHA",
    "city": "Port Hope Simpson",
    "region": "Canada",
    "tz": "America/St_Johns"
  },
  {
    "iata": "PHN",
    "city": "Port Huron",
    "region": "Michigan",
    "tz": "America/Detroit"
  },
  {
    "iata": "PLO",
    "city": "Port Lincoln",
    "region": "Australia",
    "tz": "Australia/Adelaide"
  },
  {
    "iata": "PQQ",
    "city": "Port Macquarie",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "YPN",
    "city": "Port Menier",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "PTU",
    "city": "Port Moller",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "POM",
    "city": "Port Moresby",
    "region": "Papua New Guinea",
    "tz": "Pacific/Port_Moresby"
  },
  {
    "iata": "PSD",
    "city": "Port Said",
    "region": "Egypt",
    "tz": "Africa/Cairo"
  },
  {
    "iata": "PZU",
    "city": "Port Sudan",
    "region": "Sudan",
    "tz": "Africa/Khartoum"
  },
  {
    "iata": "PAP",
    "city": "Port-au-prince",
    "region": "Haiti",
    "tz": "America/Port-au-Prince"
  },
  {
    "iata": "PAX",
    "city": "Port-de-Paix",
    "region": "Haiti",
    "tz": "America/Port-au-Prince"
  },
  {
    "iata": "POS",
    "city": "Port-of-spain",
    "region": "Trinidad and Tobago",
    "tz": "America/Port_of_Spain"
  },
  {
    "iata": "VLI",
    "city": "Port-vila",
    "region": "Vanuatu",
    "tz": "Pacific/Efate"
  },
  {
    "iata": "YPG",
    "city": "Portage-la-prairie",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "PTJ",
    "city": "Portland",
    "region": "Australia",
    "tz": "Australia/Melbourne"
  },
  {
    "iata": "PWM",
    "city": "Portland",
    "region": "Maine",
    "tz": "America/New_York"
  },
  {
    "iata": "PDX",
    "city": "Portland",
    "region": "Oregon",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "OPO",
    "city": "Porto",
    "region": "Portugal",
    "tz": "Europe/Lisbon"
  },
  {
    "iata": "POA",
    "city": "Porto Alegre",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "PBN",
    "city": "Porto Amboim",
    "region": "Angola",
    "tz": "Africa/Luanda"
  },
  {
    "iata": "PKH",
    "city": "Porto Heli",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "PNB",
    "city": "Porto Nacional",
    "region": "Brazil",
    "tz": "America/Araguaina"
  },
  {
    "iata": "PXO",
    "city": "Porto Santo",
    "region": "Portugal",
    "tz": "Atlantic/Madeira"
  },
  {
    "iata": "BPS",
    "city": "Porto Seguro",
    "region": "Brazil",
    "tz": "America/Bahia"
  },
  {
    "iata": "PVH",
    "city": "Porto Velho",
    "region": "Brazil",
    "tz": "America/Porto_Velho"
  },
  {
    "iata": "POW",
    "city": "Portoroz",
    "region": "Slovenia",
    "tz": "Europe/Ljubljana"
  },
  {
    "iata": "PVO",
    "city": "Portoviejo",
    "region": "Ecuador",
    "tz": "America/Guayaquil"
  },
  {
    "iata": "PSM",
    "city": "Portsmouth",
    "region": "New Hampshire",
    "tz": "America/New_York"
  },
  {
    "iata": "WPR",
    "city": "Porvenir",
    "region": "Chile",
    "tz": "America/Santiago"
  },
  {
    "iata": "PSS",
    "city": "Posadas",
    "region": "Argentina",
    "tz": "America/Argentina/Cordoba"
  },
  {
    "iata": "PSJ",
    "city": "Poso",
    "region": "Indonesia",
    "tz": "Asia/Makassar"
  },
  {
    "iata": "YSO",
    "city": "Postville",
    "region": "Canada",
    "tz": "America/Goose_Bay"
  },
  {
    "iata": "RKR",
    "city": "Poteau",
    "region": "United States",
    "tz": "America/Chicago"
  },
  {
    "iata": "PTG",
    "city": "Potgietersrus",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "POI",
    "city": "Potosi",
    "region": "Bolivia",
    "tz": "America/La_Paz"
  },
  {
    "iata": "POU",
    "city": "Poughkeepsie",
    "region": "New York",
    "tz": "America/New_York"
  },
  {
    "iata": "PPY",
    "city": "Pouso Alegre",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "YPW",
    "city": "Powell River",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "PAZ",
    "city": "Poza Rico",
    "region": "Mexico",
    "tz": "America/Mexico_City"
  },
  {
    "iata": "POZ",
    "city": "Poznan",
    "region": "Poland",
    "tz": "Europe/Warsaw"
  },
  {
    "iata": "HHQ",
    "city": "Prachuap Khiri Khan",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "PRG",
    "city": "Prague",
    "region": "Czech Republic",
    "tz": "Europe/Prague"
  },
  {
    "iata": "PRI",
    "city": "Praslin",
    "region": "Seychelles",
    "tz": "Indian/Mahe"
  },
  {
    "iata": "PTT",
    "city": "Pratt",
    "region": "Kansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "LOP",
    "city": "Praya",
    "region": "Indonesia",
    "tz": "Asia/Makassar"
  },
  {
    "iata": "PRV",
    "city": "Prerov",
    "region": "Czech Republic",
    "tz": "Europe/Prague"
  },
  {
    "iata": "PRC",
    "city": "Prescott",
    "region": "Arizona",
    "tz": "America/Phoenix"
  },
  {
    "iata": "PPB",
    "city": "President Prudente",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "PQI",
    "city": "Presque Isle",
    "region": "Maine",
    "tz": "America/New_York"
  },
  {
    "iata": "PIK",
    "city": "Prestwick",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "PRY",
    "city": "Pretoria",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "PVK",
    "city": "Preveza",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "PUC",
    "city": "Price",
    "region": "Utah",
    "tz": "America/Denver"
  },
  {
    "iata": "YPA",
    "city": "Prince Albert",
    "region": "Canada",
    "tz": "America/Regina"
  },
  {
    "iata": "YXS",
    "city": "Prince George",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "YPR",
    "city": "Prince Pupert",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "PCP",
    "city": "Principe",
    "region": "Sao Tome and Principe",
    "tz": "Africa/Sao_Tome"
  },
  {
    "iata": "PRN",
    "city": "Pristina",
    "region": "Serbia",
    "tz": "Europe/Belgrade"
  },
  {
    "iata": "PXH",
    "city": "Prominent Hill",
    "region": "Australia",
    "tz": "Australia/Adelaide"
  },
  {
    "iata": "PPC",
    "city": "Prospect Creek",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "PPP",
    "city": "Prosserpine",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "PVD",
    "city": "Providence",
    "region": "Rhode Island",
    "tz": "America/New_York"
  },
  {
    "iata": "PVA",
    "city": "Providencia",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "PLS",
    "city": "Providenciales",
    "region": "Turks and Caicos Islands",
    "tz": "America/Grand_Turk"
  },
  {
    "iata": "PVS",
    "city": "Provideniya Bay",
    "region": "Russia",
    "tz": "Asia/Anadyr"
  },
  {
    "iata": "PVC",
    "city": "Provincetown",
    "region": "Massachusetts",
    "tz": "America/New_York"
  },
  {
    "iata": "PVU",
    "city": "Provo",
    "region": "Utah",
    "tz": "America/Denver"
  },
  {
    "iata": "PKV",
    "city": "Pskov",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "PCL",
    "city": "Pucallpa",
    "region": "Peru",
    "tz": "America/Lima"
  },
  {
    "iata": "ZPC",
    "city": "Pucon",
    "region": "Chile",
    "tz": "America/Santiago"
  },
  {
    "iata": "PBC",
    "city": "Puebla",
    "region": "Mexico",
    "tz": "America/Mexico_City"
  },
  {
    "iata": "PUB",
    "city": "Pueblo",
    "region": "Colorado",
    "tz": "America/Denver"
  },
  {
    "iata": "PUU",
    "city": "Puerto Asis",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "PYH",
    "city": "Puerto Ayacucho",
    "region": "Venezuela",
    "tz": "America/Caracas"
  },
  {
    "iata": "PBR",
    "city": "Puerto Barrios",
    "region": "Guatemala",
    "tz": "America/Guatemala"
  },
  {
    "iata": "PBL",
    "city": "Puerto Cabello",
    "region": "Venezuela",
    "tz": "America/Caracas"
  },
  {
    "iata": "PUZ",
    "city": "Puerto Cabezas",
    "region": "Nicaragua",
    "tz": "America/Managua"
  },
  {
    "iata": "PCR",
    "city": "Puerto Carreno",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "PUD",
    "city": "Puerto Deseado",
    "region": "Argentina",
    "tz": "America/Argentina/Rio_Gallegos"
  },
  {
    "iata": "PXM",
    "city": "Puerto Escondido",
    "region": "Mexico",
    "tz": "America/Mexico_City"
  },
  {
    "iata": "PDA",
    "city": "Puerto In\u00edrida",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "PJM",
    "city": "Puerto Jimenez",
    "region": "Costa Rica",
    "tz": "America/Costa_Rica"
  },
  {
    "iata": "LQM",
    "city": "Puerto Legu\u00edzamo",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "PEU",
    "city": "Puerto Lempira",
    "region": "Honduras",
    "tz": "America/Tegucigalpa"
  },
  {
    "iata": "PMY",
    "city": "Puerto Madryn",
    "region": "Argentina",
    "tz": "America/Argentina/Catamarca"
  },
  {
    "iata": "PEM",
    "city": "Puerto Maldonado",
    "region": "Peru",
    "tz": "America/Lima"
  },
  {
    "iata": "PMC",
    "city": "Puerto Montt",
    "region": "Chile",
    "tz": "America/Santiago"
  },
  {
    "iata": "PNT",
    "city": "Puerto Natales",
    "region": "Chile",
    "tz": "America/Santiago"
  },
  {
    "iata": "PUE",
    "city": "Puerto Obaldia",
    "region": "Panama",
    "tz": "America/Panama"
  },
  {
    "iata": "POP",
    "city": "Puerto Plata",
    "region": "Dominican Republic",
    "tz": "America/Santo_Domingo"
  },
  {
    "iata": "PPS",
    "city": "Puerto Princesa",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "PUR",
    "city": "Puerto Rico/Manuripi",
    "region": "Bolivia",
    "tz": "America/La_Paz"
  },
  {
    "iata": "PSZ",
    "city": "Puerto Suarez",
    "region": "Bolivia",
    "tz": "America/La_Paz"
  },
  {
    "iata": "PVR",
    "city": "Puerto Vallarta",
    "region": "Mexico",
    "tz": "America/Mexico_City"
  },
  {
    "iata": "WPU",
    "city": "Puerto Williams",
    "region": "Chile",
    "tz": "America/Santiago"
  },
  {
    "iata": "PKP",
    "city": "Puka Puka",
    "region": "French Polynesia",
    "tz": "Pacific/Tahiti"
  },
  {
    "iata": "PUK",
    "city": "Pukarua",
    "region": "French Polynesia",
    "tz": "Pacific/Tahiti"
  },
  {
    "iata": "XPK",
    "city": "Pukatawagan",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "PUY",
    "city": "Pula",
    "region": "Croatia",
    "tz": "Europe/Zagreb"
  },
  {
    "iata": "PUW",
    "city": "Pullman",
    "region": "Washington",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "PNQ",
    "city": "Pune",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "PUQ",
    "city": "Punta Arenas",
    "region": "Chile",
    "tz": "America/Santiago"
  },
  {
    "iata": "PUJ",
    "city": "Punta Cana",
    "region": "Dominican Republic",
    "tz": "America/Santo_Domingo"
  },
  {
    "iata": "PGD",
    "city": "Punta Gorda",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "PPE",
    "city": "Punta Penasco",
    "region": "Mexico",
    "tz": "America/Hermosillo"
  },
  {
    "iata": "PDP",
    "city": "Punta del Este",
    "region": "Uruguay",
    "tz": "America/Montevideo"
  },
  {
    "iata": "DRK",
    "city": "Puntarenas",
    "region": "Costa Rica",
    "tz": "America/Costa_Rica"
  },
  {
    "iata": "PBU",
    "city": "Putao",
    "region": "Burma",
    "tz": "Asia/Rangoon"
  },
  {
    "iata": "PUT",
    "city": "Puttaparthi",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "PSU",
    "city": "Putussibau-Borneo Island",
    "region": "Indonesia",
    "tz": "Asia/Pontianak"
  },
  {
    "iata": "YPX",
    "city": "Puvirnituq",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "FNJ",
    "city": "Pyongyang",
    "region": "North Korea",
    "tz": "Asia/Pyongyang"
  },
  {
    "iata": "PEV",
    "city": "P\u00e9cs-Pog\u00e1ny",
    "region": "Hungary",
    "tz": "Europe/Budapest"
  },
  {
    "iata": "NAQ",
    "city": "Qaanaaq",
    "region": "Greenland",
    "tz": "America/Thule"
  },
  {
    "iata": "GBB",
    "city": "Qabala",
    "region": "Azerbaijan",
    "tz": "Asia/Baku"
  },
  {
    "iata": "JJU",
    "city": "Qaqortoq",
    "region": "Greenland",
    "tz": "America/Godthab"
  },
  {
    "iata": "JCH",
    "city": "Qasigiannguit",
    "region": "Greenland",
    "tz": "America/Godthab"
  },
  {
    "iata": "RQW",
    "city": "Qayyarah",
    "region": "Iraq",
    "tz": "Asia/Baghdad"
  },
  {
    "iata": "JGO",
    "city": "Qeqertarsuaq Airport",
    "region": "Greenland",
    "tz": "America/Godthab"
  },
  {
    "iata": "IQM",
    "city": "Qiemo",
    "region": "China",
    "tz": "Asia/Urumqi"
  },
  {
    "iata": "TAO",
    "city": "Qingdao",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "IQN",
    "city": "Qingyang",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "SHP",
    "city": "Qinhuangdao",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "NDG",
    "city": "Qiqihar",
    "region": "China",
    "tz": "Asia/Harbin"
  },
  {
    "iata": "UKT",
    "city": "Quakertown",
    "region": "Pennsylvania",
    "tz": "America/New_York"
  },
  {
    "iata": "NYG",
    "city": "Quantico",
    "region": "United States",
    "tz": "America/New_York"
  },
  {
    "iata": "JJN",
    "city": "Quanzhou",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "YQC",
    "city": "Quaqtaq",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "YQB",
    "city": "Quebec",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "GFL",
    "city": "Queensbury",
    "region": "New York",
    "tz": "America/New_York"
  },
  {
    "iata": "UTW",
    "city": "Queenstown",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "ZQN",
    "city": "Queenstown International",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "UEL",
    "city": "Quelimane",
    "region": "Mozambique",
    "tz": "Africa/Maputo"
  },
  {
    "iata": "XQP",
    "city": "Quepos",
    "region": "Costa Rica",
    "tz": "America/Costa_Rica"
  },
  {
    "iata": "QRO",
    "city": "Queretaro",
    "region": "Mexico",
    "tz": "America/Mexico_City"
  },
  {
    "iata": "YQZ",
    "city": "Quesnel",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "UET",
    "city": "Quetta",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "AAZ",
    "city": "Quezaltenango",
    "region": "Guatemala",
    "tz": "America/Guatemala"
  },
  {
    "iata": "UIB",
    "city": "Quibdo",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "ULP",
    "city": "Quilpie",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "UIP",
    "city": "Quimper",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "UIN",
    "city": "Quincy",
    "region": "Illinois",
    "tz": "America/Chicago"
  },
  {
    "iata": "KWN",
    "city": "Quinhagak",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "UIO",
    "city": "Quito",
    "region": "Ecuador",
    "tz": "America/Guayaquil"
  },
  {
    "iata": "YVP",
    "city": "Quujjuaq",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "JUZ",
    "city": "Quzhou",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "RBA",
    "city": "Rabat",
    "region": "Morocco",
    "tz": "Africa/Casablanca"
  },
  {
    "iata": "VKG",
    "city": "Rach Gia",
    "region": "Vietnam",
    "tz": "Asia/Ho_Chi_Minh"
  },
  {
    "iata": "RAC",
    "city": "Racine",
    "region": "Wisconsin",
    "tz": "America/Chicago"
  },
  {
    "iata": "RAT",
    "city": "Raduzhnyi",
    "region": "Russia",
    "tz": "Asia/Yekaterinburg"
  },
  {
    "iata": "RAH",
    "city": "Rafha",
    "region": "Saudi Arabia",
    "tz": "Asia/Riyadh"
  },
  {
    "iata": "RJN",
    "city": "Rafsanjan",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "RYK",
    "city": "Rahim Yar Khan",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "RFP",
    "city": "Raiatea Island",
    "region": "French Polynesia",
    "tz": "Pacific/Tahiti"
  },
  {
    "iata": "YOP",
    "city": "Rainbow Lake",
    "region": "Canada",
    "tz": "America/Edmonton"
  },
  {
    "iata": "RPR",
    "city": "Raipur",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "RVV",
    "city": "Raivavae",
    "region": "French Polynesia",
    "tz": "Pacific/Tahiti"
  },
  {
    "iata": "RJA",
    "city": "Rajahmundry",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "RAJ",
    "city": "Rajkot",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "RJH",
    "city": "Rajshahi",
    "region": "Bangladesh",
    "tz": "Asia/Dhaka"
  },
  {
    "iata": "RDU",
    "city": "Raleigh-durham",
    "region": "North Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "RBV",
    "city": "Ramata",
    "region": "Solomon Islands",
    "tz": "Pacific/Guadalcanal"
  },
  {
    "iata": "RHP",
    "city": "Ramechhap",
    "region": "Nepal",
    "tz": "Asia/Kathmandu"
  },
  {
    "iata": "RAM",
    "city": "Ramingining",
    "region": "Australia",
    "tz": "Australia/Darwin"
  },
  {
    "iata": "RNM",
    "city": "Ramona",
    "region": "United States",
    "tz": "Asia/Muscat"
  },
  {
    "iata": "RZR",
    "city": "Ramsar",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "RMS",
    "city": "Ramstein",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "NTX",
    "city": "Ranai-Natuna Besar Island",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "IXR",
    "city": "Ranchi",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "RGI",
    "city": "Rangiroa",
    "region": "French Polynesia",
    "tz": "Pacific/Tahiti"
  },
  {
    "iata": "YRT",
    "city": "Rankin Inlet",
    "region": "Canada",
    "tz": "America/Rankin_Inlet"
  },
  {
    "iata": "UNN",
    "city": "Ranong",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "RAP",
    "city": "Rapid City",
    "region": "South Dakota",
    "tz": "America/Denver"
  },
  {
    "iata": "RCA",
    "city": "Rapid City",
    "region": "South Dakota",
    "tz": "America/Denver"
  },
  {
    "iata": "RKT",
    "city": "Ras Al Khaimah",
    "region": "United Arab Emirates",
    "tz": "Asia/Dubai"
  },
  {
    "iata": "RAS",
    "city": "Rasht",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "RBE",
    "city": "Ratanakiri",
    "region": "Cambodia",
    "tz": "Asia/Phnom_Penh"
  },
  {
    "iata": "RVT",
    "city": "Ravensthorpe",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "RAZ",
    "city": "Rawala Kot",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "RWL",
    "city": "Rawlins",
    "region": "Wyoming",
    "tz": "America/Denver"
  },
  {
    "iata": "RDG",
    "city": "Reading",
    "region": "Pennsylvania",
    "tz": "America/New_York"
  },
  {
    "iata": "REA",
    "city": "Reao",
    "region": "French Polynesia",
    "tz": "Pacific/Tahiti"
  },
  {
    "iata": "REC",
    "city": "Recife",
    "region": "Brazil",
    "tz": "America/Recife"
  },
  {
    "iata": "YQF",
    "city": "Red Deer Industrial",
    "region": "Canada",
    "tz": "America/Edmonton"
  },
  {
    "iata": "YRL",
    "city": "Red Lake",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "RDR",
    "city": "Red River",
    "region": "North Dakota",
    "tz": "America/Chicago"
  },
  {
    "iata": "YRS",
    "city": "Red Sucker Lake",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "RDN",
    "city": "Redang",
    "region": "Malaysia",
    "tz": "Asia/Kuala_Lumpur"
  },
  {
    "iata": "RCL",
    "city": "Redcliffe",
    "region": "Vanuatu",
    "tz": "Pacific/Efate"
  },
  {
    "iata": "RDD",
    "city": "Redding",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "RDC",
    "city": "Redencao",
    "region": "Brazil",
    "tz": "America/Santarem"
  },
  {
    "iata": "KRH",
    "city": "Redhill",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "REI",
    "city": "Redlands",
    "region": "United States",
    "tz": "America/Cayenne"
  },
  {
    "iata": "RDM",
    "city": "Redmond-Bend",
    "region": "Oregon",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "HUA",
    "city": "Redstone",
    "region": "Alabama",
    "tz": "America/Chicago"
  },
  {
    "iata": "REG",
    "city": "Reggio Calabria",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "YQR",
    "city": "Regina",
    "region": "Canada",
    "tz": "America/Regina"
  },
  {
    "iata": "RHE",
    "city": "Reims",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "RGT",
    "city": "Rengat",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "RMK",
    "city": "Renmark",
    "region": "Australia",
    "tz": "Australia/Adelaide"
  },
  {
    "iata": "RNL",
    "city": "Rennell Island",
    "region": "Solomon Islands",
    "tz": "Pacific/Guadalcanal"
  },
  {
    "iata": "RNS",
    "city": "Rennes",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "RNO",
    "city": "Reno",
    "region": "Nevada",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "RNT",
    "city": "Renton",
    "region": "Washington",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "YUT",
    "city": "Repulse Bay",
    "region": "Canada",
    "tz": "America/Rankin_Inlet"
  },
  {
    "iata": "RES",
    "city": "Resistencia",
    "region": "Argentina",
    "tz": "America/Argentina/Cordoba"
  },
  {
    "iata": "YRB",
    "city": "Resolute",
    "region": "Canada",
    "tz": "America/Resolute"
  },
  {
    "iata": "REU",
    "city": "Reus",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "YRV",
    "city": "Revelstoke",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "REY",
    "city": "Reyes",
    "region": "Bolivia",
    "tz": "America/La_Paz"
  },
  {
    "iata": "RKV",
    "city": "Reykjavik",
    "region": "Iceland",
    "tz": "Atlantic/Reykjavik"
  },
  {
    "iata": "REX",
    "city": "Reynosa",
    "region": "Mexico",
    "tz": "America/Matamoros"
  },
  {
    "iata": "RHI",
    "city": "Rhinelander",
    "region": "Wisconsin",
    "tz": "America/Chicago"
  },
  {
    "iata": "RHO",
    "city": "Rhodos",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "RAO",
    "city": "Ribeirao Preto",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "RIB",
    "city": "Riberalta",
    "region": "Bolivia",
    "tz": "America/La_Paz"
  },
  {
    "iata": "RCB",
    "city": "Richard's Bay",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "RIF",
    "city": "Richfield",
    "region": "United States",
    "tz": "America/Denver"
  },
  {
    "iata": "RCM",
    "city": "Richmond",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "RID",
    "city": "Richmond",
    "region": "United States",
    "tz": "America/Indiana/Indianapolis"
  },
  {
    "iata": "RIC",
    "city": "Richmond",
    "region": "Virginia",
    "tz": "America/New_York"
  },
  {
    "iata": "CGF",
    "city": "Richmond Heights",
    "region": "Ohio",
    "tz": "America/New_York"
  },
  {
    "iata": "RIL",
    "city": "Rifle",
    "region": "Colorado",
    "tz": "America/Denver"
  },
  {
    "iata": "RIX",
    "city": "Riga",
    "region": "Latvia",
    "tz": "Europe/Riga"
  },
  {
    "iata": "YRG",
    "city": "Rigolet",
    "region": "Canada",
    "tz": "America/Goose_Bay"
  },
  {
    "iata": "RJK",
    "city": "Rijeka",
    "region": "Croatia",
    "tz": "Europe/Zagreb"
  },
  {
    "iata": "RMT",
    "city": "Rimatara",
    "region": "French Polynesia",
    "tz": "Pacific/Tahiti"
  },
  {
    "iata": "RMI",
    "city": "Rimini",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "YXK",
    "city": "Rimouski",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "RDS",
    "city": "Rincon de los Sauces",
    "region": "Argentina",
    "tz": "America/Argentina/Salta"
  },
  {
    "iata": "RIN",
    "city": "Ringi Cove",
    "region": "Solomon Islands",
    "tz": "Pacific/Guadalcanal"
  },
  {
    "iata": "RBR",
    "city": "Rio Branco",
    "region": "Brazil",
    "tz": "America/Rio_Branco"
  },
  {
    "iata": "RCU",
    "city": "Rio Cuarto",
    "region": "Argentina",
    "tz": "America/Argentina/Cordoba"
  },
  {
    "iata": "GIG",
    "city": "Rio De Janeiro",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "SDU",
    "city": "Rio De Janeiro",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "RGL",
    "city": "Rio Gallegos",
    "region": "Argentina",
    "tz": "America/Argentina/Rio_Gallegos"
  },
  {
    "iata": "RGA",
    "city": "Rio Grande",
    "region": "Argentina",
    "tz": "America/Argentina/Ushuaia"
  },
  {
    "iata": "RCH",
    "city": "Rio Hacha",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "RHD",
    "city": "Rio Hondo",
    "region": "Argentina",
    "tz": "America/Argentina/Cordoba"
  },
  {
    "iata": "MDE",
    "city": "Rio Negro",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "RVD",
    "city": "Rio Verde",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "RIJ",
    "city": "Rioja",
    "region": "Peru",
    "tz": "America/Lima"
  },
  {
    "iata": "RIS",
    "city": "Rishiri Island",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "RVY",
    "city": "Rivera",
    "region": "Uruguay",
    "tz": "America/Montevideo"
  },
  {
    "iata": "RAL",
    "city": "Riverside",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "RIV",
    "city": "Riverside",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "RIR",
    "city": "Riverside",
    "region": "United States",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "RIW",
    "city": "Riverton WY",
    "region": "Wyoming",
    "tz": "America/Denver"
  },
  {
    "iata": "YRI",
    "city": "Riviere Du Loup",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "RWN",
    "city": "Rivne",
    "region": "Ukraine",
    "tz": "Europe/Kiev"
  },
  {
    "iata": "RUH",
    "city": "Riyadh",
    "region": "Saudi Arabia",
    "tz": "Asia/Riyadh"
  },
  {
    "iata": "RNE",
    "city": "Roanne",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "ROA",
    "city": "Roanoke VA",
    "region": "Virginia",
    "tz": "America/New_York"
  },
  {
    "iata": "RTB",
    "city": "Roatan",
    "region": "Honduras",
    "tz": "America/Tegucigalpa"
  },
  {
    "iata": "ROD",
    "city": "Robertson",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "YRJ",
    "city": "Roberval",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "RCO",
    "city": "Rochefort",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "RST",
    "city": "Rochester",
    "region": "Minnesota",
    "tz": "America/Chicago"
  },
  {
    "iata": "ROC",
    "city": "Rochester",
    "region": "New York",
    "tz": "America/New_York"
  },
  {
    "iata": "RCS",
    "city": "Rochester",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "DAW",
    "city": "Rochester",
    "region": "United States",
    "tz": "America/New_York"
  },
  {
    "iata": "RKH",
    "city": "Rock Hill",
    "region": "South Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "RSD",
    "city": "Rock Sound",
    "region": "Bahamas",
    "tz": "America/Nassau"
  },
  {
    "iata": "RKS",
    "city": "Rock Springs",
    "region": "Wyoming",
    "tz": "America/Denver"
  },
  {
    "iata": "RFD",
    "city": "Rockford",
    "region": "Illinois",
    "tz": "America/Chicago"
  },
  {
    "iata": "ROK",
    "city": "Rockhampton",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "RKD",
    "city": "Rockland",
    "region": "Maine",
    "tz": "America/New_York"
  },
  {
    "iata": "RKP",
    "city": "Rockport",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "RWI",
    "city": "Rocky Mount",
    "region": "North Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "YRM",
    "city": "Rocky Mountain House",
    "region": "Canada",
    "tz": "America/Edmonton"
  },
  {
    "iata": "RDZ",
    "city": "Rodez",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "RRG",
    "city": "Rodriguez Island",
    "region": "Mauritius",
    "tz": "Indian/Mauritius"
  },
  {
    "iata": "ROI",
    "city": "Roi Et",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "RMA",
    "city": "Roma",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "GES",
    "city": "Romblon",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "TBH",
    "city": "Romblon",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "RMG",
    "city": "Rome",
    "region": "Georgia",
    "tz": "America/New_York"
  },
  {
    "iata": "CIA",
    "city": "Rome",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "FCO",
    "city": "Rome",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "RME",
    "city": "Rome",
    "region": "New York",
    "tz": "America/New_York"
  },
  {
    "iata": "TRS",
    "city": "Ronchi De Legionari",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "ROO",
    "city": "Rondonopolis",
    "region": "Brazil",
    "tz": "America/Cuiaba"
  },
  {
    "iata": "RNN",
    "city": "Ronne",
    "region": "Denmark",
    "tz": "Europe/Copenhagen"
  },
  {
    "iata": "RNB",
    "city": "Ronneby",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "RPB",
    "city": "Roper Bar",
    "region": "Australia",
    "tz": "Australia/Darwin"
  },
  {
    "iata": "RRS",
    "city": "Roros",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "ROS",
    "city": "Rosario",
    "region": "Argentina",
    "tz": "America/Argentina/Cordoba"
  },
  {
    "iata": "STJ",
    "city": "Rosecrans",
    "region": "Missouri",
    "tz": "America/Chicago"
  },
  {
    "iata": "RPN",
    "city": "Rosh Pina",
    "region": "Israel",
    "tz": "Asia/Jerusalem"
  },
  {
    "iata": "RFS",
    "city": "Rosita",
    "region": "Nicaragua",
    "tz": "America/Managua"
  },
  {
    "iata": "ROV",
    "city": "Rostov",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "ROW",
    "city": "Roswell",
    "region": "New Mexico",
    "tz": "America/Denver"
  },
  {
    "iata": "ROP",
    "city": "Rota",
    "region": "Northern Mariana Islands",
    "tz": "Pacific/Saipan"
  },
  {
    "iata": "ROT",
    "city": "Rotorua",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "RTM",
    "city": "Rotterdam",
    "region": "Netherlands",
    "tz": "Europe/Amsterdam"
  },
  {
    "iata": "RTS",
    "city": "Rottnest Island",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "RTA",
    "city": "Rotuma",
    "region": "Fiji",
    "tz": "Pacific/Fiji"
  },
  {
    "iata": "URO",
    "city": "Rouen",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "ZRJ",
    "city": "Round Lake",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "RRK",
    "city": "Rourkela",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "YUY",
    "city": "Rouyn",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "RVN",
    "city": "Rovaniemi",
    "region": "Finland",
    "tz": "Europe/Helsinki"
  },
  {
    "iata": "TTQ",
    "city": "Roxana",
    "region": "Costa Rica",
    "tz": "America/Costa_Rica"
  },
  {
    "iata": "RXS",
    "city": "Roxas City",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "TDF",
    "city": "Roxboro",
    "region": "United States",
    "tz": "America/New_York"
  },
  {
    "iata": "RYN",
    "city": "Royan",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "RUV",
    "city": "Rubelsanto",
    "region": "Guatemala",
    "tz": "America/Guatemala"
  },
  {
    "iata": "RBY",
    "city": "Ruby",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "GTI",
    "city": "Ruegen",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "RUG",
    "city": "Rugao",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "SRR",
    "city": "Ruidoso",
    "region": "United States",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "RUK",
    "city": "Rukumkot",
    "region": "Nepal",
    "tz": "Asia/Kathmandu"
  },
  {
    "iata": "RBX",
    "city": "Rumbek",
    "region": "Sudan",
    "tz": "Africa/Juba"
  },
  {
    "iata": "RUM",
    "city": "Rumjatar",
    "region": "Nepal",
    "tz": "Asia/Kathmandu"
  },
  {
    "iata": "NDU",
    "city": "Rundu",
    "region": "Namibia",
    "tz": "Africa/Windhoek"
  },
  {
    "iata": "RUR",
    "city": "Rurutu",
    "region": "French Polynesia",
    "tz": "Pacific/Tahiti"
  },
  {
    "iata": "RSL",
    "city": "Russell",
    "region": "Kansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "RSH",
    "city": "Russian Mission",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "RTG",
    "city": "Ruteng",
    "region": "Indonesia",
    "tz": "Asia/Makassar"
  },
  {
    "iata": "RUT",
    "city": "Rutland",
    "region": "Vermont",
    "tz": "America/New_York"
  },
  {
    "iata": "RYB",
    "city": "Rybinsk",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "RYG",
    "city": "Rygge",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "RZE",
    "city": "Rzeszow",
    "region": "Poland",
    "tz": "Europe/Warsaw"
  },
  {
    "iata": "RET",
    "city": "R\u00f8st",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "SAA",
    "city": "SARATOGA",
    "region": "Wyoming",
    "tz": "America/Denver"
  },
  {
    "iata": "ISC",
    "city": "ST MARY\\\\'S",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "SCN",
    "city": "Saarbruecken",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "SAB",
    "city": "Saba",
    "region": "Netherlands Antilles",
    "tz": "America/Kralendijk"
  },
  {
    "iata": "QSA",
    "city": "Sabadell",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "SBG",
    "city": "Sabang",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "AFZ",
    "city": "Sabzevar",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "HIN",
    "city": "Sacheon",
    "region": "South Korea",
    "tz": "Asia/Seoul"
  },
  {
    "iata": "ZPB",
    "city": "Sachigo Lake",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "YSY",
    "city": "Sachs Harbour",
    "region": "Canada",
    "tz": "America/Yellowknife"
  },
  {
    "iata": "MCC",
    "city": "Sacramento",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "MHR",
    "city": "Sacramento",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "SAC",
    "city": "Sacramento",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "SMF",
    "city": "Sacramento",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "SDS",
    "city": "Sado",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "SAD",
    "city": "Safford",
    "region": "Arizona",
    "tz": "America/Phoenix"
  },
  {
    "iata": "HSG",
    "city": "Saga",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "MBS",
    "city": "Saginaw",
    "region": "Michigan",
    "tz": "America/Detroit"
  },
  {
    "iata": "SBR",
    "city": "Saibai Island",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "SPD",
    "city": "Saidpur",
    "region": "Bangladesh",
    "tz": "Asia/Dhaka"
  },
  {
    "iata": "SDT",
    "city": "Saidu Sharif",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "YCM",
    "city": "Saint Catherines",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "STC",
    "city": "Saint Cloud",
    "region": "Minnesota",
    "tz": "America/Chicago"
  },
  {
    "iata": "SGU",
    "city": "Saint George",
    "region": "Utah",
    "tz": "America/Denver"
  },
  {
    "iata": "LDX",
    "city": "Saint-Laurent-du-Maroni",
    "region": "French Guiana",
    "tz": "America/Cayenne"
  },
  {
    "iata": "SMS",
    "city": "Sainte Marie",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "SPN",
    "city": "Saipan",
    "region": "Northern Mariana Islands",
    "tz": "Pacific/Saipan"
  },
  {
    "iata": "SNO",
    "city": "Sakon Nakhon",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "SLL",
    "city": "Salalah",
    "region": "Oman",
    "tz": "Asia/Muscat"
  },
  {
    "iata": "SLM",
    "city": "Salamanca",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "SXE",
    "city": "Sale",
    "region": "Australia",
    "tz": "Australia/Melbourne"
  },
  {
    "iata": "SLY",
    "city": "Salekhard",
    "region": "Russia",
    "tz": "Asia/Yekaterinburg"
  },
  {
    "iata": "SLE",
    "city": "Salem",
    "region": "Oregon",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "QSR",
    "city": "Salerno",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "SLN",
    "city": "Salina",
    "region": "Kansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "SNS",
    "city": "Salinas",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "SNC",
    "city": "Salinas",
    "region": "Ecuador",
    "tz": "America/Guayaquil"
  },
  {
    "iata": "SBY",
    "city": "Salisbury",
    "region": "Maryland",
    "tz": "America/New_York"
  },
  {
    "iata": "YZG",
    "city": "Salluit",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "SMN",
    "city": "Salmon",
    "region": "Idaho",
    "tz": "America/Boise"
  },
  {
    "iata": "YSN",
    "city": "Salmon Arm",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "SLX",
    "city": "Salt Cay",
    "region": "Turks and Caicos Islands",
    "tz": "America/Grand_Turk"
  },
  {
    "iata": "SLC",
    "city": "Salt Lake City",
    "region": "Utah",
    "tz": "America/Denver"
  },
  {
    "iata": "SLA",
    "city": "Salta",
    "region": "Argentina",
    "tz": "America/Argentina/Salta"
  },
  {
    "iata": "SLW",
    "city": "Saltillo",
    "region": "Mexico",
    "tz": "America/Monterrey"
  },
  {
    "iata": "STY",
    "city": "Salto",
    "region": "Uruguay",
    "tz": "America/Montevideo"
  },
  {
    "iata": "BZY",
    "city": "Saltsy",
    "region": "Moldova",
    "tz": "Europe/Chisinau"
  },
  {
    "iata": "SSA",
    "city": "Salvador",
    "region": "Brazil",
    "tz": "America/Bahia"
  },
  {
    "iata": "SZG",
    "city": "Salzburg",
    "region": "Austria",
    "tz": "Europe/Vienna"
  },
  {
    "iata": "AZS",
    "city": "Samana",
    "region": "Dominican Republic",
    "tz": "America/Santo_Domingo"
  },
  {
    "iata": "EPS",
    "city": "Samana",
    "region": "Dominican Republic",
    "tz": "America/Santo_Domingo"
  },
  {
    "iata": "KUF",
    "city": "Samara",
    "region": "Russia",
    "tz": "Europe/Samara"
  },
  {
    "iata": "SRI",
    "city": "Samarinda",
    "region": "Indonesia",
    "tz": "Asia/Makassar"
  },
  {
    "iata": "SKD",
    "city": "Samarkand",
    "region": "Uzbekistan",
    "tz": "Asia/Samarkand"
  },
  {
    "iata": "SVB",
    "city": "Sambava",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "UAS",
    "city": "Samburu South",
    "region": "Kenya",
    "tz": "Africa/Nairobi"
  },
  {
    "iata": "SMV",
    "city": "Samedan",
    "region": "Switzerland",
    "tz": "Europe/Zurich"
  },
  {
    "iata": "SMI",
    "city": "Samos",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "SMQ",
    "city": "Sampit-Borneo Island",
    "region": "Indonesia",
    "tz": "Asia/Pontianak"
  },
  {
    "iata": "SZF",
    "city": "Samsun",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "ADZ",
    "city": "San Andres Island",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "SAQ",
    "city": "San Andros",
    "region": "Bahamas",
    "tz": "America/Nassau"
  },
  {
    "iata": "SJT",
    "city": "San Angelo",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "RND",
    "city": "San Antonio",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "SAT",
    "city": "San Antonio",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "SKF",
    "city": "San Antonio",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "SVZ",
    "city": "San Antonio",
    "region": "Venezuela",
    "tz": "America/Caracas"
  },
  {
    "iata": "OES",
    "city": "San Antonio Oeste",
    "region": "Argentina",
    "tz": "America/Argentina/Salta"
  },
  {
    "iata": "SBD",
    "city": "San Bernardino",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "SRJ",
    "city": "San Borja",
    "region": "Bolivia",
    "tz": "America/La_Paz"
  },
  {
    "iata": "SQL",
    "city": "San Carlos",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "BRC",
    "city": "San Carlos De Bariloche",
    "region": "Argentina",
    "tz": "America/Argentina/Salta"
  },
  {
    "iata": "SZT",
    "city": "San Cristobal de las Casas",
    "region": "Mexico",
    "tz": "America/Mexico_City"
  },
  {
    "iata": "SCY",
    "city": "San Crist\u00f3bal",
    "region": "Ecuador",
    "tz": "Pacific/Galapagos"
  },
  {
    "iata": "NZY",
    "city": "San Diego",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "SAN",
    "city": "San Diego",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "SDM",
    "city": "San Diego",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "SFE",
    "city": "San Fernando",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "SFD",
    "city": "San Fernando De Apure",
    "region": "Venezuela",
    "tz": "America/Caracas"
  },
  {
    "iata": "SFH",
    "city": "San Filipe",
    "region": "Mexico",
    "tz": "America/Tijuana"
  },
  {
    "iata": "SFO",
    "city": "San Francisco",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "RHV",
    "city": "San Jose",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "SJC",
    "city": "San Jose",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "SJO",
    "city": "San Jose",
    "region": "Costa Rica",
    "tz": "America/Costa_Rica"
  },
  {
    "iata": "SYQ",
    "city": "San Jose",
    "region": "Costa Rica",
    "tz": "America/Costa_Rica"
  },
  {
    "iata": "SJI",
    "city": "San Jose",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "SJD",
    "city": "San Jose Del Cabo",
    "region": "Mexico",
    "tz": "America/Mazatlan"
  },
  {
    "iata": "SJE",
    "city": "San Jose Del Guaviare",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "SIG",
    "city": "San Juan",
    "region": "Puerto Rico",
    "tz": "America/Puerto_Rico"
  },
  {
    "iata": "SJU",
    "city": "San Juan",
    "region": "Puerto Rico",
    "tz": "America/Puerto_Rico"
  },
  {
    "iata": "UAQ",
    "city": "San Julian",
    "region": "Argentina",
    "tz": "America/Argentina/San_Juan"
  },
  {
    "iata": "ULA",
    "city": "San Julian",
    "region": "Argentina",
    "tz": "America/Argentina/Rio_Gallegos"
  },
  {
    "iata": "LUQ",
    "city": "San Luis",
    "region": "Argentina",
    "tz": "America/Argentina/San_Luis"
  },
  {
    "iata": "SBP",
    "city": "San Luis Obispo",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "SLP",
    "city": "San Luis Potosi",
    "region": "Mexico",
    "tz": "America/Mexico_City"
  },
  {
    "iata": "CPC",
    "city": "San Martin Des Andes",
    "region": "Argentina",
    "tz": "America/Argentina/Salta"
  },
  {
    "iata": "QSN",
    "city": "San Nicol\u00e1s",
    "region": "Cuba",
    "tz": "America/Havana"
  },
  {
    "iata": "SPY",
    "city": "San Pedro",
    "region": "Cote d'Ivoire",
    "tz": "Africa/Abidjan"
  },
  {
    "iata": "SAP",
    "city": "San Pedro Sula",
    "region": "Honduras",
    "tz": "America/Tegucigalpa"
  },
  {
    "iata": "AFA",
    "city": "San Rafael",
    "region": "Argentina",
    "tz": "America/Argentina/Mendoza"
  },
  {
    "iata": "SAL",
    "city": "San Salvador",
    "region": "El Salvador",
    "tz": "America/El_Salvador"
  },
  {
    "iata": "EAS",
    "city": "San Sebastian",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "SOM",
    "city": "San Tome",
    "region": "Venezuela",
    "tz": "America/Caracas"
  },
  {
    "iata": "SVI",
    "city": "San Vincente De Caguan",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "SAH",
    "city": "Sanaa",
    "region": "Yemen",
    "tz": "Asia/Aden"
  },
  {
    "iata": "SDG",
    "city": "Sanandaj",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "SDP",
    "city": "Sand Point",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "SDK",
    "city": "Sandakan",
    "region": "Malaysia",
    "tz": "Asia/Kuching"
  },
  {
    "iata": "SDN",
    "city": "Sandane",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "NDY",
    "city": "Sanday",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "SSJ",
    "city": "Sandnessjoen",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "YZP",
    "city": "Sandspit",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "SKY",
    "city": "Sandusky",
    "region": "Ohio",
    "tz": "America/New_York"
  },
  {
    "iata": "ZSJ",
    "city": "Sandy Lake",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "SFB",
    "city": "Sanford",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "SGS",
    "city": "Sanga Sanga",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "EAE",
    "city": "Sangafa",
    "region": "Vanuatu",
    "tz": "Pacific/Efate"
  },
  {
    "iata": "SGQ",
    "city": "Sanggata",
    "region": "Indonesia",
    "tz": "Asia/Makassar"
  },
  {
    "iata": "SUG",
    "city": "Sangley Point",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "YSK",
    "city": "Sanikiluaq",
    "region": "Canada",
    "tz": "America/Iqaluit"
  },
  {
    "iata": "PSH",
    "city": "Sankt Peter-Ording",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "GNY",
    "city": "Sanliurfa",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "SFQ",
    "city": "Sanliurfa",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "SNA",
    "city": "Santa Ana",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "NNB",
    "city": "Santa Ana",
    "region": "Solomon Islands",
    "tz": "Pacific/Guadalcanal"
  },
  {
    "iata": "SNV",
    "city": "Santa Ana De Uairen",
    "region": "Venezuela",
    "tz": "America/Caracas"
  },
  {
    "iata": "SBA",
    "city": "Santa Barbara",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "SBO",
    "city": "Santa Barbara",
    "region": "Utah",
    "tz": "America/Denver"
  },
  {
    "iata": "STB",
    "city": "Santa Barbara",
    "region": "Venezuela",
    "tz": "America/Caracas"
  },
  {
    "iata": "SNU",
    "city": "Santa Clara",
    "region": "Cuba",
    "tz": "America/Havana"
  },
  {
    "iata": "RZA",
    "city": "Santa Cruz",
    "region": "Argentina",
    "tz": "America/Argentina/Rio_Gallegos"
  },
  {
    "iata": "SRZ",
    "city": "Santa Cruz",
    "region": "Bolivia",
    "tz": "America/La_Paz"
  },
  {
    "iata": "VVI",
    "city": "Santa Cruz",
    "region": "Bolivia",
    "tz": "America/La_Paz"
  },
  {
    "iata": "SPC",
    "city": "Santa Cruz De La Palma",
    "region": "Spain",
    "tz": "Atlantic/Canary"
  },
  {
    "iata": "AQB",
    "city": "Santa Cruz des Quiche",
    "region": "Guatemala",
    "tz": "America/Guatemala"
  },
  {
    "iata": "SCZ",
    "city": "Santa Cruz/Graciosa Bay/Luova",
    "region": "Solomon Islands",
    "tz": "Pacific/Guadalcanal"
  },
  {
    "iata": "SFN",
    "city": "Santa Fe",
    "region": "Argentina",
    "tz": "America/Argentina/Cordoba"
  },
  {
    "iata": "SAF",
    "city": "Santa Fe",
    "region": "New Mexico",
    "tz": "America/Denver"
  },
  {
    "iata": "IRZ",
    "city": "Santa Isabel do Rio Negro",
    "region": "Brazil",
    "tz": "America/Manaus"
  },
  {
    "iata": "RIA",
    "city": "Santa Maria",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "SMX",
    "city": "Santa Maria",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "SMA",
    "city": "Santa Maria (island)",
    "region": "Portugal",
    "tz": "Atlantic/Azores"
  },
  {
    "iata": "SMR",
    "city": "Santa Marta",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "SMO",
    "city": "Santa Monica",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "RSA",
    "city": "Santa Rosa",
    "region": "Argentina",
    "tz": "America/Argentina/Salta"
  },
  {
    "iata": "SRA",
    "city": "Santa Rosa",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "STS",
    "city": "Santa Rosa",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "ETR",
    "city": "Santa Rosa",
    "region": "Ecuador",
    "tz": "America/Guayaquil"
  },
  {
    "iata": "SST",
    "city": "Santa Teresita",
    "region": "Argentina",
    "tz": "America/Argentina/Buenos_Aires"
  },
  {
    "iata": "STZ",
    "city": "Santa Terezinha",
    "region": "Brazil",
    "tz": "America/Cuiaba"
  },
  {
    "iata": "CMP",
    "city": "Santana do Araguaia",
    "region": "Brazil",
    "tz": "America/Santarem"
  },
  {
    "iata": "SDR",
    "city": "Santander",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "STM",
    "city": "Santarem",
    "region": "Brazil",
    "tz": "America/Manaus"
  },
  {
    "iata": "SCL",
    "city": "Santiago",
    "region": "Chile",
    "tz": "America/Santiago"
  },
  {
    "iata": "STI",
    "city": "Santiago",
    "region": "Dominican Republic",
    "tz": "America/Santo_Domingo"
  },
  {
    "iata": "SCQ",
    "city": "Santiago",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "SCU",
    "city": "Santiago De Cuba",
    "region": "Cuba",
    "tz": "America/Havana"
  },
  {
    "iata": "SDE",
    "city": "Santiago Del Estero",
    "region": "Argentina",
    "tz": "America/Argentina/Cordoba"
  },
  {
    "iata": "SON",
    "city": "Santo",
    "region": "Vanuatu",
    "tz": "Pacific/Efate"
  },
  {
    "iata": "GEL",
    "city": "Santo Angelo",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "HEX",
    "city": "Santo Domingo",
    "region": "Dominican Republic",
    "tz": "America/Santo_Domingo"
  },
  {
    "iata": "SDQ",
    "city": "Santo Domingo",
    "region": "Dominican Republic",
    "tz": "America/Santo_Domingo"
  },
  {
    "iata": "STD",
    "city": "Santo Domingo",
    "region": "Venezuela",
    "tz": "America/Caracas"
  },
  {
    "iata": "SSZ",
    "city": "Santos",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "SYX",
    "city": "Sanya",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "QSC",
    "city": "Sao Carlos",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "SXO",
    "city": "Sao Felix do Araguaia",
    "region": "Brazil",
    "tz": "America/Cuiaba"
  },
  {
    "iata": "SXX",
    "city": "Sao Felix do Xingu",
    "region": "Brazil",
    "tz": "America/Santarem"
  },
  {
    "iata": "SJL",
    "city": "Sao Gabriel",
    "region": "Brazil",
    "tz": "America/Boa_Vista"
  },
  {
    "iata": "SJZ",
    "city": "Sao Jorge Island",
    "region": "Portugal",
    "tz": "Atlantic/Azores"
  },
  {
    "iata": "SJP",
    "city": "Sao Jose Do Rio Preto",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "SJK",
    "city": "Sao Jose Dos Campos",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "SLZ",
    "city": "Sao Luis",
    "region": "Brazil",
    "tz": "America/Belem"
  },
  {
    "iata": "SQX",
    "city": "Sao Miguel do Oeste",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "SNE",
    "city": "Sao Nocolau Island",
    "region": "Cape Verde",
    "tz": "Atlantic/Cape_Verde"
  },
  {
    "iata": "CGH",
    "city": "Sao Paulo",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "GRU",
    "city": "Sao Paulo",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "OLC",
    "city": "Sao Paulo de Olivenca",
    "region": "Brazil",
    "tz": "America/Manaus"
  },
  {
    "iata": "TMS",
    "city": "Sao Tome",
    "region": "Sao Tome and Principe",
    "tz": "Africa/Sao_Tome"
  },
  {
    "iata": "VXE",
    "city": "Sao Vicente Island",
    "region": "Cape Verde",
    "tz": "Atlantic/Cape_Verde"
  },
  {
    "iata": "CTS",
    "city": "Sapporo",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "OKD",
    "city": "Sapporo",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "SJJ",
    "city": "Sarajevo",
    "region": "Bosnia and Herzegovina",
    "tz": "Europe/Sarajevo"
  },
  {
    "iata": "SLK",
    "city": "Saranac Lake",
    "region": "New York",
    "tz": "America/New_York"
  },
  {
    "iata": "SKX",
    "city": "Saransk",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "SRQ",
    "city": "Sarasota",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "RTW",
    "city": "Saratov",
    "region": "Russia",
    "tz": "Europe/Volgograd"
  },
  {
    "iata": "VNA",
    "city": "Saravane",
    "region": "Laos",
    "tz": "Asia/Vientiane"
  },
  {
    "iata": "RVE",
    "city": "Saravena",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "SGI",
    "city": "Sargodha",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "SRH",
    "city": "Sarh",
    "region": "Chad",
    "tz": "Africa/Ndjamena"
  },
  {
    "iata": "YZR",
    "city": "Sarnia",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "YXE",
    "city": "Saskatoon",
    "region": "Canada",
    "tz": "America/Regina"
  },
  {
    "iata": "TNI",
    "city": "Satna",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "SUJ",
    "city": "Satu Mare",
    "region": "Romania",
    "tz": "Europe/Bucharest"
  },
  {
    "iata": "SAK",
    "city": "Saudarkrokur",
    "region": "Iceland",
    "tz": "Atlantic/Reykjavik"
  },
  {
    "iata": "XAU",
    "city": "Saul",
    "region": "French Guiana",
    "tz": "America/Cayenne"
  },
  {
    "iata": "YAM",
    "city": "Sault Sainte Marie",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "CIU",
    "city": "Sault Ste Marie",
    "region": "Michigan",
    "tz": "America/Detroit"
  },
  {
    "iata": "SXK",
    "city": "Saumlaki",
    "region": "Indonesia",
    "tz": "Asia/Jayapura"
  },
  {
    "iata": "VHC",
    "city": "Saurimo",
    "region": "Angola",
    "tz": "Africa/Luanda"
  },
  {
    "iata": "MXS",
    "city": "Savaii Island",
    "region": "Samoa",
    "tz": "Pacific/Apia"
  },
  {
    "iata": "SAV",
    "city": "Savannah",
    "region": "Georgia",
    "tz": "America/New_York"
  },
  {
    "iata": "ZVK",
    "city": "Savannakhet",
    "region": "Laos",
    "tz": "Asia/Vientiane"
  },
  {
    "iata": "SVL",
    "city": "Savonlinna",
    "region": "Finland",
    "tz": "Europe/Helsinki"
  },
  {
    "iata": "SVA",
    "city": "Savoonga",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "SVU",
    "city": "Savusavu",
    "region": "Fiji",
    "tz": "Pacific/Fiji"
  },
  {
    "iata": "GXF",
    "city": "Sayun Intl",
    "region": "Yemen",
    "tz": "Asia/Aden"
  },
  {
    "iata": "LKS",
    "city": "Sazena",
    "region": "Czech Republic",
    "tz": "America/Chicago"
  },
  {
    "iata": "SCM",
    "city": "Scammon Bay",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "TAB",
    "city": "Scarborough",
    "region": "Trinidad and Tobago",
    "tz": "America/Port_of_Spain"
  },
  {
    "iata": "SCS",
    "city": "Scatsta",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "YKL",
    "city": "Schefferville",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "NSO",
    "city": "Scone",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "SCH",
    "city": "Scotia NY",
    "region": "New York",
    "tz": "America/New_York"
  },
  {
    "iata": "BFF",
    "city": "Scottsbluff",
    "region": "Nebraska",
    "tz": "America/Denver"
  },
  {
    "iata": "AVP",
    "city": "Scranton",
    "region": "Pennsylvania",
    "tz": "America/New_York"
  },
  {
    "iata": "BFI",
    "city": "Seattle",
    "region": "Washington",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "SEA",
    "city": "Seattle",
    "region": "Washington",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "SEB",
    "city": "Sebha",
    "region": "Libya",
    "tz": "Africa/Tripoli"
  },
  {
    "iata": "SEF",
    "city": "Sebring",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "SDX",
    "city": "Sedona",
    "region": "Arizona",
    "tz": "America/Phoenix"
  },
  {
    "iata": "EGM",
    "city": "Sege",
    "region": "Solomon Islands",
    "tz": "Pacific/Guadalcanal"
  },
  {
    "iata": "SYW",
    "city": "Sehwan Sharif",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "SJY",
    "city": "Sein\u00e4joki / Ilmajoki",
    "region": "Finland",
    "tz": "Europe/Helsinki"
  },
  {
    "iata": "WLK",
    "city": "Selawik",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "PKW",
    "city": "Selebi-phikwe",
    "region": "Botswana",
    "tz": "Africa/Gaborone"
  },
  {
    "iata": "SEY",
    "city": "Selibabi",
    "region": "Mauritania",
    "tz": "Africa/Nouakchott"
  },
  {
    "iata": "SEM",
    "city": "Selma",
    "region": "Alabama",
    "tz": "America/Chicago"
  },
  {
    "iata": "SRG",
    "city": "Semarang",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "PLX",
    "city": "Semiplatinsk",
    "region": "Kazakhstan",
    "tz": "Asia/Almaty"
  },
  {
    "iata": "SDJ",
    "city": "Sendai",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "SEH",
    "city": "Senggeh-Papua Island",
    "region": "Indonesia",
    "tz": "Asia/Jayapura"
  },
  {
    "iata": "LEU",
    "city": "Seo De Urgel",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "GMP",
    "city": "Seoul",
    "region": "South Korea",
    "tz": "Asia/Seoul"
  },
  {
    "iata": "ICN",
    "city": "Seoul",
    "region": "South Korea",
    "tz": "Asia/Seoul"
  },
  {
    "iata": "SSN",
    "city": "Seoul East",
    "region": "South Korea",
    "tz": "Asia/Seoul"
  },
  {
    "iata": "YZV",
    "city": "Sept-iles",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "SEU",
    "city": "Seronera",
    "region": "Tanzania",
    "tz": "Africa/Dar_es_Salaam"
  },
  {
    "iata": "QSF",
    "city": "Setif",
    "region": "Algeria",
    "tz": "Africa/Algiers"
  },
  {
    "iata": "UKS",
    "city": "Sevastopol",
    "region": "Ukraine",
    "tz": "Europe/Simferopol"
  },
  {
    "iata": "OZP",
    "city": "Sevilla",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "SVQ",
    "city": "Sevilla",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "SWD",
    "city": "Seward",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "SFA",
    "city": "Sfax",
    "region": "Tunisia",
    "tz": "Africa/Tunis"
  },
  {
    "iata": "SHX",
    "city": "Shageluk",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "CQD",
    "city": "Shahre Kord",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "SWX",
    "city": "Shakawe",
    "region": "Botswana",
    "tz": "Africa/Gaborone"
  },
  {
    "iata": "SKK",
    "city": "Shaktoolik",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "ZTM",
    "city": "Shamattawa",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "PVG",
    "city": "Shanghai",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "SHA",
    "city": "Shanghai",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "DIG",
    "city": "Shangri-La",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "SNN",
    "city": "Shannon",
    "region": "Ireland",
    "tz": "Europe/Dublin"
  },
  {
    "iata": "SXJ",
    "city": "Shanshan",
    "region": "China",
    "tz": "Asia/Urumqi"
  },
  {
    "iata": "SWA",
    "city": "Shantou",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "SHJ",
    "city": "Sharjah",
    "region": "United Arab Emirates",
    "tz": "Asia/Dubai"
  },
  {
    "iata": "MJK",
    "city": "Shark Bay",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "SSH",
    "city": "Sharm El Sheikh",
    "region": "Egypt",
    "tz": "Africa/Cairo"
  },
  {
    "iata": "GSQ",
    "city": "Sharq Al-Owainat",
    "region": "Egypt",
    "tz": "Africa/Cairo"
  },
  {
    "iata": "SHW",
    "city": "Sharurah",
    "region": "Saudi Arabia",
    "tz": "Asia/Riyadh"
  },
  {
    "iata": "CKL",
    "city": "Shchyolkovo",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "SBM",
    "city": "Sheboygan",
    "region": "Wisconsin",
    "tz": "America/Chicago"
  },
  {
    "iata": "SHN",
    "city": "Shelton",
    "region": "Washington",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "HPG",
    "city": "Shennongjia",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "SHE",
    "city": "Shenyang",
    "region": "China",
    "tz": "Asia/Harbin"
  },
  {
    "iata": "SZX",
    "city": "Shenzhen",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "SHT",
    "city": "Shepparton",
    "region": "Australia",
    "tz": "Australia/Melbourne"
  },
  {
    "iata": "YSC",
    "city": "Sherbrooke",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "SHR",
    "city": "Sheridan",
    "region": "Wyoming",
    "tz": "America/Denver"
  },
  {
    "iata": "SJW",
    "city": "Shijiazhuang",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "SHL",
    "city": "Shillong",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "SLV",
    "city": "Shimla",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "SHI",
    "city": "Shimojishima",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "SHY",
    "city": "Shinyanga",
    "region": "Tanzania",
    "tz": "Africa/Dar_es_Salaam"
  },
  {
    "iata": "NGQ",
    "city": "Shiquanhe",
    "region": "China",
    "tz": "Asia/Kashgar"
  },
  {
    "iata": "SYZ",
    "city": "Shiraz",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "SHH",
    "city": "Shishmaref",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "WDS",
    "city": "Shiyan",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "SSE",
    "city": "Sholapur",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "SYO",
    "city": "Shonai",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "ESH",
    "city": "Shoreham By Sea",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "SOW",
    "city": "Show Low",
    "region": "Arizona",
    "tz": "America/Phoenix"
  },
  {
    "iata": "BAD",
    "city": "Shreveport",
    "region": "Louisiana",
    "tz": "America/Chicago"
  },
  {
    "iata": "SHV",
    "city": "Shreveport",
    "region": "Louisiana",
    "tz": "America/Chicago"
  },
  {
    "iata": "SHG",
    "city": "Shungnak",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "JHQ",
    "city": "Shute Harbour",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "SKT",
    "city": "Sialkot",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "IAO",
    "city": "Siargao",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "SQQ",
    "city": "Siauliai",
    "region": "Lithuania",
    "tz": "Europe/Vilnius"
  },
  {
    "iata": "SBZ",
    "city": "Sibiu",
    "region": "Romania",
    "tz": "Europe/Bucharest"
  },
  {
    "iata": "DTB",
    "city": "Siborong-Borong",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "SBW",
    "city": "Sibu",
    "region": "Malaysia",
    "tz": "Asia/Kuching"
  },
  {
    "iata": "SDY",
    "city": "Sidney",
    "region": "Montana",
    "tz": "America/Denver"
  },
  {
    "iata": "SNY",
    "city": "Sidney",
    "region": "Nebraska",
    "tz": "America/Denver"
  },
  {
    "iata": "REP",
    "city": "Siem-reap",
    "region": "Cambodia",
    "tz": "Asia/Phnom_Penh"
  },
  {
    "iata": "SAY",
    "city": "Siena",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "SGV",
    "city": "Sierra Grande",
    "region": "Argentina",
    "tz": "America/Argentina/Salta"
  },
  {
    "iata": "GIU",
    "city": "Sigiriya",
    "region": "Sri Lanka",
    "tz": "Asia/Colombo"
  },
  {
    "iata": "SIJ",
    "city": "Siglufjordur",
    "region": "Iceland",
    "tz": "Atlantic/Reykjavik"
  },
  {
    "iata": "NSY",
    "city": "Sigonella",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "KOS",
    "city": "Sihanoukville",
    "region": "Cambodia",
    "tz": "Asia/Phnom_Penh"
  },
  {
    "iata": "KSS",
    "city": "Sikasso",
    "region": "Mali",
    "tz": "Africa/Bamako"
  },
  {
    "iata": "SIK",
    "city": "Sikeston",
    "region": "Missouri",
    "tz": "America/Chicago"
  },
  {
    "iata": "IXS",
    "city": "Silchar",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "SPZ",
    "city": "Silver Springs",
    "region": "Arkansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "SYM",
    "city": "Simao",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "SIF",
    "city": "Simara",
    "region": "Nepal",
    "tz": "Asia/Kathmandu"
  },
  {
    "iata": "SIP",
    "city": "Simferopol",
    "region": "Ukraine",
    "tz": "Europe/Simferopol"
  },
  {
    "iata": "IMK",
    "city": "Simikot",
    "region": "Nepal",
    "tz": "Asia/Kathmandu"
  },
  {
    "iata": "CNL",
    "city": "Sindal",
    "region": "Denmark",
    "tz": "Europe/Copenhagen"
  },
  {
    "iata": "RZS",
    "city": "Sindh",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "SIN",
    "city": "Singapore",
    "region": "Singapore",
    "tz": "Asia/Singapore"
  },
  {
    "iata": "XSP",
    "city": "Singapore",
    "region": "Singapore",
    "tz": "Asia/Singapore"
  },
  {
    "iata": "SIQ",
    "city": "Singkep",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "OPS",
    "city": "Sinop",
    "region": "Brazil",
    "tz": "America/Cuiaba"
  },
  {
    "iata": "SIC",
    "city": "Sinop",
    "region": "Turkey",
    "tz": "America/Panama"
  },
  {
    "iata": "SQG",
    "city": "Sintang-Borneo Island",
    "region": "Indonesia",
    "tz": "Asia/Pontianak"
  },
  {
    "iata": "SIR",
    "city": "Sion",
    "region": "Switzerland",
    "tz": "Europe/Zurich"
  },
  {
    "iata": "SUX",
    "city": "Sioux City",
    "region": "Iowa",
    "tz": "America/Chicago"
  },
  {
    "iata": "FSD",
    "city": "Sioux Falls",
    "region": "South Dakota",
    "tz": "America/Chicago"
  },
  {
    "iata": "YXL",
    "city": "Sioux Lookout",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "XSB",
    "city": "Sir Bani Yas Island",
    "region": "United Arab Emirates",
    "tz": "Asia/Dubai"
  },
  {
    "iata": "SRX",
    "city": "Sirt",
    "region": "Libya",
    "tz": "Africa/Tripoli"
  },
  {
    "iata": "SIS",
    "city": "Sishen",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "JHS",
    "city": "Sisimiut",
    "region": "Greenland",
    "tz": "America/Godthab"
  },
  {
    "iata": "JSH",
    "city": "Sitia",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "SIT",
    "city": "Sitka",
    "region": "Alaska",
    "tz": "America/Sitka"
  },
  {
    "iata": "AKY",
    "city": "Sittwe",
    "region": "Burma",
    "tz": "Asia/Rangoon"
  },
  {
    "iata": "SIU",
    "city": "Siuna",
    "region": "Nicaragua",
    "tz": "America/Managua"
  },
  {
    "iata": "VAS",
    "city": "Sivas",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "SGY",
    "city": "Skagway",
    "region": "Alaska",
    "tz": "America/Juneau"
  },
  {
    "iata": "KDU",
    "city": "Skardu",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "SFT",
    "city": "Skelleftea",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "JSI",
    "city": "Skiathos",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "SKE",
    "city": "Skien",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "SKU",
    "city": "Skiros",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "SKP",
    "city": "Skopje",
    "region": "Macedonia",
    "tz": "Europe/Skopje"
  },
  {
    "iata": "KVB",
    "city": "Skovde",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "SKS",
    "city": "Skrydstrup",
    "region": "Denmark",
    "tz": "Europe/Copenhagen"
  },
  {
    "iata": "SZK",
    "city": "Skukuza",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "YZH",
    "city": "Slave Lake",
    "region": "Canada",
    "tz": "America/Edmonton"
  },
  {
    "iata": "SLQ",
    "city": "Sleetmute",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "SLD",
    "city": "Sliac",
    "region": "Slovakia",
    "tz": "Europe/Bratislava"
  },
  {
    "iata": "SXL",
    "city": "Sligo",
    "region": "Ireland",
    "tz": "Europe/Dublin"
  },
  {
    "iata": "SMW",
    "city": "Smara",
    "region": "Western Sahara",
    "tz": "Africa/El_Aaiun"
  },
  {
    "iata": "YYD",
    "city": "Smithers",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "SFZ",
    "city": "Smithfield",
    "region": "Rhode Island",
    "tz": "America/New_York"
  },
  {
    "iata": "YSH",
    "city": "Smiths Falls",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "DWB",
    "city": "Soalala",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "QBX",
    "city": "Sobral",
    "region": "Brazil",
    "tz": "America/Fortaleza"
  },
  {
    "iata": "AER",
    "city": "Sochi",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "SCT",
    "city": "Socotra",
    "region": "Yemen",
    "tz": "Asia/Aden"
  },
  {
    "iata": "SOT",
    "city": "Sodankyla",
    "region": "Finland",
    "tz": "Europe/Helsinki"
  },
  {
    "iata": "SOO",
    "city": "Soderhamn",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "SGD",
    "city": "Soenderborg",
    "region": "Denmark",
    "tz": "Europe/Copenhagen"
  },
  {
    "iata": "UTC",
    "city": "Soesterberg",
    "region": "Netherlands",
    "tz": "Europe/Amsterdam"
  },
  {
    "iata": "SOF",
    "city": "Sofia",
    "region": "Bulgaria",
    "tz": "Europe/Sofia"
  },
  {
    "iata": "SOG",
    "city": "Sogndal",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "HMB",
    "city": "Sohag",
    "region": "Egypt",
    "tz": "Africa/Cairo"
  },
  {
    "iata": "OHS",
    "city": "Sohar",
    "region": "Oman",
    "tz": "Asia/Muscat"
  },
  {
    "iata": "SHO",
    "city": "Sokch'o",
    "region": "South Korea",
    "tz": "Africa/Mbabane"
  },
  {
    "iata": "YNY",
    "city": "Sokcho / Gangneung",
    "region": "South Korea",
    "tz": "Asia/Seoul"
  },
  {
    "iata": "SKO",
    "city": "Sokoto",
    "region": "Nigeria",
    "tz": "Africa/Lagos"
  },
  {
    "iata": "SLH",
    "city": "Sola",
    "region": "Vanuatu",
    "tz": "Pacific/Efate"
  },
  {
    "iata": "SXQ",
    "city": "Soldotna",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "SOZ",
    "city": "Solenzara",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "SOC",
    "city": "Solo City",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "CSH",
    "city": "Solovetsky Islands",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "SLI",
    "city": "Solwesi",
    "region": "Zambia",
    "tz": "Africa/Lusaka"
  },
  {
    "iata": "SME",
    "city": "Somerset",
    "region": "Kentucky",
    "tz": "America/New_York"
  },
  {
    "iata": "SQH",
    "city": "Son-La",
    "region": "Vietnam",
    "tz": "Asia/Ho_Chi_Minh"
  },
  {
    "iata": "SFJ",
    "city": "Sondrestrom",
    "region": "Greenland",
    "tz": "America/Godthab"
  },
  {
    "iata": "SGX",
    "city": "Songea",
    "region": "Tanzania",
    "tz": "Africa/Dar_es_Salaam"
  },
  {
    "iata": "SOA",
    "city": "Sonora",
    "region": "United States",
    "tz": "Asia/Ho_Chi_Minh"
  },
  {
    "iata": "SOJ",
    "city": "Sorkjosen",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "SOD",
    "city": "Sorocaba",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "SOQ",
    "city": "Sorong",
    "region": "Indonesia",
    "tz": "Asia/Jayapura"
  },
  {
    "iata": "SRT",
    "city": "Soroti",
    "region": "Uganda",
    "tz": "Africa/Kampala"
  },
  {
    "iata": "SFK",
    "city": "Soure",
    "region": "Brazil",
    "tz": "America/Belem"
  },
  {
    "iata": "MSI",
    "city": "South Aari Atoll",
    "region": "Maldives",
    "tz": "Asia/Pontianak"
  },
  {
    "iata": "SBN",
    "city": "South Bend",
    "region": "Indiana",
    "tz": "America/Indiana/Indianapolis"
  },
  {
    "iata": "XSC",
    "city": "South Caicos",
    "region": "Turks and Caicos Islands",
    "tz": "America/Grand_Turk"
  },
  {
    "iata": "LWA",
    "city": "South Haven",
    "region": "United States",
    "tz": "Asia/Manila"
  },
  {
    "iata": "XSI",
    "city": "South Indian Lake",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "TVL",
    "city": "South Lake Tahoe",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "WSN",
    "city": "South Naknek",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "SOU",
    "city": "Southampton",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "KIW",
    "city": "Southdowns",
    "region": "Zambia",
    "tz": "Africa/Lusaka"
  },
  {
    "iata": "SEN",
    "city": "Southend",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "GVN",
    "city": "Sovetskaya Gavan",
    "region": "Russia",
    "tz": "Asia/Vladivostok"
  },
  {
    "iata": "OVS",
    "city": "Sovetskiy",
    "region": "Russia",
    "tz": "Asia/Yekaterinburg"
  },
  {
    "iata": "SXN",
    "city": "Sowa",
    "region": "Botswana",
    "tz": "Africa/Gaborone"
  },
  {
    "iata": "SZA",
    "city": "Soyo",
    "region": "Angola",
    "tz": "Africa/Luanda"
  },
  {
    "iata": "SPM",
    "city": "Spangdahlem",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "VIJ",
    "city": "Spanish Town",
    "region": "British Virgin Islands",
    "tz": "America/Tortola"
  },
  {
    "iata": "SVW",
    "city": "Sparrevohn",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "SPF",
    "city": "Spearfish-South Dakota",
    "region": "South Dakota",
    "tz": "America/Denver"
  },
  {
    "iata": "YYH",
    "city": "Spence Bay",
    "region": "Canada",
    "tz": "America/Cambridge_Bay"
  },
  {
    "iata": "SPW",
    "city": "Spencer",
    "region": "Iowa",
    "tz": "America/Chicago"
  },
  {
    "iata": "SPU",
    "city": "Split",
    "region": "Croatia",
    "tz": "Europe/Zagreb"
  },
  {
    "iata": "GEG",
    "city": "Spokane",
    "region": "Washington",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "SFF",
    "city": "Spokane",
    "region": "Washington",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "SKA",
    "city": "Spokane",
    "region": "Washington",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "AXP",
    "city": "Spring Point",
    "region": "Bahamas",
    "tz": "America/Nassau"
  },
  {
    "iata": "SBU",
    "city": "Springbok",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "SPI",
    "city": "Springfield",
    "region": "Illinois",
    "tz": "America/Chicago"
  },
  {
    "iata": "SGF",
    "city": "Springfield",
    "region": "Missouri",
    "tz": "America/Chicago"
  },
  {
    "iata": "SGH",
    "city": "Springfield",
    "region": "Ohio",
    "tz": "America/New_York"
  },
  {
    "iata": "YSE",
    "city": "Squamish",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "SEK",
    "city": "Srednekolymsk",
    "region": "Russia",
    "tz": "Asia/Magadan"
  },
  {
    "iata": "SXR",
    "city": "Srinagar",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "SGO",
    "city": "St George",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "YSL",
    "city": "St Leonard",
    "region": "Canada",
    "tz": "America/Moncton"
  },
  {
    "iata": "KSM",
    "city": "St Mary's",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "YQS",
    "city": "St Thomas",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "YIF",
    "city": "St-Augustin",
    "region": "Canada",
    "tz": "America/Blanc-Sablon"
  },
  {
    "iata": "EBU",
    "city": "St-Etienne",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "SFC",
    "city": "St-Fran\u00e7ois",
    "region": "Guadeloupe",
    "tz": "America/Guadeloupe"
  },
  {
    "iata": "YAY",
    "city": "St. Anthony",
    "region": "Canada",
    "tz": "America/St_Johns"
  },
  {
    "iata": "UST",
    "city": "St. Augustine Airport",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "SKV",
    "city": "St. Catherine",
    "region": "Egypt",
    "tz": "Africa/Cairo"
  },
  {
    "iata": "STX",
    "city": "St. Croix Island",
    "region": "Virgin Islands",
    "tz": "America/St_Thomas"
  },
  {
    "iata": "YJN",
    "city": "St. Jean",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "YSJ",
    "city": "St. John",
    "region": "Canada",
    "tz": "America/Moncton"
  },
  {
    "iata": "YYT",
    "city": "St. John's",
    "region": "Canada",
    "tz": "America/St_Johns"
  },
  {
    "iata": "YFX",
    "city": "St. Lewis",
    "region": "Canada",
    "tz": "America/St_Johns"
  },
  {
    "iata": "STL",
    "city": "St. Louis",
    "region": "Missouri",
    "tz": "America/Chicago"
  },
  {
    "iata": "XLS",
    "city": "St. Louis",
    "region": "Senegal",
    "tz": "Africa/Dakar"
  },
  {
    "iata": "SFG",
    "city": "St. Martin",
    "region": "Guadeloupe",
    "tz": "America/Marigot"
  },
  {
    "iata": "SMK",
    "city": "St. Michael",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "STP",
    "city": "St. Paul",
    "region": "Minnesota",
    "tz": "America/Chicago"
  },
  {
    "iata": "SNP",
    "city": "St. Paul Island",
    "region": "Alaska",
    "tz": "America/Adak"
  },
  {
    "iata": "PIE",
    "city": "St. Petersburg",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "SPG",
    "city": "St. Petersburg",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "LED",
    "city": "St. Petersburg",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "YST",
    "city": "St. Theresa Point",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "STT",
    "city": "St. Thomas",
    "region": "Virgin Islands",
    "tz": "America/St_Thomas"
  },
  {
    "iata": "SBK",
    "city": "St.-brieuc Armor",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "RUN",
    "city": "St.-denis",
    "region": "Reunion",
    "tz": "Indian/Reunion"
  },
  {
    "iata": "SNR",
    "city": "St.-nazaire",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "ZSE",
    "city": "St.-pierre",
    "region": "Reunion",
    "tz": "Indian/Reunion"
  },
  {
    "iata": "FSP",
    "city": "St.-pierre",
    "region": "Saint Pierre and Miquelon",
    "tz": "America/Miquelon"
  },
  {
    "iata": "PSY",
    "city": "Stanley",
    "region": "Falkland Islands",
    "tz": "Atlantic/Stanley"
  },
  {
    "iata": "SCE",
    "city": "State College Pennsylvania",
    "region": "Pennsylvania",
    "tz": "America/New_York"
  },
  {
    "iata": "SVH",
    "city": "Statesville",
    "region": "North Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "STA",
    "city": "Stauning",
    "region": "Denmark",
    "tz": "Europe/Copenhagen"
  },
  {
    "iata": "SVG",
    "city": "Stavanger",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "STW",
    "city": "Stavropol",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "SBS",
    "city": "Steamboat Springs",
    "region": "Colorado",
    "tz": "America/Denver"
  },
  {
    "iata": "SML",
    "city": "Stella Maris",
    "region": "Bahamas",
    "tz": "America/Nassau"
  },
  {
    "iata": "YJT",
    "city": "Stephenville",
    "region": "Canada",
    "tz": "America/St_Johns"
  },
  {
    "iata": "STK",
    "city": "Sterling",
    "region": "Colorado",
    "tz": "America/Denver"
  },
  {
    "iata": "STE",
    "city": "Stevens Point",
    "region": "Wisconsin",
    "tz": "America/Chicago"
  },
  {
    "iata": "ZST",
    "city": "Stewart",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "SZS",
    "city": "Stewart Island",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "SSF",
    "city": "Stinson",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "MNY",
    "city": "Stirling Island",
    "region": "Solomon Islands",
    "tz": "Pacific/Guadalcanal"
  },
  {
    "iata": "ARN",
    "city": "Stockholm",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "BMA",
    "city": "Stockholm",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "NYO",
    "city": "Stockholm",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "SCK",
    "city": "Stockton",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "SMZ",
    "city": "Stoelmans Eiland",
    "region": "Suriname",
    "tz": "America/Paramaribo"
  },
  {
    "iata": "SKN",
    "city": "Stokmarknes",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "YSF",
    "city": "Stony Rapids",
    "region": "Canada",
    "tz": "America/Regina"
  },
  {
    "iata": "SRP",
    "city": "Stord",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "SYY",
    "city": "Stornoway",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "SRN",
    "city": "Strahan",
    "region": "Australia",
    "tz": "Australia/Hobart"
  },
  {
    "iata": "SXB",
    "city": "Strasbourg",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "BDR",
    "city": "Stratford",
    "region": "Connecticut",
    "tz": "America/New_York"
  },
  {
    "iata": "RBM",
    "city": "Straubing",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "SWT",
    "city": "Strezhevoy",
    "region": "Russia",
    "tz": "Asia/Novosibirsk"
  },
  {
    "iata": "SOY",
    "city": "Stronsay",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "SUA",
    "city": "Stuart",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "TNX",
    "city": "Stung Treng",
    "region": "Cambodia",
    "tz": "Asia/Phnom_Penh"
  },
  {
    "iata": "STR",
    "city": "Stuttgart",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "VAO",
    "city": "Suavanao",
    "region": "Solomon Islands",
    "tz": "Pacific/Guadalcanal"
  },
  {
    "iata": "SCV",
    "city": "Suceava",
    "region": "Romania",
    "tz": "Europe/Bucharest"
  },
  {
    "iata": "SRE",
    "city": "Sucre",
    "region": "Bolivia",
    "tz": "America/La_Paz"
  },
  {
    "iata": "YSB",
    "city": "Sudbury",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "SYU",
    "city": "Sue Islet",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "YSD",
    "city": "Suffield",
    "region": "Canada",
    "tz": "America/Edmonton"
  },
  {
    "iata": "SGR",
    "city": "Sugar Land",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "SUL",
    "city": "Sui",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "THS",
    "city": "Sukhothai",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "SUI",
    "city": "Sukhumi",
    "region": "Georgia",
    "tz": "Asia/Tbilisi"
  },
  {
    "iata": "SKZ",
    "city": "Sukkur",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "SLF",
    "city": "Sulayel",
    "region": "Saudi Arabia",
    "tz": "Asia/Riyadh"
  },
  {
    "iata": "ISU",
    "city": "Sulaymaniyah",
    "region": "Iraq",
    "tz": "Asia/Baghdad"
  },
  {
    "iata": "SWQ",
    "city": "Sumbawa Island",
    "region": "Indonesia",
    "tz": "Asia/Makassar"
  },
  {
    "iata": "NDD",
    "city": "Sumbe",
    "region": "Angola",
    "tz": "Africa/Luanda"
  },
  {
    "iata": "LSI",
    "city": "Sumburgh",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "SUR",
    "city": "Summer Beaver",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "YSU",
    "city": "Summerside",
    "region": "Canada",
    "tz": "America/Halifax"
  },
  {
    "iata": "SSC",
    "city": "Sumter",
    "region": "South Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "SDL",
    "city": "Sundsvall",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "SUY",
    "city": "Suntar",
    "region": "Russia",
    "tz": "Asia/Yakutsk"
  },
  {
    "iata": "NYI",
    "city": "Sunyani",
    "region": "Ghana",
    "tz": "Africa/Accra"
  },
  {
    "iata": "SUB",
    "city": "Surabaya",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "AAV",
    "city": "Surallah",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "STV",
    "city": "Surat",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "URT",
    "city": "Surat Thani",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "SGC",
    "city": "Surgut",
    "region": "Russia",
    "tz": "Asia/Yekaterinburg"
  },
  {
    "iata": "SKH",
    "city": "Surkhet",
    "region": "Nepal",
    "tz": "Asia/Kathmandu"
  },
  {
    "iata": "SWU",
    "city": "Suwon",
    "region": "South Korea",
    "tz": "Asia/Seoul"
  },
  {
    "iata": "SZV",
    "city": "Suzhou",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "EVG",
    "city": "Sveg",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "SVJ",
    "city": "Svolv\u00e6r",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "SWP",
    "city": "Swakopmund",
    "region": "Namibia",
    "tz": "Africa/Windhoek"
  },
  {
    "iata": "SWH",
    "city": "Swan Hill",
    "region": "Australia",
    "tz": "Australia/Melbourne"
  },
  {
    "iata": "ZJN",
    "city": "Swan River",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "SWS",
    "city": "Swansea",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "YYN",
    "city": "Swift Current",
    "region": "Canada",
    "tz": "America/Regina"
  },
  {
    "iata": "SYH",
    "city": "Syangboche",
    "region": "Nepal",
    "tz": "Asia/Kathmandu"
  },
  {
    "iata": "BWU",
    "city": "Sydney",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "SYD",
    "city": "Sydney",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "YQY",
    "city": "Sydney",
    "region": "Canada",
    "tz": "America/Glace_Bay"
  },
  {
    "iata": "SCW",
    "city": "Syktyvkar",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "SCD",
    "city": "Sylacauga",
    "region": "United States",
    "tz": "America/Tegucigalpa"
  },
  {
    "iata": "ZYL",
    "city": "Sylhet Osmani",
    "region": "Bangladesh",
    "tz": "Asia/Dhaka"
  },
  {
    "iata": "SYR",
    "city": "Syracuse",
    "region": "New York",
    "tz": "America/New_York"
  },
  {
    "iata": "JSY",
    "city": "Syros Island",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "SZZ",
    "city": "Szczecin",
    "region": "Poland",
    "tz": "Europe/Warsaw"
  },
  {
    "iata": "SZY",
    "city": "Szczytno-Szymany",
    "region": "Poland",
    "tz": "Europe/Warsaw"
  },
  {
    "iata": "SOB",
    "city": "S\u00e1rmell\u00e9k",
    "region": "Hungary",
    "tz": "Europe/Budapest"
  },
  {
    "iata": "TCP",
    "city": "Taba",
    "region": "Egypt",
    "tz": "Africa/Cairo"
  },
  {
    "iata": "TBJ",
    "city": "Tabarka",
    "region": "Tunisia",
    "tz": "Africa/Tunis"
  },
  {
    "iata": "TBT",
    "city": "Tabatinga",
    "region": "Brazil",
    "tz": "America/Porto_Velho"
  },
  {
    "iata": "TSU",
    "city": "Tabiteuea",
    "region": "Kiribati",
    "tz": "Pacific/Tarawa"
  },
  {
    "iata": "TBF",
    "city": "Tabiteuea North",
    "region": "Kiribati",
    "tz": "Pacific/Tarawa"
  },
  {
    "iata": "TBO",
    "city": "Tabora",
    "region": "Tanzania",
    "tz": "Africa/Dar_es_Salaam"
  },
  {
    "iata": "TBZ",
    "city": "Tabriz",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "TBG",
    "city": "Tabubil",
    "region": "Papua New Guinea",
    "tz": "Pacific/Port_Moresby"
  },
  {
    "iata": "TUU",
    "city": "Tabuk",
    "region": "Saudi Arabia",
    "tz": "Asia/Riyadh"
  },
  {
    "iata": "TCG",
    "city": "Tacheng",
    "region": "China",
    "tz": "Asia/Urumqi"
  },
  {
    "iata": "THL",
    "city": "Tachilek",
    "region": "Burma",
    "tz": "Asia/Rangoon"
  },
  {
    "iata": "TAC",
    "city": "Tacloban",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "TCQ",
    "city": "Tacna",
    "region": "Peru",
    "tz": "America/Lima"
  },
  {
    "iata": "TCM",
    "city": "Tacoma",
    "region": "Washington",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "TIW",
    "city": "Tacoma",
    "region": "Washington",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "TDJ",
    "city": "Tadjoura",
    "region": "Djibouti",
    "tz": "Africa/Djibouti"
  },
  {
    "iata": "XTL",
    "city": "Tadoule Lake",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "TAE",
    "city": "Taegu",
    "region": "South Korea",
    "tz": "Asia/Seoul"
  },
  {
    "iata": "TAG",
    "city": "Tagbilaran",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "THZ",
    "city": "Tahoua",
    "region": "Niger",
    "tz": "Africa/Niamey"
  },
  {
    "iata": "RMQ",
    "city": "Taichung",
    "region": "Taiwan",
    "tz": "Asia/Taipei"
  },
  {
    "iata": "TXG",
    "city": "Taichung",
    "region": "Taiwan",
    "tz": "Asia/Taipei"
  },
  {
    "iata": "TIF",
    "city": "Taif",
    "region": "Saudi Arabia",
    "tz": "Asia/Riyadh"
  },
  {
    "iata": "TNN",
    "city": "Tainan",
    "region": "Taiwan",
    "tz": "Asia/Taipei"
  },
  {
    "iata": "TPE",
    "city": "Taipei",
    "region": "Taiwan",
    "tz": "Asia/Taipei"
  },
  {
    "iata": "TSA",
    "city": "Taipei",
    "region": "Taiwan",
    "tz": "Asia/Taipei"
  },
  {
    "iata": "TYN",
    "city": "Taiyuan",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "TAI",
    "city": "Taiz",
    "region": "Yemen",
    "tz": "Asia/Aden"
  },
  {
    "iata": "MAQ",
    "city": "Tak",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "TKT",
    "city": "Tak",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "KTF",
    "city": "Takaka",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "TAK",
    "city": "Takamatsu",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "TKP",
    "city": "Takapoto",
    "region": "French Polynesia",
    "tz": "Pacific/Tahiti"
  },
  {
    "iata": "TKX",
    "city": "Takaroa",
    "region": "French Polynesia",
    "tz": "Pacific/Tahiti"
  },
  {
    "iata": "HTR",
    "city": "Taketomi",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "TKD",
    "city": "Takoradi",
    "region": "Ghana",
    "tz": "Africa/Accra"
  },
  {
    "iata": "PDO",
    "city": "Talang Gudang-Sumatra Island",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "TYL",
    "city": "Talara",
    "region": "Peru",
    "tz": "America/Lima"
  },
  {
    "iata": "TLX",
    "city": "Talca",
    "region": "Chile",
    "tz": "America/Santiago"
  },
  {
    "iata": "TDK",
    "city": "Taldykorgan",
    "region": "Kazakhstan",
    "tz": "Asia/Almaty"
  },
  {
    "iata": "BDN",
    "city": "Talhar",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "TKA",
    "city": "Talkeetna",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "ASN",
    "city": "Talladega",
    "region": "Alabama",
    "tz": "America/Chicago"
  },
  {
    "iata": "TLH",
    "city": "Tallahassee",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "TLL",
    "city": "Tallinn-ulemiste International",
    "region": "Estonia",
    "tz": "Europe/Tallinn"
  },
  {
    "iata": "TTC",
    "city": "Taltal",
    "region": "Chile",
    "tz": "America/Santiago"
  },
  {
    "iata": "TML",
    "city": "Tamale",
    "region": "Ghana",
    "tz": "Africa/Accra"
  },
  {
    "iata": "TMN",
    "city": "Tamana",
    "region": "Kiribati",
    "tz": "Pacific/Tarawa"
  },
  {
    "iata": "TMR",
    "city": "Tamanrasset",
    "region": "Algeria",
    "tz": "Africa/Algiers"
  },
  {
    "iata": "TUD",
    "city": "Tambacounda",
    "region": "Senegal",
    "tz": "Africa/Dakar"
  },
  {
    "iata": "TBW",
    "city": "Tambow",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "IKU",
    "city": "Tamchy",
    "region": "Kyrgyzstan",
    "tz": "Asia/Bishkek"
  },
  {
    "iata": "TME",
    "city": "Tame",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "MCF",
    "city": "Tampa",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "TPA",
    "city": "Tampa",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "TPF",
    "city": "Tampa",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "TMP",
    "city": "Tampere",
    "region": "Finland",
    "tz": "Europe/Helsinki"
  },
  {
    "iata": "TAM",
    "city": "Tampico",
    "region": "Mexico",
    "tz": "America/Monterrey"
  },
  {
    "iata": "TSL",
    "city": "Tamuin",
    "region": "Mexico",
    "tz": "America/Mexico_City"
  },
  {
    "iata": "TMW",
    "city": "Tamworth",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "TTA",
    "city": "Tan Tan",
    "region": "Morocco",
    "tz": "Africa/Casablanca"
  },
  {
    "iata": "TDG",
    "city": "Tandag",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "TDL",
    "city": "Tandil",
    "region": "Argentina",
    "tz": "America/Argentina/Buenos_Aires"
  },
  {
    "iata": "TNE",
    "city": "Tanegashima",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "TGT",
    "city": "Tanga",
    "region": "Tanzania",
    "tz": "Africa/Dar_es_Salaam"
  },
  {
    "iata": "TNG",
    "city": "Tanger",
    "region": "Morocco",
    "tz": "Africa/Casablanca"
  },
  {
    "iata": "TJQ",
    "city": "Tanjung Pandan",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "TNJ",
    "city": "Tanjung Pinang",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "BEJ",
    "city": "Tanjung Redep-Borneo Island",
    "region": "Indonesia",
    "tz": "Asia/Makassar"
  },
  {
    "iata": "TJS",
    "city": "Tanjung Selor-Borneo Island",
    "region": "Indonesia",
    "tz": "Asia/Makassar"
  },
  {
    "iata": "TJG",
    "city": "Tanjung-Borneo Island",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "TAH",
    "city": "Tanna",
    "region": "Vanuatu",
    "tz": "Pacific/Efate"
  },
  {
    "iata": "TAP",
    "city": "Tapachula",
    "region": "Mexico",
    "tz": "America/Merida"
  },
  {
    "iata": "TPJ",
    "city": "Taplejung",
    "region": "Nepal",
    "tz": "Asia/Kathmandu"
  },
  {
    "iata": "TRK",
    "city": "Taraken",
    "region": "Indonesia",
    "tz": "Asia/Makassar"
  },
  {
    "iata": "TPC",
    "city": "Tarapoa",
    "region": "Ecuador",
    "tz": "America/Guayaquil"
  },
  {
    "iata": "TPP",
    "city": "Tarapoto",
    "region": "Peru",
    "tz": "America/Lima"
  },
  {
    "iata": "TRW",
    "city": "Tarawa",
    "region": "Kiribati",
    "tz": "Pacific/Tarawa"
  },
  {
    "iata": "LDE",
    "city": "Tarbes",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "TRO",
    "city": "Taree",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "TIZ",
    "city": "Tari",
    "region": "Papua New Guinea",
    "tz": "Pacific/Port_Moresby"
  },
  {
    "iata": "TJA",
    "city": "Tarija",
    "region": "Bolivia",
    "tz": "America/La_Paz"
  },
  {
    "iata": "TII",
    "city": "Tarin Kowt",
    "region": "Afghanistan",
    "tz": "Asia/Kabul"
  },
  {
    "iata": "TQL",
    "city": "Tarko-Sale",
    "region": "Russia",
    "tz": "Asia/Yekaterinburg"
  },
  {
    "iata": "XTO",
    "city": "Taroom",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "TTG",
    "city": "Tartagal",
    "region": "Argentina",
    "tz": "America/Argentina/Salta"
  },
  {
    "iata": "TAY",
    "city": "Tartu",
    "region": "Estonia",
    "tz": "Europe/Tallinn"
  },
  {
    "iata": "TAS",
    "city": "Tashkent",
    "region": "Uzbekistan",
    "tz": "Asia/Tashkent"
  },
  {
    "iata": "YTQ",
    "city": "Tasiujaq",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "TLJ",
    "city": "Tatalina",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "QHP",
    "city": "Taubat\u00e9",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "TAN",
    "city": "Taunton",
    "region": "United States",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "TUO",
    "city": "Taupo",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "TRG",
    "city": "Tauranga",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "TWU",
    "city": "Tawau",
    "region": "Malaysia",
    "tz": "Asia/Kuching"
  },
  {
    "iata": "RZP",
    "city": "Taytay",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "TBS",
    "city": "Tbilisi",
    "region": "Georgia",
    "tz": "Asia/Tbilisi"
  },
  {
    "iata": "TCH",
    "city": "Tchibanga",
    "region": "Gabon",
    "tz": "Africa/Libreville"
  },
  {
    "iata": "TEE",
    "city": "Tebessa",
    "region": "Algeria",
    "tz": "Africa/Algiers"
  },
  {
    "iata": "MME",
    "city": "Teesside",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "TFF",
    "city": "Tefe",
    "region": "Brazil",
    "tz": "America/Manaus"
  },
  {
    "iata": "TGU",
    "city": "Tegucigalpa",
    "region": "Honduras",
    "tz": "America/Tegucigalpa"
  },
  {
    "iata": "THR",
    "city": "Teheran",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "IKA",
    "city": "Tehran",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "TCN",
    "city": "Tehuacan",
    "region": "Mexico",
    "tz": "America/Mexico_City"
  },
  {
    "iata": "SDV",
    "city": "Tel-aviv",
    "region": "Israel",
    "tz": "Asia/Jerusalem"
  },
  {
    "iata": "TLV",
    "city": "Tel-aviv",
    "region": "Israel",
    "tz": "Asia/Jerusalem"
  },
  {
    "iata": "TEA",
    "city": "Tela",
    "region": "Honduras",
    "tz": "America/Tegucigalpa"
  },
  {
    "iata": "TEF",
    "city": "Telfer",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "TLA",
    "city": "Teller",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "TEX",
    "city": "Telluride",
    "region": "Colorado",
    "tz": "America/Denver"
  },
  {
    "iata": "TEM",
    "city": "Temora",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "TPL",
    "city": "Temple",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "ZCO",
    "city": "Temuco",
    "region": "Chile",
    "tz": "America/Santiago"
  },
  {
    "iata": "TFN",
    "city": "Tenerife",
    "region": "Spain",
    "tz": "Atlantic/Canary"
  },
  {
    "iata": "TFS",
    "city": "Tenerife",
    "region": "Spain",
    "tz": "Atlantic/Canary"
  },
  {
    "iata": "TCZ",
    "city": "Tengchong",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "TCA",
    "city": "Tennant Creek",
    "region": "Australia",
    "tz": "Australia/Darwin"
  },
  {
    "iata": "TFL",
    "city": "Teofilo Otoni",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "TPQ",
    "city": "Tepic",
    "region": "Mexico",
    "tz": "America/Mazatlan"
  },
  {
    "iata": "THE",
    "city": "Teresina",
    "region": "Brazil",
    "tz": "America/Fortaleza"
  },
  {
    "iata": "TMJ",
    "city": "Termez",
    "region": "Uzbekistan",
    "tz": "Asia/Samarkand"
  },
  {
    "iata": "TTE",
    "city": "Ternate",
    "region": "Indonesia",
    "tz": "Asia/Jayapura"
  },
  {
    "iata": "TNL",
    "city": "Ternopol",
    "region": "Ukraine",
    "tz": "Europe/Uzhgorod"
  },
  {
    "iata": "YXT",
    "city": "Terrace",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "HUF",
    "city": "Terre Haute",
    "region": "Indiana",
    "tz": "America/Indiana/Indianapolis"
  },
  {
    "iata": "TEV",
    "city": "Teruel",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "YZW",
    "city": "Teslin",
    "region": "Canada",
    "tz": "America/Whitehorse"
  },
  {
    "iata": "TET",
    "city": "Tete",
    "region": "Mozambique",
    "tz": "Africa/Maputo"
  },
  {
    "iata": "TEB",
    "city": "Teterboro",
    "region": "New Jersey",
    "tz": "America/New_York"
  },
  {
    "iata": "TTI",
    "city": "Tetiaroa",
    "region": "French Polynesia",
    "tz": "Pacific/Tahiti"
  },
  {
    "iata": "TTU",
    "city": "Tetouan",
    "region": "Morocco",
    "tz": "Africa/Casablanca"
  },
  {
    "iata": "YGB",
    "city": "Texada",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "TXK",
    "city": "Texarkana",
    "region": "Arkansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "TEZ",
    "city": "Tezpur",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "SNW",
    "city": "Thandwe",
    "region": "Burma",
    "tz": "Asia/Rangoon"
  },
  {
    "iata": "XTG",
    "city": "Thargomindah",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "YQD",
    "city": "The Pas",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "AXA",
    "city": "The Valley",
    "region": "Anguilla",
    "tz": "America/Anguilla"
  },
  {
    "iata": "TDR",
    "city": "Theodore",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "SKG",
    "city": "Thessaloniki",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "YTD",
    "city": "Thicket Portage",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "TVF",
    "city": "Thief River Falls",
    "region": "Minnesota",
    "tz": "America/Chicago"
  },
  {
    "iata": "TMF",
    "city": "Thimarafushi",
    "region": "Maldives",
    "tz": "Indian/Maldives"
  },
  {
    "iata": "PBH",
    "city": "Thimphu",
    "region": "Bhutan",
    "tz": "Asia/Thimphu"
  },
  {
    "iata": "JTR",
    "city": "Thira",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "TED",
    "city": "Thisted",
    "region": "Denmark",
    "tz": "Europe/Copenhagen"
  },
  {
    "iata": "TVI",
    "city": "Thomasville",
    "region": "Georgia",
    "tz": "America/New_York"
  },
  {
    "iata": "YTH",
    "city": "Thompson",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "THO",
    "city": "Thorshofn",
    "region": "Iceland",
    "tz": "Atlantic/Reykjavik"
  },
  {
    "iata": "THU",
    "city": "Thule",
    "region": "Greenland",
    "tz": "America/Thule"
  },
  {
    "iata": "TTH",
    "city": "Thumrait",
    "region": "Oman",
    "tz": "Asia/Muscat"
  },
  {
    "iata": "YQT",
    "city": "Thunder Bay",
    "region": "Canada",
    "tz": "America/Thunder_Bay"
  },
  {
    "iata": "TSN",
    "city": "Tianjin",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "TID",
    "city": "Tiaret",
    "region": "Algeria",
    "tz": "Africa/Algiers"
  },
  {
    "iata": "TIY",
    "city": "Tidjikja",
    "region": "Mauritania",
    "tz": "Africa/Nouakchott"
  },
  {
    "iata": "TMA",
    "city": "Tifton",
    "region": "Georgia",
    "tz": "America/New_York"
  },
  {
    "iata": "TGJ",
    "city": "Tiga",
    "region": "New Caledonia",
    "tz": "Pacific/Noumea"
  },
  {
    "iata": "TIJ",
    "city": "Tijuana",
    "region": "Mexico",
    "tz": "America/Tijuana"
  },
  {
    "iata": "TIH",
    "city": "Tikehau",
    "region": "French Polynesia",
    "tz": "Pacific/Tahiti"
  },
  {
    "iata": "TKC",
    "city": "Tiko",
    "region": "Cameroon",
    "tz": "Africa/Douala"
  },
  {
    "iata": "IKS",
    "city": "Tiksi",
    "region": "Russia",
    "tz": "Asia/Yakutsk"
  },
  {
    "iata": "HRM",
    "city": "Tilrempt",
    "region": "Algeria",
    "tz": "Africa/Algiers"
  },
  {
    "iata": "TIU",
    "city": "Timaru",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "TIM",
    "city": "Timika",
    "region": "Indonesia",
    "tz": "Asia/Jayapura"
  },
  {
    "iata": "TMX",
    "city": "Timimoun",
    "region": "Algeria",
    "tz": "Africa/Algiers"
  },
  {
    "iata": "TSR",
    "city": "Timisoara",
    "region": "Romania",
    "tz": "Europe/Bucharest"
  },
  {
    "iata": "YTS",
    "city": "Timmins",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "TNC",
    "city": "Tin City",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "TIN",
    "city": "Tindouf",
    "region": "Algeria",
    "tz": "Africa/Algiers"
  },
  {
    "iata": "TGI",
    "city": "Tingo Maria",
    "region": "Peru",
    "tz": "America/Lima"
  },
  {
    "iata": "TOD",
    "city": "Tioman",
    "region": "Malaysia",
    "tz": "Asia/Kuala_Lumpur"
  },
  {
    "iata": "TIE",
    "city": "Tippi",
    "region": "Ethiopia",
    "tz": "Africa/Addis_Ababa"
  },
  {
    "iata": "TPN",
    "city": "Tiputini",
    "region": "Ecuador",
    "tz": "America/Guayaquil"
  },
  {
    "iata": "TIA",
    "city": "Tirana",
    "region": "Albania",
    "tz": "Europe/Tirane"
  },
  {
    "iata": "TRE",
    "city": "Tiree",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "TGM",
    "city": "Tirgu Mures",
    "region": "Romania",
    "tz": "Europe/Bucharest"
  },
  {
    "iata": "TRZ",
    "city": "Tiruchirappalli",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "TIR",
    "city": "Tirupeti",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "TIX",
    "city": "Titusville",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "TIV",
    "city": "Tivat",
    "region": "Montenegro",
    "tz": "Europe/Podgorica"
  },
  {
    "iata": "TLM",
    "city": "Tlemcen",
    "region": "Algeria",
    "tz": "Africa/Algiers"
  },
  {
    "iata": "TMM",
    "city": "Toamasina",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "TOB",
    "city": "Tobruk",
    "region": "Libya",
    "tz": "Africa/Tripoli"
  },
  {
    "iata": "TOC",
    "city": "Toccoa",
    "region": "Georgia",
    "tz": "America/New_York"
  },
  {
    "iata": "TOQ",
    "city": "Tocopilla",
    "region": "Chile",
    "tz": "America/Santiago"
  },
  {
    "iata": "YAZ",
    "city": "Tofino",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "TOG",
    "city": "Togiak Village",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "TKJ",
    "city": "Tok",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "OOK",
    "city": "Toksook Bay",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "RAB",
    "city": "Tokua",
    "region": "Papua New Guinea",
    "tz": "Pacific/Port_Moresby"
  },
  {
    "iata": "TKN",
    "city": "Tokunoshima",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "TKS",
    "city": "Tokushima",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "HND",
    "city": "Tokyo",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "NRT",
    "city": "Tokyo",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "FTU",
    "city": "Tolagnaro",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "TOW",
    "city": "Toledo",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "TOL",
    "city": "Toledo",
    "region": "Ohio",
    "tz": "America/New_York"
  },
  {
    "iata": "TLI",
    "city": "Toli-Toli",
    "region": "Indonesia",
    "tz": "Asia/Makassar"
  },
  {
    "iata": "TLE",
    "city": "Toliara",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "TLU",
    "city": "Tolu",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "TLC",
    "city": "Toluca",
    "region": "Mexico",
    "tz": "America/Mexico_City"
  },
  {
    "iata": "TMG",
    "city": "Tomanggong",
    "region": "Malaysia",
    "tz": "Asia/Kuching"
  },
  {
    "iata": "TOM",
    "city": "Tombouctou",
    "region": "Mali",
    "tz": "Africa/Bamako"
  },
  {
    "iata": "TOF",
    "city": "Tomsk",
    "region": "Russia",
    "tz": "Asia/Novosibirsk"
  },
  {
    "iata": "TBU",
    "city": "Tongatapu",
    "region": "Tonga",
    "tz": "Pacific/Tongatapu"
  },
  {
    "iata": "TNH",
    "city": "Tonghua",
    "region": "China",
    "tz": "Asia/Harbin"
  },
  {
    "iata": "TGO",
    "city": "Tongliao",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "TGH",
    "city": "Tongoa Island",
    "region": "Vanuatu",
    "tz": "Pacific/Efate"
  },
  {
    "iata": "TEN",
    "city": "Tongren",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "TPH",
    "city": "Tonopah",
    "region": "Nevada",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "TWB",
    "city": "Toowoomba",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "WTB",
    "city": "Toowoomba",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "FOE",
    "city": "Topeka",
    "region": "Kansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "TOP",
    "city": "Topeka",
    "region": "Kansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "TRN",
    "city": "Torino",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "YKZ",
    "city": "Toronto",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "YTZ",
    "city": "Toronto",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "YYZ",
    "city": "Toronto",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "TOA",
    "city": "Torrance",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "TRC",
    "city": "Torreon",
    "region": "Mexico",
    "tz": "America/Monterrey"
  },
  {
    "iata": "TYF",
    "city": "Torsby",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "EIS",
    "city": "Tortola",
    "region": "British Virgin Islands",
    "tz": "America/Tortola"
  },
  {
    "iata": "TTB",
    "city": "Tortoli",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "GMR",
    "city": "Totegegie",
    "region": "French Polynesia",
    "tz": "Pacific/Gambier"
  },
  {
    "iata": "TOT",
    "city": "Totness",
    "region": "Suriname",
    "tz": "America/Paramaribo"
  },
  {
    "iata": "TTJ",
    "city": "Tottori",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "TGR",
    "city": "Touggourt",
    "region": "Algeria",
    "tz": "Africa/Algiers"
  },
  {
    "iata": "TOU",
    "city": "Touho",
    "region": "New Caledonia",
    "tz": "Pacific/Noumea"
  },
  {
    "iata": "TLS",
    "city": "Toulouse",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "TUF",
    "city": "Tours",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "TNF",
    "city": "Toussous-le-noble",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "TSV",
    "city": "Townsville",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "TOY",
    "city": "Toyama",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "TJH",
    "city": "Toyooka",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "TOE",
    "city": "Tozeur",
    "region": "Tunisia",
    "tz": "Africa/Tunis"
  },
  {
    "iata": "TZX",
    "city": "Trabzon",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "YZZ",
    "city": "Trail",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "TST",
    "city": "Trang",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "TPS",
    "city": "Trapani",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "TDX",
    "city": "Trat",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "TVC",
    "city": "Traverse City",
    "region": "Michigan",
    "tz": "America/Detroit"
  },
  {
    "iata": "TCB",
    "city": "Treasure Cay",
    "region": "Bahamas",
    "tz": "America/Nassau"
  },
  {
    "iata": "REL",
    "city": "Trelew",
    "region": "Argentina",
    "tz": "America/Argentina/Catamarca"
  },
  {
    "iata": "YTR",
    "city": "Trenton",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "TTN",
    "city": "Trenton",
    "region": "New Jersey",
    "tz": "America/New_York"
  },
  {
    "iata": "TQS",
    "city": "Tres Esquinas",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "TJL",
    "city": "Tres Lagoas",
    "region": "Brazil",
    "tz": "America/Campo_Grande"
  },
  {
    "iata": "TSO",
    "city": "Tresco",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "TSF",
    "city": "Treviso",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "TRR",
    "city": "Trinciomalee",
    "region": "Sri Lanka",
    "tz": "Asia/Colombo"
  },
  {
    "iata": "TDD",
    "city": "Trinidad",
    "region": "Bolivia",
    "tz": "America/La_Paz"
  },
  {
    "iata": "TND",
    "city": "Trinidad",
    "region": "Cuba",
    "tz": "America/Havana"
  },
  {
    "iata": "MJI",
    "city": "Tripoli",
    "region": "Libya",
    "tz": "Africa/Tripoli"
  },
  {
    "iata": "TIP",
    "city": "Tripoli",
    "region": "Libya",
    "tz": "Africa/Tripoli"
  },
  {
    "iata": "TRV",
    "city": "Trivandrum",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "YRQ",
    "city": "Trois Rivieres",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "THN",
    "city": "Trollhattan",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "TOS",
    "city": "Tromso",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "TRD",
    "city": "Trondheim",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "TTD",
    "city": "Troutdale",
    "region": "Oregon",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "TOI",
    "city": "Troy",
    "region": "Alabama",
    "tz": "America/Chicago"
  },
  {
    "iata": "QYR",
    "city": "Troyes",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "TKF",
    "city": "Truckee",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "TRU",
    "city": "Trujillo",
    "region": "Peru",
    "tz": "America/Lima"
  },
  {
    "iata": "TCS",
    "city": "Truth Or Consequences",
    "region": "New Mexico",
    "tz": "America/Denver"
  },
  {
    "iata": "TTS",
    "city": "Tsaratanana",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "TSE",
    "city": "Tselinograd",
    "region": "Kazakhstan",
    "tz": "Asia/Almaty"
  },
  {
    "iata": "TSH",
    "city": "Tshikapa",
    "region": "Congo (Kinshasa)",
    "tz": "Africa/Lubumbashi"
  },
  {
    "iata": "WTS",
    "city": "Tsiroanomandidy",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "TSB",
    "city": "Tsumeb",
    "region": "Namibia",
    "tz": "Africa/Windhoek"
  },
  {
    "iata": "TSJ",
    "city": "Tsushima",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "TUB",
    "city": "Tubuai",
    "region": "French Polynesia",
    "tz": "Pacific/Tahiti"
  },
  {
    "iata": "AVW",
    "city": "Tucson",
    "region": "Arizona",
    "tz": "America/Phoenix"
  },
  {
    "iata": "DMA",
    "city": "Tucson",
    "region": "Arizona",
    "tz": "America/Phoenix"
  },
  {
    "iata": "TUS",
    "city": "Tucson",
    "region": "Arizona",
    "tz": "America/Phoenix"
  },
  {
    "iata": "TUC",
    "city": "Tucuman",
    "region": "Argentina",
    "tz": "America/Argentina/Tucuman"
  },
  {
    "iata": "TCC",
    "city": "Tucumcari",
    "region": "New Mexico",
    "tz": "America/Denver"
  },
  {
    "iata": "TUV",
    "city": "Tucupita",
    "region": "Venezuela",
    "tz": "America/Caracas"
  },
  {
    "iata": "TUR",
    "city": "Tucurui",
    "region": "Brazil",
    "tz": "America/Belem"
  },
  {
    "iata": "TUG",
    "city": "Tuguegarao",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "YUB",
    "city": "Tuktoyaktuk",
    "region": "Canada",
    "tz": "America/Yellowknife"
  },
  {
    "iata": "TUA",
    "city": "Tulcan",
    "region": "Ecuador",
    "tz": "America/Guayaquil"
  },
  {
    "iata": "TCE",
    "city": "Tulcea",
    "region": "Romania",
    "tz": "Europe/Bucharest"
  },
  {
    "iata": "TLD",
    "city": "Tuli Lodge",
    "region": "Botswana",
    "tz": "Africa/Gaborone"
  },
  {
    "iata": "ZFN",
    "city": "Tulita",
    "region": "Canada",
    "tz": "America/Yellowknife"
  },
  {
    "iata": "RVS",
    "city": "Tulsa",
    "region": "Oklahoma",
    "tz": "America/Chicago"
  },
  {
    "iata": "TUL",
    "city": "Tulsa",
    "region": "Oklahoma",
    "tz": "America/Chicago"
  },
  {
    "iata": "ULQ",
    "city": "Tulua",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "TCO",
    "city": "Tumaco",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "TBP",
    "city": "Tumbes",
    "region": "Peru",
    "tz": "America/Lima"
  },
  {
    "iata": "TMI",
    "city": "Tumling Tar",
    "region": "Nepal",
    "tz": "Asia/Kathmandu"
  },
  {
    "iata": "UTM",
    "city": "Tunica",
    "region": "Mississippi",
    "tz": "America/Chicago"
  },
  {
    "iata": "TUN",
    "city": "Tunis",
    "region": "Tunisia",
    "tz": "Africa/Tunis"
  },
  {
    "iata": "TUP",
    "city": "Tupelo",
    "region": "Mississippi",
    "tz": "America/Chicago"
  },
  {
    "iata": "TUI",
    "city": "Turaif",
    "region": "Saudi Arabia",
    "tz": "Asia/Riyadh"
  },
  {
    "iata": "TUK",
    "city": "Turbat",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "ZTA",
    "city": "Tureia",
    "region": "French Polynesia",
    "tz": "Pacific/Tahiti"
  },
  {
    "iata": "TKU",
    "city": "Turku",
    "region": "Finland",
    "tz": "Europe/Helsinki"
  },
  {
    "iata": "TLQ",
    "city": "Turpan",
    "region": "China",
    "tz": "Asia/Urumqi"
  },
  {
    "iata": "TCL",
    "city": "Tuscaloosa AL",
    "region": "Alabama",
    "tz": "America/Chicago"
  },
  {
    "iata": "TGZ",
    "city": "Tuxtla Gutierrez",
    "region": "Mexico",
    "tz": "America/Mexico_City"
  },
  {
    "iata": "TBB",
    "city": "Tuy Hoa",
    "region": "Vietnam",
    "tz": "Asia/Ho_Chi_Minh"
  },
  {
    "iata": "KLD",
    "city": "Tver",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "TWF",
    "city": "Twin Falls",
    "region": "Idaho",
    "tz": "America/Boise"
  },
  {
    "iata": "TYR",
    "city": "Tyler",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "TJM",
    "city": "Tyumen",
    "region": "Russia",
    "tz": "Asia/Yekaterinburg"
  },
  {
    "iata": "LTA",
    "city": "Tzaneen",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "ZTB",
    "city": "T\u00eate-\u00e0-la-Baleine",
    "region": "Canada",
    "tz": "America/Blanc-Sablon"
  },
  {
    "iata": "UAH",
    "city": "Ua Huka",
    "region": "French Polynesia",
    "tz": "Pacific/Marquesas"
  },
  {
    "iata": "UAP",
    "city": "Ua Pou",
    "region": "French Polynesia",
    "tz": "Pacific/Marquesas"
  },
  {
    "iata": "UBT",
    "city": "Ubatuba",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "UBA",
    "city": "Uberaba",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "UDI",
    "city": "Uberlandia",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "UBP",
    "city": "Ubon Ratchathani",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "UDR",
    "city": "Udaipur",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "UTH",
    "city": "Udon Thani",
    "region": "Thailand",
    "tz": "Asia/Bangkok"
  },
  {
    "iata": "UFA",
    "city": "Ufa",
    "region": "Russia",
    "tz": "Asia/Yekaterinburg"
  },
  {
    "iata": "UGO",
    "city": "Uige",
    "region": "Angola",
    "tz": "Africa/Luanda"
  },
  {
    "iata": "UJE",
    "city": "Ujae Atoll",
    "region": "Marshall Islands",
    "tz": "Pacific/Kwajalein"
  },
  {
    "iata": "UPG",
    "city": "Ujung Pandang",
    "region": "Indonesia",
    "tz": "Asia/Makassar"
  },
  {
    "iata": "UCT",
    "city": "Ukhta",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "UKI",
    "city": "Ukiah",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "UKA",
    "city": "Ukunda",
    "region": "Kenya",
    "tz": "Africa/Nairobi"
  },
  {
    "iata": "ULO",
    "city": "Ulaangom",
    "region": "Mongolia",
    "tz": "Asia/Hovd"
  },
  {
    "iata": "ULN",
    "city": "Ulan Bator",
    "region": "Mongolia",
    "tz": "Asia/Ulaanbaatar"
  },
  {
    "iata": "UUD",
    "city": "Ulan-ude",
    "region": "Russia",
    "tz": "Asia/Irkutsk"
  },
  {
    "iata": "HLH",
    "city": "Ulanhot",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "RNA",
    "city": "Ulawa",
    "region": "Solomon Islands",
    "tz": "Pacific/Guadalcanal"
  },
  {
    "iata": "USN",
    "city": "Ulsan",
    "region": "South Korea",
    "tz": "Asia/Seoul"
  },
  {
    "iata": "ULD",
    "city": "Ulundi",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "AYQ",
    "city": "Uluru",
    "region": "Australia",
    "tz": "Australia/Darwin"
  },
  {
    "iata": "ULV",
    "city": "Ulyanovsk",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "ULY",
    "city": "Ulyanovsk",
    "region": "Russia",
    "tz": "Europe/Volgograd"
  },
  {
    "iata": "ULS",
    "city": "Ulysses",
    "region": "United States",
    "tz": "America/Bogota"
  },
  {
    "iata": "UME",
    "city": "Umea",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "YUD",
    "city": "Umiujaq",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "UTT",
    "city": "Umtata",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "UMU",
    "city": "Umuarama",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "UNA",
    "city": "Una",
    "region": "Brazil",
    "tz": "America/Bahia"
  },
  {
    "iata": "UNK",
    "city": "Unalakleet",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "DUT",
    "city": "Unalaska",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "UNI",
    "city": "Union Island",
    "region": "Saint Vincent and the Grenadines",
    "tz": "America/St_Vincent"
  },
  {
    "iata": "UNT",
    "city": "Unst",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "UPL",
    "city": "Upala",
    "region": "Costa Rica",
    "tz": "America/Costa_Rica"
  },
  {
    "iata": "JUV",
    "city": "Upernavik",
    "region": "Greenland",
    "tz": "America/Godthab"
  },
  {
    "iata": "UTN",
    "city": "Upington",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "CCB",
    "city": "Upland",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "URJ",
    "city": "Uraj",
    "region": "Russia",
    "tz": "Asia/Yekaterinburg"
  },
  {
    "iata": "URA",
    "city": "Uralsk",
    "region": "Kazakhstan",
    "tz": "Asia/Oral"
  },
  {
    "iata": "YBE",
    "city": "Uranium City",
    "region": "Canada",
    "tz": "America/Regina"
  },
  {
    "iata": "UGC",
    "city": "Urgench",
    "region": "Uzbekistan",
    "tz": "Asia/Samarkand"
  },
  {
    "iata": "OMH",
    "city": "Uromiyeh",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "UPN",
    "city": "Uruapan",
    "region": "Mexico",
    "tz": "America/Mexico_City"
  },
  {
    "iata": "URG",
    "city": "Uruguaiana",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "URC",
    "city": "Urumqi",
    "region": "China",
    "tz": "Asia/Urumqi"
  },
  {
    "iata": "USQ",
    "city": "Usak",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "USH",
    "city": "Ushuaia",
    "region": "Argentina",
    "tz": "America/Argentina/Ushuaia"
  },
  {
    "iata": "USK",
    "city": "Usinsk",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "UIK",
    "city": "Ust Ilimsk",
    "region": "Russia",
    "tz": "Asia/Irkutsk"
  },
  {
    "iata": "UKK",
    "city": "Ust Kamenogorsk",
    "region": "Kazakhstan",
    "tz": "Asia/Almaty"
  },
  {
    "iata": "UKX",
    "city": "Ust-Kut",
    "region": "Russia",
    "tz": "Asia/Irkutsk"
  },
  {
    "iata": "UMS",
    "city": "Ust-Maya",
    "region": "Russia",
    "tz": "Asia/Vladivostok"
  },
  {
    "iata": "UII",
    "city": "Utila",
    "region": "Honduras",
    "tz": "America/Tegucigalpa"
  },
  {
    "iata": "UTK",
    "city": "Utirik Island",
    "region": "Marshall Islands",
    "tz": "Pacific/Kwajalein"
  },
  {
    "iata": "JQA",
    "city": "Uummannaq",
    "region": "Greenland",
    "tz": "America/Godthab"
  },
  {
    "iata": "UMD",
    "city": "Uummannaq",
    "region": "Greenland",
    "tz": "America/Godthab"
  },
  {
    "iata": "UVA",
    "city": "Uvalde",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "QUO",
    "city": "Uyo",
    "region": "Nigeria",
    "tz": "Africa/Lagos"
  },
  {
    "iata": "UYU",
    "city": "Uyuni",
    "region": "Bolivia",
    "tz": "America/La_Paz"
  },
  {
    "iata": "UDJ",
    "city": "Uzhgorod",
    "region": "Ukraine",
    "tz": "Europe/Uzhgorod"
  },
  {
    "iata": "VAA",
    "city": "Vaasa",
    "region": "Finland",
    "tz": "Europe/Helsinki"
  },
  {
    "iata": "VDS",
    "city": "Vads\u00f8",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "FAE",
    "city": "Vagar",
    "region": "Faroe Islands",
    "tz": "Atlantic/Faroe"
  },
  {
    "iata": "EGE",
    "city": "Vail",
    "region": "Colorado",
    "tz": "America/Denver"
  },
  {
    "iata": "YVO",
    "city": "Val D'or",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "VDZ",
    "city": "Valdez",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "ZAL",
    "city": "Valdivia",
    "region": "Chile",
    "tz": "America/Santiago"
  },
  {
    "iata": "VAD",
    "city": "Valdosta",
    "region": "Georgia",
    "tz": "America/New_York"
  },
  {
    "iata": "VLD",
    "city": "Valdosta",
    "region": "Georgia",
    "tz": "America/New_York"
  },
  {
    "iata": "VAL",
    "city": "Valenca",
    "region": "Brazil",
    "tz": "America/Bahia"
  },
  {
    "iata": "VAF",
    "city": "Valence",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "VLC",
    "city": "Valencia",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "VLN",
    "city": "Valencia",
    "region": "Venezuela",
    "tz": "America/Caracas"
  },
  {
    "iata": "VTN",
    "city": "Valentine",
    "region": "Nebraska",
    "tz": "America/Chicago"
  },
  {
    "iata": "VLV",
    "city": "Valera",
    "region": "Venezuela",
    "tz": "America/Caracas"
  },
  {
    "iata": "VLS",
    "city": "Valesdir",
    "region": "Vanuatu",
    "tz": "Pacific/Efate"
  },
  {
    "iata": "LID",
    "city": "Valkenburg",
    "region": "Netherlands",
    "tz": "Europe/Amsterdam"
  },
  {
    "iata": "VLL",
    "city": "Valladolid",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "VDP",
    "city": "Valle De La Pascua",
    "region": "Venezuela",
    "tz": "America/Caracas"
  },
  {
    "iata": "VUP",
    "city": "Valledupar",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "VPS",
    "city": "Valparaiso",
    "region": "Florida",
    "tz": "America/Chicago"
  },
  {
    "iata": "VPZ",
    "city": "Valparaiso IN",
    "region": "Indiana",
    "tz": "America/Chicago"
  },
  {
    "iata": "VAN",
    "city": "Van",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "VNY",
    "city": "Van Nuys",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "CXH",
    "city": "Vancouver",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "YVR",
    "city": "Vancouver",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "VAI",
    "city": "Vanimo",
    "region": "Papua New Guinea",
    "tz": "Pacific/Port_Moresby"
  },
  {
    "iata": "VNE",
    "city": "Vannes",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "VBV",
    "city": "Vanua Balavu",
    "region": "Fiji",
    "tz": "Pacific/Fiji"
  },
  {
    "iata": "VRA",
    "city": "Varadero",
    "region": "Cuba",
    "tz": "America/Havana"
  },
  {
    "iata": "VNS",
    "city": "Varanasi",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "VAG",
    "city": "Varginha",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "VRK",
    "city": "Varkaus",
    "region": "Finland",
    "tz": "Europe/Helsinki"
  },
  {
    "iata": "VAR",
    "city": "Varna",
    "region": "Bulgaria",
    "tz": "Europe/Sofia"
  },
  {
    "iata": "VST",
    "city": "Vasteras",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "VAV",
    "city": "Vava'u",
    "region": "Tonga",
    "tz": "Pacific/Tongatapu"
  },
  {
    "iata": "VXO",
    "city": "Vaxjo",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "VLU",
    "city": "Velikiye Luki",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "VEE",
    "city": "Venetie",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "VNC",
    "city": "Venice",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "VCE",
    "city": "Venice",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "VER",
    "city": "Vera Cruz",
    "region": "Mexico",
    "tz": "America/Mexico_City"
  },
  {
    "iata": "MVV",
    "city": "Verdun",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "YVG",
    "city": "Vermillion",
    "region": "Canada",
    "tz": "America/Edmonton"
  },
  {
    "iata": "VEL",
    "city": "Vernal",
    "region": "Utah",
    "tz": "America/Denver"
  },
  {
    "iata": "YVE",
    "city": "Vernon",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "VRB",
    "city": "Vero Beach",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "VEY",
    "city": "Vestmannaeyjar",
    "region": "Iceland",
    "tz": "Atlantic/Reykjavik"
  },
  {
    "iata": "VIC",
    "city": "Vicenza",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "VHY",
    "city": "Vichy",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "YYJ",
    "city": "Victoria",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "ZIC",
    "city": "Victoria",
    "region": "Chile",
    "tz": "America/Santiago"
  },
  {
    "iata": "VCT",
    "city": "Victoria",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "VFA",
    "city": "Victoria Falls",
    "region": "Zimbabwe",
    "tz": "Africa/Harare"
  },
  {
    "iata": "VCD",
    "city": "Victoria River Downs",
    "region": "Australia",
    "tz": "Australia/Darwin"
  },
  {
    "iata": "VCV",
    "city": "Victorville",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "VDM",
    "city": "Viedma",
    "region": "Argentina",
    "tz": "America/Argentina/Salta"
  },
  {
    "iata": "VIE",
    "city": "Vienna",
    "region": "Austria",
    "tz": "Europe/Vienna"
  },
  {
    "iata": "VTE",
    "city": "Vientiane",
    "region": "Laos",
    "tz": "Asia/Vientiane"
  },
  {
    "iata": "VQS",
    "city": "Vieques Island",
    "region": "Puerto Rico",
    "tz": "America/Puerto_Rico"
  },
  {
    "iata": "VGO",
    "city": "Vigo",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "VGA",
    "city": "Vijayawada",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "VRL",
    "city": "Vila Real",
    "region": "Portugal",
    "tz": "Europe/Lisbon"
  },
  {
    "iata": "VLP",
    "city": "Vila Rica",
    "region": "Brazil",
    "tz": "America/Cuiaba"
  },
  {
    "iata": "VNX",
    "city": "Vilankulu",
    "region": "Mozambique",
    "tz": "Africa/Maputo"
  },
  {
    "iata": "XVF",
    "city": "Vilefrance",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "VHM",
    "city": "Vilhelmina",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "BVH",
    "city": "Vilhena",
    "region": "Brazil",
    "tz": "America/Cuiaba"
  },
  {
    "iata": "VDR",
    "city": "Villa Dolores",
    "region": "Argentina",
    "tz": "America/Argentina/Cordoba"
  },
  {
    "iata": "VGZ",
    "city": "Villa Garzon",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "VLG",
    "city": "Villa Gesell",
    "region": "Argentina",
    "tz": "America/Argentina/Buenos_Aires"
  },
  {
    "iata": "VRN",
    "city": "Villafranca",
    "region": "Italy",
    "tz": "Europe/Rome"
  },
  {
    "iata": "VSA",
    "city": "Villahermosa",
    "region": "Mexico",
    "tz": "America/Merida"
  },
  {
    "iata": "VVC",
    "city": "Villavicencio",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "VNO",
    "city": "Vilnius",
    "region": "Lithuania",
    "tz": "Europe/Vilnius"
  },
  {
    "iata": "VYI",
    "city": "Vilyuisk",
    "region": "Russia",
    "tz": "Asia/Yakutsk"
  },
  {
    "iata": "MVY",
    "city": "Vineyard Haven MA",
    "region": "Massachusetts",
    "tz": "America/New_York"
  },
  {
    "iata": "VII",
    "city": "Vinh",
    "region": "Vietnam",
    "tz": "Asia/Ho_Chi_Minh"
  },
  {
    "iata": "VIN",
    "city": "Vinnitsa",
    "region": "Ukraine",
    "tz": "Europe/Kiev"
  },
  {
    "iata": "VRC",
    "city": "Virac",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "YVD",
    "city": "Virden",
    "region": "Canada",
    "tz": "Pacific/Port_Moresby"
  },
  {
    "iata": "VIS",
    "city": "Visalia",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "VBY",
    "city": "Visby",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "VTZ",
    "city": "Vishakhapatnam",
    "region": "India",
    "tz": "Asia/Kolkata"
  },
  {
    "iata": "VTB",
    "city": "Vitebsk",
    "region": "Belarus",
    "tz": "Europe/Minsk"
  },
  {
    "iata": "VIX",
    "city": "Vitoria",
    "region": "Brazil",
    "tz": "America/Sao_Paulo"
  },
  {
    "iata": "VIT",
    "city": "Vitoria",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "VDC",
    "city": "Vit\u00f3ria Da Conquista",
    "region": "Brazil",
    "tz": "America/Bahia"
  },
  {
    "iata": "VVO",
    "city": "Vladivostok",
    "region": "Russia",
    "tz": "Asia/Vladivostok"
  },
  {
    "iata": "VOH",
    "city": "Vohemar",
    "region": "Madagascar",
    "tz": "Indian/Antananarivo"
  },
  {
    "iata": "VOG",
    "city": "Volgograd",
    "region": "Russia",
    "tz": "Europe/Volgograd"
  },
  {
    "iata": "VGD",
    "city": "Vologda",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "VPN",
    "city": "Vopnafj\u00f6r\u00f0ur",
    "region": "Iceland",
    "tz": "Atlantic/Reykjavik"
  },
  {
    "iata": "VKT",
    "city": "Vorkuta",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "VOZ",
    "city": "Voronezh",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "VRU",
    "city": "Vryburg",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "KDV",
    "city": "Vunisea",
    "region": "Fiji",
    "tz": "Pacific/Fiji"
  },
  {
    "iata": "VRY",
    "city": "V\u00e6r\u00f8y",
    "region": "Norway",
    "tz": "Europe/Oslo"
  },
  {
    "iata": "BMY",
    "city": "Waala",
    "region": "New Caledonia",
    "tz": "Pacific/Noumea"
  },
  {
    "iata": "YWK",
    "city": "Wabush",
    "region": "Canada",
    "tz": "America/Goose_Bay"
  },
  {
    "iata": "ACT",
    "city": "Waco",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "CNW",
    "city": "Waco",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "WTN",
    "city": "Waddington",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "PKT",
    "city": "Wadeye",
    "region": "Australia",
    "tz": "Australia/Darwin"
  },
  {
    "iata": "WHF",
    "city": "Wadi Halfa",
    "region": "Sudan",
    "tz": "Africa/Khartoum"
  },
  {
    "iata": "EWD",
    "city": "Wadi-al-dawasir",
    "region": "Saudi Arabia",
    "tz": "America/Anchorage"
  },
  {
    "iata": "AGI",
    "city": "Wageningen",
    "region": "Suriname",
    "tz": "America/Paramaribo"
  },
  {
    "iata": "WGA",
    "city": "Wagga Wagga",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "HHI",
    "city": "Wahiawa",
    "region": "Hawaii",
    "tz": "Pacific/Honolulu"
  },
  {
    "iata": "TMC",
    "city": "Waikabubak-Sumba Island",
    "region": "Indonesia",
    "tz": "Asia/Makassar"
  },
  {
    "iata": "WGP",
    "city": "Waingapu",
    "region": "Indonesia",
    "tz": "Asia/Makassar"
  },
  {
    "iata": "AIN",
    "city": "Wainwright",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "NTQ",
    "city": "Wajima",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "WJR",
    "city": "Wajir",
    "region": "Kenya",
    "tz": "Africa/Nairobi"
  },
  {
    "iata": "AWK",
    "city": "Wake island",
    "region": "Wake Island",
    "tz": "Pacific/Wake"
  },
  {
    "iata": "WKJ",
    "city": "Wakkanai",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "WLH",
    "city": "Walaha",
    "region": "Vanuatu",
    "tz": "Pacific/Efate"
  },
  {
    "iata": "WAA",
    "city": "Wales",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "WGE",
    "city": "Walgett",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "WKR",
    "city": "Walker's Cay",
    "region": "Bahamas",
    "tz": "America/Nassau"
  },
  {
    "iata": "ALW",
    "city": "Walla Walla",
    "region": "Washington",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "WLS",
    "city": "Wallis",
    "region": "Wallis and Futuna",
    "tz": "Pacific/Wallis"
  },
  {
    "iata": "WVB",
    "city": "Walvis Bay",
    "region": "Namibia",
    "tz": "Africa/Windhoek"
  },
  {
    "iata": "WMX",
    "city": "Wamena",
    "region": "Indonesia",
    "tz": "Asia/Jayapura"
  },
  {
    "iata": "WKA",
    "city": "Wanaka",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "WOT",
    "city": "Wang An",
    "region": "Taiwan",
    "tz": "Asia/Taipei"
  },
  {
    "iata": "WAG",
    "city": "Wanganui",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "AGE",
    "city": "Wangerooge",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "WXN",
    "city": "Wanxian",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "WBM",
    "city": "Wapenamanda",
    "region": "Papua New Guinea",
    "tz": "Pacific/Port_Moresby"
  },
  {
    "iata": "WAR",
    "city": "Waris-Papua Island",
    "region": "Indonesia",
    "tz": "Asia/Jayapura"
  },
  {
    "iata": "WMB",
    "city": "Warrnambool",
    "region": "Australia",
    "tz": "Australia/Melbourne"
  },
  {
    "iata": "WAW",
    "city": "Warsaw",
    "region": "Poland",
    "tz": "Europe/Warsaw"
  },
  {
    "iata": "WMI",
    "city": "Warsaw",
    "region": "Poland",
    "tz": "Europe/Warsaw"
  },
  {
    "iata": "DCA",
    "city": "Washington",
    "region": "District of Columbia",
    "tz": "America/New_York"
  },
  {
    "iata": "IAD",
    "city": "Washington",
    "region": "District of Columbia",
    "tz": "America/New_York"
  },
  {
    "iata": "WST",
    "city": "Washington County",
    "region": "Rhode Island",
    "tz": "America/New_York"
  },
  {
    "iata": "WSR",
    "city": "Wasior",
    "region": "Indonesia",
    "tz": "Asia/Jayapura"
  },
  {
    "iata": "YKQ",
    "city": "Waskaganish",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "WSP",
    "city": "Waspam",
    "region": "Nicaragua",
    "tz": "America/Managua"
  },
  {
    "iata": "CWA",
    "city": "Wassau",
    "region": "Wisconsin",
    "tz": "America/Chicago"
  },
  {
    "iata": "WAT",
    "city": "Waterford",
    "region": "Ireland",
    "tz": "Europe/Dublin"
  },
  {
    "iata": "YKF",
    "city": "Waterloo",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "ALO",
    "city": "Waterloo",
    "region": "Iowa",
    "tz": "America/Chicago"
  },
  {
    "iata": "ART",
    "city": "Watertown",
    "region": "New York",
    "tz": "America/New_York"
  },
  {
    "iata": "ATY",
    "city": "Watertown",
    "region": "South Dakota",
    "tz": "America/Chicago"
  },
  {
    "iata": "YQH",
    "city": "Watson Lake",
    "region": "Canada",
    "tz": "America/Whitehorse"
  },
  {
    "iata": "WVI",
    "city": "Watsonville",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "WUU",
    "city": "Wau",
    "region": "Sudan",
    "tz": "Africa/Juba"
  },
  {
    "iata": "AUW",
    "city": "Wausau",
    "region": "Wisconsin",
    "tz": "America/Chicago"
  },
  {
    "iata": "YXZ",
    "city": "Wawa",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "YWP",
    "city": "Webequie",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "NRN",
    "city": "Weeze",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "WEF",
    "city": "Weifang",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "WEH",
    "city": "Weihai",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "WEI",
    "city": "Weipa",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "EJH",
    "city": "Wejh",
    "region": "Saudi Arabia",
    "tz": "Asia/Riyadh"
  },
  {
    "iata": "YFJ",
    "city": "Wekweeti",
    "region": "Canada",
    "tz": "America/Yellowknife"
  },
  {
    "iata": "WEL",
    "city": "Welkom",
    "region": "South Africa",
    "tz": "Africa/Johannesburg"
  },
  {
    "iata": "WLG",
    "city": "Wellington",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "YNC",
    "city": "Wemindji",
    "region": "Canada",
    "tz": "America/Montreal"
  },
  {
    "iata": "EAT",
    "city": "Wenatchee",
    "region": "Washington",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "ENV",
    "city": "Wendover",
    "region": "Utah",
    "tz": "America/Denver"
  },
  {
    "iata": "WNZ",
    "city": "Wenzhou",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "DPA",
    "city": "West Chicago",
    "region": "Illinois",
    "tz": "America/Chicago"
  },
  {
    "iata": "OQN",
    "city": "West Goshen Township",
    "region": "United States",
    "tz": "America/New_York"
  },
  {
    "iata": "FOK",
    "city": "West Hampton Beach",
    "region": "New York",
    "tz": "America/New_York"
  },
  {
    "iata": "LNA",
    "city": "West Palm Beach",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "PBI",
    "city": "West Palm Beach",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "TIQ",
    "city": "West Tinian",
    "region": "Northern Mariana Islands",
    "tz": "Pacific/Saipan"
  },
  {
    "iata": "WWY",
    "city": "West Wyalong",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "WYS",
    "city": "West Yellowstone",
    "region": "Montana",
    "tz": "America/Denver"
  },
  {
    "iata": "GWT",
    "city": "Westerland",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "BAF",
    "city": "Westfield",
    "region": "Massachusetts",
    "tz": "America/New_York"
  },
  {
    "iata": "WSZ",
    "city": "Westport",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "WRY",
    "city": "Westray",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "WWK",
    "city": "Wewak",
    "region": "Papua New Guinea",
    "tz": "Pacific/Port_Moresby"
  },
  {
    "iata": "SHD",
    "city": "Weyers Cave",
    "region": "Virginia",
    "tz": "America/New_York"
  },
  {
    "iata": "WHK",
    "city": "Whakatane",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "YXN",
    "city": "Whale Cove",
    "region": "Canada",
    "tz": "America/Rankin_Inlet"
  },
  {
    "iata": "WRE",
    "city": "Whangarei",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "YLE",
    "city": "What\u00ec",
    "region": "Canada",
    "tz": "America/Yellowknife"
  },
  {
    "iata": "HLG",
    "city": "Wheeling",
    "region": "West Virginia",
    "tz": "America/New_York"
  },
  {
    "iata": "NUW",
    "city": "Whidbey Island",
    "region": "Washington",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "WMO",
    "city": "White Mountain",
    "region": "Alaska",
    "tz": "America/Nome"
  },
  {
    "iata": "HPN",
    "city": "White Plains",
    "region": "New York",
    "tz": "America/New_York"
  },
  {
    "iata": "WSD",
    "city": "White Sands",
    "region": "New Mexico",
    "tz": "America/Denver"
  },
  {
    "iata": "YZU",
    "city": "Whitecourt",
    "region": "Canada",
    "tz": "America/Edmonton"
  },
  {
    "iata": "YXY",
    "city": "Whitehorse",
    "region": "Canada",
    "tz": "America/Whitehorse"
  },
  {
    "iata": "WYA",
    "city": "Whyalla",
    "region": "Australia",
    "tz": "Australia/Adelaide"
  },
  {
    "iata": "YVV",
    "city": "Wiarton",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "BEC",
    "city": "Wichita",
    "region": "Kansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "IAB",
    "city": "Wichita",
    "region": "Kansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "ICT",
    "city": "Wichita",
    "region": "Kansas",
    "tz": "America/Chicago"
  },
  {
    "iata": "SPS",
    "city": "Wichita Falls",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "WIC",
    "city": "Wick",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "ASI",
    "city": "Wide Awake",
    "region": "Saint Helena",
    "tz": "Atlantic/St_Helena"
  },
  {
    "iata": "WIO",
    "city": "Wilcannia",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "WWD",
    "city": "Wildwood",
    "region": "New Jersey",
    "tz": "America/New_York"
  },
  {
    "iata": "WVN",
    "city": "Wilhelmshaven",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "WBW",
    "city": "Wilkes-Barre",
    "region": "Pennsylvania",
    "tz": "America/New_York"
  },
  {
    "iata": "CUR",
    "city": "Willemstad",
    "region": "Netherlands Antilles",
    "tz": "America/Curacao"
  },
  {
    "iata": "YWM",
    "city": "Williams Harbour",
    "region": "Canada",
    "tz": "America/St_Johns"
  },
  {
    "iata": "YWL",
    "city": "Williams Lake",
    "region": "Canada",
    "tz": "America/Vancouver"
  },
  {
    "iata": "SDC",
    "city": "Williamson",
    "region": "United States",
    "tz": "America/Guyana"
  },
  {
    "iata": "IPT",
    "city": "Williamsport",
    "region": "Pennsylvania",
    "tz": "America/New_York"
  },
  {
    "iata": "ISN",
    "city": "Williston",
    "region": "North Dakota",
    "tz": "America/Chicago"
  },
  {
    "iata": "LNN",
    "city": "Willoughby",
    "region": "Ohio",
    "tz": "America/New_York"
  },
  {
    "iata": "NXX",
    "city": "Willow Grove",
    "region": "United States",
    "tz": "America/New_York"
  },
  {
    "iata": "ILG",
    "city": "Wilmington",
    "region": "Delaware",
    "tz": "America/New_York"
  },
  {
    "iata": "ILM",
    "city": "Wilmington",
    "region": "North Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "ILN",
    "city": "Wilmington",
    "region": "Ohio",
    "tz": "America/New_York"
  },
  {
    "iata": "WUN",
    "city": "Wiluna",
    "region": "Australia",
    "tz": "Australia/Perth"
  },
  {
    "iata": "WDR",
    "city": "Winder",
    "region": "Georgia",
    "tz": "America/New_York"
  },
  {
    "iata": "ERS",
    "city": "Windhoek",
    "region": "Namibia",
    "tz": "Africa/Windhoek"
  },
  {
    "iata": "WDH",
    "city": "Windhoek",
    "region": "Namibia",
    "tz": "Africa/Windhoek"
  },
  {
    "iata": "MWM",
    "city": "Windom",
    "region": "United States",
    "tz": "America/Chicago"
  },
  {
    "iata": "WNR",
    "city": "Windorah",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "YQG",
    "city": "Windsor",
    "region": "Canada",
    "tz": "America/Toronto"
  },
  {
    "iata": "BDL",
    "city": "Windsor Locks",
    "region": "Connecticut",
    "tz": "America/New_York"
  },
  {
    "iata": "INK",
    "city": "Wink",
    "region": "Texas",
    "tz": "America/Chicago"
  },
  {
    "iata": "WMC",
    "city": "Winnemucca",
    "region": "Nevada",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "YWG",
    "city": "Winnipeg",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "INW",
    "city": "Winslow",
    "region": "Arizona",
    "tz": "America/Phoenix"
  },
  {
    "iata": "INT",
    "city": "Winston-salem",
    "region": "North Carolina",
    "tz": "America/New_York"
  },
  {
    "iata": "GIF",
    "city": "Winter Haven",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "WIN",
    "city": "Winton",
    "region": "Australia",
    "tz": "Australia/Brisbane"
  },
  {
    "iata": "WRZ",
    "city": "Wirawila",
    "region": "Sri Lanka",
    "tz": "Asia/Colombo"
  },
  {
    "iata": "ISW",
    "city": "Wisconsin Rapids",
    "region": "Wisconsin",
    "tz": "America/Chicago"
  },
  {
    "iata": "WOE",
    "city": "Woensdrecht",
    "region": "Netherlands",
    "tz": "Europe/Amsterdam"
  },
  {
    "iata": "OLF",
    "city": "Wolf Point",
    "region": "Montana",
    "tz": "America/Denver"
  },
  {
    "iata": "ZWL",
    "city": "Wollaston Lake",
    "region": "Canada",
    "tz": "America/Regina"
  },
  {
    "iata": "WOL",
    "city": "Wollongong",
    "region": "Australia",
    "tz": "Australia/Sydney"
  },
  {
    "iata": "WJU",
    "city": "Wonju",
    "region": "South Korea",
    "tz": "Asia/Seoul"
  },
  {
    "iata": "WOS",
    "city": "Wonsan",
    "region": "North Korea",
    "tz": "Asia/Pyongyang"
  },
  {
    "iata": "HZP",
    "city": "Wood Buffalo",
    "region": "Canada",
    "tz": "America/Edmonton"
  },
  {
    "iata": "BHE",
    "city": "Woodbourne",
    "region": "New Zealand",
    "tz": "Pacific/Auckland"
  },
  {
    "iata": "WWR",
    "city": "Woodward",
    "region": "Oklahoma",
    "tz": "America/Chicago"
  },
  {
    "iata": "UMR",
    "city": "Woomera",
    "region": "Australia",
    "tz": "Australia/Adelaide"
  },
  {
    "iata": "ORH",
    "city": "Worcester",
    "region": "Massachusetts",
    "tz": "America/New_York"
  },
  {
    "iata": "WRL",
    "city": "Worland",
    "region": "Wyoming",
    "tz": "America/Denver"
  },
  {
    "iata": "WRG",
    "city": "Wrangell",
    "region": "Alaska",
    "tz": "America/Sitka"
  },
  {
    "iata": "WRI",
    "city": "Wrightstown",
    "region": "New Jersey",
    "tz": "America/New_York"
  },
  {
    "iata": "YWY",
    "city": "Wrigley",
    "region": "Canada",
    "tz": "America/Yellowknife"
  },
  {
    "iata": "WRO",
    "city": "Wroclaw",
    "region": "Poland",
    "tz": "Europe/Warsaw"
  },
  {
    "iata": "WUA",
    "city": "Wuhai",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "WUH",
    "city": "Wuhan",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "WHU",
    "city": "Wuhu",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "WNN",
    "city": "Wunnumin Lake",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "WUX",
    "city": "Wuxi",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "WUS",
    "city": "Wuyishan",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "WUZ",
    "city": "Wuzhou",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "XGN",
    "city": "Xangongo",
    "region": "Angola",
    "tz": "Africa/Luanda"
  },
  {
    "iata": "XIY",
    "city": "Xi'an",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "SIA",
    "city": "Xi\\\\'AN",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "GXH",
    "city": "Xiahe city",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "XMN",
    "city": "Xiamen",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "XFN",
    "city": "Xiangfan",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "XIC",
    "city": "Xichang",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "XIL",
    "city": "Xilinhot",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "ACX",
    "city": "Xingyi",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "XNN",
    "city": "Xining",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "NLT",
    "city": "Xinyuan",
    "region": "China",
    "tz": "Asia/Urumqi"
  },
  {
    "iata": "XUZ",
    "city": "Xuzhou",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "BYC",
    "city": "Yacuiba",
    "region": "Bolivia",
    "tz": "America/La_Paz"
  },
  {
    "iata": "CYT",
    "city": "Yakataga",
    "region": "Alaska",
    "tz": "America/Anchorage"
  },
  {
    "iata": "YKM",
    "city": "Yakima",
    "region": "Washington",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "KUM",
    "city": "Yakushima",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "YAK",
    "city": "Yakutat",
    "region": "Alaska",
    "tz": "America/Yakutat"
  },
  {
    "iata": "PYJ",
    "city": "Yakutia",
    "region": "Russia",
    "tz": "Asia/Yakutsk"
  },
  {
    "iata": "YKS",
    "city": "Yakutsk",
    "region": "Russia",
    "tz": "Asia/Yakutsk"
  },
  {
    "iata": "XMY",
    "city": "Yam Island",
    "region": "Australia",
    "tz": "Australia/Lindeman"
  },
  {
    "iata": "GAJ",
    "city": "Yamagata",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "UBJ",
    "city": "Yamaguchi",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "ASK",
    "city": "Yamoussoukro",
    "region": "Cote d'Ivoire",
    "tz": "Africa/Abidjan"
  },
  {
    "iata": "ENY",
    "city": "Yan'an",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "YNZ",
    "city": "Yancheng",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "XYA",
    "city": "Yandina",
    "region": "Solomon Islands",
    "tz": "Pacific/Guadalcanal"
  },
  {
    "iata": "RGN",
    "city": "Yangon",
    "region": "Burma",
    "tz": "Asia/Rangoon"
  },
  {
    "iata": "YTY",
    "city": "Yangzhou",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "YNJ",
    "city": "Yanji",
    "region": "China",
    "tz": "Asia/Harbin"
  },
  {
    "iata": "YKN",
    "city": "Yankton",
    "region": "South Dakota",
    "tz": "America/Chicago"
  },
  {
    "iata": "YNT",
    "city": "Yantai",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "NSI",
    "city": "Yaounde",
    "region": "Cameroon",
    "tz": "Africa/Douala"
  },
  {
    "iata": "YAO",
    "city": "Yaounde",
    "region": "Cameroon",
    "tz": "Africa/Douala"
  },
  {
    "iata": "YAP",
    "city": "Yap",
    "region": "Micronesia",
    "tz": "Pacific/Chuuk"
  },
  {
    "iata": "YQI",
    "city": "Yarmouth",
    "region": "Canada",
    "tz": "America/Halifax"
  },
  {
    "iata": "IAR",
    "city": "Yaroslavl",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "YES",
    "city": "Yasuj",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "AZD",
    "city": "Yazd",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "XYE",
    "city": "Ye",
    "region": "Burma",
    "tz": "Asia/Rangoon"
  },
  {
    "iata": "YEC",
    "city": "Yechon",
    "region": "South Korea",
    "tz": "Asia/Seoul"
  },
  {
    "iata": "SVX",
    "city": "Yekaterinburg",
    "region": "Russia",
    "tz": "Asia/Yekaterinburg"
  },
  {
    "iata": "YZF",
    "city": "Yellowknife",
    "region": "Canada",
    "tz": "America/Yellowknife"
  },
  {
    "iata": "YNB",
    "city": "Yenbo",
    "region": "Saudi Arabia",
    "tz": "Asia/Riyadh"
  },
  {
    "iata": "WYE",
    "city": "Yengema",
    "region": "Sierra Leone",
    "tz": "Africa/Freetown"
  },
  {
    "iata": "YEI",
    "city": "Yenisehir",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "EIE",
    "city": "Yeniseysk",
    "region": "Russia",
    "tz": "Asia/Krasnoyarsk"
  },
  {
    "iata": "RSU",
    "city": "Yeosu",
    "region": "South Korea",
    "tz": "Asia/Seoul"
  },
  {
    "iata": "YEO",
    "city": "Yeovilton",
    "region": "United Kingdom",
    "tz": "Europe/London"
  },
  {
    "iata": "ERG",
    "city": "Yerbogachen",
    "region": "Russia",
    "tz": "Asia/Irkutsk"
  },
  {
    "iata": "EVN",
    "city": "Yerevan",
    "region": "Armenia",
    "tz": "Asia/Yerevan"
  },
  {
    "iata": "YBP",
    "city": "Yibin",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "YIH",
    "city": "Yichang",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "YIC",
    "city": "Yichun",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "INC",
    "city": "Yinchuan",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "LDS",
    "city": "Yinchun",
    "region": "China",
    "tz": "Asia/Harbin"
  },
  {
    "iata": "YKH",
    "city": "Yingkou",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "YIN",
    "city": "Yining",
    "region": "China",
    "tz": "Asia/Kashgar"
  },
  {
    "iata": "YIW",
    "city": "Yiwu",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "JOG",
    "city": "Yogyakarta",
    "region": "Indonesia",
    "tz": "Asia/Jakarta"
  },
  {
    "iata": "OKO",
    "city": "Yokota",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "YOL",
    "city": "Yola",
    "region": "Nigeria",
    "tz": "Africa/Lagos"
  },
  {
    "iata": "OGN",
    "city": "Yonaguni Jima",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "LLF",
    "city": "Yongzhou",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "EYP",
    "city": "Yopal",
    "region": "Colombia",
    "tz": "America/Bogota"
  },
  {
    "iata": "ZAC",
    "city": "York Landing",
    "region": "Canada",
    "tz": "America/Winnipeg"
  },
  {
    "iata": "OKR",
    "city": "Yorke Island",
    "region": "Australia",
    "tz": "Australia/Lindeman"
  },
  {
    "iata": "YQV",
    "city": "Yorkton",
    "region": "Canada",
    "tz": "America/Regina"
  },
  {
    "iata": "RNJ",
    "city": "Yoron",
    "region": "Japan",
    "tz": "Asia/Tokyo"
  },
  {
    "iata": "JOK",
    "city": "Yoshkar-Ola",
    "region": "Russia",
    "tz": "Europe/Moscow"
  },
  {
    "iata": "YNG",
    "city": "Youngstown",
    "region": "Ohio",
    "tz": "America/New_York"
  },
  {
    "iata": "MYV",
    "city": "Yuba City",
    "region": "California",
    "tz": "America/Los_Angeles"
  },
  {
    "iata": "YUE",
    "city": "Yuendumu",
    "region": "Australia",
    "tz": "Australia/Darwin"
  },
  {
    "iata": "UYN",
    "city": "Yulin",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "YUM",
    "city": "Yuma",
    "region": "Arizona",
    "tz": "America/Phoenix"
  },
  {
    "iata": "YCU",
    "city": "Yuncheng",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "YMS",
    "city": "Yurimaguas",
    "region": "Peru",
    "tz": "America/Lima"
  },
  {
    "iata": "YUS",
    "city": "Yushu",
    "region": "China",
    "tz": "Asia/Urumqi"
  },
  {
    "iata": "DEE",
    "city": "Yuzhno-Kurilsk",
    "region": "Russia",
    "tz": "Asia/Sakhalin"
  },
  {
    "iata": "UUS",
    "city": "Yuzhno-sakhalinsk",
    "region": "Russia",
    "tz": "Asia/Sakhalin"
  },
  {
    "iata": "APZ",
    "city": "ZAPALA",
    "region": "Argentina",
    "tz": "America/Argentina/Salta"
  },
  {
    "iata": "ACZ",
    "city": "Zabol",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "ZCL",
    "city": "Zacatecas",
    "region": "Mexico",
    "tz": "America/Monterrey"
  },
  {
    "iata": "ZAD",
    "city": "Zadar",
    "region": "Croatia",
    "tz": "Europe/Zagreb"
  },
  {
    "iata": "ZAG",
    "city": "Zagreb",
    "region": "Croatia",
    "tz": "Europe/Zagreb"
  },
  {
    "iata": "ZAH",
    "city": "Zahedan",
    "region": "Iran",
    "tz": "Asia/Tehran"
  },
  {
    "iata": "ZTH",
    "city": "Zakynthos",
    "region": "Greece",
    "tz": "Europe/Athens"
  },
  {
    "iata": "ZAM",
    "city": "Zamboanga",
    "region": "Philippines",
    "tz": "Asia/Manila"
  },
  {
    "iata": "ZMM",
    "city": "Zamora",
    "region": "Mexico",
    "tz": "America/Mexico_City"
  },
  {
    "iata": "PBM",
    "city": "Zandery",
    "region": "Suriname",
    "tz": "America/Paramaribo"
  },
  {
    "iata": "ZZV",
    "city": "Zanesville",
    "region": "Ohio",
    "tz": "America/New_York"
  },
  {
    "iata": "ZNZ",
    "city": "Zanzibar",
    "region": "Tanzania",
    "tz": "Africa/Dar_es_Salaam"
  },
  {
    "iata": "OZH",
    "city": "Zaporozhye",
    "region": "Ukraine",
    "tz": "Europe/Zaporozhye"
  },
  {
    "iata": "ZTU",
    "city": "Zaqatala",
    "region": "Azerbaijan",
    "tz": "Asia/Baku"
  },
  {
    "iata": "AFS",
    "city": "Zarafshan",
    "region": "Uzbekistan",
    "tz": "Asia/Samarkand"
  },
  {
    "iata": "ZAZ",
    "city": "Zaragoza",
    "region": "Spain",
    "tz": "Europe/Madrid"
  },
  {
    "iata": "ZAJ",
    "city": "Zaranj",
    "region": "Afghanistan",
    "tz": "Asia/Kabul"
  },
  {
    "iata": "ZAR",
    "city": "Zaria",
    "region": "Nigeria",
    "tz": "Africa/Lagos"
  },
  {
    "iata": "IAM",
    "city": "Zarzaitine",
    "region": "Algeria",
    "tz": "Africa/Algiers"
  },
  {
    "iata": "ZPH",
    "city": "Zephyrhills",
    "region": "Florida",
    "tz": "America/New_York"
  },
  {
    "iata": "ZHA",
    "city": "Zhanjiang",
    "region": "China",
    "tz": "Asia/Urumqi"
  },
  {
    "iata": "ZAT",
    "city": "Zhaotong",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "CGO",
    "city": "Zhengzhou",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "DZN",
    "city": "Zhezkazgan",
    "region": "Kazakhstan",
    "tz": "Asia/Almaty"
  },
  {
    "iata": "ZIX",
    "city": "Zhigansk",
    "region": "Russia",
    "tz": "Asia/Yakutsk"
  },
  {
    "iata": "HJJ",
    "city": "Zhijiang",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "PZH",
    "city": "Zhob",
    "region": "Pakistan",
    "tz": "Asia/Karachi"
  },
  {
    "iata": "ZHY",
    "city": "Zhongwei",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "HSN",
    "city": "Zhoushan",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "ZUH",
    "city": "Zhuhai",
    "region": "China",
    "tz": "Asia/Shanghai"
  },
  {
    "iata": "ZTR",
    "city": "Zhytomyr",
    "region": "Ukraine",
    "tz": "Europe/Kiev"
  },
  {
    "iata": "IEG",
    "city": "Zielona Gora",
    "region": "Poland",
    "tz": "Europe/Warsaw"
  },
  {
    "iata": "ZIG",
    "city": "Ziguinchor",
    "region": "Senegal",
    "tz": "Africa/Dakar"
  },
  {
    "iata": "ZIH",
    "city": "Zihuatanejo",
    "region": "Mexico",
    "tz": "America/Mexico_City"
  },
  {
    "iata": "ZND",
    "city": "Zinder",
    "region": "Niger",
    "tz": "Africa/Niamey"
  },
  {
    "iata": "ONQ",
    "city": "Zonguldak",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "OUZ",
    "city": "Zouerat",
    "region": "Mauritania",
    "tz": "Africa/Nouakchott"
  },
  {
    "iata": "ZYI",
    "city": "Zunyi",
    "region": "China",
    "tz": "Asia/Chongqing"
  },
  {
    "iata": "ZRH",
    "city": "Zurich",
    "region": "Switzerland",
    "tz": "Europe/Zurich"
  },
  {
    "iata": "ZQW",
    "city": "Zweibruecken",
    "region": "Germany",
    "tz": "Europe/Berlin"
  },
  {
    "iata": "ZKP",
    "city": "Zyryanka",
    "region": "Russia",
    "tz": "Asia/Magadan"
  },
  {
    "iata": "AGH",
    "city": "\u00c4ngelholm",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "TEQ",
    "city": "\u00c7orlu",
    "region": "Turkey",
    "tz": "Europe/Istanbul"
  },
  {
    "iata": "IDY",
    "city": "\u00cele d'Yeu",
    "region": "France",
    "tz": "Europe/Paris"
  },
  {
    "iata": "ILP",
    "city": "\u00cele des Pins",
    "region": "New Caledonia",
    "tz": "Pacific/Noumea"
  },
  {
    "iata": "OSD",
    "city": "\u00d6stersund",
    "region": "Sweden",
    "tz": "Europe/Stockholm"
  },
  {
    "iata": "ILZ",
    "city": "\u017dilina",
    "region": "Slovakia",
    "tz": "Europe/Bratislava"
  }
];
