import React from 'react';
import { Highlighter, Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import airports from './airports';

export default ({
  ...props
}) =>
  <Typeahead
    options={airports}
    labelKey={option => `${option.city}, ${option.region} (${option.iata})`}
    minLength={2}
    renderMenuItemChildren={(option, props, index) => [
      <Highlighter key="iata" search={props.text}>
        {`${option.city}, ${option.region}`}
      </Highlighter>,
      <div key="code" className="iata">
        <small>
          <Highlighter key="iata-highlight" search={props.text}>
            {option.iata}
          </Highlighter>
        </small>
      </div>
    ]}
    {...props}
  >
  </Typeahead>;
