import { API } from 'aws-amplify';
import React, { Component } from 'react';
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import LoaderButton from '../components/LoaderButton';
import "./Ideas.css";

const dayMap = {
  'sun': 'Sunday',
  'mon': 'Monday',
  'tue': 'Tuesday',
  'wed': 'Wednesday',
  'thu': 'Thursday',
  'fri': 'Friday',
  'sat': 'Saturday',
}

export default class Ideas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idea: null,
      plans: null,
      isLoading: true,
      isShopping: null,
      isDeleting: null
    };
  }

  formatDay(day) {
    const isoStr = new Date(day).toUTCString();
    return isoStr.replace(/(\w+), (\d{2}) (\w+) (\d{4}).*/, '$1, $3 $2');
  }

  async componentDidMount() {
    try {
      const idea = await this.getIdea();
      this.setState({idea});

      const crawls = await this.getCrawls();
      const plans = crawls.map(crawl => {
        crawl.crawledAt = new Date(crawl.crawledAt).toLocaleString();
        crawl.depart = this.formatDay(crawl.depart);
        crawl.goback = this.formatDay(crawl.goback);
        return crawl;
      });
      this.setState({plans, isLoading: false});
    } catch (e) {
      alert(e);
    }
  }

  getIdea() {
    return API.get('ideas', `/ideas/${this.props.match.params.id}`);
  }

  getCrawls(idea) {
    return API.get('ideas', `/ideas/${this.props.match.params.id}/crawls`);
  }

  crawlIdea(idea) {
    return API.post('ideas', `/ideas/${this.props.match.params.id}/crawls`);
  }

  deleteNote() {
    return API.del('ideas', `/ideas/${this.props.match.params.id}`);
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isShopping: true });

    try {
      await this.crawlIdea();
    } catch (e) {
      alert(e);
    } finally {
      this.setState({ isShopping: false });
    }
  }

  handleDelete = async event => {
    event.preventDefault();

    if (!window.confirm('Are you sure you want to delete this note?')) {
      return;
    }
    this.setState({ isDeleting: true });

    try {
      await this.deleteNote();
      this.props.history.push("/");
    } catch (e) {
      alert(e);
      this.setState({ isDeleting: false });
    }
  }

  inBudget(plan) {
    const planPrice = Number(plan.minPrice.slice(1));
    return planPrice <= this.state.idea.budget;
  }

  renderPlansList(plans) {
    return plans.map(plan => {
      let sep = plan.minPrice[0] === '$' ? 'for' : '-';
      let item = (
        <ListGroupItem
          key={plan.tripId}
          header={`${plan.depart} - ${plan.goback} ${sep} ${plan.minPrice}.`}
          className={this.inBudget(plan) ? 'winner' : 'loser'}
        >
          {"Shopped: " + plan.crawledAt}
        </ListGroupItem>
      );

      if (plan.minPrice === 'No flights') {
        return item;
      }
      return <LinkContainer
        key={plan.tripId}
        to={`/ideas/${this.state.idea.ideaId}/plans/${plan.tripId}`}
      >
        {item}
      </LinkContainer>
    });
  }

  render() {
    let inner = '';
    if (this.state.idea) {
      let idea = this.state.idea;
      let depart = dayMap[idea.depart];
      let goback = dayMap[idea.goback];
      let monthWord = idea.horizon === 1 ? 'month' : 'months';
      let formatted = `${idea.origin}->${idea.destination}, leaving on ${depart} and returning on ${goback}, for less than $${idea.budget}, and no later than ${idea.horizon} ${monthWord} out.`;
      inner = (
        <form onSubmit={this.handleSubmit}>
          <div>
            {formatted}
          </div>
          <div className="plans">
            <ListGroup>
              {!this.state.isLoading && this.renderPlansList(this.state.plans)}
            </ListGroup>
          </div>
          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            type="submit"
            isLoading={this.state.isShopping}
            text="Shop"
            loadingText="Shopping…"
          />
          <LoaderButton
            block
            bsStyle="danger"
            bsSize="large"
            isLoading={this.state.isDeleting}
            onClick={this.handleDelete}
            text="Delete"
            loadingText="Deleting…"
          />
        </form>
      );
    }
    return (
      <div className="Ideas">{inner}</div>
    );
  }
}
