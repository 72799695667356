import { API } from "aws-amplify";
import React, { Component } from 'react';
import { ControlLabel, Form, FormGroup, FormControl, InputGroup } from 'react-bootstrap';

import AirportAutocomplete from '../components/AirportAutocomplete';
import LoaderButton from '../components/LoaderButton';
import './NewIdea.css';

export default class NewIdea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: null,
      origin: '',
      destination: '',
      depart: 'fri',
      goback: 'sun',
      budget: '',
      horizon: '3'
    };
  }

  validateForm() {
    return this.state.origin &&
      this.state.destination &&
      this.state.origin.iata.length > 0 &&
      this.state.destination.iata.length > 0 &&
      this.state.budget.length > 0 &&
      this.state.horizon.length > 0 &&
      this.state.horizon >= 1 && this.state.horizon <= 6;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });

    try {
      await this.createIdea({
        origin: this.state.origin.iata,
        destination: this.state.destination.iata,
        depart: this.state.depart,
        goback: this.state.goback,
        budget: Number(this.state.budget),
        horizon: Number(this.state.horizon),
      });
      this.props.history.push("/");
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  }

  async createIdea(idea) {
    return API.post("ideas", "/ideas", {
      body: idea,
    });
  }

  render() {
    return (
      <div className="NewIdea">
        <h2>Travel Idea</h2>
        <Form onSubmit={this.handleSubmit}>
          <FormGroup controlId="origin" bsSize="large">
            <ControlLabel>Leave from</ControlLabel>
            <AirportAutocomplete
              autoFocus
              id="origin-auto"
              placeholder="Where from?"
              value={this.state.origin}
              onChange={sel => this.handleChange({target: {id: 'origin', value: sel[0]}})}
            />
          </FormGroup>
          <FormGroup controlId="destination" bsSize="large">
            <ControlLabel>To eventually visit</ControlLabel>
            <AirportAutocomplete
              id="destination-auto"
              placeholder="Where to?"
              value={this.state.destination}
              onChange={sel => this.handleChange({target: {id: 'destination', value: sel[0]}})}
            />
          </FormGroup>
          <FormGroup controlId="depart" bsSize="large">
            <ControlLabel>Depart on</ControlLabel>
            <FormControl componentClass="select" onChange={this.handleChange}>
              <option value="fri">Friday</option>
              <option value="sat">Saturday</option>
              <option value="sun">Sunday</option>
              <option value="mon">Monday</option>
              <option value="tue">Tuesday</option>
              <option value="wed">Wednesday</option>
              <option value="thu">Thursday</option>
            </FormControl>
          </FormGroup>
          <FormGroup controlId="goback" bsSize="large">
            <ControlLabel>Return on</ControlLabel>
            <FormControl componentClass="select" onChange={this.handleChange}>
              <option value="sun">Sunday</option>
              <option value="mon">Monday</option>
              <option value="tue">Tuesday</option>
              <option value="wed">Wednesday</option>
              <option value="thu">Thursday</option>
              <option value="fri">Friday</option>
              <option value="sat">Saturday</option>
            </FormControl>
          </FormGroup>
          <FormGroup controlId="budget" bsSize="large">
            <ControlLabel>Budget</ControlLabel>
            <InputGroup>
              <InputGroup.Addon>$</InputGroup.Addon>
              <FormControl
                type="tel"
                value={this.state.budget}
                onChange={this.handleChange}
              />
              <InputGroup.Addon>.00</InputGroup.Addon>
            </InputGroup>
          </FormGroup>
          <FormGroup controlId="horizon" bsSize="large">
            <ControlLabel>Within</ControlLabel>
            <InputGroup>
              <FormControl
                type="tel"
                value={this.state.horizon}
                onChange={this.handleChange}
              />
              <InputGroup.Addon>months</InputGroup.Addon>
            </InputGroup>
          </FormGroup>
          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Create"
            loadingText="Creating…"
          />
        </Form>
      </div>
    );
  }
}
