import { API } from "aws-amplify";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import "./Home.css";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      ideas: []
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    await this.loadIdeas();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.isAuthenticated || !this.props.isAuthenticated) {
      return;
    }
    await this.loadIdeas();
  }

  async loadIdeas() {
    try {
      const ideas = await this.ideas();
      this.setState({ ideas });
    } catch (e) {
      alert(e);
    }
    this.setState({ isLoading: false });
  }

  ideas() {
    return API.get('ideas', '/ideas');
  }

  renderIdeasList(ideas) {
    return [{}].concat(ideas).map(
      (idea, i) =>
        i !== 0
          ? <LinkContainer
              key={idea.ideaId}
              to={`/ideas/${idea.ideaId}`}
            >
              <ListGroupItem header={`${idea.origin}->${idea.destination} for less than $${idea.budget}.`}>
                {"Created: " + new Date(idea.createdAt).toLocaleString()}
              </ListGroupItem>
            </LinkContainer>
          : <LinkContainer
              key="new"
              to="/ideas/new"
            >
              <ListGroupItem>
                <h4>
                  <b>{"\uFF0B"}</b> New travel idea
                </h4>
              </ListGroupItem>
            </LinkContainer>
    );
  }

  renderLander() {
    return (
      <div className="Home">
        <div className="lander">
          <h1>Whenever</h1>
          <p>Travel Eventually</p>
          <div>
            <Link to="/login" className="btn btn-info btn-lg">
              Login
            </Link>
            <Link to="/signup" className="btn btn-success btn-lg">
              Signup
            </Link>
          </div>
        </div>
      </div>
    );
  }

  renderIdeas() {
    return (
      <div className="ideas">
        <ListGroup>
          {!this.state.isLoading && this.renderIdeasList(this.state.ideas)}
        </ListGroup>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated ? this.renderIdeas() : this.renderLander()}
      </div>
    );
  }
}