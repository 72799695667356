export default {
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_Gi8D88vuO",
    APP_CLIENT_ID: "15th8iom8aj0o3nebvs0laa6pr",
    IDENTITY_POOL_ID: "us-west-2:8ed51665-682c-40c3-8598-5560a02b83c3"
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://wnx7ir9hwb.execute-api.us-west-2.amazonaws.com/dev"
  }
};
