import { API } from 'aws-amplify';
import React, { Component, Fragment } from 'react';
import { ListGroup, ListGroupItem } from "react-bootstrap";

import "./Plans.css";

const dayMap = {
  'sun': 'Sunday',
  'mon': 'Monday',
  'tue': 'Tuesday',
  'wed': 'Wednesday',
  'thu': 'Thursday',
  'fri': 'Friday',
  'sat': 'Saturday',
}

export default class Plans extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idea: null,
      plan: null,
      isLoading: true,
    };
  }

  async componentDidMount() {
    try {
      const idea = await this.getIdea();
      const plan = await this.getPlan();
      this.setState({idea, plan, isLoading: false});
    } catch (e) {
      alert(e);
    }
  }

  getIdea() {
    return API.get('ideas', `/ideas/${this.props.match.params.id}`);
  }

  getPlan(idea) {
    const params = this.props.match.params;
    return API.get('ideas', `/ideas/${params.id}/crawls/${params.tripId}`);
  }

  inBudget(plan) {
    const planPrice = Number(plan.price.slice(1));
    return planPrice <= this.state.idea.budget;
  }

  renderFlightsList(flights) {
    return flights.map(flight => {
      let sep = flight.price[0] === '$' ? 'for' : '-';
      return <ListGroupItem
        key={flight.tripId}
        header={`${flight.times} ${sep} ${flight.price}.`}
        className={this.inBudget(flight) ? 'winner' : 'loser'}
      >
        {`${flight.carriers}, ${flight.stops}`}
      </ListGroupItem>
      }
    );
  }

  render() {
    let inner = '';
    if (this.state.idea) {
      let idea = this.state.idea;
      let depart = dayMap[idea.depart];
      let goback = dayMap[idea.goback];
      let monthWord = idea.horizon === 1 ? 'month' : 'months';
      let formatted = `${idea.origin}->${idea.destination}, leaving on ${depart} and returning on ${goback}, for less than $${idea.budget}, and no later than ${idea.horizon} ${monthWord} out.`;
      inner = (
        <Fragment>
          <div>
            {formatted}
          </div>
          <div className="plans">
            <ListGroup>
              {!this.state.isLoading && this.renderFlightsList(this.state.plan.flights)}
            </ListGroup>
          </div>
        </Fragment>
      );
    }
    return (
      <div className="Plans">{inner}</div>
    );
  }
}
